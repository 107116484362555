// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_1vx4E{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.container_1vx4E .title_3H1mA{font-family:\"Hiragino Kaku Gothic Pro\";font-size:16px;font-weight:600;color:#555}.container_1vx4E .list_3j2qe,.container_1vx4E .list_3j2qe .item_2KQlz{display:flex;flex-direction:column;grid-gap:5px;gap:5px}.container_1vx4E .list_3j2qe .item_2KQlz{padding:10px;border-radius:5px;font-family:\"Hiragino Kaku Gothic Pro\";font-size:14px;font-weight:600;color:#555}.container_1vx4E .list_3j2qe .item_2KQlz.status0_KlNHA{padding:2px 10px;font-weight:400;color:#999}.container_1vx4E .list_3j2qe .item_2KQlz.status1_3-hxa{background:#eff5fb}.container_1vx4E .list_3j2qe .item_2KQlz.status1_3-hxa .label_1J4qT{background:#3379b7}.container_1vx4E .list_3j2qe .item_2KQlz.status2_2sM0h{background:#fcf6ee}.container_1vx4E .list_3j2qe .item_2KQlz.status2_2sM0h .label_1J4qT{background:#de9b36}.container_1vx4E .list_3j2qe .item_2KQlz .support_1HTBY{display:flex;justify-content:space-between;grid-gap:10px;gap:10px}.container_1vx4E .list_3j2qe .item_2KQlz .support_1HTBY .label_1J4qT{padding:2px 5px;word-break:keep-all;display:flex;align-items:center;justify-content:center;border-radius:2px;width:34px;height:22px;font-size:12px;font-weight:600;color:#fff}.container_1vx4E .list_3j2qe .item_2KQlz .areas_2q_ao{display:flex;flex-wrap:wrap}.container_1vx4E .list_3j2qe .item_2KQlz .areas_2q_ao .area_3aZVA,.container_1vx4E .list_3j2qe .item_2KQlz .areas_2q_ao .delimiter_3Olz_{word-break:keep-all;font-family:\"Hiragino Kaku Gothic Pro\";font-size:13px;font-weight:400;color:#555}.container_1vx4E .list_3j2qe .item_2KQlz .areas_2q_ao .delimiter_3Olz_:last-child{display:none}.container_1vx4E .list_3j2qe .item_2KQlz .etc_2G3d-{font-family:\"Hiragino Kaku Gothic Pro\";font-size:13px;font-weight:400;color:#555}", ""]);
// Exports
exports.locals = {
	"container": "container_1vx4E",
	"title": "title_3H1mA",
	"list": "list_3j2qe",
	"item": "item_2KQlz",
	"status0": "status0_KlNHA",
	"status1": "status1_3-hxa",
	"label": "label_1J4qT",
	"status2": "status2_2sM0h",
	"support": "support_1HTBY",
	"areas": "areas_2q_ao",
	"area": "area_3aZVA",
	"delimiter": "delimiter_3Olz_",
	"etc": "etc_2G3d-"
};
module.exports = exports;
