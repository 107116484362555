// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".block_VMlQA{cursor:pointer;padding-bottom:10px;flex-direction:column}.block_VMlQA,.block_VMlQA .upper_3fvWs{display:flex;justify-content:space-between;grid-gap:10px;gap:10px}.block_VMlQA .upper_3fvWs .step_2eLYQ{display:flex;align-items:flex-start;grid-gap:10px;gap:10px}.block_VMlQA .upper_3fvWs .step_2eLYQ .img_2ej0y{display:none;align-items:center;justify-content:center}.block_VMlQA .upper_3fvWs .step_2eLYQ .img_2ej0y .checked_28Uut{display:none}.block_VMlQA .upper_3fvWs .step_2eLYQ p{text-overflow:ellipsis;font-size:16px;font-weight:600;transition:all .1s ease-out}.block_VMlQA .upper_3fvWs .count_McOGz,.block_VMlQA .upper_3fvWs .step_2eLYQ p{color:#999;color:var(--text-40,#999);font-family:Hiragino Kaku Gothic Pro;font-style:normal;line-height:normal}.block_VMlQA .upper_3fvWs .count_McOGz{font-size:12px;font-weight:400}.block_VMlQA .bar_3Up-O{height:2px;width:100%;background:#d6d5d4;background:var(--Grey-40,#d6d5d4);transition:all .1s ease-out}.block_VMlQA.active_3f24A{pointer-events:none}.block_VMlQA.active_3f24A .step_2eLYQ p{color:#3379b7;color:var(--primary-60,#3379b7)}.block_VMlQA.active_3f24A .bar_3Up-O{background:#3379b7;background:var(--primary-60,#3379b7)}.block_VMlQA:hover .step_2eLYQ p{color:#3379b7;color:var(--primary-60,#3379b7)}.block_VMlQA:hover .bar_3Up-O{background:#3379b7;background:var(--primary-60,#3379b7)}.block_VMlQA.submitted_3oPJA .step_2eLYQ .img_2ej0y{display:flex;align-items:center;justify-content:center;height:100%}.block_VMlQA.submitted_3oPJA .step_2eLYQ .img_2ej0y .checked_28Uut{display:block}.block_VMlQA.submitted_3oPJA .step_2eLYQ p{color:#1ac08f;color:var(--green-60,#1ac08f)}.block_VMlQA.submitted_3oPJA .bar_3Up-O{background:#1ac08f;background:var(--green-60,#1ac08f)}", ""]);
// Exports
exports.locals = {
	"block": "block_VMlQA",
	"upper": "upper_3fvWs",
	"step": "step_2eLYQ",
	"img": "img_2ej0y",
	"checked": "checked_28Uut",
	"count": "count_McOGz",
	"bar": "bar_3Up-O",
	"active": "active_3f24A",
	"submitted": "submitted_3oPJA"
};
module.exports = exports;
