// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".navigation_hxQ3l{padding:30px 40px 20px;background:#f3f2f2;background:var(--background-grey,#f3f2f2);width:100%}.navigation_hxQ3l .title_3KsGt{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.navigation_hxQ3l .title_3KsGt h1{color:#2c3846;color:var(--text-black,#2c3846);font-size:24px;font-weight:600;line-height:130%}.navigation_hxQ3l .title_3KsGt .description_BLcAq,.navigation_hxQ3l .title_3KsGt h1{font-family:Hiragino Kaku Gothic Pro;font-style:normal}.navigation_hxQ3l .title_3KsGt .description_BLcAq{color:#454545;color:var(--text-100,#454545);font-size:14px;font-weight:400;line-height:normal}.navigation_hxQ3l .steps_3wzQv{margin-top:30px;display:grid;grid-gap:10px;gap:10px}@media(min-width:700px){.navigation_hxQ3l .steps_3wzQv{grid-template-columns:1fr 1fr}}", ""]);
// Exports
exports.locals = {
	"navigation": "navigation_hxQ3l",
	"title": "title_3KsGt",
	"description": "description_BLcAq",
	"steps": "steps_3wzQv"
};
module.exports = exports;
