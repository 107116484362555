// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_fwrUz{margin-top:60px;margin-bottom:20px;text-align:center;font-size:20px;font-weight:600;letter-spacing:0;color:#002853}.content_8ioPH{margin-top:20px;padding-bottom:60px;overflow-y:scroll;width:100%}.content_8ioPH::-webkit-scrollbar{display:none}.content_8ioPH .drag_drop_area_1roHc{position:relative;justify-content:space-between;width:100%;background-color:#e9edf1;border-radius:10px;border:2px dashed #38597d;padding:30px}.content_8ioPH .drag_drop_area_1roHc,.content_8ioPH .drag_drop_area_1roHc .left_20VZU{display:flex;align-items:center}.content_8ioPH .drag_drop_area_1roHc .left_20VZU .description_aVJgt{margin-left:20px;font-size:14px;line-height:14px}.content_8ioPH .drag_drop_area_1roHc .upload_button_24-PQ{display:flex;align-items:center;justify-content:center;background:#224d74;border-radius:34px;width:158px;height:40px;font-size:16px;line-height:16px;font-weight:600;color:#fff}.content_8ioPH .drag_drop_area_1roHc .input_I3YH_{cursor:pointer;position:absolute;left:0;top:0;opacity:0;display:block;width:100%;height:100%}.content_8ioPH .buttons_1swva{margin-top:30px;display:flex;justify-content:center}.content_8ioPH .buttons_1swva .submit_button_1yi41{cursor:pointer;display:flex;align-items:center;justify-content:center;width:200px;height:40px;background:transparent linear-gradient(259deg,#00a7a7,#00a1c7 49%,#0065cb);border-radius:30px;font-size:14px;line-height:14px;font-weight:600;color:#fff}", ""]);
// Exports
exports.locals = {
	"title": "title_fwrUz",
	"content": "content_8ioPH",
	"drag_drop_area": "drag_drop_area_1roHc",
	"left": "left_20VZU",
	"description": "description_aVJgt",
	"upload_button": "upload_button_24-PQ",
	"input": "input_I3YH_",
	"buttons": "buttons_1swva",
	"submit_button": "submit_button_1yi41"
};
module.exports = exports;
