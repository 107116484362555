













































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposalStatus } from '~/Interfaces'
@Component
export default class ClientProposalStatusLabel extends Vue {
  @Prop()
  private connectProposalStatus!: IConnectProposalStatus
}
