

















































































































import {Component, Vue, Prop, Emit} from 'nuxt-property-decorator'
import {ModalStore} from '~/utils/store-accessor'
import IConnectCompanyMember from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMember'
import ProfileConnectCompanyMemberDeleteModal
  from '~/components/common/profile/connectCompany/profile-connect-company-member-delete-modal.vue'
import SnackBar from '~/components/SnackBar.vue'
import HighlightText from "~/components/common/profile/connectCompany/highlight-text.vue";
import ConnectSnackbar from "~/components/common/components/ConnectSnackbar.vue";
import draggable from "vuedraggable";
import dayjs from "dayjs";

@Component({
  components: {draggable, ConnectSnackbar, SnackBar, ProfileConnectCompanyMemberDeleteModal, HighlightText},
})
export default class ProfileConnectCompanyMembers extends Vue {
  @Prop()
  private connectCompanyMembers!: IConnectCompanyMember[]

  @Prop()
  private searchWords!: string[]

  @Prop({default: false})
  private isClient!: boolean

  @Prop()
  private isRenewal!: boolean

  @Prop()
  private isRenewalPreview!: boolean

  private showContent = true
  private drag = false

  mounted() {
    if (this.isClient) {
      this.showContent = false

      const members = <HTMLDivElement>this.$refs.members;
      if (members.scrollHeight > 650) {
        members.style.maxHeight = members.scrollHeight + 'px';
      } else {
        this.showContent = true
      }
    }
  }

  get dragOptions(): object {
    return {
      animation: 200,
      group: 'description',
      disabled: false,
    }
  }

  private async onDragEnd() {
    await this.$services.companyMember.sort({
      connectCompanyMemberHashIds: this.connectCompanyMembers.map(
        (member) => member.connectCompanyMemberHashId
      ),
    })
    this.drag = false
  }

  get needForEmptyMemberBoxCount() {
    if (this.isClient) {
      return 0
    }

    if (this.connectCompanyMembers.length > 3) {
      return 0
    }

    return 3 - this.connectCompanyMembers.length
  }

  showProfileConnectCompanyMemberDeleteModal(
    connectCompanyMemberHashId: string
  ) {
    ModalStore.show(
      'ProfileConnectCompanyMemberDeleteModal' + connectCompanyMemberHashId
    )
  }

  goMemberDetailPage(connectCompanyMemberHashId: string) {
    if (this.isRenewal) {
      this.$router.push(
        '/company/profile/connect_company/members/detail/' + connectCompanyMemberHashId
      )
      return
    }
  }

  private formatDate(date: string, format: string): string {
    return dayjs(date).format(format);
  }

  @Emit()
  deleteMemberSnackBarLeave() {
  }
}
