// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".attachment_1J7mj{margin-top:30px;display:flex;justify-content:flex-end;align-items:center}.attachment_1J7mj.thumbnail_3P2Fh{justify-content:flex-start}.attachment_1J7mj:not(.thumbnail_3P2Fh) a{text-decoration:none;display:flex;align-items:center}.attachment_1J7mj:not(.thumbnail_3P2Fh) a span{margin-left:7px}", ""]);
// Exports
exports.locals = {
	"attachment": "attachment_1J7mj",
	"thumbnail": "thumbnail_3P2Fh"
};
module.exports = exports;
