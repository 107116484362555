



















import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class PartnerBanner extends Vue {
  get isVisible() {
    return !this.$route.path.includes('/edit')
  }
}
