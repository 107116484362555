













import { Component, Prop, Vue } from 'nuxt-property-decorator'

import ThreadMessageAdmin from './components/ThreadMessageAdmin/index.vue'
import ThreadMessageOther from './components/ThreadMessageOther/index.vue'
import ThreadMessageSelf from './components/ThreadMessageSelf/index.vue'
import { TopicStore } from '~/utils/store-accessor'
import { IThreadDetail, IThreadGroupAccount } from '~/Interfaces'
@Component({
  components: {
    ThreadMessageAdmin,
    ThreadMessageOther,
    ThreadMessageSelf,
  },
})
export default class ThreadMessage extends Vue {
  @Prop()
  private threadDetail!: IThreadDetail

  get threadGroupAccount(): IThreadGroupAccount | null {
    return TopicStore.getThreadGroupAccountSelf
  }

  mounted() {}

  private isSelf(): boolean {
    const account = this.threadGroupAccount
    if (account === null) {
      return false
    }

    return (
      this.threadDetail.threadDetailWriterGroupHashId ===
      account.sourceGroupHashIdSelf
    )
  }

  private isOther(): boolean {
    const account = this.threadGroupAccount
    if (account === null) {
      return false
    }

    return (
      this.threadDetail.threadDetailWriterGroupHashId ===
      account.sourceGroupHashIdOther
    )
  }

  private isAdmin(): boolean {
    return this.threadDetail.threadDetailWriterAccountType === 'admin'
  }

  private isVisible(): boolean {
    if (this.threadDetail.threadDetailVisibleGroupHashId === null) {
      return true
    }

    return (
      this.threadDetail.threadDetailVisibleGroupHashId ===
      this.threadGroupAccount?.sourceGroupHashIdSelf
    )
  }
}
