import {
  ConstantValue,
  IConnectCompanyConstants,
  IConnectCompanyProfile,
  IConnectCompanySupport,
} from '~/Interfaces'
import AchievementValidateService from '~/components/Profile/ConnectCompany/Achievement/AchievementValidateService'
import EditFeaturePage from '~/pages/company/profile/connect_company/features/edit.vue'
import EditSupportSystemPage from '~/pages/company/profile/connect_company/support_system/edit.vue'
import EditRepresentativePage from '~/pages/company/profile/connect_company/representative/edit.vue'

export default class ConnectCompanyProfileValidator {
  value: IConnectCompanyProfile | undefined
  private preferredCustomerOrProductConstants: ConstantValue[]
  private connectCompanyConstants: IConnectCompanyConstants
  private supportConstants: ConstantValue[]
  private companyName: string | undefined | null
  private companyAddress: string | undefined | null

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(
    connectCompanyProfile: IConnectCompanyProfile | undefined,
    preferredCustomerOrProductConstants: ConstantValue[],
    supportConstants: ConstantValue[],
    connectCompanyConstants: IConnectCompanyConstants,
    companyName: string | undefined | null,
    companyAddress: string | undefined | null
  ) {
    this.connectCompanyConstants = connectCompanyConstants
    this.value = connectCompanyProfile
    this.preferredCustomerOrProductConstants = preferredCustomerOrProductConstants
    this.supportConstants = supportConstants
    this.companyName = companyName
    this.companyAddress = companyAddress
  }

  private isEmpty(value: any): boolean {
    if (value === undefined || value === '' || value === null) {
      return true
    }

    return false
  }

  get validateAll(): boolean {
    if (!this.validateInfo) {
      return false
    }
    if (!this.validateFeature) {
      return false
    }
    if (!this.validateSupportAchievements) {
      return false
    }
    if (!this.validateSupport) {
      return false
    }
    if (!this.validateSupportSystem) {
      return false
    }
    if (!this.validateRepresentative) {
      return false
    }

    return true
  }

  // @TODO Serviceに切り出すなどして、二重管理を防ぐため共通化する
  // @SEE nuxt/src/pages/company/profile/connect_company/info/edit.vue
  get validateInfo(): boolean {
    if (this.value === undefined) {
      return false
    }
    if (this.isEmpty(this.companyName)) {
      return false
    }
    if (this.isEmpty(this.companyAddress)) {
      return false
    }
    if (
      this.isEmpty(this.value.info?.invoiceNumber) &&
      this.isEmpty(this.value.info?.isIssuingInvoiceNumber)
    ) {
      return false
    }

    return true
  }

  // @TODO Serviceに切り出すなどして、二重管理を防ぐため共通化する
  // @SEE nuxt/src/pages/company/profile/connect_company/features/edit.vue
  get validateFeature(): boolean {
    if (this.value === undefined) {
      return false
    }
    if (!this.value.features) {
      return false
    }

    if (this.value.features.length === 0) {
      return false
    }

    const feature = this.value.features[0]
    if (this.isEmpty(feature.featureTitle)) {
      return false
    }
    if (
      feature.featureTitle!.length > EditFeaturePage.FEATURE_TITLE_MAXLENGTH
    ) {
      return false
    }

    if (this.isEmpty(feature.featureDetail)) {
      return false
    }
    if (
      feature.featureDetail!.length > EditFeaturePage.FEATURE_DETAIL_MAXLENGTH
    ) {
      return false
    }

    return true
  }

  get validateSupportAchievements(): boolean {
    if (this.value === undefined) {
      return false
    }
    if (!this.value.achievement) {
      return false
    }

    if (this.value.achievement.achievements.length === 0) {
      return false
    }

    const constants = this.value.achievement.constants

    const errors = this.value.achievement.achievements
      .map((achievement) => {
        const achievementValidateService = new AchievementValidateService()
        return achievementValidateService.validate(achievement, constants)
      })
      .filter((error) => {
        return error === false
      })

    return errors.length === 0
  }

  // @TODO Serviceに切り出すなどして、二重管理を防ぐため共通化する
  // @SEE nuxt/src/pages/company/profile/connect_company/support/edit.vue
  get validateSupport(): boolean {
    if (this.value === undefined) {
      return false
    }

    // @MEMO: 対応可能な支援
    if (!this.value.supports) {
      return false
    }

    const supports = this.value.supports
    if (supports.length === 0) {
      return false
    }

    // @MEMO: 得意を10件以上選択している
    if (this.countSupportPreferred() > 10) {
      return false
    }

    const filteredSupportConstants = this.supportConstants.filter(
      (supportConstant: ConstantValue) => {
        const support: IConnectCompanySupport | undefined = supports.find(
          (support: IConnectCompanySupport) => {
            return support.supportId === supportConstant.value
          }
        )

        if (support === undefined) {
          return false
        }

        // @TODO: IDを直接指定しているのを修正する
        // @MEMO: 訪問営業（飛び込み営業）
        if (supportConstant.value === 4) {
          if (support.supportAreas.length === 0) {
            return false
          }
        }
        // @MEMO: 対面商談
        if (supportConstant.value === 19) {
          if (support.supportAreas.length === 0) {
            return false
          }
        }

        // @MEMO: 架電の録音
        if (supportConstant.value === 41) {
          if (this.isEmpty(support.supportEtc)) {
            return false
          }
        }
        // @MEMO: 商談の録画
        if (supportConstant.value === 42) {
          if (this.isEmpty(support.supportEtc)) {
            return false
          }
        }
        // @MEMO: SFA/CRMへの記録
        if (supportConstant.value === 43) {
          if (this.isEmpty(support.supportEtc)) {
            return false
          }
        }
        return true
      }
    )

    if (filteredSupportConstants.length === 0) {
      return false
    }

    // @MEMO: 得意な販売先・商材
    const constantETCs = this.preferredCustomerOrProductConstants.filter(
      (constant: ConstantValue) => {
        return constant.label === 'その他'
      }
    )

    // @MEMO: その他の内容チェック
    if (constantETCs.length > 0) {
      // @MEMO: 得意な販売先・商材にチェックしていない場合はスルー
      if (this.value.preferredCustomerOrProducts === undefined) {
        return true
      }

      const othersEmpties = this.value.preferredCustomerOrProducts.filter(
        (value) => {
          const othersEmpty = value.preferredValues.filter((preferredValue) => {
            if (
              constantETCs.find((constant) => constant.value === preferredValue)
            ) {
              if (this.isEmpty(value.preferredOthers)) {
                return true
              }
            }
            return false
          })

          return othersEmpty.length > 0
        }
      )

      return othersEmpties.length === 0
    }

    return true
  }

  private countSupportPreferred(): number {
    if (!this.value) {
      return 0
    }

    let supportCount = 0
    if (this.value.supports) {
      supportCount = this.value.supports.reduce((acc, cur) => {
        if (cur.supportStatus === 2) {
          return acc + 1
        }
        return acc
      }, 0)
    }

    let preferredCustomerOrProductsCount = 0
    if (this.value.preferredCustomerOrProducts) {
      preferredCustomerOrProductsCount = this.value.preferredCustomerOrProducts.reduce(
        (acc, cur) => {
          return acc + cur.preferredValues.length
        },
        0
      )
    }

    return supportCount + preferredCustomerOrProductsCount
  }

  // @TODO Serviceに切り出すなどして、二重管理を防ぐため共通化する
  // @SEE nuxt/src/pages/company/profile/connect_company/features/edit.vue
  get validateSupportSystem(): boolean {
    if (this.value === undefined) {
      return false
    }
    if (!this.value.supportSystem) {
      return false
    }

    if (this.isEmpty(this.value.supportSystem.activityType)) {
      return false
    }
    if (this.isEmpty(this.value.supportSystem.workingStyle)) {
      return false
    }
    if (
      (this.value.supportSystem.feature?.length ?? 0) >
      EditSupportSystemPage.FEATURE_MAXLENGTH
    ) {
      return false
    }

    return true
  }

  // @TODO Serviceに切り出すなどして、二重管理を防ぐため共通化する
  // @SEE nuxt/src/pages/company/profile/connect_company/representative/edit.vue
  get validateRepresentative(): boolean {
    if (this.value === undefined) {
      return false
    }
    if (this.value.info === undefined) {
      return false
    }

    if (
      (this.value.representative?.profile ?? 0) >
      EditRepresentativePage.PROFILE_MAXLENGTH
    ) {
      return false
    }

    // @MEMO: 最終学歴
    if (!this.value.info.connectCompanyEducation) {
      return false
    }
    if (this.isEmpty(this.value.info.connectCompanyEducation.schoolName)) {
      return false
    }
    if (this.isEmpty(this.value.info.connectCompanyEducation.major)) {
      return false
    }
    if (this.isEmpty(this.value.info.connectCompanyEducation.graduateYear)) {
      return false
    }
    if (this.isEmpty(this.value.info.connectCompanyEducation.graduateMonth)) {
      return false
    }

    // @MEMO: 職務経歴
    if (this.value.info.connectCompanyCareers.length === 0) {
      return false
    }

    const filtered = this.value.info.connectCompanyCareers.filter(
      (connectCompanyCareer) => {
        if (this.isEmpty(connectCompanyCareer.startDate)) {
          return false
        }

        // @MEMO: 終了日が空で現在継続中にチェックが入っていない場合
        if (
          this.isEmpty(connectCompanyCareer.endDate) &&
          connectCompanyCareer.isOnGoing === false
        ) {
          return false
        }

        // @MEMO: 開始日が終了日よりも後の場合
        if (
          !this.isEmpty(connectCompanyCareer.endDate) &&
          connectCompanyCareer.startDate > connectCompanyCareer.endDate!
        ) {
          return false
        }

        if (this.isEmpty(connectCompanyCareer.companyName)) {
          return false
        }

        // @MEMO: その他の場合
        const etc = this.connectCompanyConstants.employment.find(
          (employment) => {
            return employment.isEtc === true
          }
        )

        if (
          etc &&
          connectCompanyCareer.employment === etc.id &&
          this.isEmpty(connectCompanyCareer.employmentEtc)
        ) {
          return false
        }

        return true
      }
    )

    return filtered.length > 0
  }
}
