import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectRenewalAggregate from '~/Interfaces/IConnectRenewalAggregate'
import IConnectContractRenewal from '~/Interfaces/IConnectContractRenewal'

export default class ConnectRenewalService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * @param connect_proposal_hash_id
   * @param is_client
   */
  async find(
    connect_proposal_hash_id: string,
    is_client: boolean = false
  ): Promise<IConnectRenewalAggregate | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      let response
      if (is_client) {
        response = await this.axios.get(
          'api/renew/client/find/' + connect_proposal_hash_id
        )
      } else {
        response = await this.axios.get(
          'api/renew/company/find/' + connect_proposal_hash_id
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   * @param connect_proposal_hash_id
   */
  async request(
    connect_proposal_hash_id: string,
    text: string
  ): Promise<IConnectRenewalAggregate | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.post(
        'api/renew/client/request/' + connect_proposal_hash_id,
        { text }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   * @param connectProposalHashId
   */
  async requestFromPartner(
    connectProposalHashId: string
  ): Promise<IConnectRenewalAggregate | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.post(
        'api/renew/company/request/' + connectProposalHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   * @param connectProductHashId
   * @param is_client
   */
  async findProposal(
    connectProductHashId: string,
    is_client: boolean = false
  ): Promise<IConnectRenewalAggregate | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      let response
      if (is_client) {
        response = await this.axios.get(
          'api/renew/client/find/' + connectProductHashId
        )
      } else {
        response = await this.axios.get(
          'api/renew/company/find/proposal/' + connectProductHashId
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   * 更新最新
   */
  async findLast(
    connectProposalHashId: string | undefined
  ): Promise<IConnectContractRenewal | null> {
    if (connectProposalHashId === null) {
      return null
    }

    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.get(
        'api/renew/company/find/last/' + connectProposalHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return null
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }

  /**
   * 更新キャンセル
   */
  async cancel(
    connectContractRenewalHashId: string,
    isClient: boolean = false
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.connect)
    try {
      if (isClient) {
        await this.axios.post(
          'api/renew/client/delete/' + connectContractRenewalHashId
        )
      } else {
        await this.axios.post(
          'api/renew/company/delete/' + connectContractRenewalHashId
        )
      }

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }
}
