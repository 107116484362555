// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".degree_WK1-j{max-width:100%;display:flex;align-items:center;grid-gap:5px;gap:5px;color:#555;color:var(--Text-60,#555);font-style:normal}.degree_WK1-j>img{width:18px}.degree_WK1-j>div{font-size:13px;font-weight:600;line-height:1;white-space:nowrap;overflow-x:hidden;text-overflow:ellipsis;margin-top:1px}", ""]);
// Exports
exports.locals = {
	"degree": "degree_WK1-j"
};
module.exports = exports;
