// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".upload_image_1X4NQ{border-radius:50%}.upload_image_1X4NQ.circle_2mcBK{width:24px;height:24px;border-radius:50%}.upload_image_1X4NQ.small_fcRsX{width:40px;height:40px;font-size:20px}.upload_image_1X4NQ.large_HEkXI{width:100px;height:100px;font-size:34px}.upload_image_1X4NQ.proposal_card_2S1s5{width:60px;height:60px;border-radius:5px;font-size:34px}.upload_image_1X4NQ.proposal_detail_3-PyB{width:80px;height:80px;border-radius:5px;font-size:34px}", ""]);
// Exports
exports.locals = {
	"upload_image": "upload_image_1X4NQ",
	"circle": "circle_2mcBK",
	"small": "small_fcRsX",
	"large": "large_HEkXI",
	"proposal_card": "proposal_card_2S1s5",
	"proposal_detail": "proposal_detail_3-PyB"
};
module.exports = exports;
