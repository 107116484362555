// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".label_3SF7b{display:flex;width:55px;min-width:55px;height:25px;justify-content:center;align-items:center;border-radius:5px;font-size:12px;font-style:normal;font-weight:600;line-height:normal}", ""]);
// Exports
exports.locals = {
	"label": "label_3SF7b"
};
module.exports = exports;
