









import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IPartnerSideMenuLink } from '~/Interfaces'

@Component
export default class PartnerSideMenuLink extends Vue {
  @Prop()
  private sideMenuLink!: IPartnerSideMenuLink
}
