















































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { ModalStore } from '~/utils/store-accessor'
import ConnectButton from '~/components/common/components/ConnectButton.vue'

@Component({
  components: {
    ConnectButton,
  },
})
export default class ConnectV2ConfirmModal extends Vue {
  @Prop()
  private name!: string

  @Prop()
  private title!: string

  @Prop()
  private cancel!: string

  @Prop()
  private submit!: string

  @Prop()
  private hideClose!: boolean

  @Prop()
  private width!: string

  @Prop({ default: false })
  private disableSubmit!: boolean

  @Prop({ default: false })
  private disableTeleport!: boolean

  @Prop({ default: false })
  private disableClose!: boolean

  get isVisible(): boolean {
    return ModalStore.shouldDisplay(this.name)
  }

  get style(): string {
    return `width: ${this.width}`
  }

  get titleId(): string {
    return Math.random().toString(32).substring(2)
  }

  get descriptionId(): string {
    return Math.random().toString(32).substring(2)
  }

  private onClose() {
    ModalStore.hide(this.name)
  }

  @Emit('cancel')
  private onCancel() {
    this.onClose()
  }

  @Emit('submit')
  private onSubmit() {
    this.onClose()
  }

  @Watch('isVisible')
  private teleport(newValue: boolean) {
    if (this.disableTeleport || !newValue) {
      return
    }

    setTimeout(() => {
      const modal = this.$refs.modal as HTMLElement
      const body = document.querySelector('body')
      if (modal && body) {
        body.appendChild(modal)
      }
    }, 301)
  }
}
