// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_1n-4A{display:flex;flex-direction:column;align-items:flex-start;grid-gap:10px;gap:10px}.container_1n-4A .header_DQyj_{font-size:12px;font-weight:600;font-family:\"Hiragino Kaku Gothic Pro\";color:#555}.container_1n-4A .buttons_ys0BG{overflow:clip;display:flex;justify-content:space-evenly;height:35px;max-width:600px;width:100%;border:1px solid #afadac;border-radius:100px}.container_1n-4A .buttons_ys0BG .button_IzwVr{cursor:pointer;display:flex;align-items:center;justify-content:center;grid-gap:8px;gap:8px;max-width:200px;width:100%;font-size:12px;font-weight:600;font-family:\"Hiragino Kaku Gothic Pro\";color:#454545}.container_1n-4A .buttons_ys0BG .button_IzwVr.center_3mSz6{border-left:1px solid #afadac;border-right:1px solid #afadac}.container_1n-4A .buttons_ys0BG .button_IzwVr.active_1R10q{pointer-events:none;background:#e3eef7}.container_1n-4A .buttons_ys0BG .button_IzwVr:hover{background:#e3eef7}", ""]);
// Exports
exports.locals = {
	"container": "container_1n-4A",
	"header": "header_DQyj_",
	"buttons": "buttons_ys0BG",
	"button": "button_IzwVr",
	"center": "center_3mSz6",
	"active": "active_1R10q"
};
module.exports = exports;
