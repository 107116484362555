import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { IConnectProposal } from '../Interfaces'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'CurrentConnectProposal',
})
export default class CurrentConnectProposal extends VuexModule {
  currentConnectProposal: IConnectProposal | null = null

  @Mutation
  setCurrentConnectProposal(currentConnectProposal: IConnectProposal | null) {
    this.currentConnectProposal = currentConnectProposal
  }

  get getCurrentConnectProposal(): IConnectProposal | null {
    return this.currentConnectProposal
  }
}
