// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header_QX0x3{display:flex;justify-content:space-between;align-items:center;padding-bottom:20px}", ""]);
// Exports
exports.locals = {
	"header": "header_QX0x3"
};
module.exports = exports;
