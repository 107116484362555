import { render, staticRenderFns } from "./proposal-confirm-proposal-document-agreement-modal.vue?vue&type=template&id=85b73586&"
import script from "./proposal-confirm-proposal-document-agreement-modal.vue?vue&type=script&lang=ts&"
export * from "./proposal-confirm-proposal-document-agreement-modal.vue?vue&type=script&lang=ts&"
import style0 from "./proposal-confirm-proposal-document-agreement-modal.vue?vue&type=style&index=0&module=s&lang=scss&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/var/www/kakutoku_connect/nuxt/src/components/Form/Input.vue').default,FormModal: require('/var/www/kakutoku_connect/nuxt/src/components/common/modal/form-modal.vue').default})
