import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import { IGroups, ITopic } from '~/Interfaces'
import IProposalDocumentGroups from '~/Interfaces/Message/IProposalDocumentGroups'

export default class MessageService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * 商談する
   * @param proposal_hash_id
   */
  async openThread(proposal_hash_id: string): Promise<string | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post(
        'api/thread/connect_client/create_thread',
        {
          proposal_hash_id,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.threadHashId))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * @param proposal_hash_id
   * @param isClient
   */
  async findThreadHashId(
    proposal_hash_id: string,
    isClient = false
  ): Promise<string | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      let response

      if (isClient) {
        response = await this.axios.get(
          'api/thread/connect_client/thread_hash_id/' + proposal_hash_id
        )
      } else {
        response = await this.axios.get(
          'api/thread/connect_company/thread_hash_id/' + proposal_hash_id
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.threadHashId))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * 商談する
   * @param thread_hash_id
   * @param text
   */
  async createMessage(
    thread_hash_id: string,
    text: string,
    file: File | null
  ): Promise<[] | undefined> {
    LockStore.lock(LockStore.types.message)

    const headers = { 'content-type': 'multipart/form-data' }
    const data = new FormData()
    data.append('thread_hash_id', thread_hash_id)
    data.append('text', text)
    if (file !== null) {
      data.append('file', file)
    }

    try {
      const response = await this.axios.post(
        'api/thread/connect_client/detail',
        data,
        {
          headers,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * グループを取得する
   */
  async getGroups(): Promise<IGroups | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.get('api/thread/connect_client/groups')

      console.log(response)

      const thread_groups = JSON.parse(
        JSON.stringify(response.data.thread_groups)
      )
      const reads = JSON.parse(JSON.stringify(response.data.reads))

      return { thread_groups, reads }
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * トピックを取得する
   * @param thread_hash_id
   */
  async getTopic(thread_hash_id: string): Promise<ITopic | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.get(
        'api/thread/connect_client/topic/' + thread_hash_id
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.topic))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * 既読にする
   * @param thread_hash_id
   */
  async postRead(thread_hash_id: string): Promise<boolean> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post(
        'api/thread/connect_client/read/' + thread_hash_id
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  async agreeAttentionToClient(threadHashId: string): Promise<boolean> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post(
        'api/thread/connect_client/agree_attention/' + threadHashId
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  async getProposalDocumentList(
    proposal_hash_id: string
  ): Promise<Array<any> | null> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.get(
        'api/thread/proposal_document_list/' + proposal_hash_id
      )
      return JSON.parse(JSON.stringify(response.data.proposal_document_list))
    } catch (err) {
      console.log(err)
      return null
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }
}
