




































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import {
  TAchievementReviewConstants,
  TConnectCompanySupportAchievementReviewContent,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievementReview'

@Component({})
export default class PartnerProfileReviewContent extends Vue {
  @Prop()
  private content!: TConnectCompanySupportAchievementReviewContent

  @Prop()
  private constants!: TAchievementReviewConstants

  @Prop({ default: false })
  private isAchievementDetail!: boolean

  get companyScale(): string {
    if (!this.content) {
      return ''
    }

    const result = this.constants.companyScales.find(
      (obj) => obj.id === this.content?.companyScale
    )
    return result ? result.name : ''
  }

  get createdDate(): string {
    // @MEMO: プロフィール詳細画面の場合はYYYY年MM月の形で渡ってくるため
    if (this.isAchievementDetail) {
      return this.$services.day.getYmJa(this.content.createdAt)
    }

    return this.content.createdAt
  }
}
