// MEMO: `max:${number}`
export type Rule = 'required' | 'email' | 'zenkakuKataKana' | 'custom' | string

export type FormItem = {
  id: string
  name: string
  value: any
  rules?: Rule[]
  message: string
  validate: Function
  customValidate?: () => boolean
}

type FormItemFields = { [key: string]: FormItem['value'] }

export const validators: FormValidator[] = []

export class FormValidator {
  private readonly _formItems: FormItem[]

  constructor() {
    this._formItems = [] as FormItem[]
    validators.push(this)
  }

  static get() {
    if (validators.length) {
      return validators[0]
    }
    return new FormValidator()
  }

  static reset() {
    validators.splice(0, validators.length)
  }

  delete() {
    const index = validators.indexOf(this)
    if (index !== -1) {
      validators.splice(index, 1)
    }
  }

  get formItemFields(): FormItemFields {
    return this._formItems.reduce((acc, formItem) => {
      acc[formItem.name] = formItem.value
      return acc as FormItemFields
    }, {} as FormItemFields)
  }

  get errorFormItemFirst() {
    return this._formItems.find((formItem) => !this.validate(formItem.name))
  }

  focusItem() {
    const errorFormItem = this.errorFormItemFirst
    if (!errorFormItem) return

    const element = document.getElementById(errorFormItem.id)
    if (!element) return

    element.scrollIntoView({})
    setTimeout(() => {
      window.scrollTo(0, window.scrollY - 40)
    }, 200)
  }

  addFormItem(formItem: FormItem) {
    const index = this._formItems.findIndex(
      (item) => item.name === formItem.name
    )

    if (index === -1) {
      this._formItems.push(formItem)
    }
  }

  updateFormItem(name: FormItem['name'], value: FormItem['value']) {
    const index = this._formItems.findIndex((item) => item.name === name)
    if (index !== -1) {
      this._formItems[index].value = value
    }
  }

  updateFormRule(name: FormItem['name'], rules: FormItem['rules']) {
    const index = this._formItems.findIndex((item) => item.name === name)
    if (index !== -1) {
      this._formItems[index].rules = rules
    }
  }

  updateFormCustomRule(
    name: FormItem['name'],
    customValidate: FormItem['customValidate']
  ) {
    const index = this._formItems.findIndex((item) => item.name === name)
    if (index !== -1) {
      const rules: Rule[] = this._formItems[index]?.rules || []
      rules.push('custom')
      this._formItems[index].rules = rules
      this._formItems[index].customValidate = customValidate
    }
  }

  getFormItem(name: FormItem['name']) {
    return this._formItems.find((item) => item.name === name)
  }

  getFormItemValue(name: FormItem['name']) {
    const formItem = this._formItems.find((item) => item.name === name)
    return formItem?.value
  }

  message(name: FormItem['name']): string {
    const formItem = this._formItems.find((item) => item.name === name)
    return formItem?.message || ''
  }

  public validateAll(skipError?: boolean) {
    const inputs = document.querySelectorAll('input')
    inputs.forEach((input) => {
      if (!skipError) {
        input.dispatchEvent(new Event('blur'))
      }
    })

    const selects = document.querySelectorAll('select')
    selects.forEach((select) => {
      if (!skipError) {
        select.dispatchEvent(new Event('blur'))
      }
    })

    return this._formItems.every((formItem) => {
      if (!formItem.validate && typeof formItem.validate !== 'function') {
        return this.validate(formItem.name)
      }

      return formItem.validate()
    })
  }

  public validate(name: FormItem['name']) {
    const formItem = this.getFormItem(name)
    if (!formItem || !formItem.rules) {
      return true
    }

    return !!formItem.rules?.every((rule: Rule) => {
      switch (rule) {
        case 'required':
          return this.validateRequired(formItem)
        case 'email':
          return this.validateEmail(formItem)
        case 'zenkakuKataKana':
          return this.validateZenkakuKataKana(formItem)
        case 'custom':
          return formItem.customValidate ? formItem.customValidate() : true
        default:
          if (rule.match(/^max:\d+$/)) {
            return this.validateMaxLength(formItem)
          }
          console.warn(`Unknown rule: ${rule}`)
          return true
      }
    })
  }

  private validateRequired(formItem: FormItem) {
    if (!formItem.value) {
      formItem.message = '必ず入力してください'
      return false
    }

    return true
  }

  private validateEmail(formItem: FormItem) {
    // 必須判定はここでは行わない
    if (!formItem.value) {
      return true
    }

    // @ が含まれないとき
    if (!formItem.value.includes('@')) {
      formItem.message = `メールアドレスに「@」を挿入してください。「${formItem.value}」内に「@」がありません。`
      return false
    }

    // @ の後に文字列を含まないとき
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, afterAt] = formItem.value.split('@')
    if (!afterAt) {
      formItem.message = `「${formItem.value}」は完全なメールアドレスではありません。「@」に続く文字列を入力してください。`
      return false
    }

    return true
  }

  private validateZenkakuKataKana(formItem: FormItem) {
    // 必須判定はここでは行わない
    if (!formItem.value) {
      return true
    }

    if (!/^[\u30A0-\u30FF]+$/.test(formItem.value)) {
      formItem.message = '全角カタカナで入力してください'
      return false
    }

    return true
  }

  private validateMaxLength(formItem: FormItem) {
    // 必須判定はここでは行わない
    if (!formItem.value) {
      return true
    }

    const [_, max] =
      formItem.rules
        ?.find((rule: Rule) => /^max:\d+$/.test(rule))
        ?.split(':') || []

    if (formItem.value.length > Number(max)) {
      formItem.message = `${max}文字以内で入力してください`
      return false
    }
    return true
  }
}
