import { Context } from '@nuxt/types'
import { LockStore } from '~/utils/store-accessor'
import IConnectProductV2Master from '~/Interfaces/connectProduct/IConnectProductV2Master'

export default class ConnectProductV2Service {
  private readonly context: Context

  constructor(context: Context) {
    this.context = context
  }

  /**
   */
  async getMasterData(): Promise<IConnectProductV2Master | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.get(
        'api/connect_product/v2/master'
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }
}
