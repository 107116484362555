




































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import DetailLabelEnforce from '~/components/Profile/ConnectCompany/Achievement/Detail/DetailLabelEnforce.vue'
import DetailLabelFocus from '~/components/Profile/ConnectCompany/Achievement/Detail/DetailLabelFocus.vue'
import {
  TAchievementConstants,
  TConnectCompanySupportAchievement,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'
import DetailLabelUnEnforce from '~/components/Profile/ConnectCompany/Achievement/Detail/DetailLabelUnEnforce.vue'
import {
  TSupportButtonTemplates,
  TSupportButtonTemplateValue,
} from '~/Interfaces/connectCompany/ISupportItem'

@Component({
  components: { DetailLabelUnEnforce, DetailLabelFocus, DetailLabelEnforce },
})
export default class PartnerProfileAchievementDetailsPreview extends Vue {
  @Prop()
  achievement!: TConnectCompanySupportAchievement

  @Prop()
  constants!: TAchievementConstants

  @Prop({ default: false })
  showGuide!: boolean

  private detailStatus(detailId: number): number {
    const result = this.achievement.details.find(
      (obj) => obj.detailId === detailId
    )
    if (!result) {
      return 0
    }
    return result.status === undefined ? 0 : result.status
  }

  private detailRemark(detailId: number): string {
    const result = this.achievement.details.find(
      (obj) => obj.detailId === detailId
    )
    return result ? result.remark : ''
  }

  private areas(detailId: number): number[] {
    const result = this.achievement.details.find(
      (obj) => obj.detailId === detailId
    )

    return result?.areas ?? []
  }

  private selectedDetailArea(detailId: number, areaId: number): boolean {
    const result = this.achievement.details.find(
      (obj) => obj.detailId === detailId
    )

    return result ? result.areas.includes(areaId) : false
  }

  private isShowSubItems(value: TSupportButtonTemplateValue): boolean {
    if (!value.subItems) {
      return false
    }

    const status = this.detailStatus(value.id)
    return status !== 0
  }

  get achievementDetails(): TSupportButtonTemplates[][] {
    const leftDetails = this.constants.achievementDetails.filter(
      (obj) => obj.position === 'left'
    )

    const rightDetails = this.constants.achievementDetails.filter(
      (obj) => obj.position === 'right'
    )
    return [leftDetails, rightDetails]
  }
}
