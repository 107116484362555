






































































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import {
  ConnectClientProposalMemoStore,
  SnackbarStore,
} from '~/utils/store-accessor'
import IConnectClientProposalMemo from '~/Interfaces/IConnectClientProposalMemo'

@Component
export default class ClientProposalMemo extends Vue {
  @Prop()
  private clientMemo!: IConnectClientProposalMemo

  private memoData: string = ''
  private renderHelp: boolean = false

  private onClose() {
    if (this.isStartEditMemo) {
      if (
        !confirm(
          '入力内容が保存されない可能性があります。本当にメモを閉じますか？'
        )
      ) {
        return false
      }
    }

    this.memoData = ''
    ConnectClientProposalMemoStore.closeAll()
  }

  private onEditButton() {
    this.memoData = this.clientMemo?.memo
    ConnectClientProposalMemoStore.openEditor()
  }

  private async onSaveButton() {
    await this.$services.connectClientProposalMemo.write(
      this.clientMemo?.connectClientProposalMemoHashId,
      this.clientMemo?.connectProposalHashId,
      this.memoData
    )
    await this.refreshProposals()
    ConnectClientProposalMemoStore.closeEditor()
    SnackbarStore.show('client_memo')
  }

  private unloadConfirmEvent(event: BeforeUnloadEvent) {
    if (ConnectClientProposalMemoStore.isOpenEditor) {
      event.returnValue =
        '入力内容が保存されない可能性があります。ページを離れますか？'
    }
  }

  get isStartEditMemo() {
    return ConnectClientProposalMemoStore.isOpenEditor
  }

  created() {
    window.addEventListener('beforeunload', this.unloadConfirmEvent)
  }

  destroyed() {
    window.removeEventListener('beforeunload', this.unloadConfirmEvent)
  }

  @Emit('refreshProposals')
  private refreshProposals() {}
}
