// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2xmM5{display:inline-block;background:#f9f9f9;padding:20px;width:100%}.title_2xyGa{font-style:normal;font-weight:600;font-size:18px;line-height:130%;color:#2c3846;padding:0 0 20px}.list_1-nLJ{padding:0 0 0 22px;margin:0}.item_mJ17f{font-style:normal;font-weight:600;font-size:14px;line-height:21px;color:#2c3846;margin-bottom:2px}.item_mJ17f .support_1tKoJ{position:relative}.item_mJ17f .icon_3WXWZ{position:absolute;top:1px;left:-20px}.item_mJ17f .thin_iPxX7{font-weight:400;color:#b5bdbe}.item_mJ17f .etc_1agI_{background:#eaebeb;padding:5px;font-weight:600;font-size:12px;line-height:18px;color:#707070}.item_mJ17f .areas_1LSS5{background:#eaebeb;padding:10px;display:flex;flex-wrap:wrap;grid-gap:5px 10px;gap:5px 10px}.item_mJ17f .areas_1LSS5 .area_3gImx{font-weight:600;font-size:12px;line-height:18px;display:flex;justify-content:center;align-items:center;background:#f9f9f9;color:#999;padding:2px 10px}.item_mJ17f .areas_1LSS5 .area_3gImx.selected_17Yew{background:#7ca8d5;color:#fff}.item_mJ17f .areas_1LSS5 .spacer_3Sunm{width:100%}", ""]);
// Exports
exports.locals = {
	"container": "container_2xmM5",
	"title": "title_2xyGa",
	"list": "list_1-nLJ",
	"item": "item_mJ17f",
	"support": "support_1tKoJ",
	"icon": "icon_3WXWZ",
	"thin": "thin_iPxX7",
	"etc": "etc_1agI_",
	"areas": "areas_1LSS5",
	"area": "area_3gImx",
	"selected": "selected_17Yew",
	"spacer": "spacer_3Sunm"
};
module.exports = exports;
