
























import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ClientProductIdIcon extends Vue {
  @Prop()
  private colorIndex!: number

  @Prop()
  private size!:
    | 'small'
    | 'large'
    | 'circle'
    | 'mdCircle'
    | 'proposalCard'
    | 'proposalDetail'
}
