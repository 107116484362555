











import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: { ConnectIcon },
})
export default class ConnectFilterChip extends Vue {
  @Prop()
  private name!: string

  @Prop()
  number!: number

  @Prop()
  selected!: boolean

  @Prop()
  width: number | undefined

  get numberDisplay() {
    return this.number > 99 ? '99+' : this.number
  }
}
