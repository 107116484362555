// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper_33l7i{padding:20px;display:flex;flex-direction:column;grid-gap:10px;gap:10px;width:100%;border-radius:5px;background:#f3f2f2;background:var(--grey-20,#f3f2f2)}.wrapper_33l7i .title_3gTEV{display:flex;align-items:center;grid-gap:5px;gap:5px}.wrapper_33l7i .title_3gTEV span{font-weight:600}.wrapper_33l7i .description_22bI_,.wrapper_33l7i .title_3gTEV span{color:#555;color:var(--text-60,#555);font-family:Hiragino Kaku Gothic Pro;font-size:14px;font-style:normal;line-height:normal}.wrapper_33l7i .description_22bI_{font-weight:400}.wrapper_33l7i .table_30zAs{display:grid;grid-template-columns:auto 1fr 1fr 1fr 1fr;background:#fff;background:var(--background-white,#fff);border-bottom:1px solid #afadac;border-bottom:1px solid var(--grey-60,#afadac);border-right:1px solid #afadac;border-right:1px solid var(--grey-60,#afadac)}.wrapper_33l7i .table_30zAs .column_29VN3{display:grid;grid-template-rows:1fr 1fr 1fr 1fr}.wrapper_33l7i .table_30zAs .column_29VN3.type_Nf9KB{display:grid;grid-template-rows:1fr 2fr 1fr}.wrapper_33l7i .table_30zAs .column_29VN3.type_Nf9KB .box_2KIcH{display:flex}.wrapper_33l7i .table_30zAs .column_29VN3.type_Nf9KB .box_2KIcH .left_F5lIt{display:grid;grid-template-rows:1fr}.wrapper_33l7i .table_30zAs .column_29VN3.type_Nf9KB .box_2KIcH .right_AejmP{display:grid;grid-template-rows:1fr 1fr}.wrapper_33l7i .table_30zAs .column_29VN3 .cell_20dWf{padding:10px;display:flex;align-items:center;justify-content:flex-start;border-top:1px solid #afadac;border-top:1px solid var(--grey-60,#afadac);border-left:1px solid #afadac;border-left:1px solid var(--grey-60,#afadac);color:#555;color:var(--text-60,#555);font-family:Hiragino Kaku Gothic Pro;font-size:13px;font-style:normal;font-weight:600;line-height:19px}.wrapper_33l7i .table_30zAs .column_29VN3 .cell_20dWf.head_color_3_R-R{background:#ebebea;background:var(--grey-30,#ebebea)}", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper_33l7i",
	"title": "title_3gTEV",
	"description": "description_22bI_",
	"table": "table_30zAs",
	"column": "column_29VN3",
	"type": "type_Nf9KB",
	"box": "box_2KIcH",
	"left": "left_F5lIt",
	"right": "right_AejmP",
	"cell": "cell_20dWf",
	"head_color": "head_color_3_R-R"
};
module.exports = exports;
