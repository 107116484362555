// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".footer_1v2Q6{position:relative;bottom:0;display:flex;align-items:center;justify-content:center;grid-gap:20px;gap:20px;padding:21px;margin:0 auto;height:60px;width:100%;overflow:hidden}.footer_1v2Q6 .item_2-os1{white-space:nowrap;color:#555;color:var(--text-grey-dark,var(--text-60,#555));font-family:Hiragino Kaku Gothic Pro;font-size:12px;font-style:normal;font-weight:400;line-height:1.5}", ""]);
// Exports
exports.locals = {
	"footer": "footer_1v2Q6",
	"item": "item_2-os1"
};
module.exports = exports;
