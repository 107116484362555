













































































































import {Component, Vue, Prop, Emit} from "nuxt-property-decorator";
import IConnectSupportAchievementCategoryList from "~/Interfaces/connectMember/IConnectSupportAchievementCategoryList";
import Textarea from "~/components/Form/Textarea.vue";
import IConnectSupportAchievement from "~/Interfaces/connectMember/IConnectSupportAchievement";
import IConnectCompanySupportAchievement from "~/Interfaces/connectCompany/IConnectCompanySupportAchievement";
import {ConnectCompanyStore} from "~/utils/store-accessor";
import IConnectCompanySupportAchievementCategory
  from "~/Interfaces/connectCompany/IConnectCompanySupportAchievementCategory";
@Component({
  components: {Textarea}
})
export default class ProfileConnectMemberAchievementTemplate extends Vue {
  @Prop()
  private index!: number
  @Prop()
  private categoryList!: IConnectSupportAchievementCategoryList[];

  selected(index: number, i: number, categoryId: string)
  {
    if(this.achievement === undefined) {
      return false
    }
    if(this.achievement.connectCompanySupportAchievementCategories === undefined) {
      return false
    }
    if(this.achievement.connectCompanySupportAchievementCategories[i-1] === undefined) {
      return false
    }
    if(this.achievement.connectCompanySupportAchievementCategories[i-1].categoryId === parseInt(categoryId)) {
      this.$nextTick(() => {
        const select = <HTMLSelectElement>this.$parent.$el.querySelector('select[name=category_select_' + index + '' + i + ']')
        this.$set(select.dataset, 'selected', 'selected')
      })

      return true
    }

    return false
  }

  get overview(): string|null {
    if(this.achievement === undefined) {
      return null
    }

    return this.achievement.overview
  }

  private connectCompanySupportAchievementCategory(index: number): IConnectCompanySupportAchievementCategory| undefined
  {
    if(this.achievement == undefined) {
      return undefined
    }
    if(this.achievement.connectCompanySupportAchievementCategories == undefined) {
      return undefined
    }

    return this.achievement.connectCompanySupportAchievementCategories[index];
  }

  get achievement(): IConnectCompanySupportAchievement|undefined
  {
    if(this.achievements == undefined) {
      return undefined
    }

    if(this.achievements[this.index] == undefined) {
      return undefined
    }

    return this.achievements[this.index]
  }

  get achievements(): IConnectCompanySupportAchievement[]|undefined
  {
    const connectCompany = ConnectCompanyStore.getConnectCompany

    return connectCompany?.connectCompanySupportAchievements
  }

  mounted()
  {
    console.log(this.achievement)
  }
}
