











import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ConnectIcon } from '~/components'

@Component({
  components: {
    ConnectIcon,
  },
})
export default class ClientProposalListItem extends Vue {}
