import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import ICompanyProposal from '../Interfaces/ICompanyProposal'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'CompanyProposalStoreDefine',
})
export default class CompanyProposalStoreDefine extends VuexModule {
  companyProposals: ICompanyProposal[] = []

  @Mutation
  setCompanyProposals(companyProposals: ICompanyProposal[]) {
    this.companyProposals = companyProposals
  }

  get getCompanyProposals(): ICompanyProposal[] {
    return this.companyProposals
  }
}
