import { render, staticRenderFns } from "./ClientProductStatusIcon.vue?vue&type=template&id=7031d666&"
import script from "./ClientProductStatusIcon.vue?vue&type=script&lang=ts&"
export * from "./ClientProductStatusIcon.vue?vue&type=script&lang=ts&"
import style0 from "./ClientProductStatusIcon.vue?vue&type=style&index=0&lang=scss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports