import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  TAchievementConstants,
  TConnectCompanySupportAchievement,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'

export default class ConnectClientSupportAchievementService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async fetchAchievement(
    connectCompanyHashId: string,
    connectCompanySupportAchievementHashId: string
  ): Promise<TConnectCompanySupportAchievement | undefined> {
    try {
      const response = await this.axios.get<TConnectCompanySupportAchievement>(
        `api/client/connect_company/${connectCompanyHashId}/support_achievement/${connectCompanySupportAchievementHashId}`
      )
      return response.data as TConnectCompanySupportAchievement
    } catch (err) {
      console.log(err)
    }
  }

  async fetchConstants(
    connectCompanyHashId: string
  ): Promise<TAchievementConstants | undefined> {
    try {
      const response = await this.axios.get<TAchievementConstants>(
        `api/client/connect_company/${connectCompanyHashId}/support_achievement/constants`
      )
      return response.data as TAchievementConstants
    } catch (err) {
      console.log(err)
    }
  }
}
