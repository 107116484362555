






import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import ConnectV2Input from '~/components/common/components/v2/ConnectV2Input.vue'
import { FormValidator } from '~/components'

type ConnectFormItem = ConnectV2Input

@Component
export default class ConnectV2Form extends Vue {
  @Prop()
  private skipValidation!: boolean

  @Prop()
  private id!: string

  private formValidator!: FormValidator

  mounted() {
    this.formValidator = FormValidator.get()
  }

  beforeDestroy() {
    this.formValidator.delete()
  }

  get componentId(): string {
    return this.id || Math.random().toString(32).substring(2)
  }

  private onSubmit() {
    if (this.validateAll()) {
      console.log('isValid: true')
      this.submit()
    } else {
      console.log('isValid: false')
      this.error()
    }
  }

  validateAll() {
    return this.formValidator.validateAll()
  }

  @Emit('submit')
  submit() {}

  @Emit('error')
  error() {}
}
