// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textarea_2X_PK{width:100%;background:#fff;border:1px solid #71a8d9;border-radius:2px;padding:18px 16px;resize:vertical}.textarea_2X_PK:-moz-placeholder-shown{background:#e4f0fc}.textarea_2X_PK:-ms-input-placeholder{background:#e4f0fc}.textarea_2X_PK:placeholder-shown{background:#e4f0fc}.textarea_2X_PK.textareaError_QzNoo{background:#ffe6f2!important;border:1px solid #c94282!important}.hint_12ZOM{font-style:normal;font-weight:400;font-size:13px;line-height:20px;color:#707070;padding:0 0 10px;white-space:pre-line}.error_fpyBI{color:#c94282;padding:10px 0 0}.characterCount_1FMZR,.error_fpyBI{font-style:normal;font-weight:400;font-size:12px;line-height:18px}.characterCount_1FMZR{color:#707070;padding:5px 0 0}.characterCount_1FMZR.error_fpyBI{color:#c94282}", ""]);
// Exports
exports.locals = {
	"textarea": "textarea_2X_PK",
	"textareaError": "textareaError_QzNoo",
	"hint": "hint_12ZOM",
	"error": "error_fpyBI",
	"characterCount": "characterCount_1FMZR"
};
module.exports = exports;
