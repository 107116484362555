// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fade_active_1RJvT{transition:opacity .3s ease-out}.fade_leave_1okjh{opacity:0}.template_2ODPd .layout_xlNie{position:fixed;z-index:10;top:0;left:0;cursor:auto;display:flex;align-items:center;justify-content:center;height:100%;width:100%}.template_2ODPd .thread_form_template_WuIFy{position:absolute;z-index:11;bottom:30px;left:0;cursor:auto;padding:20px 61px 20px 31px;background:#fff;box-shadow:0 3px 6px rgba(0,0,0,.16078);border-radius:6px}", ""]);
// Exports
exports.locals = {
	"fade_active": "fade_active_1RJvT",
	"fade_leave": "fade_leave_1okjh",
	"template": "template_2ODPd",
	"layout": "layout_xlNie",
	"thread_form_template": "thread_form_template_WuIFy"
};
module.exports = exports;
