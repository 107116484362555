// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textarea_3Je-3{padding:13px;background:#fff;border:1px solid #71a8d9;border-radius:2px;font-size:14px;font-weight:400;letter-spacing:0}.textarea_3Je-3:-moz-placeholder-shown{background:#e4f0fc}.textarea_3Je-3:-ms-input-placeholder{background:#e4f0fc}.textarea_3Je-3:placeholder-shown{background:#e4f0fc}.textarea_3Je-3::-moz-placeholder{color:#aabdcc}.textarea_3Je-3:-ms-input-placeholder{color:#aabdcc}.textarea_3Je-3::placeholder{color:#aabdcc}.textarea_3Je-3.error_26Y3X{border:1px solid #c94282!important;background:#ffe6f2;color:#002853}.error_alert_QWoIs{margin-top:8px;text-align:left;font-size:12px;color:#c94282}", ""]);
// Exports
exports.locals = {
	"textarea": "textarea_3Je-3",
	"error": "error_26Y3X",
	"error_alert": "error_alert_QWoIs"
};
module.exports = exports;
