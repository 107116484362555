import { render, staticRenderFns } from "./ClientProposalLarge.vue?vue&type=template&id=24255e14&"
import script from "./ClientProposalLarge.vue?vue&type=script&lang=ts&"
export * from "./ClientProposalLarge.vue?vue&type=script&lang=ts&"
import style0 from "./ClientProposalLarge.vue?vue&type=style&index=0&module=s&lang=scss&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClientProductStatusIcon: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProductStatusIcon.vue').default,ClientProductIdIconWrapper: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProductIdIconWrapper.vue').default,ProposalFavoriteButton: require('/var/www/kakutoku_connect/nuxt/src/components/Proposal/proposal-favorite-button.vue').default,HighlightText: require('/var/www/kakutoku_connect/nuxt/src/components/common/profile/connectCompany/highlight-text.vue').default,ClientProposalRejectModal: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposalRejectModal.vue').default,ClientUrgeProfileModal: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientUrgeProfileModal.vue').default,ClientConfirmModal: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientConfirmModal.vue').default})
