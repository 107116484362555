// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".enter_active_sW6Mh{transition:opacity .3s}.leave_active_INuxz{opacity:0}.layout_1OUVz{display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;height:100%;width:100%;z-index:12;background:rgba(14,39,60,.30196)}.layout_1OUVz .modal_wrapper_33FlG{border-radius:10px;max-height:85vh;overflow:hidden;position:relative}.layout_1OUVz .modal_wrapper_33FlG.max_height_95vh_1F8IC,.layout_1OUVz .modal_wrapper_33FlG.max_height_95vh_1F8IC .modal_2DJAT{max-height:95vh}.layout_1OUVz .modal_wrapper_33FlG .modal_2DJAT{overflow-y:auto;max-height:85vh;border-radius:10px;display:flex;flex-flow:column;align-items:center;padding:0 40px 60px;width:680px;background:#fff;box-shadow:0 2px 40px rgba(0,0,0,.16078)}.layout_1OUVz .modal_wrapper_33FlG .modal_2DJAT.scroll_y_rCxAP{overflow-y:scroll}.layout_1OUVz .modal_wrapper_33FlG .modal_2DJAT.bottom_padding_40px_1klXb{padding-bottom:40px}.layout_1OUVz .modal_wrapper_33FlG .modal_2DJAT.bottom_padding_0px_3SP83{padding-bottom:0}.layout_1OUVz .modal_wrapper_33FlG .modal_2DJAT.padding_width_50px_DKTMb{padding-left:50px;padding-right:50px}.layout_1OUVz .modal_wrapper_33FlG .modal_2DJAT .close_button_3pD3D{cursor:pointer;position:absolute;top:25px;right:25px;text-align:right;font-size:24px;color:#cfd9eb}", ""]);
// Exports
exports.locals = {
	"enter_active": "enter_active_sW6Mh",
	"leave_active": "leave_active_INuxz",
	"layout": "layout_1OUVz",
	"modal_wrapper": "modal_wrapper_33FlG",
	"max_height_95vh": "max_height_95vh_1F8IC",
	"modal": "modal_2DJAT",
	"scroll_y": "scroll_y_rCxAP",
	"bottom_padding_40px": "bottom_padding_40px_1klXb",
	"bottom_padding_0px": "bottom_padding_0px_3SP83",
	"padding_width_50px": "padding_width_50px_DKTMb",
	"close_button": "close_button_3pD3D"
};
module.exports = exports;
