import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectClientProfileStoreDefine',
})
export default class ConnectClientProfileStoreDefine extends VuexModule {
  private ignorePromote: boolean = false

  @Mutation
  setIgnorePromote() {
    this.ignorePromote = true
  }

  @Mutation
  resetIgnorePromote() {
    this.ignorePromote = false
  }

  get getIgnorePromote(): boolean {
    return this.ignorePromote
  }
}
