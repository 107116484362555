














import {Component, Vue, Prop, Emit} from "nuxt-property-decorator";
import {SnackbarStore} from "~/store";

/**
 * SnackBar
 */
@Component
export default class SnackBar extends Vue {
  @Prop()
  private message!: string

  @Prop()
  private name!: string

  private setAfterEnter()
  {
    setTimeout( () => {
      SnackbarStore.hide(this.name)
      this.snackBarLeave()
    }, 2500)
  }

  get shouldDisplay()
  {
    return SnackbarStore.shouldDisplay(this.name)
  }

  @Emit('snackBarLeave')
  snackBarLeave(){}
}
