


































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Paging from '~/components/common/connect/paging.vue'

@Component({
  components: { Paging },
})
export default class SupportSystemLabel extends Vue {
  @Prop()
  private type!: 'activityType' | 'workingStyleString'

  @Prop()
  private label!: string

  @Prop()
  private value!: number

  get splitLabel(): string[] {
    return this.label.split(' ')
  }

  get existsValue(): boolean {
    return this.value !== null && this.value !== undefined
  }
}
