// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".card_l3J_x{text-decoration:none;display:flex;flex-direction:column;min-width:260px;width:260px;height:302px;border-radius:10px;border:1px solid #d6d5d4;box-shadow:0 1px 4px rgba(0,0,0,.12)}.card_l3J_x:hover{cursor:pointer;opacity:.8}.card_l3J_x .image_3Q324{height:144px}.card_l3J_x .image_3Q324 img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover;border-top-left-radius:10px;border-top-right-radius:10px}.card_l3J_x .bottom_1FnF3{padding:10px;display:flex;flex-direction:column;grid-gap:8px;gap:8px}.card_l3J_x .bottom_1FnF3 .title_3yTiC{-webkit-line-clamp:2;font-family:\"Hiragino Sans\";font-size:14px;font-weight:700;color:#454545}.card_l3J_x .bottom_1FnF3 .description_2Sftm,.card_l3J_x .bottom_1FnF3 .title_3yTiC{word-break:break-all;overflow:hidden;display:-webkit-box;text-overflow:ellipsis;-webkit-box-orient:vertical}.card_l3J_x .bottom_1FnF3 .description_2Sftm{-webkit-line-clamp:3;font-family:\"Hiragino Kaku Gothic Pro\";font-size:13px;font-weight:600;color:#555}.card_l3J_x .bottom_1FnF3 .link_34qiy{display:flex;align-items:center;grid-gap:5px;gap:5px}.card_l3J_x .bottom_1FnF3 .link_34qiy span{word-break:break-all;overflow:hidden;display:-webkit-box;text-overflow:ellipsis;-webkit-box-orient:vertical;-webkit-line-clamp:1;font-family:\"Hiragino Sans\";font-size:12px;font-weight:400;color:#454545}", ""]);
// Exports
exports.locals = {
	"card": "card_l3J_x",
	"image": "image_3Q324",
	"bottom": "bottom_1FnF3",
	"title": "title_3yTiC",
	"description": "description_2Sftm",
	"link": "link_34qiy"
};
module.exports = exports;
