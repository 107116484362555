// @ts-nocheck
export default function ({ $auth, $admin }) {
  $auth.onRedirect((to, from) => {
    console.log('[!] auth redirect from: ' + from)
    console.log('[!] auth redirect to: ' + to)
    console.log('[!] auth strategy name: ' + $auth.strategy.name)

    // TOP やログインページからの遷移は redirect_uri を設定しない
    let query = ''
    if (
      !from.match('/login/') &&
      from !== '/' &&
      from !== '/company/product' &&
      from !== '/client/product'
    ) {
      query = `?redirect_uri=${from}`
    }

    /**
     * login page
     */
    if (to === '/login') {
      if (from.match('/client/')) {
        return '/client/login' + query
      }
      if (from.match('/company/')) {
        return '/partner/login' + query
      }
      if ($auth.strategy.name === 'client') {
        return '/client/login' + query
      }
      if ($auth.strategy.name === 'company') {
        return '/partner/login' + query
      }
      if ($auth.strategy.name === 'admin_client') {
        const companyHashId = $admin.getCompanyHashId()
        return `/client/profile/connect_company/${companyHashId}`
      }
    }

    /**
     * after login
     */
    if (from.match('/client_survey/')) {
      return from
    }

    if ($auth.loggedIn === true) {
      if (to === '/' && $auth.strategy.name === 'company') {
        return '/company/product'
      }

      if (to === '/' && $auth.strategy.name === 'client') {
        return '/client/product'
      }
    }
  })
}
