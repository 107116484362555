















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IThreadFlags, IThreadGroupAccount } from '~/Interfaces'

@Component
export default class ServiceIcon extends Vue {
  @Prop()
  private threadFlags!: IThreadFlags | undefined

  @Prop({ default: undefined })
  private accountSelf!: IThreadGroupAccount | undefined

  @Prop()
  private inTopic!: boolean
}
