// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".enterActive_EW59U{transition:opacity .3s}.leaveActive_Khe-g{opacity:0}.wrapper_d3LIt{display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;height:100%;width:100%;z-index:1000;background:rgba(0,0,0,.5)}.modal_2RAQf{position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);padding:60px 40px;background:#fff;box-shadow:0 0 20px rgba(109,132,155,.3);border-radius:10px;text-align:center;margin:0 auto;z-index:1001;max-width:720px}.close_2Fgg4{position:absolute;background:none;border:none;cursor:pointer;padding:0;margin:0;width:32px;height:32px;border-radius:50%;top:11px;right:11px}.close_2Fgg4 .icon_2CBZK{color:#7ca8d5;font-size:18px}", ""]);
// Exports
exports.locals = {
	"enterActive": "enterActive_EW59U",
	"leaveActive": "leaveActive_Khe-g",
	"wrapper": "wrapper_d3LIt",
	"modal": "modal_2RAQf",
	"close": "close_2Fgg4",
	"icon": "icon_2CBZK"
};
module.exports = exports;
