









































import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
@Component
export default class FormModal extends Vue {
  @Prop()
  private close_button_margin_small!: boolean

  @Prop()
  private width_680px!: boolean

  @Prop()
  private height_434px!: boolean

  @Prop()
  private height_initial!: boolean

  @Prop({ default: null })
  private paddingBottom!: string | null

  @Prop({ default: false })
  private closeButtonDisplayNone!: boolean

  @Emit('closeModal')
  private closeModal() {}
}
