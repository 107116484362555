





































































































































































































































































































































































































































































































































































































































import { Component, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import {
  IConnectCompanyConstants,
  IConnectCompanyFeature,
  IConnectCompanyMember,
  IConnectCompanyPreferredCustomerOrProduct,
  IConnectCompanySupport,
  IConnectCompanySupportAchievement,
} from '~/Interfaces'
import {
  EmptyStateText,
  FeatureLabel,
  PartnerDescription,
  PartnerSubtitle,
  PreferredItems,
  SupportAchievement,
  SupportItems,
  SupportSystemLabel,
  SupportUsage,
  InfoIcon,
} from '~/components/common/profile/partner'
import ConnectButton from '~/components/common/components/ConnectButton.vue'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import ProfileConnectCompanyMembers from '~/components/common/profile/connectCompany/profile-connect-company-members.vue'
import { TConnectCompanySupportAchievement } from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'
import ConnectCompanyProfile from '~/components/Client/profile/ConnectCompanyProfile'
import { ConnectCompanyProfilesStore, ModalStore } from '~/utils/store-accessor'
import MemberListRow from '~/components/Profile/ConnectCompany/Member/MemberListRow.vue'
import PreviewMemberDetail from '~/components/Profile/ConnectCompany/Member/PreviewMemberDetail.vue'
import PartnerProfileSupportAchievement from '~/components/common/profile/partner/PartnerProfile/PartnerProfileSupportAchievement.vue'
import PartnerProfileFeature from '~/components/common/profile/partner/PartnerProfile/PartnerProfileFeature.vue'
import PartnerProfilePreferredItems from '~/components/common/profile/partner/PartnerProfile/PartnerProfilePreferredItems.vue'
import PartnerProfileCareerModal from '~/components/common/profile/partner/PartnerProfile/PartnerProfileCareerModal.vue'
import PartnerProfileSupportItems from '~/components/common/profile/partner/PartnerProfile/PartnerProfileSupportItems.vue'
import PartnerProfileReviewContent from '~/components/common/profile/partner/PartnerProfile/PartnerProfileReviewContent.vue'
import PartnerProfileAchievementDetailModal from '~/components/common/profile/partner/PartnerProfile/PartnerProfileAchievementDetailModal.vue'
import PartnerProfileCaseInterviewCards from '~/components/common/profile/partner/PartnerProfile/PartnerProfileCaseInterviewCards.vue'

@Component({
  components: {
    PartnerProfileCaseInterviewCards,
    PartnerProfileAchievementDetailModal,
    PreviewMemberDetail,
    MemberListRow,
    PartnerProfileReviewContent,
    PartnerProfileSupportItems,
    PartnerProfileCareerModal,
    PartnerProfilePreferredItems,
    PartnerProfileFeature,
    PartnerProfileSupportAchievement,
    InfoIcon,
    ConnectButton,
    ConnectIcon,
    EmptyStateText,
    FeatureLabel,
    PartnerDescription,
    PartnerSubtitle,
    PreferredItems,
    ProfileConnectCompanyMembers,
    SupportAchievement,
    SupportItems,
    SupportSystemLabel,
    SupportUsage,
  },
})
export default class PartnerProfile extends Vue {
  @Prop()
  private companyHashId!: string

  @Prop({ default: false })
  private isClient!: boolean

  private isVisibleAchievement: boolean = false
  private isVisibleCareer: boolean = false
  private isVisibleMember: boolean = false
  private isVisibleAchievementDetail: boolean = false
  private selectedAchievement: IConnectCompanySupportAchievement | null = null
  private isVisibleReview: boolean = false
  private selectedNewAchievement: TConnectCompanySupportAchievement | null = null
  private showAllMembersFlag: boolean = false

  private memberForModal: IConnectCompanyMember | null = null

  private REVIEW_CONTENT_LIMIT: number = 2
  private ACHIEVEMENT_CONTENT_LIMIT: number = 5
  private MEMBER_CONTENT_LIMIT: number = 5

  showDegreeHelpModal() {
    ModalStore.show('DegreeHelpModal')
  }

  showSupportSystemHelpModal() {
    ModalStore.show('SupportSystemHelpModal')
  }

  get connectCompanyProfile(): ConnectCompanyProfile | undefined {
    return ConnectCompanyProfilesStore.findByCompanyHashId(this.companyHashId)
  }

  get connectCompanyConstants(): IConnectCompanyConstants {
    return ConnectCompanyProfilesStore.getConstants!
  }

  get connectCompany() {
    return this.connectCompanyProfile?.value.info
  }

  get activityType() {
    return this.connectCompanyProfile?.value.supportSystem?.activityType
  }

  get activityTypeString() {
    return this.connectCompanyProfile?.value.supportSystem?.activityTypeString
  }

  get workingStyleString() {
    return this.connectCompanyProfile?.value.supportSystem?.workingStyleString
  }

  get achievementCount() {
    return this.connectCompanyProfile?.value.achievement
      ? this.connectCompanyProfile?.value.achievement.achievements.length
      : 0
  }

  get existsAchievement() {
    return (
      this.connectCompanyProfile?.value.achievement &&
      this.connectCompanyProfile?.value.achievement.achievements.length > 0
    )
  }

  get achievements() {
    if (!this.existsAchievement) {
      return []
    }

    return this.isVisibleAchievement
      ? this.connectCompanyProfile?.value.achievement?.achievements
      : this.connectCompanyProfile?.value.achievement?.achievements.slice(
          0,
          this.ACHIEVEMENT_CONTENT_LIMIT
        )
  }

  get isVisibleAchievementMoreButton() {
    if (this.achievementCount > this.ACHIEVEMENT_CONTENT_LIMIT) {
      return true
    }

    return false
  }

  private onClickAchievement(hash: string) {
    const result = (this.achievements ?? []).find(
      (obj: TConnectCompanySupportAchievement) => obj.hash === hash
    )
    if (result) {
      this.selectedNewAchievement = result
      this.isVisibleAchievementDetail = true
    }
  }

  get reviewCount(): number {
    if (
      !this.connectCompanyProfile?.value.review ||
      !this.connectCompanyProfile?.value.review.contents
    ) {
      return 0
    }

    return this.connectCompanyProfile?.value.review.contents.length
  }

  get existsReviewContents(): boolean {
    if (
      !this.connectCompanyProfile?.value.review ||
      !this.connectCompanyProfile?.value.review.contents
    ) {
      return false
    }

    return this.connectCompanyProfile?.value.review.contents.length >= 1
  }

  get reviewContents() {
    if (!this.existsReviewContents) {
      return []
    }

    return this.isVisibleReview
      ? this.connectCompanyProfile?.value.review?.contents
      : this.connectCompanyProfile?.value.review?.contents.slice(
          0,
          this.REVIEW_CONTENT_LIMIT
        )
  }

  get isVisibleReviewMoreButton() {
    if (this.reviewCount > this.REVIEW_CONTENT_LIMIT) {
      return true
    }

    return false
  }

  get isVisibleSupport() {
    return (
      this.connectCompanyProfile?.value.supports &&
      this.connectCompanyProfile?.value.supports?.length > 0
    )
  }

  get isVisiblePreferred() {
    return this.connectCompanyProfile?.value.preferredCustomerOrProducts?.some(
      (p: IConnectCompanyPreferredCustomerOrProduct) =>
        p.preferredValues.length > 0
    )
  }

  get isVisibleSystem() {
    return (
      this.activityTypeString ||
      this.workingStyleString ||
      (this.connectCompanyProfile?.value.members?.length ?? 0) > 0 ||
      this.connectCompanyProfile?.value.supportSystem?.feature
    )
  }

  get isVisibleFeatures() {
    if (this.connectCompanyProfile?.value.features) {
      const feature = this.connectCompanyProfile?.value.features.find(
        (f: IConnectCompanyFeature) => f.featureOrder === 1
      )

      if (!feature) return false

      return feature.featureTitle && feature.featureDetail
    }

    return false
  }

  get isVisiblePolicy() {
    return (
      !!this.connectCompanyProfile?.value.policy?.executionQualityAssurance ??
      !!this.connectCompanyProfile?.value.policy?.strategyAccuracy
    )
  }

  get isVisibleDegreeCompanies() {
    if (this.connectCompanyProfile?.isExistsDegreeCompanies) {
      return this.connectCompanyProfile?.value.degreeCompanies!.length > 0
    }

    return false
  }

  private getSupport(categoryId: number) {
    const list = [8, 17, 19, 26, 28, 34, 40, 44, 53, 56]
    let startId = -1
    if (list[categoryId - 1]) {
      startId = list[categoryId - 1]
    }
    return this.connectCompanyProfile?.value.supports
      ? this.connectCompanyProfile?.value.supports.filter(
          (s: IConnectCompanySupport) =>
            s.supportId > startId && s.supportId <= list[categoryId]
        )
      : []
  }

  private getPreferred(categoryId: number) {
    return (
      this.connectCompanyProfile?.value.preferredCustomerOrProducts ?? []
    ).find(
      (p: IConnectCompanyPreferredCustomerOrProduct) =>
        p.categoryId === categoryId
    )
  }

  private formatUnit(unit: string, value?: string | number) {
    if (!value) return ''

    return `${value}${unit}`
  }

  private openCareer() {
    this.isVisibleCareer = true
    this.selectedAchievement = null
  }

  private openMember(member: IConnectCompanyMember) {
    this.memberForModal = member
    this.isVisibleMember = true
    this.selectedAchievement = null
  }

  get showingMembers() {
    return this.showAllMembersFlag
      ? this.connectCompanyProfile?.value.members
      : this.connectCompanyProfile?.value.members?.slice(
          0,
          this.MEMBER_CONTENT_LIMIT
        )
  }

  get isVisibleMembersLessOrMoreButton() {
    if (this.connectCompanyProfile?.value.members === undefined) {
      return false
    }
    return (
      this.connectCompanyProfile.value.members?.length >=
      this.MEMBER_CONTENT_LIMIT
    )
  }

  get isVisiblePriceRange(): boolean {
    return !!(
      this.connectCompanyProfile &&
      this.connectCompanyProfile.value.price &&
      (this.connectCompanyProfile.value.price.priceRangeFrom ||
        this.connectCompanyProfile.value.price.priceRangeTo)
    )
  }

  get isVisiblePriceEstimate(): boolean {
    return !!(
      this.connectCompanyProfile &&
      this.connectCompanyProfile.value.price &&
      this.connectCompanyProfile.value.price.priceEstimate
    )
  }

  get isVisiblePrice(): boolean {
    return this.isVisiblePriceRange || this.isVisiblePriceEstimate
  }
}
