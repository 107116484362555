export default class StorageService {

  findLocal(key: string): any|null {
    const item = localStorage.getItem(key)
    if (item === null) {
      return null
    }

    return JSON.parse(item)
  }

  saveLocal(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data))
  }

  deleteLocal(key: string) {
    localStorage.removeItem(key)
  }
}
