import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ModalStore } from '~/utils/store-accessor'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectServiceAgreementModalStoreDefine',
})
export default class ConnectServiceAgreementModalStoreDefine extends VuexModule {
  closure: Function | undefined = undefined

  @Mutation
  open(closure: Function) {
    this.closure = closure

    ModalStore.show('ConnectServiceAgreementModal')
  }

  @Mutation
  async execClosure() {
    if (this.closure === undefined) {
      return
    }
    await this.closure()
  }
}
