// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".message_admin_2yFpF .top_orL5x{display:flex;justify-content:space-between}.message_admin_2yFpF .top_orL5x .user_2YPDp{display:flex;align-items:center}.message_admin_2yFpF .top_orL5x .user_2YPDp .name_2jg3r{margin-left:10px;font-size:14px;font-weight:600;color:#000;letter-spacing:0}.message_admin_2yFpF .top_orL5x .user_2YPDp .image_2dYRv{width:34px;height:34px;border-radius:50%}.message_admin_2yFpF .top_orL5x .time_1cIsV{display:flex;align-items:flex-end;font-size:10px;letter-spacing:0;color:#b3b3b3}.message_admin_2yFpF .message_1z24I{margin-top:10px;padding:30px;background:rgba(0,0,0,.10196);border-radius:10px;font-size:14px;letter-spacing:0;line-height:21px;color:#000}.message_admin_2yFpF .message_1z24I .text_3wubL{white-space:pre-wrap;word-break:break-all}", ""]);
// Exports
exports.locals = {
	"message_admin": "message_admin_2yFpF",
	"top": "top_orL5x",
	"user": "user_2YPDp",
	"name": "name_2jg3r",
	"image": "image_2dYRv",
	"time": "time_1cIsV",
	"message": "message_1z24I",
	"text": "text_3wubL"
};
module.exports = exports;
