// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_tQQrn{margin-top:60px;margin-bottom:40px;text-align:center;font-size:18px;font-weight:600;letter-spacing:0;color:#002853}.buttons_3WXFc{margin-bottom:60px;display:flex;grid-gap:20px;gap:20px}.buttons_3WXFc .cancel_2q3gG{background:#e9edf1;color:#2f5980}.buttons_3WXFc .cancel_2q3gG,.buttons_3WXFc .submit_1QgvF{cursor:pointer;display:flex;align-items:center;justify-content:center;width:200px;height:40px;border-radius:20px;font-size:14px;font-weight:600}.buttons_3WXFc .submit_1QgvF{background:#2f5980;color:#fff}", ""]);
// Exports
exports.locals = {
	"title": "title_tQQrn",
	"buttons": "buttons_3WXFc",
	"cancel": "cancel_2q3gG",
	"submit": "submit_1QgvF"
};
module.exports = exports;
