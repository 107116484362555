// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_b7re3{flex-direction:column;grid-gap:30px;gap:30px;height:100%}.container_b7re3,.container_b7re3 .button_1ITeb{display:flex;align-items:center;justify-content:center}.container_b7re3 .button_1ITeb{width:200px;height:40px;border-radius:20px;font-style:normal;font-weight:600;font-size:14px;line-height:21px;text-align:center;cursor:pointer}.container_b7re3 .button_1ITeb.quotation_2aFDA{grid-gap:0 10px;gap:0 10px;background:#224d74;color:#fff;text-decoration:none}.container_b7re3 .button_1ITeb.quotation_2aFDA .icon_1qdoh{font-size:10px}.container_b7re3 .button_1ITeb.cancel_1my9s{background:#fff;border:1px solid #2f5980;color:#2f5980}.container_b7re3 .button_1ITeb.cancel_1my9s:disabled{background:#e9edf1;border:none;color:#c0cbd8;cursor:not-allowed}", ""]);
// Exports
exports.locals = {
	"container": "container_b7re3",
	"button": "button_1ITeb",
	"quotation": "quotation_2aFDA",
	"icon": "icon_1qdoh",
	"cancel": "cancel_1my9s"
};
module.exports = exports;
