







































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'
import { ModalStore } from '~/utils/store-accessor'

@Component({})
export default class ClientProposalDetailSegmentedButtons extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  private get isRejected(): boolean {
    return ['rejected', 'rejected_not_correspond'].includes(
      this.connectProposal.connectProposalStatus as any
    )
  }

  private get isNegotiating(): boolean {
    return (
      ((this.connectProposal.connectProposalStatus as any) as string) ===
      'negotiating'
    )
  }

  private get isPending(): boolean {
    return (
      ((this.connectProposal.connectProposalStatus as any) as string) ===
      'pending'
    )
  }

  private changeStatus(status: string) {
    this.updateStatus(this.connectProposal, status)
  }

  private async changeStatusToNegotiating() {
    const exists = await this.$services.proposalDecisionReason.existsReason(
      this.connectProposal.connectProposalHashId,
      'negotiating'
    )

    if (exists) {
      this.updateStatus(this.connectProposal, 'negotiating')
    } else {
      ModalStore.show('ClientQuestionnaireModal_Negotiating')
    }
  }

  private async changeStatusToRejected() {
    const exists = await this.$services.proposalDecisionReason.existsReason(
      this.connectProposal.connectProposalHashId,
      'rejected'
    )

    if (exists) {
      this.updateStatus(this.connectProposal, 'rejected')
    } else {
      ModalStore.show('ClientQuestionnaireModal_Rejected')
    }
  }

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}
}
