var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modal",class:_vm.s.modal},[_c('div',{ref:"interaction_observer_entry"}),_vm._v(" "),_c('client-proposal-detail-header',{on:{"close":_vm.close}}),_vm._v(" "),_c('div',{class:_vm.s.content_wrapper},[(_vm.isLoading)?_c('div',{class:_vm.s.loading},[_c('img',{attrs:{"src":require("assets/images/proposal/detail/tdesign_loading.svg"),"alt":""}})]):_c('div',{class:_vm.s.content},[_c('div',{class:_vm.s.left},[(_vm.connectProposal.connectProposalStatus === 'waiting')?_c('client-proposal-detail-banner',{class:_vm.s.banner}):_vm._e(),_vm._v(" "),(
            _vm.connectProposal.connectProposalStatus !== 'negotiating' &&
            _vm.connectProposal.connectProposalStatus !== 'accepted'
          )?_c('client-proposal-detail-segmented-buttons',{class:_vm.s.segmented_buttons,attrs:{"connect-proposal":_vm.connectProposal},on:{"updateStatus":_vm.updateStatus}}):_vm._e(),_vm._v(" "),(_vm.connectProposal.connectProposalStatus === 'negotiating')?_c('client-proposal-detail-proposal-message',{class:_vm.s.messages,attrs:{"connect-proposal":_vm.connectProposal}}):_vm._e(),_vm._v(" "),_c('client-proposal-detail-content',{attrs:{"connect-proposal":_vm.connectProposal},on:{"updateStatus":_vm.updateStatus}})],1),_vm._v(" "),_c('div',{class:_vm.s.right},[(_vm.connectProposal.connectProposalStatus === 'negotiating')?_c('client-proposal-detail-negotiation-button',{class:_vm.s.negotiation_button,attrs:{"connect-proposal":_vm.connectProposal}}):_vm._e(),_vm._v(" "),(_vm.connectProposal.connectProposalStatus === 'negotiating')?_c('client-proposal-detail-segmented-buttons-mini',{class:_vm.s.segmented_buttons,attrs:{"connect-proposal":_vm.connectProposal},on:{"updateStatus":_vm.updateStatus}}):_vm._e(),_vm._v(" "),_c('client-proposal-detail-partner-info',{attrs:{"connect-proposal":_vm.connectProposal}})],1),_vm._v(" "),_c('div',{class:[
          _vm.s.scroll_top_button,
          ( _obj = {}, _obj[_vm.s.hide] = _vm.isScrollTop, _obj[_vm.s.show] = !_vm.isScrollTop, _obj[_vm.s.close] = _vm.isClosed, _obj ) ],on:{"click":_vm.scroll}},[_c('img',{attrs:{"src":require("assets/images/proposal/detail/mdi_arrow.svg"),"alt":""}}),_vm._v(" "),_c('span',{class:_vm.s.text},[_vm._v("上へ戻る")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }