// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_1StAo{position:relative;cursor:pointer;display:flex;justify-content:flex-start;align-items:flex-start;grid-gap:30px;gap:30px;padding:30px;font-family:Hiragino Kaku Gothic Pro,serif;transition:all .1s ease-out}.container_1StAo .number_1YrmU{width:26px;color:#454545;color:var(--Text-100,#454545);font-size:18px;font-weight:600;line-height:130%;padding-bottom:5px;border-bottom:2px solid #63615f;border-bottom:2px solid var(--Grey-90,#63615f)}.container_1StAo .image_2KS1y{display:flex;width:45px;min-width:45px;height:45px;background-color:#f8f7f7;border-radius:5px;overflow:hidden}.container_1StAo .info_2OSeV{width:180px;min-width:180px;display:flex;flex-direction:column;grid-gap:5px;gap:5px}.container_1StAo .info_2OSeV .partner_name_3ivp9{color:#555;color:var(--Text-60,#555);font-size:13px;font-weight:600;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;line-height:18px}.container_1StAo .description_27hoM{max-width:500px;flex:1}.container_1StAo .buttons_2L6ZN{width:80px;display:flex;justify-content:flex-end;align-items:flex-start;grid-gap:10px;gap:10px;margin-top:-10px}.container_1StAo .show_detail_3v5ip{position:absolute;bottom:30px;right:30px;display:flex;align-items:center;color:#555;color:var(--Text-60,#555);font-size:12px;font-weight:400;opacity:0}.container_1StAo:hover{background:#fafafa!important;background:var(--Grey-10,#fafafa)!important}.container_1StAo:hover .show_detail_3v5ip{opacity:1}", ""]);
// Exports
exports.locals = {
	"container": "container_1StAo",
	"number": "number_1YrmU",
	"image": "image_2KS1y",
	"info": "info_2OSeV",
	"partner_name": "partner_name_3ivp9",
	"description": "description_27hoM",
	"buttons": "buttons_2L6ZN",
	"show_detail": "show_detail_3v5ip"
};
module.exports = exports;
