// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".proposal_box_21SPx{padding:30px;background:#d2dfeb}.proposal_box_21SPx .propose_not_exist_2FTIh{color:#0e273c}.proposal_box_21SPx .propose_not_exist_2FTIh,.proposal_box_21SPx .unexpired_4ZbHS{display:flex;justify-content:center;font-size:16px;font-weight:600;letter-spacing:0}.proposal_box_21SPx .unexpired_4ZbHS{color:#7b94ac}.proposal_box_21SPx .title_1R1jm{font-weight:600;font-size:16px;letter-spacing:0;color:#0e273c}.proposal_box_21SPx .proposal_container_1g72F{display:flex;flex-direction:column;align-items:center}.proposal_box_21SPx .proposal_container_1g72F .attention_sWIL3{font-weight:600;font-size:16px;line-height:22px;text-align:center;color:#209cd0;margin-bottom:20px}.proposal_box_21SPx .proposal_container_1g72F .button_1vqE9{width:200px;height:40px;border-radius:20px;background:transparent linear-gradient(259deg,#00a7a7,#00a1c7 49%,#0065cb);border:none;display:flex;justify-content:center;align-items:center;cursor:pointer}.proposal_box_21SPx .proposal_container_1g72F .button_1vqE9 .text_1WN9H{font-size:14px;font-weight:600;line-height:21px;color:#fff}", ""]);
// Exports
exports.locals = {
	"proposal_box": "proposal_box_21SPx",
	"propose_not_exist": "propose_not_exist_2FTIh",
	"unexpired": "unexpired_4ZbHS",
	"title": "title_1R1jm",
	"proposal_container": "proposal_container_1g72F",
	"attention": "attention_sWIL3",
	"button": "button_1vqE9",
	"text": "text_1WN9H"
};
module.exports = exports;
