














































































































































import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import IConnectProposal from '~/Interfaces/IConnectProposal'
import ClientProductStatusIcon from '~/components/Client/ClientProductStatusIcon.vue'
import ClientProductIdIcon from '~/components/Client/ClientProductIdIcon.vue'
import ClientProposalRejectModal from '~/components/Client/ClientProposalRejectModal.vue'
import {
  ConnectServiceAgreementModalStore,
  ModalStore,
  SnackbarStore,
} from '~/utils/store-accessor'
import ClientConfirmModal from '~/components/Client/ClientConfirmModal.vue'
import ClientUrgeProfileModal from '~/components/Client/ClientUrgeProfileModal.vue'
import ClientProductIdIconWrapper from '~/components/Client/ClientProductIdIconWrapper.vue'
import HighlightText from '~/components/common/profile/connectCompany/highlight-text.vue'
import ProposalFavoriteButton from '~/components/Proposal/proposal-favorite-button.vue'

@Component({
  components: {
    ClientConfirmModal,
    ClientProductIdIconWrapper,
    ClientUrgeProfileModal,
    ClientProposalRejectModal,
    ClientProductIdIcon,
    ClientProductStatusIcon,
    HighlightText,
    ProposalFavoriteButton,
  },
})
export default class ClientProposalLarge extends Vue {
  @Prop()
  private proposal!: IConnectProposal

  @Prop({ default: false })
  private is_from_detail!: boolean

  @Prop()
  private searchWords!: string[]

  private is_opened_reject_modal: boolean = false

  private isLocked: boolean = false
  private updatedProposal: IConnectProposal | null = null

  get canNegotiate(): boolean {
    if (!this.$auth.user) {
      return false
    }

    if (!this.$auth.user.connect_client_address) {
      return false
    }

    return this.$auth.user.connect_client_address !== ''
  }

  /**
   * チーム設立日
   */
  get ymd() {
    if (this.proposal.company.companyFoundedAt === null) {
      return
    }

    return this.$services.day.getYmdJa(this.proposal.company.companyFoundedAt)
  }

  get isRequestedQuotation(): boolean | undefined {
    return this.proposal?.isRequestedQuotation
  }

  get isRequestedQuotationConfirm(): boolean | undefined {
    return this.proposal?.isRequestedQuotationConfirm
  }

  get isRequestedQuotationEdit(): boolean | undefined {
    return this.proposal?.isRequestedQuotationEdit
  }

  get isRequestedQuotationApprove(): boolean | undefined {
    return this.proposal?.isRequestedQuotationApprove
  }

  get hasCompanyHighLight(): boolean {
    if (!this.searchWords) {
      return false
    }

    const companyHashId = this.proposal?.company.companyHashId
    if (!companyHashId) {
      return false
    }

    const companyFulltextService = this.$services.companyFulltextService.getInstance()

    return this.searchWords.some((searchWord) =>
      companyFulltextService.getFulltext(companyHashId).includes(searchWord)
    )
  }

  /**
   * 商談する(確認)
   */
  negotiate() {
    if (!this.canNegotiate) {
      ModalStore.show('ClientUrgeProfileModal')

      return
    }
    ModalStore.show('ClientConfirmModal' + this.proposal.connectProposalHashId)
  }

  /**
   * 商談する(実行)
   */
  async executeNegotiate() {
    this.isLocked = true
    const proposal = await this.$services.proposal.negotiate(
      this.proposal.connectProposalHashId
    )

    await this.openThread(this.proposal)

    ModalStore.hide('ClientConfirmModal' + this.proposal.connectProposalHashId)
    this.isLocked = false
    if (proposal) {
      this.proposal.connectProposalStatus = proposal.connectProposalStatus
    }
  }

  /**
   * 依頼する
   */
  async accept() {
    const proposal = await this.$services.proposal.accept(
      this.proposal.connectProposalHashId
    )
    if (proposal) {
      this.proposal.connectProposalStatus = proposal.connectProposalStatus
    }
  }

  /**
   * 見積作成を依頼する(確認)
   */
  requestQuotation() {
    ModalStore.show('ClientConfirmModal' + this.proposal.connectProposalHashId)
  }

  /**
   * 見積作成を依頼する(実行)
   */
  async executeRequestQuotation() {
    this.isLocked = true
    this.updatedProposal = await this.$services.quotation.requestQuotation(
      this.proposal.connectProposalHashId
    )
    this.refreshProposals()

    ModalStore.hide('ClientConfirmModal' + this.proposal.connectProposalHashId)
    SnackbarStore.show('quotation_create_request')
    this.isLocked = false
  }

  quotation() {
    const goToQuotation = async () => {
      await this.$router.push(
        '/client/quotation/' +
          this.proposal.connectProductHashId +
          '/' +
          this.proposal.connectProposalHashId
      )
    }
    if (!this.$auth.user?.is_agreed_service_agreement ?? false) {
      ConnectServiceAgreementModalStore.open(goToQuotation)
      return
    }

    goToQuotation()
  }

  /**
   * 見送る
   * @param e
   * @param force
   */
  async reject(e: Event, force: boolean = false) {
    // @MEMO: open modal
    if (!force) {
      this.is_opened_reject_modal = true
      return
    }

    // @MEMO: close modal
    if (force) {
      this.is_opened_reject_modal = false
    }

    const proposal = await this.$services.proposal.reject(
      this.proposal.connectProposalHashId
    )
    if (proposal) {
      this.proposal.connectProposalStatus = proposal.connectProposalStatus
    }
  }

  private async openThread(proposal: IConnectProposal | null) {
    if (proposal === null) {
      return
    }

    const thread_hash_id = await this.$services.message.openThread(
      proposal.connectProposalHashId
    )
    if (thread_hash_id === undefined) {
      return
    }

    // @MEMO ヘッダーのメッセージアイコンを出すため
    this.$auth.fetchUser()

    window.open(this.$config.APP_URL_MSG + 'entry/topic/' + thread_hash_id)
  }

  @Emit('refreshProposals')
  refreshProposals() {}
}
