






import { Component, Vue } from 'nuxt-property-decorator'
import Chatbot from '~/components/Chatbot.vue'

@Component
export default class Default extends Vue {
  constructor() {
    super()

    this.runChatbot()
  }

  private runChatbot() {
    // @MEMO: SP版は無効
    // @ts-ignore
    if (this.$device.isMobile) {
      return
    }
    // @MEMO: 利用企業側のログインページは無効
    if (this.$route.path === '/login/client') {
      return
    }
    // @MEMO: 利用企業側ページは無効
    if (this.$route.path.startsWith('/client/')) {
      return
    }
    // @MEMO: 利用企業側は無効
    if (this.$services.user.isClient()) {
      return
    }
    // @MEMO: ヒアリングシート(事前も含む)は無効
    if (
      this.$route.name === 'hearing_sheet-hash' ||
      this.$route.name === 'hearing_sheet-pre-hash'
    ) {
      return
    }
    // @MEMO: 規約・約款ページは無効
    if (this.$route.path.startsWith('/terms/')) {
      return
    }
    // @MEMO: 支援実績詳細入力ページは無効
    if (
      this.$route.name ===
      'company-profile-connect_company-support_achievements-edit'
    ) {
      return
    }

    new Chatbot()
  }
}
