import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  TConnectCompanySupportAchievement,
  TAchievementConstants,
  TSortableConnectCompanySupportAchievement,
  TConnectCompanySupportAchievementModifyRequest,
  TConnectCompanySupportAchievementProfile,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'
import { LockStore } from '~/utils/store-accessor'

export default class ConnectCompanySupportAchievementService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async fetchSortableAchievements(): Promise<
    TSortableConnectCompanySupportAchievement[] | undefined
  > {
    try {
      const response = await this.axios.get<
        TSortableConnectCompanySupportAchievement[]
      >('api/company/support_achievement/sortable')
      return response.data as TSortableConnectCompanySupportAchievement[]
    } catch (err) {
      console.log(err)
    }
  }

  async fetchAchievements(): Promise<
    TConnectCompanySupportAchievement[] | undefined
  > {
    try {
      const response = await this.axios.get<
        TConnectCompanySupportAchievement[]
      >('api/company/support_achievement/')
      return response.data as TConnectCompanySupportAchievement[]
    } catch (err) {
      console.log(err)
    }
  }

  async fetchAchievement(
    hash: string
  ): Promise<TConnectCompanySupportAchievement | undefined> {
    try {
      const response = await this.axios.get<TConnectCompanySupportAchievement>(
        `api/company/support_achievement/${hash}`
      )
      return response.data as TConnectCompanySupportAchievement
    } catch (err) {
      console.log(err)
    }
  }

  async fetchConstants(): Promise<TAchievementConstants | undefined> {
    try {
      const response = await this.axios.get<TAchievementConstants>(
        'api/company/support_achievement/constants'
      )
      return response.data as TAchievementConstants
    } catch (err) {
      console.log(err)
    }
  }

  async register(
    achievement: TConnectCompanySupportAchievement
  ): Promise<string | undefined> {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      const response = await this.axios.post(
        'api/company/support_achievement',
        achievement
      )
      return response.data
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }

  async update(
    achievement: TConnectCompanySupportAchievement
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      await this.axios.put('api/company/support_achievement', achievement)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }

  async modifyRequest(
    achievement: TConnectCompanySupportAchievement
  ): Promise<TConnectCompanySupportAchievementModifyRequest | false> {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      const response = await this.axios.post(
        'api/company/support_achievement/modify_request',
        achievement
      )
      return response.data as TConnectCompanySupportAchievementModifyRequest
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }

  async sort(sortData: { achievementHashIds: string[] }): Promise<void> {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      await this.axios.post('api/company/support_achievement/sort', sortData)
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }

  async delete(achievement: TConnectCompanySupportAchievement): Promise<void> {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      await this.axios.delete(
        `api/company/support_achievement/${achievement.hash}`
      )
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }

  async deleteMember(
    achievementHash: string,
    memberHash: string
  ): Promise<void> {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      await this.axios.delete(
        `api/company/support_achievement/member/${achievementHash}/${memberHash}`
      )
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }

  async findProfile(): Promise<
    TConnectCompanySupportAchievementProfile | undefined
  > {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      const response = await this.axios.get<TConnectCompanySupportAchievementProfile>(
        `api/company/support_achievement/profile`
      )
      return response.data as TConnectCompanySupportAchievementProfile
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }

  async findProfileAdmin(
    companyHashId: string
  ): Promise<TConnectCompanySupportAchievementProfile | undefined> {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      const response = await this.axios.get<TConnectCompanySupportAchievementProfile>(
        `api/admin/connect_company/support_achievement/profile/${companyHashId}`
      )
      return response.data as TConnectCompanySupportAchievementProfile
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }
}
