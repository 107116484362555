
























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import StepBlock from '~/components/hearingSheet/parts/step-block.vue'
import { ConnectClientProfile } from '~/components'

@Component({
  components: {
    StepBlock,
  },
})
export default class ClientNavigation extends Vue {
  @Prop()
  private connectClientProfile!: ConnectClientProfile

  get isCompany() {
    return this.$route.query.mode !== 'billing'
  }

  get isBilling() {
    return this.$route.query.mode === 'billing'
  }

  private onClick() {
    this.$router.push({ query: { mode: 'company' } })
  }

  private onClickBilling() {
    if (!this.connectClientProfile.isRegistered) {
      return
    }
    this.$router.push({ query: { mode: 'billing' } })
  }
}
