// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header_1BucD{justify-content:space-between}.header_1BucD,.header_1BucD .left_7AqxN{display:flex;align-items:center;grid-gap:20px;gap:20px}.header_1BucD .left_7AqxN{overflow:hidden}.header_1BucD .left_7AqxN h1{height:32px;color:#555;color:var(--text-grey-dark,var(--text-60,#555));font-family:Hiragino Kaku Gothic Pro;font-size:26px;font-style:normal;font-weight:600;line-height:130%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.header_1BucD .left_7AqxN .back_2r5H8{cursor:pointer;display:flex;flex-wrap:wrap;align-content:center;justify-content:center;height:32px;width:32px;border-radius:5px}.header_1BucD .left_7AqxN .back_2r5H8:hover{background:#e3eef7;background:var(--primary-20,#e3eef7)}.header_1BucD .left_7AqxN .back_2r5H8:hover .normal_3PV5w{display:none}.header_1BucD .left_7AqxN .back_2r5H8:hover .hover_F7Ns0{display:inline;display:initial}.header_1BucD .left_7AqxN .back_2r5H8 img{height:24px;width:24px}.header_1BucD .left_7AqxN .back_2r5H8 img.normal_3PV5w{display:inline;display:initial}.header_1BucD .left_7AqxN .back_2r5H8 img.hover_F7Ns0{display:none}", ""]);
// Exports
exports.locals = {
	"header": "header_1BucD",
	"left": "left_7AqxN",
	"back": "back_2r5H8",
	"normal": "normal_3PV5w",
	"hover": "hover_F7Ns0"
};
module.exports = exports;
