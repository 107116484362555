import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ITerm } from '~/Interfaces'

type PageIndex =
  | 'sa_terms'
  | 'privacy_policy'
  | 'connect_terms'
  | 'connect_terms_company'
  | 'connect_service_agreement'
  | 'connect_prime_partner_terms'
  | 'connect_partners_guidelines'
  | 'connect_guidelines'
  | 'connect_partners_price_table'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'TermStoreDefine',
})
export default class TermStoreDefine extends VuexModule {
  terms: ITerm[] = []

  public pageTypes: { [key in PageIndex]: string } = {
    sa_terms: 'sa_terms',
    privacy_policy: 'privacy_policy',
    connect_terms: 'connect_terms',
    connect_terms_company: 'connect_terms_company',
    connect_service_agreement: 'connect_service_agreement',
    connect_prime_partner_terms: 'connect_prime_partner_terms',
    connect_partners_guidelines: 'connect_partners_guidelines',
    connect_guidelines: 'connect_guidelines',
    connect_partners_price_table: 'connect_partners_price_table',
  }

  public excerptTypes: { [index: string]: string } = {
    contract_payment: 'contract_payment',
    contract_termination: 'contract_termination',
  }

  @Mutation
  set(terms: ITerm[]) {
    this.terms = terms
  }

  get getHtmlByType() {
    return (pageType: string) => {
      const term = this.terms.find((term) => {
        return term.pageType === pageType
      })

      if (!term) {
        return ''
      }

      return term.html
    }
  }

  get getExcerptByType() {
    return (pageType: string, excerptTypes: string) => {
      const term = this.terms.find((term) => {
        return term.pageType === pageType
      })

      if (!term) {
        return null
      }

      const excerpt = term.excerpts.find((excerpt) => {
        return excerpt.type === excerptTypes
      })

      if (!excerpt) {
        return null
      }

      return excerpt
    }
  }
}
