import { NuxtAxiosInstance } from '@nuxtjs/axios'

export default class ConnectProductUnlockService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   *
   * @param connectProductHashId
   */
  async exists(connectProductHashId: string): Promise<boolean> {
    try {
      const response = await this.axios.get(
        'api/product_unlock/exists/' + connectProductHashId
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data.exists))
    } catch (err) {
      console.log(err)
      return false
    }
  }

  /**
   *
   * @param connectProductHashId
   * @param password
   */
  async insert(
    connectProductHashId: string,
    password: string
  ): Promise<boolean> {
    try {
      const response = await this.axios.post(
        'api/product_unlock/insert/' + connectProductHashId,
        {
          password,
        }
      )
      return JSON.parse(JSON.stringify(response.data.result))
    } catch (err) {
      console.log(err)
      return false
    }
  }
}
