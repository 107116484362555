import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectProposal from '~/Interfaces/IConnectProposal'
import ICompanyProposal from '~/Interfaces/ICompanyProposal'
import IContracted from '~/Interfaces/contracted/IContracted'

export default class ProposalService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async getCompanyProposals(): Promise<ICompanyProposal[] | undefined> {
    try {
      const response = await this.axios.get('api/company/proposal/get')

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  /*
   * 1件取得する
   * @param product_hash_id
   */
  async find(
    proposal_hash_id: string,
    is_client: boolean = false
  ): Promise<IConnectProposal | undefined> {
    try {
      let response

      if (is_client) {
        response = await this.axios.get(
          'api/client/proposal/find/' + proposal_hash_id
        )
      } else {
        response = await this.axios.get(
          'api/company/proposal/find/' + proposal_hash_id
        )
      }

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 商談する
   * @param proposal_hash_id
   */
  async negotiate(
    proposal_hash_id: string
  ): Promise<IConnectProposal | undefined> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post(
        'api/client/proposal/negotiate/' + proposal_hash_id
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  /**
   * 依頼する
   * @param proposal_hash_id
   */
  async accept(
    proposal_hash_id: string
  ): Promise<IConnectProposal | undefined> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post(
        'api/client/proposal/accept/' + proposal_hash_id
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  /**
   * 見送る
   * @param proposal_hash_id
   */
  async reject(
    proposal_hash_id: string
  ): Promise<IConnectProposal | undefined> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post(
        'api/client/proposal/reject/' + proposal_hash_id
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  /**
   * 保留
   * @param proposalHashId
   */
  async pending(
    proposalHashId: IConnectProposal['connectProductHashId']
  ): Promise<IConnectProposal | undefined> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post(
        'api/client/proposal/pending/' + proposalHashId
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  /**
   * お気に入りに登録
   * @param proposal_hash_id
   */
  async favorite(
    proposal_hash_id: string
  ): Promise<IConnectProposal | undefined> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post(
        'api/client/proposal/favorite/' + proposal_hash_id
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  /**
   * お気に入りから削除
   * @param proposal_hash_id
   */
  async unfavorite(
    proposal_hash_id: string
  ): Promise<IConnectProposal | undefined> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post(
        'api/client/proposal/unfavorite/' + proposal_hash_id
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  getCompanyName(connectProposal: IConnectProposal): string {
    if (this.shouldMask(connectProposal)) {
      return this.maskPartnerName(connectProposal)
    }
    return connectProposal.company.companyName
  }

  getCompanyNameNotMasked(connectProposal: IConnectProposal): string {
    if (this.shouldMask(connectProposal)) {
      return ''
    }
    return connectProposal.company.companyName
  }

  public shouldMask(connectProposal: IConnectProposal): boolean {
    if (
      ['waiting', 'pending', 'rejected', 'rejected_not_correspond'].includes(
        connectProposal.connectProposalStatus as any
      )
    ) {
      return true
    }

    return ![
      connectProposal.isRequestedQuotation,
      connectProposal.isRequestedQuotationApprove,
      connectProposal.isRequestedQuotationEdit,
      connectProposal.isRequestedQuotationConfirm,
    ].some((status) => status)
  }

  public maskPartnerName(connectProposal: IConnectProposal): string {
    if (connectProposal.connectProposalIndex === undefined) {
      return '01'
    }

    return connectProposal.connectProposalIndex.toString().padStart(2, '0')
  }

  /**
   * @param proposal_hash_id
   */
  async getContracted(): Promise<IContracted | undefined> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post('api/client/proposal/contracted')

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  /**
   * 提案書の約款を同意済みにする
   */
  async makeAgreedProposalDocumentAgreement(
    connectProposalHashId: string
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.propose)
    try {
      await this.axios.post(
        `api/client/proposal/make_agreed_proposal_document_agreement/${connectProposalHashId}`
      )

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }
}
