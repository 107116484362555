// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".degrees_3hf8v{flex-wrap:nowrap;width:100%;grid-gap:0 10px;gap:0 10px}.degrees_3hf8v,.degrees_3hf8v .degree_7ALvf{display:flex;align-items:center}.degrees_3hf8v .degree_7ALvf{padding:2px 5px;overflow:hidden;grid-gap:5px;gap:5px;min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;height:24px;border-radius:2px;background:#e3eef7;background:var(--Primary-20,#e3eef7)}.degrees_3hf8v .degree_7ALvf span{white-space:nowrap;color:#3379b7;color:var(--Primary-60,#3379b7);font-size:13px}.degrees_3hf8v .degree_7ALvf span,.degrees_3hf8v .ellipsis_3qUWD{font-family:Hiragino Kaku Gothic Pro;font-style:normal;font-weight:600;line-height:normal}.degrees_3hf8v .ellipsis_3qUWD{color:grey;color:var(--Text-50,grey);font-size:16px}", ""]);
// Exports
exports.locals = {
	"degrees": "degrees_3hf8v",
	"degree": "degree_7ALvf",
	"ellipsis": "ellipsis_3qUWD"
};
module.exports = exports;
