
































































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'
import {
  ConnectProposalThreadStore,
  CurrentConnectProposalStore,
  ReadsStore,
} from '~/utils/store-accessor'
import { ClientProposalPartner, ClientProposalStatus } from '~/components'
import ProposalFavoriteButton from '~/components/Proposal/proposal-favorite-button.vue'
import IConnectDegreeCompany from '~/Interfaces/connectCompany/IConnectDegreeCompany'

@Component({
  components: {
    ClientProposalPartner,
    ClientProposalStatus,
    ProposalFavoriteButton,
  },
})
export default class ClientProposalCell extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  private isUnread: boolean = false
  private renderShortMemo: boolean = false

  mounted() {
    this.loadUnread()
  }

  get selected(): boolean {
    const selectedProposal =
      CurrentConnectProposalStore.getCurrentConnectProposal
    if (!selectedProposal) return false

    return (
      this.connectProposal.connectProposalHashId ===
      selectedProposal.connectProposalHashId
    )
  }

  get connectProposalStatus(): string {
    return this.connectProposal.connectProposalStatus as any
  }

  get support(): number {
    return (
      this.connectProposal.company.connectCompany
        ?.connectCompanySupportAchievements.length ?? 0
    )
  }

  get connectReferenceCheckCount(): number {
    return this.connectProposal.company.connectReferenceCheckCount
  }

  get shortMemo(): string {
    if (!this.connectProposal.connectClientProposalMemo) {
      return ''
    }
    if (this.connectProposal.connectClientProposalMemo.memo.length <= 100) {
      return this.connectProposal.connectClientProposalMemo.memo
    }
    return (
      this.connectProposal.connectClientProposalMemo.memo.substring(0, 100) +
      '...'
    )
  }

  /**
   * 称号の一番目を取得
   */
  get getFirstConnectDegreeCompany(): IConnectDegreeCompany | false {
    const connectDegreeCompanies = this.connectProposal.company.connectCompany
      ?.connectDegreeCompanies

    if (connectDegreeCompanies === undefined) {
      return false
    }

    if (connectDegreeCompanies.length === 0) {
      return false
    }

    return connectDegreeCompanies[0]
  }

  get isVisibleProposalContent(): boolean {
    return [
      'waiting',
      'negotiating',
      'pending',
      'rejected',
      'rejected_not_correspond',
    ].includes(this.connectProposalStatus)
  }

  get isVisibleUnread(): boolean {
    return this.isUnread
  }

  private onClick() {
    this.$services.connectProposalRead.read(
      this.connectProposal?.connectProposalHashId
    )
    CurrentConnectProposalStore.setCurrentConnectProposal(this.connectProposal)

    this.$router.push({
      query: { proposal_hash_id: this.connectProposal.connectProposalHashId },
    })
  }

  private async loadUnread() {
    if (['waiting', 'pending'].includes(this.connectProposalStatus)) {
      return
    }

    const messageService = this.$services.message

    let threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    if (!threadHashId) {
      threadHashId = await messageService.findThreadHashId(
        this.connectProposal.connectProposalHashId,
        true
      )
    }

    if (!threadHashId) return

    // threadHashId を cache
    ConnectProposalThreadStore.setThreadHashId({
      connectProposalHashId: this.connectProposal.connectProposalHashId,
      threadHashId,
    })

    this.isUnread = !ReadsStore.isReadByThreadHashId(threadHashId)
  }

  @Watch('selected')
  private updateReads() {
    const threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )
    if (!threadHashId) return
    this.isUnread = !ReadsStore.isReadByThreadHashId(threadHashId)
  }

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}
}
