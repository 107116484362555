// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".enterActive_18QjD{transition:opacity .3s}.leaveActive_2Mn-q{opacity:0}.wrapper_10P_p{display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;height:100%;width:100%;z-index:10;background:rgba(0,0,0,.15)}.modal_Nd6kv{position:relative;padding:40px 50px;background:#fff;box-shadow:0 0 20px rgba(109,132,155,.3);border-radius:10px;text-align:center;margin:0 auto;z-index:11;max-width:720px}.title_2aQHh{color:#555;font-size:22px;font-weight:600;line-height:29px;letter-spacing:0;text-align:left;margin:0 0 30px;padding:0}.buttons_2vbhq{display:flex;justify-content:flex-end;align-items:center;grid-gap:0 20px;gap:0 20px}", ""]);
// Exports
exports.locals = {
	"enterActive": "enterActive_18QjD",
	"leaveActive": "leaveActive_2Mn-q",
	"wrapper": "wrapper_10P_p",
	"modal": "modal_Nd6kv",
	"title": "title_2aQHh",
	"buttons": "buttons_2vbhq"
};
module.exports = exports;
