import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import IConnectProposalDetail from '../Interfaces/ConnectProposalDetail/IConnectProposalDetail'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ClientConnectProposalDetailsDefine',
})
export default class ClientConnectProposalDetailsDefine extends VuexModule {
  priorities: string[] = []
  simplifiedPriorities: string[] = []
  connectProposalDetails: IConnectProposalDetail[] = []

  @Mutation
  set(connectProposalDetails: IConnectProposalDetail[]) {
    this.connectProposalDetails = connectProposalDetails
  }

  get find(): (
    connectProposalHashId: string
  ) => IConnectProposalDetail | undefined {
    const self = this

    return (connectProposalHashId: string) => {
      return self.connectProposalDetails.find(
        (connectProposalDetail) =>
          connectProposalDetail.connectProposalHashId === connectProposalHashId
      )
    }
  }

  @Mutation
  setPrioritiesMaster(priorities: string[]) {
    this.priorities = priorities
  }

  get getPrioritiesMaster(): string[] {
    return this.priorities
  }

  @Mutation
  setSimplifiedPrioritiesMaster(simplifiedPriorities: string[]) {
    this.simplifiedPriorities = simplifiedPriorities
  }

  get getSimplifiedPrioritiesMaster(): string[] {
    return this.simplifiedPriorities
  }
}
