











import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectCompanySupportSystem } from '~/Interfaces'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: { ConnectIcon },
})
export default class ClientProposalListItemActivityType extends Vue {
  @Prop()
  private connectCompanySupportSystem!: IConnectCompanySupportSystem

  get iconType() {
    switch (this.connectCompanySupportSystem?.activityType) {
      case 0: // 個人プレイヤー型
        return 'anonymous-user'
      case 1: // チーム型
        return 'anonymous-users'
      case 2: // 組織型
        return 'building'
      case 3: // 個人ディレクター型
        return 'anonymous-user'
      default:
        return null
    }
  }
}
