




















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ConnectQuotationConfirmToClient } from './components'
import { IConnectProposal, IThreadDetail } from '~/Interfaces'
import { CurrentConnectProposalStore } from '~/utils/store-accessor'

@Component({
  components: {
    ConnectQuotationConfirmToClient,
  },
})
export default class ThreadMessageAdmin extends Vue {
  @Prop()
  private threadDetail!: IThreadDetail

  get connectProposal(): IConnectProposal | null {
    return CurrentConnectProposalStore.getCurrentConnectProposal
  }

  get threadDetailText(): string {
    const currentProposal =
      CurrentConnectProposalStore.getCurrentConnectProposal

    if (!currentProposal) {
      return this.threadDetail.threadDetailText
    }

    return this.threadDetail.threadDetailText.replaceAll(
      currentProposal.company.companyName,
      this.partnerName
    )
  }

  get partnerName(): string {
    if (!this.connectProposal) return 'パートナーA'

    return this.$services.proposal.getCompanyName(this.connectProposal)
  }
}
