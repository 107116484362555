


















import {Component, Vue, Prop, Emit} from "nuxt-property-decorator";
import IConnectMemberWrapper from "~/Interfaces/connectMember/IConnectMemberWrapper";
import {ConnectTeamWithMemberWrapperStore} from "~/utils/store-accessor";
@Component
export default class ProfileConnectTeamMemberForClient extends Vue {
  @Prop()
  private member!: IConnectMemberWrapper

  get haveImage(): boolean
  {
    return this.member.sa.basic.saImage !== null && this.member.sa.basic.saImage !==''
  }

  get index(): number|undefined
  {
    return ConnectTeamWithMemberWrapperStore.getIndex(this.member.sa.saHashId)
  }
}
