





















import { Component, Emit, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import ConnectSmallButton from '~/components/common/components/ConnectSmallButton.vue'

@Component({
  components: { ConnectSmallButton, ConnectIcon },
})
export default class PartnerModal extends Vue {
  @PropSync('isVisible')
  private visible!: boolean

  @Prop({ default: false })
  private isClient!: boolean

  @Prop({ default: null })
  private newTabOpenButtonUrl!: string

  private onClick() {
    this.visible = false
    this.onClose()
  }

  private onClickNewTabOpenButton() {
    window.open(this.newTabOpenButtonUrl, '_blank')
  }

  @Emit('close')
  private onClose() {}
}
