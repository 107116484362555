



























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Auth } from '@nuxtjs/auth-next'
import {
  ConnectClientBilling,
  ConnectClientProfile,
  ConnectIcon,
} from '~/components'
import ConnectButton from '~/components/common/components/v2/ConnectV2Button.vue'
import { ConnectClientProfileStore } from '~/utils'

@Component({
  components: { ConnectButton, ConnectIcon },
})
export default class ClientProfileRegisterBanner extends Vue {
  @Prop()
  private isClient!: boolean

  private isVisible: boolean = false
  private connectClientBilling!: ConnectClientBilling
  private connectClientProfile!: ConnectClientProfile

  mounted() {
    if (!this.isClient) {
      return
    }

    if (!this.$auth.user) {
      return
    }
    this.fetchContracts()
  }

  get isVisibleBanner() {
    if (ConnectClientProfileStore.getIgnorePromote) {
      return false
    }

    return this.isVisible
  }

  get settingLink() {
    if (this.connectClientProfile.isRegistered) {
      return '/client/setting/billing'
    }

    return '/client/setting/company'
  }

  private close() {
    ConnectClientProfileStore.setIgnorePromote()
    this.isVisible = false
  }

  private async fetchContracts() {
    const [billing, connectProposalClientPresentation] = await Promise.all([
      this.$services.connectClientBilling.find(),
      this.$services.connectProposalDetail.getContractedByClient(),
    ])

    // 契約がない場合は表示しない
    if (!connectProposalClientPresentation?.connectProposalDetails?.length) {
      return
    }

    this.connectClientBilling = new ConnectClientBilling(billing)
    this.connectClientProfile = new ConnectClientProfile(this.$auth as Auth)

    if (
      !this.connectClientBilling.isRegistered ||
      !this.connectClientProfile.isRegistered
    ) {
      this.isVisible = true
    }
  }
}
