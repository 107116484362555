





















import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { IProduct } from '~/Interfaces'
import ProductContentButton from '~/components/Client/ClientProduct/proposal/product-content-button.vue'

@Component({
  components: { ProductContentButton },
})
export default class ClientProposalHeader extends Vue {
  @Prop()
  private product!: IProduct
}
