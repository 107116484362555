// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".inputLabel_hI5kD{display:flex;align-items:center;color:#454545;font-weight:600;font-size:14px;letter-spacing:0;line-height:21px;padding:0 0 10px}.inputLabel_hI5kD.hidden_3mb87{visibility:hidden}.required_FP9h_{margin-right:10px;display:flex;align-items:center;justify-content:center;background:#d7e6f4;border-radius:4px;width:34px;height:20px;font-size:12px;font-weight:700;line-height:18px;letter-spacing:.7px;color:#3379b7}", ""]);
// Exports
exports.locals = {
	"inputLabel": "inputLabel_hI5kD",
	"hidden": "hidden_3mb87",
	"required": "required_FP9h_"
};
module.exports = exports;
