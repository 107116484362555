





































































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import ClientProposalDetailPartnerInfo from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailPartnerInfo.vue'
import ClientProposalDetailHeader from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailHeader.vue'
import { IConnectProposal } from '~/Interfaces'
import ClientProposalDetailBanner from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailBanner.vue'
import ClientProposalDetailSegmentedButtons from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailSegmentedButtons.vue'
import ClientProposalDetailSegmentedButtonsMini from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailSegmentedButtonsMini.vue'
import ClientProposalDetailNegotiationButton from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailNegotiationButton.vue'
import ClientProposalMessage from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalMessage/index.vue'
import ClientProposalDetailContent from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalDetailContent/index.vue'
import ClientProposalDetailProposalMessage from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/message/ClientProposalDetailProposalMessage.vue'

@Component({
  components: {
    ClientProposalDetailProposalMessage,
    ClientProposalDetailNegotiationButton,
    ClientProposalDetailSegmentedButtonsMini,
    ClientProposalDetailSegmentedButtons,
    ClientProposalDetailBanner,
    ClientProposalDetailHeader,
    ClientProposalDetailPartnerInfo,
    ClientProposalMessage,
    ClientProposalDetailContent,
  },
})
export default class ClientProposalDetailModal extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop()
  private isLoading!: boolean

  private isScrollTop: boolean = true
  private isClosed: boolean = true

  private scrollObserver: IntersectionObserver | null = null

  disappearScrollButton() {
    this.isScrollTop = true
    this.isClosed = true
  }

  @Emit('close')
  close() {}

  mounted() {
    this.$services.connectProposalRead.read(
      this.connectProposal?.connectProposalHashId
    )

    setTimeout(() => {
      this.initScrollObserve()
    }, 300)
    this.isClosed = false
  }

  destroyed() {
    this.scrollObserver?.disconnect()
    this.scrollObserver = null
  }

  initScrollObserve() {
    if (this.$refs.interaction_observer_entry) {
      this.scrollObserver = new IntersectionObserver(this.checkScrollTop)
      this.scrollObserver.observe(
        this.$refs.interaction_observer_entry as HTMLDivElement
      )
    }
  }

  checkScrollTop(entries: IntersectionObserverEntry[]) {
    this.isScrollTop = entries[0].isIntersecting
  }

  scroll() {
    const modal = this.$refs.modal as HTMLDivElement
    modal.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}
}
