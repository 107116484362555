


























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: {
    ConnectIcon,
  },
})
export default class SupportAttention extends Vue {
  @Prop({ default: 'waring' })
  private type!: 'waring' | 'danger' | 'info' | 'lightbulb'
}
