



























import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import IConnectClientProposalMemo from '~/Interfaces/IConnectClientProposalMemo'
import { ConnectClientProposalMemoStore } from '~/utils/store-accessor'
import { IConnectProposal } from '~/Interfaces'

@Component({})
export default class ClientProposalDetailMemo extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  private memo: string = ''

  mounted() {
    this.initMemo()

    this.setUpFocusEvent()
  }

  private get isFocused(): boolean {
    return ConnectClientProposalMemoStore.isOpenEditor
  }

  private initMemo() {
    const text = ConnectClientProposalMemoStore.findTmpMemoByConnectProposalHashId(
      this.connectProposal.connectProposalHashId
    )
    if (text !== undefined) {
      ConnectClientProposalMemoStore.setConnectClientProposalMemo({
        connectClientProposalMemoId: null,
        connectClientProposalMemoHashId: null,
        connectProposalHashId: this.connectProposal.connectProposalHashId,
        memo: text,
        lastUpdated: null,
      })
    } else {
      ConnectClientProposalMemoStore.setConnectClientProposalMemo(
        this.connectProposal?.connectClientProposalMemo !== undefined
          ? this.connectProposal.connectClientProposalMemo
          : {
              connectClientProposalMemoId: null,
              connectClientProposalMemoHashId: null,
              connectProposalHashId: this.connectProposal.connectProposalHashId,
              memo: '',
              lastUpdated: null,
            }
      )
    }

    this.memo = this.clientMemo.memo ?? ''
  }

  private setUpFocusEvent() {
    ;(this.$refs.textarea as HTMLTextAreaElement).addEventListener(
      'focus',
      () => {
        ConnectClientProposalMemoStore.openEditor()
      }
    )
  }

  private cancel() {
    ConnectClientProposalMemoStore.closeEditor()

    this.memo = this.clientMemo.memo ?? ''
  }

  private async save() {
    ConnectClientProposalMemoStore.closeEditor()

    await this.$services.connectClientProposalMemo.write(
      this.clientMemo?.connectClientProposalMemoHashId ?? null,
      this.connectProposal.connectProposalHashId,
      this.memo
    )

    ConnectClientProposalMemoStore.replaceTmpMemos({
      connectProposalHashId: this.connectProposal.connectProposalHashId,
      text: this.memo,
    })

    this.memo = this.clientMemo.memo ?? ''
  }

  get clientMemo(): IConnectClientProposalMemo {
    return ConnectClientProposalMemoStore.getConnectClientProposalMemo!
  }
}
