// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fade_active_1CSB7{transition:opacity .3s ease-out}.fade_leave_1XRNG{opacity:0}.upload_YvSOv .layout_103TB{position:fixed;z-index:10;top:0;left:0;cursor:auto;display:flex;align-items:center;justify-content:center;height:100%;width:100%}.upload_YvSOv .layout_103TB .box_17zUO{padding:0 60px;position:absolute;width:760px;background:#fff;box-shadow:0 0 20px rgba(56,72,88,.2);border-radius:6px}.upload_YvSOv .layout_103TB .box_17zUO .close_button_3u5Al{cursor:pointer;position:absolute;top:25px;right:25px}.upload_YvSOv .layout_103TB .box_17zUO .title_2EVPH{margin-top:40px;display:flex;justify-content:center}.upload_YvSOv .layout_103TB .box_17zUO .title_2EVPH span{font-size:16px;font-weight:600;line-height:16px;letter-spacing:0;color:#2c3846}.upload_YvSOv .layout_103TB .box_17zUO p{margin-top:30px;font-size:12px;line-height:20px;letter-spacing:0;color:#2c3846}.upload_YvSOv .layout_103TB .box_17zUO p span{font-weight:600}.upload_YvSOv .layout_103TB .box_17zUO .drop_area_3oSME{margin-top:30px;margin-bottom:60px;display:flex;flex-flow:column;align-items:center;background:#edf4ff;border:2px dashed #b5bdbe;border-radius:10px}.upload_YvSOv .layout_103TB .box_17zUO .drop_area_3oSME img{margin-top:30px}.upload_YvSOv .layout_103TB .box_17zUO .drop_area_3oSME p{margin-top:15px;font-size:12px;letter-spacing:0;color:#b5bdbe}.upload_YvSOv .layout_103TB .box_17zUO .drop_area_3oSME .button_2s21B{cursor:pointer;margin-top:13px;margin-bottom:30px;display:flex;align-items:center;justify-content:center;width:178px;height:40px;background:transparent linear-gradient(90deg,#2b61e8,#0030ce);border-radius:30px;font-size:16px;font-weight:600;letter-spacing:0;color:#fff}.upload_YvSOv .layout_103TB .box_17zUO .drop_area_3oSME input{display:none}", ""]);
// Exports
exports.locals = {
	"fade_active": "fade_active_1CSB7",
	"fade_leave": "fade_leave_1XRNG",
	"upload": "upload_YvSOv",
	"layout": "layout_103TB",
	"box": "box_17zUO",
	"close_button": "close_button_3u5Al",
	"title": "title_2EVPH",
	"drop_area": "drop_area_3oSME",
	"button": "button_2s21B"
};
module.exports = exports;
