// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3ejkJ{display:inline-block;background:#fff;padding:40px 10px 20px;min-height:100vh;height:100%}.inner_1xylj{padding:0 10px}.title_1j7H0{font-weight:600;font-size:18px;line-height:130%;color:#555;padding:0;margin:0 0 30px}.list_1-0VH{padding:0;list-style:none}", ""]);
// Exports
exports.locals = {
	"container": "container_3ejkJ",
	"inner": "inner_1xylj",
	"title": "title_1j7H0",
	"list": "list_1-0VH"
};
module.exports = exports;
