











import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  components: {},
})
export default class ClientProposalDetailHeader extends Vue {
  @Emit('close')
  private onClose() {}
}
