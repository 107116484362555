// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2Icaa{display:flex;flex-direction:column}.container_2Icaa .text_2mbUc{color:#555;color:var(--Text-60,#555);font-size:14px;font-weight:400}.container_2Icaa .banner_3MnoX{margin-top:20px;display:flex;padding:20px;flex-direction:column;align-items:flex-start;grid-gap:10px;gap:10px;flex:1 0 0;border-radius:5px;background:#f3f2f2;background:var(--Grey-20,#f3f2f2)}.container_2Icaa .banner_3MnoX .banner_title_2tEV8{display:flex;align-items:center;grid-gap:10px;gap:10px;color:#454545;color:var(--Text-100,#454545);font-size:14px;font-weight:600}.container_2Icaa .banner_3MnoX .banner_text_N1dOy{color:#454545;color:var(--Text-100,#454545);font-size:13px;font-weight:400}.container_2Icaa .filters_2mqh_{margin-top:50px;display:flex;grid-gap:10px;gap:10px}.container_2Icaa .loading_3NEuv{display:flex;padding:200px 0;flex-direction:column;justify-content:center;align-items:center;align-self:stretch}.container_2Icaa .list_MNG9Y{margin-top:30px;display:flex;flex-direction:column;border:1px solid #d6d5d4;border-radius:5px;overflow:hidden;grid-gap:1px;gap:1px;background-color:#d6d5d4}.container_2Icaa .list_MNG9Y>*{background-color:#fff}.container_2Icaa .empty_list_2Wu1m{display:flex;padding:200px 0;flex-direction:column;justify-content:center;align-items:center;align-self:stretch;color:#555;color:var(--Text-60,#555);font-size:14px;font-weight:400}", ""]);
// Exports
exports.locals = {
	"container": "container_2Icaa",
	"text": "text_2mbUc",
	"banner": "banner_3MnoX",
	"banner_title": "banner_title_2tEV8",
	"banner_text": "banner_text_N1dOy",
	"filters": "filters_2mqh_",
	"loading": "loading_3NEuv",
	"list": "list_MNG9Y",
	"empty_list": "empty_list_2Wu1m"
};
module.exports = exports;
