// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3jUOG{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.container_3jUOG .title_1n6Qw{font-family:\"Hiragino Kaku Gothic Pro\";font-size:14px;font-weight:600;color:#555}.container_3jUOG .list_11-ZB{display:flex;flex-wrap:wrap;grid-gap:5px;gap:5px}.container_3jUOG .list_11-ZB .item_1-8BT{padding:5px 10px;background:#f3f2f2;border-radius:5px;font-family:\"Hiragino Kaku Gothic Pro\";font-size:13px;font-weight:400;color:#454545}", ""]);
// Exports
exports.locals = {
	"container": "container_3jUOG",
	"title": "title_1n6Qw",
	"list": "list_11-ZB",
	"item": "item_1-8BT"
};
module.exports = exports;
