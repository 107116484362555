// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".inputLabel_35jat{font-size:16px;line-height:16px;color:#0e273c;margin-bottom:10px}.inputLabel_35jat,.required_3lrTT{display:flex;align-items:center;font-weight:600;letter-spacing:0}.required_3lrTT{margin-right:10px;justify-content:center;width:34px;height:20px;background:#d7e6f4;border-radius:2px;padding:1px 5px;font-size:12px;line-height:18px;text-align:center;color:#3379b7}", ""]);
// Exports
exports.locals = {
	"inputLabel": "inputLabel_35jat",
	"required": "required_3lrTT"
};
module.exports = exports;
