// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_rQ0r2{grid-gap:5px;gap:5px;color:#555;color:var(--Text-60,#555)}.container_rQ0r2,.container_rQ0r2 .icon_17le-{display:flex;align-items:center}.container_rQ0r2 .icon_17le-{justify-content:center;width:18px}.container_rQ0r2 .text_Bm9Mu{display:flex;align-items:center;font-size:13px;font-weight:600;line-height:1;margin-top:1px}", ""]);
// Exports
exports.locals = {
	"container": "container_rQ0r2",
	"icon": "icon_17le-",
	"text": "text_Bm9Mu"
};
module.exports = exports;
