import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import {
  IConnectCompanyFeatures,
  IConnectCompanyPolicy,
  IConnectCompanyPreferredCustomerOrProducts,
  IConnectCompanyPrice,
  IConnectCompanyProfile,
  IConnectCompanyRepresentative,
  IConnectCompanySupports,
  IConnectCompanySupportSystem,
} from '../Interfaces'
import { TConnectCompanySupportAchievementReviewContentProfile } from '~/Interfaces/connectCompany/IConnectCompanySupportAchievementReview'
import {TConnectCompanySupportAchievementProfile} from "~/Interfaces/connectCompany/IConnectCompanySupportAchievement";

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectCompanyProfileDefine',
})
export default class ConnectCompanyProfileDefine extends VuexModule {
  connectCompanyProfile: IConnectCompanyProfile = {
    connectCompanyHashId: '',
    features: undefined,
    supports: undefined,
    preferredCustomerOrProducts: undefined,
    supportSystem: undefined,
    representative: undefined,
    policy: undefined,
    price: undefined,
    review: undefined,
  }

  @Mutation
  setFeatures(features: IConnectCompanyFeatures | undefined) {
    if (features === undefined) {
      return
    }

    this.connectCompanyProfile.features = features
  }

  @Mutation
  setSupports(supports: IConnectCompanySupports | undefined) {
    if (supports === undefined) {
      return
    }

    this.connectCompanyProfile.supports = supports
  }

  @Mutation
  setPreferredCustomerOrProducts(
    preferredCustomerOrProducts:
      | IConnectCompanyPreferredCustomerOrProducts
      | undefined
  ) {
    if (preferredCustomerOrProducts === undefined) {
      return
    }

    this.connectCompanyProfile.preferredCustomerOrProducts = preferredCustomerOrProducts
  }

  @Mutation
  setSupportSystem(supportSystem: IConnectCompanySupportSystem | undefined) {
    if (supportSystem === undefined) {
      return
    }

    this.connectCompanyProfile.supportSystem = supportSystem
  }

  @Mutation
  setRepresentative(representative: IConnectCompanyRepresentative | undefined) {
    if (representative === undefined) {
      return
    }

    this.connectCompanyProfile.representative = representative
  }

  @Mutation
  setPolicy(policy: IConnectCompanyPolicy | undefined) {
    if (policy === undefined) {
      return
    }

    this.connectCompanyProfile.policy = policy
  }

  @Mutation
  setPrice(price: IConnectCompanyPrice | undefined) {
    if (price === undefined) {
      return
    }

    this.connectCompanyProfile.price = price
  }

  @Mutation
  setReview(
    review: TConnectCompanySupportAchievementReviewContentProfile | undefined
  ) {
    if (review === undefined) {
      return
    }

    this.connectCompanyProfile.review = review
  }

  @Mutation
  setAchievement(
    achievement: TConnectCompanySupportAchievementProfile | undefined
  ) {
    if (achievement === undefined) {
      return
    }

    this.connectCompanyProfile.achievement = achievement
  }

  get getConnectCompanyProfile(): IConnectCompanyProfile | undefined {
    return this.connectCompanyProfile
  }
}
