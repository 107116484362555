// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".message_self_foVBY{margin-left:110px}.message_self_foVBY .time_1j-tX{display:flex;justify-content:flex-end;font-size:10px;letter-spacing:0;color:#b3b3b3}.message_self_foVBY .message_2uPxt{margin-top:10px;padding:30px;border-radius:10px 10px 0 10px;font-size:14px;letter-spacing:0;line-height:21px;color:#fff}.message_self_foVBY .message_2uPxt.connect_Rscbr{background:#4d6f90}.message_self_foVBY .message_2uPxt .text_2X3_V{white-space:pre-wrap;word-break:break-all}.message_self_foVBY .message_2uPxt .attachment_28j8D a{color:#fff}.message_self_foVBY .user_1Ubqs{margin-top:10px;display:flex;justify-content:flex-end;font-size:12px;font-weight:600;letter-spacing:0;color:#b3b3b3}", ""]);
// Exports
exports.locals = {
	"message_self": "message_self_foVBY",
	"time": "time_1j-tX",
	"message": "message_2uPxt",
	"connect": "connect_Rscbr",
	"text": "text_2X3_V",
	"attachment": "attachment_28j8D",
	"user": "user_1Ubqs"
};
module.exports = exports;
