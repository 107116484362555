import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  TAchievementReviewDetail,
  TAchievementReviewRequestForm,
  TConnectCompanySupportAchievementReviewContentProfile,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievementReview'
import { LockStore } from '~/utils/store-accessor'

export default class ConnectCompanySupportAchievementReviewService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async detail(
    achievementHash: string
  ): Promise<TAchievementReviewDetail | undefined> {
    LockStore.lock(LockStore.types.support_achievement_review)
    try {
      const response = await this.axios.get(
        `api/company/support_achievement/review/detail/${achievementHash}`
      )
      return response.data as TAchievementReviewDetail
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement_review)
    }
  }

  async requestRegister(
    reviewRequestForm: TAchievementReviewRequestForm
  ): Promise<TAchievementReviewDetail | undefined> {
    LockStore.lock(LockStore.types.support_achievement_review)
    try {
      const response = await this.axios.post(
        'api/company/support_achievement/review/request',
        reviewRequestForm
      )
      return response.data as TAchievementReviewDetail
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement_review)
    }
  }

  async requestCancel(achievementHash: string): Promise<void> {
    LockStore.lock(LockStore.types.support_achievement_review)
    try {
      await this.axios.delete(
        `api/company/support_achievement/review/request/${achievementHash}`
      )
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement_review)
    }
  }

  async update(achievementHash: string, status: string): Promise<boolean> {
    LockStore.lock(LockStore.types.support_achievement_review)
    try {
      await this.axios.post(
        `api/company/support_achievement/review/${achievementHash}`,
        { status }
      )
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.support_achievement_review)
    }
  }

  async reRequest(
    hash: string,
    achievementHash: string,
    message: string
  ): Promise<TAchievementReviewDetail | undefined> {
    LockStore.lock(LockStore.types.support_achievement_review)
    try {
      const response = await this.axios.post(
        'api/company/support_achievement/review/re-request',
        { hash, achievementHash, message }
      )
      return response.data as TAchievementReviewDetail
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement_review)
    }
  }

  async linkReference(
    achievementHash: string,
    referenceCheckHash: string
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.support_achievement)
    try {
      await this.axios.post(
        'api/company/support_achievement/review/link_reference',
        {
          achievementHash,
          referenceCheckHash,
        }
      )
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.support_achievement)
    }
  }

  async findProfile(): Promise<
    TConnectCompanySupportAchievementReviewContentProfile | undefined
  > {
    LockStore.lock(LockStore.types.support_achievement_review)
    try {
      const response = await this.axios.get(
        `api/company/support_achievement/review/profile`
      )
      return response.data as TConnectCompanySupportAchievementReviewContentProfile
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement_review)
    }
  }

  async findProfileAdmin(
    companyHashId: string
  ): Promise<
    TConnectCompanySupportAchievementReviewContentProfile | undefined
  > {
    LockStore.lock(LockStore.types.support_achievement_review)
    try {
      const response = await this.axios.get(
        `api/admin/connect_company/profile/review/${companyHashId}`
      )
      return response.data as TConnectCompanySupportAchievementReviewContentProfile
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.support_achievement_review)
    }
  }
}
