







































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ConnectButton from '~/components/common/components/ConnectButton.vue'
import {
  TAchievementConstants,
  TConnectCompanySupportAchievement,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'
import AchievementGeneralService from '~/components/Profile/ConnectCompany/Achievement/AchievementGeneralService'

@Component({
  components: {
    ConnectButton,
  },
})
export default class SupportAchievement extends Vue {
  @Prop()
  private achievement!: TConnectCompanySupportAchievement

  @Prop()
  private constants!: TAchievementConstants

  private selected: boolean = false

  private service: AchievementGeneralService = new AchievementGeneralService()

  get rewardType(): string {
    return this.service.getRewardType(this.achievement, this.constants)
  }

  get term(): string {
    return this.service.getTerm(this.achievement)
  }

  get supportedProducts(): string[] {
    return this.service.getSupportedProducts(this.achievement, this.constants)
  }

  get industries(): string[] {
    return this.service.getIndustries(this.achievement, this.constants)
  }

  get companyScale(): string {
    return this.service.getCompanyScale(this.achievement, this.constants)
  }

  get companyForm(): string {
    return this.service.getCompanyForm(this.achievement, this.constants)
  }
}
