import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IConnectProposal, IThread } from '../Interfaces'

type SetThreadHashIdProps = {
  connectProposalHashId: IConnectProposal['connectProposalHashId']
  threadHashId: IThread['threadHashId']
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectProposalThreadStoreDefine',
})
export default class ConnectProposalThreadStoreDefine extends VuexModule {
  connectProposalThreadHash: {
    [key in IConnectProposal['connectProposalHashId']]: IThread['threadHashId']
  } = {}

  get getThreadHashId(): (
    connectProposalHashId: IConnectProposal['connectProposalHashId']
  ) => IThread['threadHashId'] | undefined {
    const self = this

    return (
      connectProposalHashId: IConnectProposal['connectProposalHashId']
    ) => {
      if (!self.connectProposalThreadHash[connectProposalHashId]) {
        return undefined
      }
      return self.connectProposalThreadHash[connectProposalHashId]
    }
  }

  @Mutation
  setThreadHashId(props: SetThreadHashIdProps) {
    this.connectProposalThreadHash[props.connectProposalHashId] =
      props.threadHashId
  }
}
