import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { IConnectCompanyConstants, IConnectCompanyProfile } from '~/Interfaces'
import ConnectCompanyProfile from '~/components/Client/profile/ConnectCompanyProfile'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectCompanyProfilesDefine',
})
export default class ConnectCompanyProfilesDefine extends VuexModule {
  connectCompanyProfiles: ConnectCompanyProfile[] = []
  connectCompanyConstants: IConnectCompanyConstants | undefined = undefined

  @Mutation
  set(connectCompanyProfiles: IConnectCompanyProfile[]) {
    this.connectCompanyProfiles = connectCompanyProfiles.map(
      (e) => new ConnectCompanyProfile(e)
    )
  }

  @Mutation
  replace(connectCompanyProfiles: IConnectCompanyProfile[]) {
    const profiles = this.connectCompanyProfiles

    connectCompanyProfiles.map((e) => {
      const index = this.connectCompanyProfiles.findIndex(
        (connectCompanyProfile) =>
          connectCompanyProfile.value.companyHashId === e.companyHashId
      )
      if (index !== -1) {
        profiles.splice(index, 1)
      }

      profiles.push(new ConnectCompanyProfile(e))
    })

    this.connectCompanyProfiles = profiles
  }

  get findByCompanyHashId(): (
    companyHashId: string
  ) => ConnectCompanyProfile | undefined {
    const self = this

    return (companyHashId: string) => {
      return self.connectCompanyProfiles.find(
        (connectCompanyProfile) =>
          connectCompanyProfile.value.companyHashId === companyHashId
      )
    }
  }

  @Mutation
  setConstants(connectCompanyConstants: IConnectCompanyConstants) {
    this.connectCompanyConstants = connectCompanyConstants
  }

  get getConstants(): IConnectCompanyConstants | undefined {
    return this.connectCompanyConstants
  }
}
