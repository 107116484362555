













import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
@Component({})
export default class InfoIcon extends Vue {}
