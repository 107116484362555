






























































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

import { CurrentConnectProposalStore } from '~/utils/store-accessor'
import FormModal from '~/components/common/modal/form-modal.vue'

@Component({
  components: { FormModal },
})
export default class ProposalConfirmProposalDocumentAgreementModal extends Vue {
  private is_checked: boolean = false
  @Prop({ default: null })
  private closure!: () => void | null
  @Prop({ default: null })
  private closeClosure!: () => void | null

  @Emit('closeModal')
  private closeModal() {
    if (this.closeClosure) {
      this.closeClosure()
    }
  }

  private changeCheckbox(e: Event) {
    const checkbox = <HTMLInputElement>e.target

    this.is_checked = checkbox.checked
  }

  private async submit(e: Event) {
    if (this.is_checked) {
      const proposal = CurrentConnectProposalStore.getCurrentConnectProposal
      if (!proposal) {
        console.error('proposal is not found')
        return
      }
      await this.$services.proposal.makeAgreedProposalDocumentAgreement(
        proposal.connectProposalHashId
      )

      proposal.isAgreedProposalDocumentAgreement = true
      CurrentConnectProposalStore.setCurrentConnectProposal(proposal)

      if (this.closure) {
        this.closure()
      }

      this.closeModal()
    }
  }
}
