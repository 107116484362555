import IConnectClientBilling from '~/Interfaces/ConnectClientBilling/IConnectClientBilling'
import { ConnectClientProfile } from '~/components'

export default class ConnectClientBilling {
  value: IConnectClientBilling
  emails!: string[]

  constructor(connectClientBilling: IConnectClientBilling) {
    this.value = {
      shouldUseDefaultEmail:
        connectClientBilling.shouldUseDefaultEmail ?? false,
      connectClientBillingDefaultEmail:
        connectClientBilling.connectClientBillingDefaultEmail,
      connectClientBillingEMails:
        connectClientBilling.connectClientBillingEMails,
      connectClientBillingStaffName:
        connectClientBilling.connectClientBillingStaffName,
      connectClientBillingTel: connectClientBilling.connectClientBillingTel,
      shouldUseHeadquartersAddress:
        connectClientBilling.shouldUseHeadquartersAddress ?? false,
      connectClientBillingZipCode:
        connectClientBilling.connectClientBillingZipCode,
      connectClientBillingPrefecture:
        connectClientBilling.connectClientBillingPrefecture,
      connectClientBillingCity: connectClientBilling.connectClientBillingCity,
      connectClientBillingAddress:
        connectClientBilling.connectClientBillingAddress,
      connectClientBillingContactFirstName:
        connectClientBilling.connectClientBillingContactFirstName,
      connectClientBillingContactLastName:
        connectClientBilling.connectClientBillingContactLastName,
      connectClientBillingContactFirstNameKana:
        connectClientBilling.connectClientBillingContactFirstNameKana,
      connectClientBillingContactLastNameKana:
        connectClientBilling.connectClientBillingContactLastNameKana,
      connectClientBillingDepartmentName:
        connectClientBilling.connectClientBillingDepartmentName,
      connectClientBillingPositionTitle:
        connectClientBilling.connectClientBillingPositionTitle,
    }
    if (!this.value.connectClientBillingContactLastName) {
      this.value.connectClientBillingContactLastName =
        this.value.connectClientBillingStaffName ?? ''
    }

    this.emails = connectClientBilling?.connectClientBillingEMails
      ? connectClientBilling.connectClientBillingEMails.map(
          (billingEmail) => billingEmail.email
        )
      : []
  }

  validateEmail(): boolean {
    const length = this.emails.filter((email) => email !== '').length

    if (length > 0) {
      return true
    }

    if (this.value.shouldUseDefaultEmail) {
      return true
    }

    return false
  }

  toData() {
    return {
      shouldUseDefaultEmail: this.value.shouldUseDefaultEmail,
      connectClientBillingEMails: this.emails ?? null,
      connectClientBillingStaffName: this.value.connectClientBillingStaffName,
    }
  }

  toParams() {
    return {
      shouldUseDefaultEmail: this.value.shouldUseDefaultEmail,
      connectClientBillingEMails: this.emails ?? null,
      connectClientBillingTel: this.value.connectClientBillingTel,
      shouldUseHeadquartersAddress: this.value.shouldUseHeadquartersAddress,
      connectClientBillingZipCode: this.value.connectClientBillingZipCode,
      connectClientBillingPrefecture: this.value.connectClientBillingPrefecture,
      connectClientBillingCity: this.value.connectClientBillingCity,
      connectClientBillingAddress: this.value.connectClientBillingAddress,
      connectClientBillingContactFirstName: this.value
        .connectClientBillingContactFirstName,
      connectClientBillingContactLastName: this.value
        .connectClientBillingContactLastName,
      connectClientBillingContactFirstNameKana: this.value
        .connectClientBillingContactFirstNameKana,
      connectClientBillingContactLastNameKana: this.value
        .connectClientBillingContactLastNameKana,
      connectClientBillingDepartmentName: this.value
        .connectClientBillingDepartmentName,
      connectClientBillingPositionTitle: this.value
        .connectClientBillingPositionTitle,
    }
  }

  useSameValue(connectClientProfile: ConnectClientProfile) {
    if (this.value.shouldUseHeadquartersAddress) {
      this.value.connectClientBillingZipCode =
        connectClientProfile.value.connectClientZipCode
      this.value.connectClientBillingPrefecture =
        connectClientProfile.value.connectClientPrefecture
      this.value.connectClientBillingCity =
        connectClientProfile.value.connectClientCity
      this.value.connectClientBillingAddress =
        connectClientProfile.value.connectClientAddress
    }
  }

  get isRegistered(): boolean {
    return (
      !!this.value.connectClientBillingTel &&
      this.isRegisteredAddress &&
      !!this.value.connectClientBillingContactFirstName &&
      !!this.value.connectClientBillingContactLastName &&
      !!this.value.connectClientBillingContactFirstNameKana &&
      !!this.value.connectClientBillingContactLastName
    )
  }

  get isRegisteredAddress(): boolean {
    if (this.value.shouldUseHeadquartersAddress) {
      return true
    }

    return (
      !!this.value.connectClientBillingZipCode &&
      !!this.value.connectClientBillingPrefecture &&
      !!this.value.connectClientBillingCity &&
      !!this.value.connectClientBillingAddress
    )
  }
}
