var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('div',{ref:"threadForm",class:_vm.s.threadForm},[_c('div',{class:_vm.s.form},[_c('div',{class:_vm.s.textareaContainer},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],ref:"textarea",class:[
          _vm.s.textarea,
          ( _obj = {}, _obj[_vm.s.disabled] = _vm.disabled, _obj[_vm.s.rejected] = _vm.disabledType === 'rejected', _obj[_vm.s.isSending] = _vm.isSending, _obj ) ],attrs:{"id":"message","placeholder":_vm.placeholder,"disabled":_vm.disabledForm},domProps:{"value":(_vm.message)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.message=$event.target.value},_vm.autoTextareaHeight]}}),_vm._v(" "),(_vm.isSending)?_c('div',{class:_vm.s.sending},[_c('ConnectIcon',{class:_vm.s.loading,attrs:{"icon-type":"loading","fill":"#63615F"}}),_vm._v(" "),_c('p',{class:_vm.s.text},[_vm._v("メッセージを送信中です")])],1):_vm._e()]),_vm._v(" "),_c('button',{ref:"submitButton",class:[
        _vm.s.submit_button,
        ( _obj$1 = {}, _obj$1[_vm.s.file_uploaded] = _vm.file || _vm.message, _obj$1[_vm.s.disabled] = _vm.disabled, _obj$1[_vm.s.rejected] = _vm.disabledType === 'rejected', _obj$1[_vm.s.isSending] = _vm.isSending, _obj$1 ) ],attrs:{"aria-label":"送信","type":"button","disabled":_vm.disabledForm},on:{"click":_vm.submitMessage}},[_c('font-awesome-icon',{attrs:{"icon":"paper-plane"}})],1),_vm._v(" "),_c('div',{class:_vm.s.bottom},[_c('button',{class:[
          _vm.s.template_button,
          ( _obj$2 = {}, _obj$2[_vm.s.disabled] = _vm.disabled, _obj$2[_vm.s.rejected] = _vm.disabledType === 'rejected', _obj$2 ) ],attrs:{"type":"button","disabled":_vm.disabledForm},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.isTemplateOpened = true}}},[_vm._v("\n        テンプレートから入力\n        "),_c('ThreadFormTemplate',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTemplateOpened),expression:"isTemplateOpened"}],on:{"closeTemplate":_vm.closeTemplate,"copyTemplate":_vm.copyTemplate}})],1),_vm._v(" "),(_vm.$device.isDesktop)?_c('button',{class:[
          _vm.s.paperclip,
          ( _obj$3 = {}, _obj$3[_vm.s.disabled] = _vm.disabled, _obj$3[_vm.s.rejected] = _vm.disabledType === 'rejected', _obj$3 ) ],attrs:{"type":"button","aria-label":"ファイル添付","disabled":_vm.disabledForm},on:{"click":_vm.openFileUpload}},[_c('font-awesome-icon',{attrs:{"icon":"paperclip"}})],1):_vm._e(),_vm._v(" "),(_vm.file)?_c('div',{class:_vm.s.file_uploaded},[_c('span',[_vm._v("添付あり（"+_vm._s(_vm.file.name)+"）")]),_vm._v(" "),_c('div',{class:_vm.s.close,on:{"click":_vm.fileUploadCancel}},[_c('font-awesome-icon',{attrs:{"icon":"times-circle"}})],1)]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }