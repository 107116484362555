import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { IConnectInvoice } from '~/Interfaces'

@Module({ stateFactory: true, namespaced: true, name: 'ConnectInvoicesDefine' })
export default class ConnectInvoicesDefine extends VuexModule {
  connectInvoices: IConnectInvoice[] = []

  @Mutation
  setConnectInvoices(connectInvoices: IConnectInvoice[]) {
    if (connectInvoices === undefined) {
      return
    }

    this.connectInvoices = connectInvoices
  }

  @Mutation
  clear() {
    this.connectInvoices = []
  }

  get getConnectInvoices(): IConnectInvoice[] {
    return this.connectInvoices
  }

  get findConnectInvoice(): (
    connectInvoiceHashId: string
  ) => IConnectInvoice | undefined {
    const self = this

    return (connectInvoiceHashId: string) => {
      return self.connectInvoices.find((connectInvoice) => {
        return connectInvoice.connectInvoiceHashId === connectInvoiceHashId
      })
    }
  }
}
