






















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { SnackbarStore } from '~/utils/store-accessor'

@Component
export default class ClientProposalSnackbar extends Vue {
  @Prop()
  private message!: string

  @Prop()
  private name!: string

  get isVisible() {
    return SnackbarStore.shouldDisplay(this.name)
  }

  private close() {
    SnackbarStore.hide(this.name)
  }

  private onAfterEnter() {
    setTimeout(() => {
      this.close()
    }, 3000)
  }
}
