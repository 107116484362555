










import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ConnectCompanyProfileCard from '~/components/Client/profile/ConnectCompanyProfileCard'

@Component({})
export default class ClientProposalListItemReview extends Vue {
  @Prop()
  private connectCompanyProfileCard!: ConnectCompanyProfileCard

  get reviewContentsLength() {
    return this.connectCompanyProfileCard.value.review?.length ?? 0
  }
}
