


















































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IConnectReferenceCheck from '~/Interfaces/IConnectReferenceCheck'
import HighlightText from '~/components/common/profile/connectCompany/highlight-text.vue'

@Component({
  components: {
    HighlightText,
  },
})
export default class ProfileConnectReferenceClientView extends Vue {
  @Prop()
  private connectReferenceCheck!: IConnectReferenceCheck

  @Prop()
  private searchWords!: string[]

  @Prop()
  private isRenewal!: boolean

  get requestedIssuesLabels(): string {
    return this.connectReferenceCheck?.requestedIssuesLabel.join('、')
  }

  canShowStar(index: number): boolean {
    return index <= this.connectReferenceCheck.recommendPoint
  }
}
