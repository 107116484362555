import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import dayjs from 'dayjs'
import { IThreadGroup, IThreadGroupAccount } from '~/Interfaces'

@Module({ stateFactory: true, namespaced: true, name: 'ThreadGroup' })
export default class ThreadGroup extends VuexModule {
  threadGroup: IThreadGroup | undefined = undefined

  @Mutation
  setThreadGroup(threadGroup: IThreadGroup) {
    this.threadGroup = threadGroup
  }

  get getThreadGroup(): IThreadGroup | undefined {
    return this.threadGroup
  }

  get getThreadGroupAccountSelf(): IThreadGroupAccount | null {
    if (this.threadGroup === undefined) {
      return null
    }

    const index = this.threadGroup.threadGroupAccounts.findIndex(
      (threadGroupAccount: IThreadGroupAccount) => {
        return threadGroupAccount.isSelf
      }
    )

    return this.threadGroup.threadGroupAccounts[index]
  }

  get getThreadGroupAccountOther(): IThreadGroupAccount | null {
    if (this.threadGroup === undefined) {
      return null
    }

    const index = this.threadGroup.threadGroupAccounts.findIndex(
      (threadGroupAccount: IThreadGroupAccount) => {
        return !threadGroupAccount.isSelf
      }
    )

    return this.threadGroup.threadGroupAccounts[index]
  }

  get getNameOther(): string | null {
    const account = this.getThreadGroupAccountOther
    if (account === null) {
      return ''
    }

    if (account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }

    if (account.company) {
      return account.company.companyName
    }

    return ''
  }

  get getNameSelf(): string | null {
    const account = this.getThreadGroupAccountSelf
    if (account === null) {
      return ''
    }

    if (account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }

    if (account.company) {
      return account.company.companyName
    }

    return ''
  }

  get getImageOther(): string | null {
    const account = this.getThreadGroupAccountOther
    if (account === null) {
      return null
    }

    if (account.connectClient?.connectClientHashId) {
      return null
    }

    if (account.company?.companyHashId) {
      return account.company.companyImage === ''
        ? null
        : account.company.companyImage
    }

    return null
  }

  get getFoundationDate(): string | null {
    const account = this.getThreadGroupAccountOther
    if (account === null) {
      return null
    }

    if (account.company) {
      if (account.company.companyFoundedAt !== null) {
        return dayjs(account.company.companyFoundedAt).format('YYYY/MM/DD')
      }
    }
    if (account.connectClient) {
      return null
    }

    return null
  }
}
