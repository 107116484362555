// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".input_1MPYL{padding:13px;background:#fff;border:1px solid #71a8d9;border-radius:2px;font-size:14px;font-weight:400;letter-spacing:0}.input_1MPYL:-moz-placeholder-shown{background:#e4f0fc}.input_1MPYL:-ms-input-placeholder{background:#e4f0fc}.input_1MPYL:placeholder-shown{background:#e4f0fc}.input_1MPYL::-moz-placeholder{color:#aabdcc}.input_1MPYL:-ms-input-placeholder{color:#aabdcc}.input_1MPYL::placeholder{color:#aabdcc}.input_1MPYL.error_3_J3p{border:1px solid #c94282!important;background:#ffe6f2;color:#002853}.error_alert_3ckuL{margin-top:8px;text-align:left;font-size:12px;color:#c94282}", ""]);
// Exports
exports.locals = {
	"input": "input_1MPYL",
	"error": "error_3_J3p",
	"error_alert": "error_alert_3ckuL"
};
module.exports = exports;
