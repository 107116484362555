var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.s.layout,on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal($event)}}},[_c('div',{class:[_vm.s.modal_wrapper, ( _obj = {}, _obj[_vm.s.max_height_95vh] = _vm.max_height_95vh, _obj )]},[_c('div',{class:[
        _vm.s.modal,
        ( _obj$1 = {}, _obj$1[_vm.s.scroll_y] = _vm.scroll_y, _obj$1 ),
        ( _obj$2 = {}, _obj$2[_vm.s.bottom_padding_40px] = _vm.bottom_padding_40px, _obj$2 ),
        ( _obj$3 = {}, _obj$3[_vm.s.bottom_padding_0px] = _vm.bottom_padding_0px, _obj$3 ),
        ( _obj$4 = {}, _obj$4[_vm.s.padding_width_50px] = _vm.padding_width_50px, _obj$4 ) ]},[(!_vm.hide_close_button)?_c('div',{class:_vm.s.close_button,on:{"click":_vm.closeModal}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1):_vm._e(),_vm._v(" "),_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }