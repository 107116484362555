export default (app, inject) => {
  let isAdmin = false
  let adminHashId: string | null
  let connectClientHashId: string | null
  let companyHashId: string | null

  const isAdminUser = (): boolean => isAdmin
  const getAdminHashId = (): string => adminHashId

  const getConnectClientHashId = (): string | null => connectClientHashId

  const getCompanyHashId = (): string | null => companyHashId

  window.addEventListener('message', function (e) {
    const postMessageDomain = process.env.NUXT_ENV_POST_MESSAGE_DOMAIN ?? ''
    // 送信元のドメインのチェック
    if (!e.origin.includes(postMessageDomain)) return
    switch (e.data.action) {
      case 'bridgeAdmin':
        isAdmin = true
        adminHashId = e.data.adminHashId as string
        if (e.data.connectClientHashId) {
          connectClientHashId = e.data.connectClientHashId as string
        }
        if (e.data.companyHashId) {
          app.$auth.loginWith('admin_client', {
            data: { admin_hash_id: adminHashId },
          })
          companyHashId = e.data.companyHashId as string
        }
        break
    }
  })

  inject('admin', {
    isAdminUser,
    getAdminHashId,
    getConnectClientHashId,
    getCompanyHashId,
  })
}
