import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import ConnectCompanyProfileValidator from '../components/Profile/ConnectCompany/ConnectCompanyProfileValidator'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectCompanyProfileValidatorDefine',
})
export default class ConnectCompanyProfileValidatorDefine extends VuexModule {
  connectCompanyProfileValidator:
    | ConnectCompanyProfileValidator
    | undefined = undefined

  @Mutation
  set(connectCompanyProfileValidator: ConnectCompanyProfileValidator) {
    this.connectCompanyProfileValidator = connectCompanyProfileValidator
  }

  get get(): ConnectCompanyProfileValidator | undefined {
    return this.connectCompanyProfileValidator
  }
}
