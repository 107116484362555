








































import Vue from 'vue'
import { Component, Emit, Prop } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import IConnectCompanyMemberSupport from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberSupport'
import { IConnectCompanyMember } from '~/Interfaces'

@Component({
  components: { ConnectIcon },
})
export default class Page extends Vue {
  @Prop()
  private member!: IConnectCompanyMember

  @Prop()
  private index!: number

  get goodAtSupports(): IConnectCompanyMemberSupport[] {
    return this.member.supports.filter((support) => support.supportStatus === 2)
  }

  @Emit('click')
  private onClick() {}

  private formatDate(date: string) {
    return dayjs(date).format('YYYY年M月')
  }
}
