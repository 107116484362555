import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import IConnectContractRenewal from '../Interfaces/IConnectContractRenewal'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectContractRenewalStoreDefine',
})
export default class ConnectContractRenewalStoreDefine extends VuexModule {
  connectContractRenewals: IConnectContractRenewal[] = []

  get get(): (
    connectProposalHashId: string
  ) => IConnectContractRenewal | undefined {
    const self = this

    return (connectProposalHashId: string) => {
      return self.connectContractRenewals.find(
        (connectContractRenewal) =>
          connectContractRenewal.connectProposalHashId === connectProposalHashId
      )
    }
  }

  @Mutation
  set(connectContractRenewals: IConnectContractRenewal[]) {
    this.connectContractRenewals = connectContractRenewals
  }
}
