// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".swiper_1RvuF{margin-left:0;margin-right:0;max-width:680px}", ""]);
// Exports
exports.locals = {
	"swiper": "swiper_1RvuF"
};
module.exports = exports;
