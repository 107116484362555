import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectProposal from '~/Interfaces/IConnectProposal'

export default class ProposalDecisionReasonService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * アンケートを送信
   */
  async postReason(
    proposal_hash_id: string,
    type: 'negotiating' | 'quotationApproveRequest' | 'rejected',
    value: 'policy' | 'career' | 'personality' | 'price'
  ): Promise<IConnectProposal | undefined> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post(
        'api/client/proposal/reason/' + proposal_hash_id,
        {
          type,
          value,
        }
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  /**
   * アンケートを取得
   */
  async existsReason(
    proposal_hash_id: string,
    type: 'negotiating' | 'quotationApproveRequest' | 'rejected'
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.get(
        'api/client/proposal/reason/' + proposal_hash_id + '/' + type
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }
}
