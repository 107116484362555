// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3BzxH{display:flex;align-items:center;grid-gap:5px;gap:5px;line-height:18px}.container_3BzxH .icon_container_1EwZy{width:18px;height:18px;display:flex;justify-content:center;align-items:center}.container_3BzxH .text_container_FK28D{white-space:nowrap;display:flex;align-items:center;color:#555;color:var(--Text-60,#555);font-size:13px;font-style:normal;font-weight:600;line-height:1}", ""]);
// Exports
exports.locals = {
	"container": "container_3BzxH",
	"icon_container": "icon_container_1EwZy",
	"text_container": "text_container_FK28D"
};
module.exports = exports;
