import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import IConnectQuotationDiffer from '../Interfaces/connectQuotaton/IConnectQuotationDiffer'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectQuotationDifferStoreDefine',
})
export default class ConnectQuotationDifferStoreDefine extends VuexModule {
  differ: IConnectQuotationDiffer = {}

  @Mutation
  setDiffer(differ: IConnectQuotationDiffer | undefined) {
    if (differ === undefined) {
      return
    }
    console.log(differ)
    this.differ = differ
  }

  @Mutation
  clear() {
    this.differ = {}
  }

  get getDiffer() {
    return this.differ
  }
}
