











import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class HighlightText extends Vue {
  @Prop()
  private originText!: string

  @Prop()
  private searchWords!: string[]

  get highLight(): string[] {
    if (!this.searchWords || this.searchWords.length === 0) {
      return [this.originText]
    }

    const replacedText = this.searchWords.reduce((oldText, searchWord) => {
      oldText = oldText?.replaceAll(searchWord, ',' + searchWord + ',') ?? ''
      return oldText.replace(/^,/g, '').replace(/(,$)/g, '')
    }, this.originText)

    return replacedText.split(',').filter((e) => e !== '')
  }

  private shouldHighLight(text: string): boolean {
    return !!this.searchWords && this.searchWords.includes(text)
  }
}
