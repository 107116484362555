import {Module, Mutation, VuexModule} from 'vuex-module-decorators'
import IReferenceCheck from "../Interfaces/referenceCheck/IReferenceCheck";

export interface IReferenceList {
  references: IReferenceCheck[],
  saHashId: string
}
@Module({ stateFactory: true, namespaced: true, name: 'References'})
export default class References extends VuexModule {
  referencesLists: IReferenceList[] = []

  @Mutation
  setReferences(referenceList: IReferenceList|undefined)
  {
    if(referenceList === undefined) {
      return
    }

    this.referencesLists.push(referenceList);
  }


  get getReferences() {
    var self = this;

    return (sa_hash_id: string) => {
      const referencesList = self.referencesLists.find(referencesList => referencesList.saHashId === sa_hash_id)
      return referencesList?.references
    }
  }
}
