

















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'
import ClientProductIdIconWrapper from '~/components/Client/ClientProductIdIconWrapper.vue'
import { ConnectProposalThreadStore } from '~/utils/store-accessor'

@Component({
  components: {
    ClientProductIdIconWrapper,
  },
})
export default class ClientProposalPartner extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop()
  private isHeader!: boolean

  get isVisibleIcon(): boolean {
    const threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    return !!threadHashId
  }

  get partnerName(): string {
    return this.$services.proposal.getCompanyName(this.connectProposal)
  }
}
