// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon_wrapper_2sh7r{display:flex;align-items:center;justify-content:center}.icon_wrapper_2sh7r img{cursor:pointer;border-radius:50%;width:24px;height:24px}.icon_wrapper_2sh7r .icon_1GJ9K{display:flex;align-items:center;justify-content:center}.icon_wrapper_2sh7r .icon_1GJ9K .active_6DUAB{display:none}.icon_wrapper_2sh7r .icon_1GJ9K .inactive_2TuJD,.icon_wrapper_2sh7r .icon_1GJ9K.is_active_1x8cB .active_6DUAB,.icon_wrapper_2sh7r .icon_1GJ9K:hover .active_6DUAB{display:inline;display:initial}.icon_wrapper_2sh7r .icon_1GJ9K.is_active_1x8cB .inactive_2TuJD,.icon_wrapper_2sh7r .icon_1GJ9K:hover .inactive_2TuJD{display:none}", ""]);
// Exports
exports.locals = {
	"icon_wrapper": "icon_wrapper_2sh7r",
	"icon": "icon_1GJ9K",
	"active": "active_6DUAB",
	"inactive": "inactive_2TuJD",
	"is_active": "is_active_1x8cB"
};
module.exports = exports;
