






import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ClientProposalDetailTitle extends Vue {
  @Prop()
  private label!: string
}
