import { Context } from '@nuxt/types'
import { LockStore } from '~/utils/store-accessor'
import IHearingSheetMaster from '~/Interfaces/hearingSheet/IHearingSheetMaster'
import IProduct from '~/Interfaces/IProduct'
import ConnectProduct from '~/components/Product/ConnectProduct'
import IConnectOrder from '~/Interfaces/IConnectOrder'

export default class HearingSheetService {
  private readonly context: Context

  constructor(context: Context) {
    this.context = context
  }

  /**
   * ログイン済みかどうか
   */
  async getMasterData(): Promise<IHearingSheetMaster | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.get(
        'api/operation/hearing_sheet/master_data'
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }

  /**
   */
  async get(
    hash_id: string
  ): Promise<{ order: IConnectOrder; product: IProduct | null } | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.get(
        'api/operation/hearing_sheet/' + hash_id
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }

  /**
   */
  async save(
    hash_id: string,
    connectProduct: ConnectProduct
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.post(
        'api/operation/hearing_sheet/save/' + hash_id,
        {
          ...connectProduct.value,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }
}
