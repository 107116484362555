import {
  ConstantValue,
  IConnectCompanyPreferredCustomerOrProducts,
  IConnectCompanySupport,
  IConnectCompanySupports,
} from '~/Interfaces'
import IConnectCompanyMemberSupport from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberSupport'
import IConnectCompanyMemberPreferredCustomerOrProduct from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberPreferredCustomerOrProduct'

export default class SupportItemInspector {
  private constSupports: ConstantValue[]
  private constPreferredCustomerOrProducts: ConstantValue[]
  private supports: IConnectCompanySupports | IConnectCompanyMemberSupport[]
  private preferredCustomerOrProducts:
    | IConnectCompanyPreferredCustomerOrProducts
    | IConnectCompanyMemberPreferredCustomerOrProduct[]

  private readonly preferred0Others!: string | null
  private readonly preferred3Others!: string | null
  private readonly preferredCount: number = 0
  private errors: {
    supports: boolean[]
    preferredCount: boolean
    preferred0Others: boolean
    preferred3Others: boolean
  } = {
    supports: [],
    preferredCount: false,
    preferred0Others: false,
    preferred3Others: false,
  }

  constructor(
    constSupports: ConstantValue[],
    constPreferredCustomerOrProducts: ConstantValue[],
    supports: IConnectCompanySupports | IConnectCompanyMemberSupport[],
    preferredCustomerOrProducts:
      | IConnectCompanyPreferredCustomerOrProducts
      | IConnectCompanyMemberPreferredCustomerOrProduct[],
    preferred0Others: string | null,
    preferred3Others: string | null,
    preferredCount: number
  ) {
    this.constSupports = constSupports
    this.constPreferredCustomerOrProducts = constPreferredCustomerOrProducts
    this.supports = supports
    this.preferredCustomerOrProducts = preferredCustomerOrProducts
    this.preferred0Others = preferred0Others
    this.preferred3Others = preferred3Others
    this.preferredCount = preferredCount
  }

  public getSupportId(id: number, order: number) {
    const list = [-1, 8, 17, 19, 26, 28, 34, 40, 44, 53]
    return this.constSupports[id + list[order]]?.value
  }

  public getPreferredCheckId(id: number, order: number) {
    const list = [-1, 21, 40, 45, 51, 69]
    const key = id + list[order]
    return this.constPreferredCustomerOrProducts[key]?.value
  }

  public getPreferredLabel(id: number | undefined) {
    if (id === undefined) return ''

    const constPreferredCustomerOrProduct = this.constPreferredCustomerOrProducts.find(
      (p) => p.value === id
    )
    return constPreferredCustomerOrProduct?.label
  }

  public getPreferredValue(categoryId: number, id: number | undefined) {
    if (id === undefined) return false

    const preferredCustomerOrProduct = this.preferredCustomerOrProducts.find(
      (p) => p.categoryId === categoryId
    )

    if (!preferredCustomerOrProduct) return false

    return preferredCustomerOrProduct.preferredValues.includes(id)
  }

  public getSupport(id: number | undefined) {
    if (id === undefined) {
      return {
        label: '',
        support: {
          supportStatus: undefined,
          supportAreas: [],
          supportEtc: null,
        },
      }
    }

    const support = this.findSupport(id)
    const constSupport = this.constSupports.find((s) => s.value === id)

    return {
      label: constSupport?.label,
      support: {
        supportStatus: support?.supportStatus ?? undefined,
        supportAreas: support?.supportAreas ?? [],
        supportEtc: support?.supportEtc ?? null,
      },
    }
  }

  public getSupportOptions(id: number, order: number) {
    const support = this.getSupport(this.getSupportId(id, order)).support
    return {
      supportAreas: support?.supportAreas ?? [],
      supportEtc: support?.supportEtc ?? null,
    }
  }

  public isVisibleOthers(categoryId: number) {
    const preferredCustomerOrProduct = this.preferredCustomerOrProducts.find(
      (p) => p.categoryId === categoryId
    )

    if (!preferredCustomerOrProduct) return false

    // その他の各ID
    let otherId = 85
    if (categoryId === 0) {
      otherId = 40
    }

    return preferredCustomerOrProduct.preferredValues.some(
      (id) => id === otherId
    )
  }

  get isExceedPreferredCount(): boolean {
    return this.preferredCount > 10
  }

  public validate(): boolean {
    this.constSupports.forEach((constSupport) => {
      const support = this.getSupport(constSupport.value).support
      if (support.supportStatus === undefined) {
        this.errors.supports[constSupport.value] = true
      } else {
        // 対象エリアが未選択
        if (
          [4, 19].includes(constSupport.value) &&
          support.supportStatus &&
          !support.supportAreas.length
        ) {
          this.errors.supports[constSupport.value] = true
        }

        // 対応可能ツールが未入力
        if (
          [41, 42, 43].includes(constSupport.value) &&
          support.supportStatus &&
          !support.supportEtc
        ) {
          this.errors.supports[constSupport.value] = true
        }
      }
    })

    // 得意を10件以上選択している
    this.errors.preferredCount = this.isExceedPreferredCount

    // その他にチェックを入れているが内容が未記入
    this.errors.preferred0Others =
      this.isVisibleOthers(0) && !this.preferred0Others
    this.errors.preferred3Others =
      this.isVisibleOthers(3) && !this.preferred3Others

    const errors = [
      this.errors.preferredCount,
      this.errors.preferred0Others,
      this.errors.preferred3Others,
      ...this.errors.supports,
    ]
    return errors.every((error) => !error)
  }

  public getErrors() {
    return this.errors
  }

  public fillEmptySupportsWithDefaultValues():
    | IConnectCompanySupports
    | IConnectCompanyMemberSupport[] {
    return this.constSupports.map((constSupport) => {
      const support = this.findSupport(constSupport.value)
      if (support) {
        return support
      }
      return {
        connectCompanyHashId: '',
        connectCompanySupportHashId: '',
        connectCompanySupportId: 0,
        supportId: constSupport.value,
        supportString: '',
        supportSimplifiedString: '',
        supportStatus: 0,
        supportAreas: [],
        supportEtc: null,
      } as IConnectCompanySupport | IConnectCompanyMemberSupport
    }) as IConnectCompanySupports | IConnectCompanyMemberSupport[]
  }

  private findSupport(supportId: number) {
    let support:
      | IConnectCompanyMemberSupport
      | IConnectCompanySupport
      | undefined
    this.supports.forEach(
      (s: IConnectCompanySupport | IConnectCompanyMemberSupport) => {
        if (s.supportId === supportId) {
          support = s
        }
      }
    )
    return support
  }
}
