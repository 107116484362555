// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".clientProposalDetailHeader_2lq67{padding:10px 20px;height:55px;background:#f3f2f2}.clientProposalDetailHeader_2lq67 .closeButton_3OKys{cursor:pointer;padding:7.5px 15px;display:flex;align-items:center;grid-gap:10px;gap:10px;border-radius:100px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.clientProposalDetailHeader_2lq67 .closeButton_3OKys span{font-size:13px;font-weight:600;font-family:\"Hiragino Kaku Gothic Pro\";color:#555}.clientProposalDetailHeader_2lq67 .closeButton_3OKys .icon_3pvAI{display:flex;align-items:center;justify-content:center;height:18px;width:18px}.clientProposalDetailHeader_2lq67 .closeButton_3OKys:hover{background:#d7e6f4}.clientProposalDetailHeader_2lq67 .closeButton_3OKys:active{background:#afcee9}", ""]);
// Exports
exports.locals = {
	"clientProposalDetailHeader": "clientProposalDetailHeader_2lq67",
	"closeButton": "closeButton_3OKys",
	"icon": "icon_3pvAI"
};
module.exports = exports;
