// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".label_2cq_T{display:flex;justify-content:center;align-items:center;width:34px;height:20px;border-radius:2px;background:#d7e6f4;background:var(--Primary-30,#d7e6f4);color:#3379b7;color:var(--Primary-60,#3379b7);text-align:center;font-family:Hiragino Kaku Gothic Pro;font-size:12px;font-style:normal;font-weight:600;line-height:normal}", ""]);
// Exports
exports.locals = {
	"label": "label_2cq_T"
};
module.exports = exports;
