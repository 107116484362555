













import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'

@Component
export default class WrapperIcon extends Vue {
  @Prop({ default: false })
  private isActive!: boolean

  private get getImage(): string | undefined {
    const teamImage = this.$services.user.getTeamImage
    if (teamImage) {
      return teamImage
    }

    const connectCompanyImage = this.$services.user.getConnectCompanyImage

    return connectCompanyImage
  }
}
