































































































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import ConnectInput from '~/components/common/components/ConnectInput.vue'
import { SupportItemButtonStatus } from '~/Interfaces'

@Component({
  components: {
    ConnectInput,
  },
})
export default class SupportItemButton extends Vue {
  @Prop()
  private id!: number

  @Prop()
  private index!: number

  @Prop()
  private label!: string

  @Prop()
  private value!: SupportItemButtonStatus

  @Prop({
    default: {
      supportAreas: [],
      supportEtc: null,
    },
  })
  private options!: {
    supportAreas: number[]
    supportEtc: string | null
  }

  @Prop()
  private error!: boolean

  private IDS_WITH_AREA = [4, 19]
  private IDS_WITH_ETC = [41, 42, 43]
  private AREA_LIST = [
    '1都3県',
    '北海道',
    '東北',
    '関東',
    '中部',
    '近畿',
    '中国',
    '四国',
    '九州',
  ]

  private areas: {
    id: number
    label: string
    checked: boolean
  }[] = []

  mounted() {
    this.refreshArea()
  }

  get isGrey() {
    return this.index % 2 === 0
  }

  // MEMO: 未入力と未対応の支援に関してはオプションを編集させない
  get disabledOptions() {
    return !this.value
  }

  get isVisibleArea() {
    return this.IDS_WITH_AREA.includes(this.id)
  }

  get isVisibleEtc() {
    return this.IDS_WITH_ETC.includes(this.id)
  }

  get areaTitle() {
    const splitLabel = this.label.split('（')

    return `${splitLabel[0]}の営業対象エリア`
  }

  get placeholder() {
    const list: { [key in number]: string } = {
      41: '例：Zoom Phone',
      42: '例：Zoom、Google Meets',
      43: '例：Salesforce、キントーン',
    }
    return list[this.id] ?? `例）対応可能ツール`
  }

  get isSelectedAll() {
    return this.areas.every((a) => a.checked)
  }

  get statusError() {
    return this.error && this.value === undefined
  }

  get optionError() {
    return this.error && !this.disabledOptions
  }

  isSelectedArea(areaId: number) {
    return (
      this.options.supportAreas && this.options.supportAreas.includes(areaId)
    )
  }

  private isSelected(status: SupportItemButtonStatus) {
    return this.value === status
  }

  private toggle(areaId: number) {
    if (!this.options.supportAreas) {
      this.options.supportAreas = []
    }
    const index = this.options.supportAreas.indexOf(areaId)
    if (index === -1) {
      this.options.supportAreas.push(areaId)
    } else {
      const supportAreas = this.options.supportAreas
      this.options.supportAreas = supportAreas.filter((a) => a !== areaId)
    }
    this.onUpdate(this.id, this.value, this.options.supportAreas, null)
    this.refreshArea()
  }

  private toggleAll() {
    if (this.isSelectedAll) {
      this.options.supportAreas = []
    } else {
      this.options.supportAreas = [0, 1, 2, 3, 4, 5, 6, 7, 8]
    }
    this.onUpdate(this.id, this.value, this.options.supportAreas, null)
    this.refreshArea()
  }

  @Watch('options')
  private refreshArea() {
    this.areas = this.AREA_LIST.map((area, id) => {
      return {
        id,
        label: area,
        checked: this.isSelectedArea(id),
      }
    })
  }

  private onInput() {
    this.onUpdate(this.id, this.value, null, this.options.supportEtc ?? null)
  }

  private onClick(status: SupportItemButtonStatus) {
    this.onUpdate(
      this.id,
      status,
      this.options.supportAreas,
      this.options.supportEtc ?? null
    )
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  @Emit('click')
  private onUpdate(
    id: number,
    status: SupportItemButtonStatus,
    areas: number[] | null,
    etc: string | null
  ) {}
  /* eslint-enable @typescript-eslint/no-unused-vars */
}
