













import { Component, Prop, Vue } from 'nuxt-property-decorator'
import PartnerProfileCaseInterviewCard from '~/components/common/profile/partner/PartnerProfile/PartnerProfileCaseInterviewCard.vue'
import { ICaseInterviewOgp } from '~/Interfaces/connectCompany/IConnectCompany'

@Component({
  components: { PartnerProfileCaseInterviewCard },
})
export default class PartnerProfileCaseInterviewCards extends Vue {
  @Prop()
  private caseInterviewOgps!: ICaseInterviewOgp[]

  private get options() {
    return {
      spaceBetween: 20,
      slidesPerView: 3,
      width: 280 * 3,
      grabCursor: true,
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
    }
  }
}
