// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".description_eS15K{font-style:normal;font-weight:400;font-size:14px;line-height:21px;color:#2c3846;padding:0 0 40px}.example_3k09C{position:relative;background:#f9f9f9;padding:20px;margin-bottom:50px}.exampleIcon_3VA6a{position:absolute;display:flex;justify-content:center;align-items:center;width:33px;height:20px;background:#2f5980;border-radius:5px 5px 0 0;top:-20px;left:0;font-weight:600;font-size:13px;line-height:20px;color:#fff}.labelDescription_mjjkF{font-style:normal;font-weight:400;font-size:14px;line-height:21px;color:#707070;padding-left:30px;padding-top:10px}.textField_YrTlP{margin:0 0 20px}.featureBox_2E12B{background:#f9f9f9;padding:20px;margin-bottom:40px}.featureBox_2E12B:last-child{margin-bottom:0}.featureOrder_7IjRh{display:flex;justify-content:center;align-items:center;width:24px;height:24px;background:#2f5980;border-radius:50px;font-style:normal;font-weight:600;font-size:12px;line-height:130%;color:#fff;margin-bottom:20px}", ""]);
// Exports
exports.locals = {
	"description": "description_eS15K",
	"example": "example_3k09C",
	"exampleIcon": "exampleIcon_3VA6a",
	"labelDescription": "labelDescription_mjjkF",
	"textField": "textField_YrTlP",
	"featureBox": "featureBox_2E12B",
	"featureOrder": "featureOrder_7IjRh"
};
module.exports = exports;
