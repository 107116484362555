












import { Component, Vue } from 'nuxt-property-decorator'

import { ThreadDetailsStore, TopicStore } from '~/utils/store-accessor'
import { IThreadDetail, IThreadGroupAccount, ITopic } from '~/Interfaces'
import ThreadMessage from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalMessage/components/ThreadMessage/index.vue'
@Component({
  components: { ThreadMessage },
})
export default class ThreadMessages extends Vue {
  get topic(): ITopic | undefined {
    return TopicStore.getTopic
  }

  get threadDetails(): IThreadDetail[] {
    return ThreadDetailsStore.getThreadDetails
  }

  get threadGroupAccount(): IThreadGroupAccount | null {
    return TopicStore.getThreadGroupAccountSelf
  }
}
