var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shouldDisplay)?_c('Modal',{attrs:{"padding_width_50px":true,"bottom_padding_0px":true,"max_height_95vh":true},on:{"closeModal":_vm.closeModal}},[_c('div',{class:_vm.s.title},[_vm._v("プロフィール写真の調整")]),_vm._v(" "),_c('div',{class:_vm.s.content},[_c('div',{class:_vm.s.drag_drop_area},[_c('div',{class:_vm.s.left},[_c('img',{attrs:{"src":require("assets/images/icon_cloud.svg")}}),_vm._v(" "),_c('div',{class:_vm.s.description},[_vm._v("ファイルをドラッグしてアップロード")])]),_vm._v(" "),_c('div',{class:_vm.s.upload_button},[_c('span',[_vm._v("ファイルを選択")])]),_vm._v(" "),_c('input',{ref:"input",class:_vm.s.input,attrs:{"type":"file","accept":"image/*","value":"ファイルを選択"},on:{"change":_vm.inputFile}})]),_vm._v(" "),_c('div',{staticClass:"crop_box"},[(_vm.imageSrc)?_c('vue-cropper',{ref:"cropper",class:_vm.s.cropper,attrs:{"src":_vm.imageSrc,"preview":".upload_img_preview_outer, .upload_img_preview_inner","guides":true,"view-mode":0,"background":true,"auto-crop-area":0.9,"aspect-ratio":1,"container-style":{
          maxWidth: '200px',
          maxHeight: '400px',
          width: '100%',
          height: '100%',
        },"img-style":{
          maxWidth: '180px',
          maxHeight: '180px',
          width: '100%',
          height: '100%',
        }}}):_vm._e()],1),_vm._v(" "),(_vm.imageSrc)?_c('div',{staticClass:"upload_img_previews"},[_c('div',{staticClass:"upload_img_preview_outer"},[_c('img',{attrs:{"src":_vm.imageSrc}})]),_vm._v(" "),_c('div',{staticClass:"upload_img_preview_inner"},[_c('img',{attrs:{"src":_vm.imageSrc}})])]):_vm._e(),_vm._v(" "),(_vm.imageSrc)?_c('div',{class:_vm.s.buttons},[_c('div',{class:_vm.s.submit_button,on:{"click":_vm.submit}},[_c('span',[_vm._v("確定")])])]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }