// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".message_3GhUZ{margin-top:20px}.message_3GhUZ,.message_3GhUZ .button_3vvzI{display:flex;justify-content:center}.message_3GhUZ .button_3vvzI{text-decoration:none;cursor:pointer;align-items:center;width:200px;height:40px;border:none;background:linear-gradient(90deg,#0066cb,#00a1c7 50.13%,#00a5a7);border-radius:20px;font-size:14px;font-weight:600;line-height:14px;letter-spacing:0;color:#fff}", ""]);
// Exports
exports.locals = {
	"message": "message_3GhUZ",
	"button": "button_3vvzI"
};
module.exports = exports;
