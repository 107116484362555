import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectClientBilling from '~/Interfaces/ConnectClientBilling/IConnectClientBilling'
import ConnectClientBilling from '~/components/Client/ConnectClientBilling'

export default class ClientBillingService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   *
   */
  async find(): Promise<IConnectClientBilling> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get('api/client/connect_client/billing')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return {}
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   * @param connectClientBilling
   */
  async update(connectClientBilling: ConnectClientBilling): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/client/connect_client/billing/update',
        {
          data: connectClientBilling.toData(),
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
