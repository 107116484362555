// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_Cd4_U{position:relative;font-style:normal;font-weight:600;font-size:18px;line-height:130%;color:#0e273c;padding-left:10px;margin-left:10px}.title_Cd4_U:before{content:\"\";position:absolute;top:-5px;left:-10px;width:5px;height:30px;background:#0750a8}", ""]);
// Exports
exports.locals = {
	"title": "title_Cd4_U"
};
module.exports = exports;
