



















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IConnectProposal from '~/Interfaces/IConnectProposal'

@Component
export default class ProposalBox extends Vue {
  @Prop()
  private expired: boolean | undefined

  @Prop()
  private connectProposals!: IConnectProposal[]

  @Prop()
  private onClick!: () => void

  /**
   * 提案件数
   */
  get countProposalLength(): number {
    return this.connectProposals.filter((proposal) => proposal.isVisible).length
  }
}
