import { Auth } from '@nuxtjs/auth-next'
import { IConnectClientProfile } from '~/Interfaces'

export default class ConnectClientProfile {
  value: IConnectClientProfile

  constructor(auth: Auth) {
    const user = auth.user as any
    // スネークケースのデータを変換する
    /* eslint-disable camelcase */
    this.value = {
      connectClientHashId: user?.connect_client_hash_id,
      connectClientCompanyName: user?.connect_client_company_name,
      connectClientCompanyNameKana: user?.connect_client_company_name_kana,
      connectClientCorporateNumber: user?.connect_client_corporate_number,
      connectClientTel: user?.connect_client_tel,
      connectClientZipCode: user?.connect_client_zip_code,
      connectClientPrefecture: user?.connect_client_prefecture,
      connectClientCity: user?.connect_client_city,
      connectClientAddress: user?.connect_client_address,
      connectClientRepresentativeLastName:
        user?.connect_client_representative_last_name,
      connectClientRepresentativeFirstName:
        user?.connect_client_representative_first_name,
      connectClientRepresentativeLastNameKana:
        user?.connect_client_representative_last_name_kana,
      connectClientRepresentativeFirstNameKana:
        user?.connect_client_representative_first_name_kana,
      connectClientRepresentativeBirthDate:
        user?.connect_client_representative_birth_date,
    }
    /* eslint-enable camelcase */
  }

  toParams() {
    return {
      connect_client_company_name: this.value.connectClientCompanyName,
      connect_client_company_name_kana: this.value.connectClientCompanyNameKana,
      connect_client_corporate_number: this.value.connectClientCorporateNumber,
      connect_client_tel: this.value.connectClientTel,
      connect_client_zip_code: this.value.connectClientZipCode,
      connect_client_prefecture: this.value.connectClientPrefecture,
      connect_client_city: this.value.connectClientCity,
      connect_client_address: this.value.connectClientAddress,
      connect_client_representative_last_name: this.value
        .connectClientRepresentativeLastName,
      connect_client_representative_first_name: this.value
        .connectClientRepresentativeFirstName,
      connect_client_representative_last_name_kana: this.value
        .connectClientRepresentativeLastNameKana,
      connect_client_representative_first_name_kana: this.value
        .connectClientRepresentativeFirstNameKana,
      connect_client_representative_birth_date: this.value
        .connectClientRepresentativeBirthDate,
    }
  }

  get isRegistered(): boolean {
    return (
      !!this.value.connectClientCompanyName &&
      !!this.value.connectClientTel &&
      !!this.value.connectClientZipCode &&
      !!this.value.connectClientPrefecture &&
      !!this.value.connectClientCity &&
      !!this.value.connectClientAddress &&
      !!this.value.connectClientRepresentativeLastName &&
      !!this.value.connectClientRepresentativeFirstName &&
      !!this.value.connectClientRepresentativeLastNameKana &&
      !!this.value.connectClientRepresentativeFirstNameKana
    )
  }
}
