// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal_kEqju{padding:40px 40px 200px;display:flex;flex-direction:column;grid-gap:50px;gap:50px}.modal_kEqju .button_2B0XU{cursor:pointer;display:flex;align-items:center;justify-content:center;grid-gap:5px;gap:5px;background:#fff;border:1px solid #afadac;border-radius:100px;width:120px;height:30px}.modal_kEqju .button_2B0XU:hover{background:#fafafa}.modal_kEqju .button_2B0XU:active{background:#f3f2f2}.modal_kEqju .button_2B0XU span{font-size:12px;color:#555}.modal_kEqju .button_2B0XU span,.modal_kEqju .title_33OhA{font-family:\"Hiragino Kaku Gothic Pro\";font-weight:600}.modal_kEqju .title_33OhA{font-size:26px;line-height:130%;color:#454545}.modal_kEqju .blocks_ZxYmW{display:flex;flex-direction:column;grid-gap:40px;gap:40px}.modal_kEqju .blocks_ZxYmW .block_OLvmR{padding:30px 20px;display:flex;flex-direction:column;grid-gap:30px;gap:30px;border:1px solid #d6d5d4;border-radius:5px}.modal_kEqju .blocks_ZxYmW .block_OLvmR .subtitle_1ex0k{font-family:\"Hiragino Kaku Gothic Pro\";font-size:18px;font-weight:600;line-height:130%;color:#454545}.modal_kEqju .blocks_ZxYmW .block_OLvmR .text_3FaDP{word-break:break-word;white-space:pre-wrap;font-family:\"Hiragino Kaku Gothic Pro\";font-size:14px;font-weight:400;color:#454545}", ""]);
// Exports
exports.locals = {
	"modal": "modal_kEqju",
	"button": "button_2B0XU",
	"title": "title_33OhA",
	"blocks": "blocks_ZxYmW",
	"block": "block_OLvmR",
	"subtitle": "subtitle_1ex0k",
	"text": "text_3FaDP"
};
module.exports = exports;
