






































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { SnackbarStore } from '~/utils/store-accessor'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: {
    ConnectIcon,
  },
})
export default class ConnectV2Snackbar extends Vue {
  @Prop()
  private message!: string

  @Prop()
  private name!: string

  @Prop()
  private error!: boolean

  @Prop()
  private hint!: string

  @Prop({ default: false })
  hideClose!: boolean

  get isVisible() {
    return SnackbarStore.shouldDisplay(this.name)
  }

  private onHidden() {
    SnackbarStore.hide(this.name)
    this.snackBarLeave()
  }

  private onAfterEnter() {
    setTimeout(() => {
      this.onHidden()
    }, 3000)
  }

  @Emit('snackBarLeave')
  snackBarLeave() {}
}
