











import { Component, Vue, Emit } from 'nuxt-property-decorator'
import Modal from '~/components/Modal.vue'
@Component({
  components: {
    Modal,
  },
})
export default class ClientProductModal extends Vue {
  @Emit('closeModal')
  private closeModal() {}

  @Emit('reject')
  private reject() {}
}
