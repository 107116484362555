import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import IConnectRenewalAggregate from '../Interfaces/IConnectRenewalAggregate'
import IConnectContractRenewal from '~/Interfaces/IConnectContractRenewal'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectRenewalAggregateStoreDefine',
})
export default class ConnectRenewalAggregateStoreDefine extends VuexModule {
  connectRenewalAggregate: IConnectRenewalAggregate | undefined = undefined

  @Mutation
  setConnectRenewalAggregate(
    connectRenewalAggregate: IConnectRenewalAggregate | undefined
  ) {
    if (connectRenewalAggregate === undefined) {
      return
    }

    this.connectRenewalAggregate = connectRenewalAggregate
  }

  get getConnectRenewalAggregate() {
    return this.connectRenewalAggregate
  }

  get getLastConnectContractRenewal(): IConnectContractRenewal | undefined {
    if (!this.connectRenewalAggregate) {
      return undefined
    }
    if (this.connectRenewalAggregate?.connectContractRenewals.length === 0) {
      return undefined
    }

    return this.connectRenewalAggregate.connectContractRenewals[0]
  }
}
