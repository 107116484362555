import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Auth } from '@nuxtjs/auth-next'
import { ConnectProposalReadStore } from '~/utils/store-accessor'

export default class ConnectProposalReadService {
  private readonly axios: NuxtAxiosInstance
  private readonly auth: Auth

  constructor(axios: NuxtAxiosInstance, auth: Auth) {
    this.axios = axios
    this.auth = auth
  }

  /**
   * 既読取得
   */
  async get(): Promise<void> {
    try {
      const response = await this.axios.get('api/client/proposal_read')

      console.log(response)
      const connectProposalReads = JSON.parse(JSON.stringify(response.data))

      ConnectProposalReadStore.set(connectProposalReads)
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  /**
   * 既読にする
   * @param connectProposalHashId
   */
  async read(connectProposalHashId: string | undefined): Promise<void> {
    if (!connectProposalHashId) {
      return
    }
    if (ConnectProposalReadStore.isRead(connectProposalHashId)) {
      return
    }
    if (!this.auth.user?.connect_client_account_hash_id) {
      return
    }

    try {
      ConnectProposalReadStore.read(connectProposalHashId)

      await this.axios.post('api/client/proposal_read/read', {
        connectProposalHashId,
      })
    } catch (err) {
      console.log(err)
    } finally {
    }
  }
}
