// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".un_clickable_G0PIs{pointer-events:all;z-index:11}.overlay_1zwr8,.un_clickable_G0PIs{position:fixed;width:100%;height:100vh;top:0;left:0}.overlay_1zwr8{background:rgba(0,0,0,.25);z-index:9}.clientProposalDetail_2EnM0{overscroll-behavior-y:contain;overflow-y:auto;position:fixed;background:#fff;width:100%;max-width:1080px;height:100vh;top:0;bottom:0;right:0;z-index:10}@-webkit-keyframes slideIn_2w15Q{0%{right:-100vw}to{right:0}}.leaveActive_1yl7N{-webkit-animation:slideOut_2XRUU .3s ease-out;animation:slideOut_2XRUU .3s ease-out}@-webkit-keyframes slideOut_2XRUU{0%{right:0}to{right:-100vw}}@keyframes slideOut_2XRUU{0%{right:0}to{right:-100vw}}.enterActive_3R-oN{-webkit-animation:slideIn_2w15Q .3s ease-out;animation:slideIn_2w15Q .3s ease-out}@keyframes slideIn_2w15Q{0%{right:-100vw}to{right:0}}.fade_active_3G05M{transition:all .3s}.fade_leave_2UBdM{opacity:0}", ""]);
// Exports
exports.locals = {
	"un_clickable": "un_clickable_G0PIs",
	"overlay": "overlay_1zwr8",
	"clientProposalDetail": "clientProposalDetail_2EnM0",
	"leaveActive": "leaveActive_1yl7N",
	"slideOut": "slideOut_2XRUU",
	"enterActive": "enterActive_3R-oN",
	"slideIn": "slideIn_2w15Q",
	"fade_active": "fade_active_3G05M",
	"fade_leave": "fade_leave_2UBdM"
};
module.exports = exports;
