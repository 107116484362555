














































import { Component, Emit, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import { FormItem, FormValidator, Rule } from '~/components'

type InputType = 'text' | 'number' | 'date' | 'tel' | 'email'

@Component
export default class ConnectV2Input extends Vue {
  @PropSync('modelValue')
  private model!: string | number | null

  @Prop()
  private id!: string

  @Prop()
  private name!: string

  @Prop()
  private disabled!: boolean

  @Prop()
  private placeholder!: string

  @Prop()
  private inputType!: InputType

  @Prop()
  private width!: string

  @Prop()
  private icon!: string

  @Prop()
  private hint!: string

  @Prop()
  private error!: boolean

  @Prop({ default: '必ず入力してください' })
  private errorText!: string

  @Prop()
  private maxlength!: number

  @Prop()
  private required!: boolean

  @Prop()
  private max!: number

  @Prop()
  private min!: number

  @Prop()
  private hideLabel!: boolean

  @Prop()
  private groupId!: string | number

  @Prop({ default: false })
  private hiddenHint!: boolean

  @Prop()
  private rules!: Rule[]

  @Prop({ default: false })
  private integerOnly!: boolean

  private formValidator!: FormValidator

  mounted() {
    this.formValidator = FormValidator.get()
    this.formValidator.addFormItem(this.formItem)
    this.$emit('register', this)
  }

  get formItem(): FormItem {
    return {
      id: this.componentId,
      name: this.name,
      value: this.model,
      rules: this.rules,
      message: this.errorText,
      validate: this.validate,
    }
  }

  get componentId(): string {
    return this.id || Math.random().toString(32).substring(2)
  }

  get hintId(): string {
    return `${this.componentId}-hint`
  }

  get errorId(): string {
    return `${this.componentId}-error`
  }

  get ariaDescribedby(): string | undefined {
    if (this.error) {
      return this.errorId
    }

    if (this.hint) {
      return this.hintId
    }

    return undefined
  }

  get style() {
    if (!this.width) {
      return undefined
    }
    return { width: this.width }
  }

  get characterCount(): number {
    return this.model ? this.model.toString().length : 0
  }

  get isExceeded(): boolean {
    if (!this.maxlength) {
      return false
    }
    return this.characterCount > this.maxlength
  }

  get hasError(): boolean {
    return this.error || this.isExceeded
  }

  public validate(): boolean {
    const isValid = this.formValidator.validate(this.name)
    if (!isValid) {
      this.errorText = this.formValidator.message(this.name)
      this.error = true
      return false
    }

    return true
  }

  @Emit('focus')
  private onFocus() {}

  @Emit('input')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onInput(event: Event) {
    if (this.inputType === 'number') {
      const value = Number((event.target as HTMLInputElement).value)
      if (this.min !== undefined && value < this.min) {
        this.model = this.min
        event.preventDefault()
      }

      if (this.max !== undefined && value > this.max) {
        this.model = this.max
        event.preventDefault()
      }

      if (this.integerOnly) {
        this.model = Math.floor(value)
        event.preventDefault()
        this.$forceUpdate()
      }
    }

    if (this.inputType === 'tel') {
      let value = (event.target as HTMLInputElement).value

      // 全角数字を半角数字に変換
      value = value.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
      })

      // 数字以外の文字を削除
      this.model = value.replace(/[^0-9+\-() ]/g, '')
      event.preventDefault()
      this.$forceUpdate()
    }
  }

  @Emit('change')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange(event: Event) {}

  @Emit('blur')
  private onBlur(event: Event) {
    this.formValidator.updateFormItem(
      this.name,
      (event.target as HTMLInputElement).value
    )

    if (!this.formValidator.validate(this.name)) {
      this.errorText = this.formValidator.message(this.name)
      this.error = !!this.errorText
    }
  }
}
