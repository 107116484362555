









import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class FeatureLabel extends Vue {
  @Prop()
  private order!: 1 | 2 | 3

  @Prop()
  private label!: string | null
}
