// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".proposal_detail_3XycX{max-width:100%;display:flex;flex-direction:column;grid-gap:5px;gap:5px;font-family:Hiragino Kaku Gothic Pro}.proposal_detail_3XycX .top_oN4t9{width:100%;grid-gap:5px;gap:5px}.proposal_detail_3XycX .top_oN4t9,.proposal_detail_3XycX .top_oN4t9 .heading_1Iv7o{display:flex;align-items:center}.proposal_detail_3XycX .top_oN4t9 .priorities_3_f0O{width:auto;display:flex;align-items:center;grid-gap:7px;gap:7px;overflow:hidden}.proposal_detail_3XycX .top_oN4t9 .priorities_3_f0O .priority_2xn4s{width:100%;display:flex;align-items:center;grid-gap:7px;gap:7px;overflow:hidden}.proposal_detail_3XycX .top_oN4t9 .priorities_3_f0O .priority_2xn4s .text_mNzdJ{color:#2c69a0;color:var(--Primary-70,#2c69a0);font-size:14px;font-style:normal;font-weight:600;line-height:normal;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.proposal_detail_3XycX .top_oN4t9 .priorities_3_f0O .priority_2xn4s .bullet_1ov0Q{display:flex;align-items:center;justify-content:center;border-radius:100px;background:#63615f;background:var(--Grey-90,#63615f);width:4px;height:4px}.proposal_detail_3XycX .bottom_niGxJ .priority_detail_6uBXl{-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow:hidden;display:-webkit-box;color:#454545;color:var(--Text-100,#454545);text-overflow:ellipsis;font-size:14px;font-style:normal;font-weight:400;line-height:normal}", ""]);
// Exports
exports.locals = {
	"proposal_detail": "proposal_detail_3XycX",
	"top": "top_oN4t9",
	"heading": "heading_1Iv7o",
	"priorities": "priorities_3_f0O",
	"priority": "priority_2xn4s",
	"text": "text_mNzdJ",
	"bullet": "bullet_1ov0Q",
	"bottom": "bottom_niGxJ",
	"priority_detail": "priority_detail_6uBXl"
};
module.exports = exports;
