import IPaging from "~/Interfaces/IPaging";

export default class PageService {
  private page!: IPaging;

  constructor(page: IPaging|undefined) {
    if(page === undefined) {
      return;
    }

    this.page = page
  }

  /**
   * インスタンスを取得する
   * @param page
   */
  public getInstance(page: IPaging): PageService
  {
    return new PageService(page)
  }

  /**
   * 現在のページ番号
   */
  public currentPage(): number
  {
    return this.page.current_page
  }

  /**
   * 前のページ番号
   */
  public prevPage(): number
  {
    if(this.page.current_page <= 1) {
      return 1;
    }

    return this.page.current_page - 1;
  }

  /**
   * 次のページ番号
   */
  public nextPage(): number
  {
    if(this.page.last_page === this.page.current_page ) {
      return this.page.current_page;
    }

    return this.page.current_page + 1;

  }

  /**
   * 最後のページ番号
   */
  public lastPage(): number
  {
    return this.page.last_page
  }

  /**
   * エレメント
   */
  public elements()
  {
    return this.page.elements
  }
}
