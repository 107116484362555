// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".text_2cw6J .highlight_1ecWq{padding:2px 0;background:linear-gradient(transparent 60%,#f4c9c7 0)}", ""]);
// Exports
exports.locals = {
	"text": "text_2cw6J",
	"highlight": "highlight_1ecWq"
};
module.exports = exports;
