export default function ({ app, redirect, route }) {
  const isInactive =
    app.$auth.user && app.$auth.user.is_inactive !== undefined
      ? app.$auth.user.is_inactive
      : 0

  if (isInactive === 1 && route.name !== 'client-inactive') {
    // // 休眠中の場合は休眠画面
    redirect('/client/inactive')
  }
}
