import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import IConnectClientProposalMemo from '../Interfaces/IConnectClientProposalMemo'

export interface ITmpMemo {
  connectProposalHashId: string
  text: string
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectClientProposalMemoStoreDefine',
})
export default class ConnectClientProposalMemoStoreDefine extends VuexModule {
  connectClientProposalMemo: IConnectClientProposalMemo | null = null
  tmpMemos: ITmpMemo[] = []
  editorOpen: boolean = false

  @Mutation
  setConnectClientProposalMemo(
    connectClientProposalMemo: IConnectClientProposalMemo | null
  ) {
    this.connectClientProposalMemo = connectClientProposalMemo
  }

  @Mutation
  openEditor(): void {
    this.editorOpen = true
  }

  @Mutation
  closeEditor(): void {
    this.editorOpen = false
  }

  @Mutation
  closeAll(): void {
    this.connectClientProposalMemo = null
    this.editorOpen = false
  }

  get getConnectClientProposalMemo(): IConnectClientProposalMemo | null {
    return this.connectClientProposalMemo
  }

  get isOpenClientMemoModal(): boolean {
    return this.connectClientProposalMemo !== null
  }

  get isOpenEditor(): boolean {
    return this.editorOpen
  }

  @Mutation
  replaceTmpMemos(connectClientProposalMemo: ITmpMemo): void {
    const memos = this.tmpMemos

    const index = this.tmpMemos.findIndex(
      (memo) =>
        memo.connectProposalHashId ===
        connectClientProposalMemo.connectProposalHashId
    )

    if (index !== -1) {
      memos.splice(index, 1)
    }
    memos.push(connectClientProposalMemo)

    this.tmpMemos = memos
  }

  get findTmpMemoByConnectProposalHashId(): (
    connectProposalHashId: string
  ) => string | undefined {
    const self = this

    return (connectProposalHashId: string) => {
      const memo = self.tmpMemos.find(
        (memo) => memo.connectProposalHashId === connectProposalHashId
      )

      return memo ? memo.text : undefined
    }
  }
}
