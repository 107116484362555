// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".proposal_status_2jepI{display:flex;align-items:center;width:100px;height:26px;background:#f5f5f5;font-size:12px;font-weight:600;letter-spacing:0;color:#82919f}.proposal_status_2jepI .description_30MHx{position:relative;padding-left:40px}.proposal_status_2jepI .description_30MHx:before{position:absolute;left:24px;top:3px;content:\"\";border-radius:50%;width:12px;height:12px}.proposal_status_2jepI .description_30MHx.waiting_2y-bf:before{background:#d6dce2}.proposal_status_2jepI .description_30MHx.negotiating_2WvOT:before{background:#ebaf0b}.proposal_status_2jepI .description_30MHx.accepted_3nt6a:before{background:#e82a4e}.proposal_status_2jepI .description_30MHx.rejected_2_vUV:before{background:#5d6d7c}", ""]);
// Exports
exports.locals = {
	"proposal_status": "proposal_status_2jepI",
	"description": "description_30MHx",
	"waiting": "waiting_2y-bf",
	"negotiating": "negotiating_2WvOT",
	"accepted": "accepted_3nt6a",
	"rejected": "rejected_2_vUV"
};
module.exports = exports;
