






























































import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import { ConnectCompanyStore } from '~/utils/store-accessor'
import IConnectCompanyEducation from '~/Interfaces/connectCompany/IConnectCompanyEducation'
import IConnectCompanyCareer from '~/Interfaces/connectCompany/IConnectCompanyCareer'
import IConnectCompanyConstants from '~/Interfaces/connectCompany/IConnectCompanyConstants'
import HighlightText from '~/components/common/profile/connectCompany/highlight-text.vue'

@Component({
  components: {
    HighlightText,
  },
})
export default class ProfileConnectCompanyCareer extends Vue {
  @Prop()
  private constants!: IConnectCompanyConstants

  @Prop()
  private searchWords!: string[]

  @Prop({ default: false })
  private isPartnerProfile!: boolean

  private canDisplayResumeBox(career: IConnectCompanyCareer) {
    if (career.role !== null) {
      return true
    }
    if (career.employment !== null) {
      return true
    }
    if (career.involvement !== null) {
      return true
    }
    if (career.mainAchievement !== null) {
      return true
    }

    return false
  }

  private canDisplayResumeRole(career: IConnectCompanyCareer) {
    if (career.role !== null) {
      return true
    }
    if (career.employment !== null) {
      return true
    }
    if (career.involvement !== null) {
      return true
    }

    return false
  }

  private date(date: string): string {
    return this.$services.day.getYnJa(date)
  }

  private employment(index: number): string {
    return this.constants.employment[index].text
  }

  private involvement(index: number): string {
    return this.constants.involvement[index].text
  }

  private roleText(career: IConnectCompanyCareer) {
    const roles = [] as string[]
    if (career.role) {
      roles.push(career.role.toString())
    }

    if (career.employment !== null) {
      roles.push(this.employment(career.employment))
    }

    if (career.employment !== null && career.employmentEtc !== null) {
      roles.push(career.employmentEtc)
    }

    if (career.involvement !== null) {
      roles.push(this.involvement(career.involvement))
    }

    return roles.join('・')
  }

  get careers(): IConnectCompanyCareer[] | undefined {
    const connectCompany = ConnectCompanyStore.getConnectCompany

    return connectCompany?.connectCompanyCareers
  }

  get education(): IConnectCompanyEducation | undefined {
    const connectCompany = ConnectCompanyStore.getConnectCompany

    return connectCompany?.connectCompanyEducation
  }

  get educationText(): string {
    if (!this.education) {
      return ''
    }
    return `${this.education.schoolName} ${this.education.major} 卒業`
  }
}
