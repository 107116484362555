// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2zGYy{width:100%}.usage_XkSk4{justify-content:flex-start;width:100%;height:38px}.label_3X1ao,.usage_XkSk4{display:flex;align-items:center}.label_3X1ao{width:64px;height:100%;background:#2f5980;font-weight:600;font-size:12px;line-height:18px;justify-content:center;color:#fff;border-radius:100px 0 0 100px}.content_3oxEK{justify-content:flex-start;width:100%;height:100%;background:#e4ecf5;border:1px solid #2f5980;border-left-width:0;border-radius:0 100px 100px 0;padding:0 20px;grid-gap:0 20px;gap:0 20px}.content_3oxEK,.tag_14_ML{display:flex;align-items:center}.tag_14_ML{justify-content:center;background:#f9f9f9;border-radius:2px;padding:2px 10px;font-weight:600;font-size:12px;line-height:18px}.tag_14_ML .icon_f510M{margin-right:5px;margin-top:1px}.tag_14_ML.thin_35j7Z{font-weight:400;color:#999}", ""]);
// Exports
exports.locals = {
	"container": "container_2zGYy",
	"usage": "usage_XkSk4",
	"label": "label_3X1ao",
	"content": "content_3oxEK",
	"tag": "tag_14_ML",
	"icon": "icon_f510M",
	"thin": "thin_35j7Z"
};
module.exports = exports;
