// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".infoIcon_3K0VO{cursor:pointer;display:flex;width:30px;height:30px;justify-content:center;align-items:center;border-radius:5px}.infoIcon_3K0VO .normal_1nG7H{display:block}.infoIcon_3K0VO .hover_dwjVb{display:none}.infoIcon_3K0VO:hover{background:#e3eef7;background:var(--Primary-20,#e3eef7)}.infoIcon_3K0VO:hover .normal_1nG7H{display:none}.infoIcon_3K0VO:hover .hover_dwjVb{display:block}", ""]);
// Exports
exports.locals = {
	"infoIcon": "infoIcon_3K0VO",
	"normal": "normal_1nG7H",
	"hover": "hover_dwjVb"
};
module.exports = exports;
