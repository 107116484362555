// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_1TuzK{margin-top:60px;margin-bottom:20px;font-size:20px;font-weight:600;color:#002853}.notice_2kLmQ,.title_1TuzK{text-align:center;letter-spacing:0}.notice_2kLmQ{font-size:14px;font-weight:400;line-height:21px;margin-bottom:34px}.content_1vrMJ{margin-top:20px;padding-bottom:60px;overflow-y:scroll;width:100%}.content_1vrMJ::-webkit-scrollbar{display:none}.content_1vrMJ .drag_drop_area_y81_T{position:relative;justify-content:space-between;width:100%;background-color:#e9edf1;border-radius:10px;border:2px dashed #38597d;padding:30px}.content_1vrMJ .drag_drop_area_y81_T,.content_1vrMJ .drag_drop_area_y81_T .left_1SbsO{display:flex;align-items:center}.content_1vrMJ .drag_drop_area_y81_T .left_1SbsO .description_1A_BE{margin-left:20px;font-size:14px;line-height:14px}.content_1vrMJ .drag_drop_area_y81_T .upload_button_3jgy1{display:flex;align-items:center;justify-content:center;background:#224d74;border-radius:34px;width:158px;height:40px;font-size:16px;line-height:16px;font-weight:600;color:#fff}.content_1vrMJ .drag_drop_area_y81_T .input_3E9Sf{cursor:pointer;position:absolute;left:0;top:0;opacity:0;display:block;width:100%;height:100%}.content_1vrMJ .buttons_3qcwp{margin-top:30px;display:flex;justify-content:center}.content_1vrMJ .buttons_3qcwp .submit_button_WYlH9{cursor:pointer;display:flex;align-items:center;justify-content:center;width:200px;height:40px;background:transparent linear-gradient(259deg,#00a7a7,#00a1c7 49%,#0065cb);border-radius:30px;font-size:14px;line-height:14px;font-weight:600;color:#fff}", ""]);
// Exports
exports.locals = {
	"title": "title_1TuzK",
	"notice": "notice_2kLmQ",
	"content": "content_1vrMJ",
	"drag_drop_area": "drag_drop_area_y81_T",
	"left": "left_1SbsO",
	"description": "description_1A_BE",
	"upload_button": "upload_button_3jgy1",
	"input": "input_3E9Sf",
	"buttons": "buttons_3qcwp",
	"submit_button": "submit_button_WYlH9"
};
module.exports = exports;
