// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".buttonColumn_3z1_Y{display:flex;flex-direction:column;align-items:center;grid-gap:30px 0;gap:30px 0;padding-top:60px}.buttonColumn_3z1_Y.rightAligned_1HwjO{align-items:flex-end}", ""]);
// Exports
exports.locals = {
	"buttonColumn": "buttonColumn_3z1_Y",
	"rightAligned": "rightAligned_1HwjO"
};
module.exports = exports;
