import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'QuotationIsFirstApproveRequestDefine',
})
export default class QuotationIsFirstApproveRequestDefine extends VuexModule {
  isFirstApproveRequest: boolean = false

  @Mutation
  setStatus(isFirstApproveRequest: boolean) {
    this.isFirstApproveRequest = isFirstApproveRequest
  }

  get getStatus(): boolean {
    return this.isFirstApproveRequest
  }
}
