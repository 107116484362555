







































import Vue from 'vue'
import { Component, Emit, Prop } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { TConnectCompanySupportAchievementMember } from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import IConnectCompanyMemberSupport from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberSupport'

@Component({
  components: { ConnectIcon },
})
export default class Page extends Vue {
  @Prop()
  private member!: TConnectCompanySupportAchievementMember

  get goodAtSupports(): IConnectCompanyMemberSupport[] {
    return this.member.supports.filter((support) => support.supportStatus === 2)
  }

  @Emit('click-detail-link')
  private onClickDetailLink() {}

  private formatDate(date: string) {
    return dayjs(date).format('YYYY年M月')
  }
}
