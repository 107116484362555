var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    _vm.s.wrapper,
    ( _obj = {}, _obj[_vm.s.client] = _vm.is_client, _obj[_vm.s.outside] = _vm.outside, _obj[_vm.s.supportClient] = _vm.isSupportAccount, _obj[_vm.s.isProfile] = _vm.isProfile, _obj ) ],style:(_vm.style),attrs:{"id":"wrapper"},on:{"wheel":_vm.onWheel}},[(_vm.isSupportAccount)?_c('div',{class:_vm.s.supportHeader},[_c('span',{class:_vm.s.supportHeaderLabel},[_vm._v("閲覧のみ")]),_vm._v("サポートアカウントでログイン中です。\n  ")]):_vm._e(),_vm._v(" "),(!_vm.isTerm && !_vm.should_header_hide && _vm.is_client)?_c('header-client'):(!_vm.isTerm && !_vm.should_header_hide && !_vm.is_client)?_c('header-partner'):_vm._e(),_vm._v(" "),_c('ClientProfileRegisterBanner',{attrs:{"is-client":_vm.is_client}}),_vm._v(" "),_c('transition',{attrs:{"name":_vm.s.fade,"enter-active-class":_vm.s.fade_active,"leave-active-class":_vm.s.fade_active,"enter-class":_vm.s.fade_leave,"leave-to-class":_vm.s.fade_leave}},[(_vm.isTerm)?_c('div',{class:_vm.s.header},[_c('div',{class:_vm.s.header_nav},[_c('nuxt-link',{staticClass:"disable_link_active",class:_vm.s.logo,attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("assets/images/header/logo_company_v2.svg"),"width":"93px","height":"25px","alt":"kakutoku connect"}})])],1)]):_vm._e()]),_vm._v(" "),(_vm.should_title_wrapper_show)?_c('div',{class:[
      _vm.s.title_wrapper,
      ( _obj$1 = {}, _obj$1[_vm.s.isProfile] = _vm.isProfile, _obj$1 ),
      ( _obj$2 = {}, _obj$2[_vm.s.productTopPage] = _vm.isProductTopPage, _obj$2 ) ]},[_c('transition',{attrs:{"enter-active-class":_vm.s.fade_active,"leave-active-class":_vm.s.fade_active,"enter-class":_vm.s.fade_leave,"leave-to-class":_vm.s.fade_leave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.should_title_show),expression:"should_title_show"}],class:_vm.s.title},[_c('div',{class:_vm.s.title_img},[(_vm.is_client)?_c('img',{attrs:{"src":require("assets/images/logo_black.svg")}}):_c('img',{attrs:{"src":require("assets/images/logo.svg")}}),_vm._v(" "),_vm._t("title")],2)])])],1):_vm._e(),_vm._v(" "),_vm._t("body"),_vm._v(" "),_c('ConnectFooter',{attrs:{"is-client":_vm.is_client}}),_vm._v(" "),_c('ConnectV2Snackbar',{attrs:{"error":true,"name":"ConnectQuotationFromError","message":"入力内容にエラーがあります","hint":"すべての項目のエラーを解消してから保存してください"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }