// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button_3z8kx{background:transparent;border:none;cursor:pointer;padding:0;margin:0;display:flex;align-items:center}.button_3z8kx .icon_tES_b{color:#d9e1ea;font-size:22px}.button_3z8kx .icon_tES_b.fill_35Ghz{color:#ebaf0b}.button_3z8kx .icon_version2_pFKVY{border-radius:5px}.button_3z8kx .icon_version2_pFKVY:hover{background:#e3eef7;background:var(--Primary-20,#e3eef7)}.button_3z8kx .icon_version2_pFKVY .empty_mrVpU .normal_13Gat{display:inline;display:initial}.button_3z8kx .icon_version2_pFKVY .empty_mrVpU .hover_jJUNA,.button_3z8kx .icon_version2_pFKVY .empty_mrVpU:hover .normal_13Gat{display:none}.button_3z8kx .icon_version2_pFKVY .empty_mrVpU:hover .hover_jJUNA{display:inline;display:initial}.button_3z8kx p{margin:0 4px;color:#209cd0}", ""]);
// Exports
exports.locals = {
	"button": "button_3z8kx",
	"icon": "icon_tES_b",
	"fill": "fill_35Ghz",
	"icon_version2": "icon_version2_pFKVY",
	"empty": "empty_mrVpU",
	"normal": "normal_13Gat",
	"hover": "hover_jJUNA"
};
module.exports = exports;
