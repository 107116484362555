// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".waiting_Uk2ws{background:#f8e9d3;background:var(--Secondary-20,#f8e9d3);color:#a66f1c;color:var(--Secondary-80,#a66f1c)}.negotiating_2tM9z{background:#e3eef7;background:var(--Primary-20,#e3eef7);color:#2c69a0;color:var(--Primary-70,#2c69a0)}.accepted_cUwET,.pending_2BVb-{background:#f3f2f2;background:var(--Grey-20,#f3f2f2);color:#454545;color:var(--Text-100,#454545)}.rejected_31htP,.rejected_not_correspond_1F-jY,.terminated_3gwUZ{background:#d6d5d4;background:var(--Grey-40,#d6d5d4);color:#454545;color:var(--Text-100,#454545)}", ""]);
// Exports
exports.locals = {
	"waiting": "waiting_Uk2ws",
	"negotiating": "negotiating_2tM9z",
	"accepted": "accepted_cUwET",
	"pending": "pending_2BVb-",
	"rejected": "rejected_31htP",
	"rejected_not_correspond": "rejected_not_correspond_1F-jY",
	"terminated": "terminated_3gwUZ"
};
module.exports = exports;
