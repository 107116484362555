






















































































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { IClientProposalDetailHeaderTab, IConnectProposal } from '~/Interfaces'
import ClientProposalPartner from '~/components/Client/ClientProposal/ClientProposalPartner/index.vue'
import ClientProposalStatus from '~/components/Client/ClientProposal/ClientProposalStatus/index.vue'
import ClientProposalStatusLabel from '~/components/Client/ClientProposal/ClientProposalStatusLabel/index.vue'
import {
  ConnectClientProposalMemoStore,
  ConnectProposalThreadStore,
  CurrentConnectProposalStore,
  ReadsStore,
} from '~/utils/store-accessor'
import ProposalFavoriteButton from '~/components/Proposal/proposal-favorite-button.vue'

@Component({
  components: {
    ProposalFavoriteButton,
    ClientProposalPartner,
    ClientProposalStatus,
    ClientProposalStatusLabel,
  },
})
export default class ClientProposalDetailHeader extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop({ default: false })
  private isMessageTabDefault!: boolean

  private tabState: IClientProposalDetailHeaderTab = 'proposal'

  private isUnread: boolean = false

  mounted() {
    this.loadUnread()
    if (this.isMessageTabDefault) {
      this.tabState = 'message'
    }
  }

  get connectProposalStatus(): string {
    const status = this.connectProposal.connectProposalStatus as any

    if (status === 'accepted' && !this.connectProposal.isExpired) {
      return status
    }

    if (status === 'terminated' || this.connectProposal.isExpired) {
      return 'terminated'
    }

    return status
  }

  get isVisibleProposalContent(): boolean {
    return [
      'waiting',
      'negotiating',
      'pending',
      'rejected',
      'rejected_not_correspond',
    ].includes(this.connectProposalStatus)
  }

  get isVisibleContract(): boolean {
    return ['accepted', 'terminated'].includes(this.connectProposalStatus)
  }

  get isVisibleUnread(): boolean {
    return this.isUnread && this.tabState === 'proposal'
  }

  private onClose() {
    if (ConnectClientProposalMemoStore.isOpenEditor) {
      if (
        !confirm(
          '入力内容が保存されない可能性があります。本当にメモを閉じますか？'
        )
      ) {
        return false
      }
    }

    this.onChangeTab('proposal')
    ConnectClientProposalMemoStore.closeAll()
    CurrentConnectProposalStore.setCurrentConnectProposal(null)
  }

  private onClick(tabState: IClientProposalDetailHeaderTab) {
    if (this.tabState === 'message') {
      this.isUnread = false
    }
    this.tabState = tabState
    this.onChangeTab(tabState)
  }

  private onClickMemo() {
    ConnectClientProposalMemoStore.setConnectClientProposalMemo(
      this.connectProposal.connectClientProposalMemo
        ? this.connectProposal.connectClientProposalMemo
        : {
            connectClientProposalMemoId: null,
            connectClientProposalMemoHashId: null,
            connectProposalHashId: this.connectProposal.connectProposalHashId,
            memo: '',
            lastUpdated: null,
          }
    )
  }

  private async loadUnread() {
    if (['waiting', 'pending'].includes(this.connectProposalStatus)) {
      return
    }

    const messageService = this.$services.message

    let threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    if (!threadHashId) {
      threadHashId = await messageService.findThreadHashId(
        this.connectProposal.connectProposalHashId,
        true
      )
    }

    if (!threadHashId) return

    // threadHashId を cache
    ConnectProposalThreadStore.setThreadHashId({
      connectProposalHashId: this.connectProposal.connectProposalHashId,
      threadHashId,
    })

    this.isUnread = !ReadsStore.isReadByThreadHashId(threadHashId)
  }

  @Emit('onChangeTab')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChangeTab(tabState: IClientProposalDetailHeaderTab) {}

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}
}
