































import {Component, Emit, Prop, Vue} from "nuxt-property-decorator";

import {
  ConnectServiceAgreementModalStore,
  ModalStore, TermStore
} from "~/utils/store-accessor";
import FormModal from "~/components/common/modal/form-modal.vue";
@Component({
  components: {FormModal,}
})
export default class ConnectServiceAgreementModal extends Vue{
  private is_checked: boolean = false;

  get getConnectServiceAgreement(): string {
    return TermStore.getHtmlByType(TermStore.pageTypes.connect_service_agreement) ?? ''
  }

  get shouldDisplay():boolean
  {
    return ModalStore.shouldDisplay('ConnectServiceAgreementModal')
  }

  private closeModal()
  {
    ModalStore.hide('ConnectServiceAgreementModal')
  }

  private changeCheckbox(e: Event)
  {
    const checkbox = <HTMLInputElement>e.target

    this.is_checked = checkbox.checked
  }

  private async submit(e: Event)
  {
    const form = <HTMLFormElement>this.$refs.form

    if(form.checkValidity()) {
      e.preventDefault()
      await this.$services.client.makeAgreedServiceAgreement()

      await ConnectServiceAgreementModalStore.execClosure()
    }
    this.closeModal()
  }
}
