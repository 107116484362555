import IConnectClientContact from '~/Interfaces/ConnectClientContact/IConnectClientContact'

export default class ConnectClientContact {
  value: IConnectClientContact
  emails!: string[]

  constructor(connectClientContact: IConnectClientContact) {
    this.value = {
      isOpenContactMail: connectClientContact.isOpenContactMail ?? false,
      isOpenContactTel: connectClientContact.isOpenContactTel ?? false,
      connectClientContactTel:
        connectClientContact.connectClientContactTel ?? null,
      connectClientContactEMails:
        connectClientContact.connectClientContactEMails ?? [],
    }
    this.emails = connectClientContact?.connectClientContactEMails
      ? connectClientContact.connectClientContactEMails.map(
          (contactEmail) => contactEmail.email
        )
      : ['']

    if (this.emails.length === 0) {
      this.emails.push('')
    }
  }

  validateEmail() {
    if (!this.value.isOpenContactMail) {
      return true
    }

    return this.emails.filter((email) => email !== '').length > 0
  }

  validateTel() {
    if (!this.value.isOpenContactTel) {
      return true
    }

    return (
      this.value.connectClientContactTel &&
      this.value.connectClientContactTel !== ''
    )
  }

  isOpen(): boolean {
    return (
      this.value.isOpenContactMail === true ||
      this.value.isOpenContactTel === true
    )
  }

  isOpenEmail() {
    if (!this.value.isOpenContactMail) {
      return false
    }

    return this.emails.filter((email) => email !== '').length > 0
  }

  isOpenTel() {
    if (!this.value.isOpenContactTel) {
      return false
    }

    return (
      this.value.connectClientContactTel &&
      this.value.connectClientContactTel !== ''
    )
  }

  isOpenOnlyTel() {
    return !this.isOpenEmail() && this.isOpenTel()
  }

  toData() {
    return {
      isOpenContactMail: this.value.isOpenContactMail ?? false,
      isOpenContactTel: this.value.isOpenContactTel ?? false,
      connectClientContactTel: this.value.connectClientContactTel ?? null,
      connectClientContactEMails: this.emails,
    }
  }

  toParams() {
    return {
      is_open_contact_tel: this.value.isOpenContactTel,
      connect_client_contact_tel: this.value.connectClientContactTel,
    }
  }

  getCsvEmailsForCopy(): string {
    const emails = this.value.connectClientContactEMails
      ?.map((connectClientContactEMail) => connectClientContactEMail.email)
      .join(',')
    return emails + ',kakutoku.connect@kakutoku.co.jp'
  }
}
