













































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import IConnectProposal from '~/Interfaces/IConnectProposal'

@Component
export default class ClientProposalStatusVersion2 extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop({ default: false })
  private isHeader!: boolean

  private isFocused: boolean = false
  get connectProposalStatus(): string {
    return this.connectProposal.connectProposalStatus as any
  }

  private isSelected(proposalStatus: string) {
    if (proposalStatus === 'rejected') {
      return ['rejected', 'rejected_not_correspond'].includes(
        this.connectProposalStatus
      )
    }
    return this.connectProposalStatus === proposalStatus
  }

  private onChange(event: Event) {
    const target = event.target as HTMLOptionElement
    this.updateStatus(this.connectProposal, target.value)
    if (this.$services.user.shouldRegisterAddress) {
      target.value = this.connectProposalStatus
    }
  }

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}
}
