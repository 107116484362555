





















import { Component, Prop, Vue, Emit, Watch } from 'nuxt-property-decorator'
import ConnectCompanyProfileCard from '~/components/Client/profile/ConnectCompanyProfileCard'

@Component({})
export default class ClientProposalCellDegree extends Vue {
  @Prop()
  private connectCompanyProfileCard!: ConnectCompanyProfileCard

  private mount: boolean = false

  private visible: boolean[] = []
  private isExistsInvisible: boolean = false

  canVisible(index: number): boolean {
    return this.visible[index] ?? true
  }

  mounted() {
    this.mount = true
  }

  @Watch('mount')
  onMountedChange() {
    const degrees = this.$refs.degrees as HTMLDivElement
    const parentWidth = degrees.getBoundingClientRect().width
    let childrenWidth = 0
    const degreeChildren = this.$refs.degree as HTMLDivElement[]
    degreeChildren.map((degree: HTMLDivElement) => {
      childrenWidth += degree.getBoundingClientRect().width + 10

      if (childrenWidth >= parentWidth) {
        // degree.style.display = 'none'
        this.visible.push(false)
        this.isExistsInvisible = true
      } else {
        // degree.style.display = 'flex'
        //
        this.visible.push(true)
      }
    })
  }
}
