
















import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import VueCropper from 'vue-cropperjs'

import { ModalStore, SnackbarStore } from '~/utils/store-accessor'
import Modal from '~/components/Modal.vue'
import 'cropperjs/dist/cropper.css'
import IConnectCompanyMember from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMember'

@Component({
  components: { Modal, VueCropper },
})
export default class ProfileConnectCompanyMemberDeleteModal extends Vue {
  @Prop()
  private connectCompanyMember!: IConnectCompanyMember

  mounted() {}

  private closeModal() {
    ModalStore.hide(
      'ProfileConnectCompanyMemberDeleteModal' +
        this.connectCompanyMember.connectCompanyMemberHashId
    )
  }

  get shouldDisplay(): boolean {
    return ModalStore.shouldDisplay(
      'ProfileConnectCompanyMemberDeleteModal' +
        this.connectCompanyMember.connectCompanyMemberHashId
    )
  }

  private cancel(e: Event) {
    this.closeModal()
  }

  private async submit() {
    await this.$services.companyMember.deleteMember(this.connectCompanyMember)
    this.closeModal()
    SnackbarStore.show(
      'connect-company-member-delete' +
        this.connectCompanyMember.connectCompanyMemberHashId
    )
  }
}
