// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2nga7{display:flex;justify-content:flex-start;align-items:center;grid-gap:0 20px;gap:0 20px}.title_1z6Az{font-weight:600;font-size:26px;line-height:130%;color:#555;padding:0 0 80px;margin:0}.button_2bJGL,.title_1z6Az.noPadding_PsZFg{padding:0}.button_2bJGL{background:none;border:none;cursor:pointer;margin:0;width:32px;height:32px;border-radius:50%}.icon_1XLDB{color:#7ca8d5;font-size:18px}.required_2B6SY{display:flex;align-items:center;justify-content:center;background:#2f5980;border-radius:4px;width:34px;height:22px;font-size:12px;font-weight:700;line-height:18px;color:#fff}", ""]);
// Exports
exports.locals = {
	"container": "container_2nga7",
	"title": "title_1z6Az",
	"button": "button_2bJGL",
	"noPadding": "noPadding_PsZFg",
	"icon": "icon_1XLDB",
	"required": "required_2B6SY"
};
module.exports = exports;
