
























































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import IConnectProposalDetail from '~/Interfaces/ConnectProposalDetail/IConnectProposalDetail'
import { IConnectProposal } from '~/Interfaces'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: { ConnectIcon },
})
export default class ProposalDetailContent extends Vue {
  @Prop()
  private prioritiesMaster!: string[]

  @Prop()
  private connectProposalDetail!: IConnectProposalDetail

  @Prop()
  private connectProposal!: IConnectProposal | null

  get existPriorities() {
    return this.connectProposalDetail.priorities.length > 0
  }

  get priorities(): string[] {
    return this.connectProposalDetail.priorities.slice().sort((v, v2) => {
      if (v === '0') {
        return 1
      }

      return parseInt(v) - parseInt(v2)
    })
  }
}
