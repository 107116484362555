// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".threadMessage_L_LyD:not(:first-child){margin-top:38px}", ""]);
// Exports
exports.locals = {
	"threadMessage": "threadMessage_L_LyD"
};
module.exports = exports;
