









































































import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import Modal from '../Modal.vue'
import { ModalStore } from '~/utils/store-accessor'
import IConnectProposal from '~/Interfaces/IConnectProposal'
@Component({
  components: { Modal },
})
export default class ClientConfirmModal extends Vue {
  @Prop()
  private proposal!: IConnectProposal
  private isChecked: boolean = false;

  private onClose() {
    this.isChecked = false
    ModalStore.hide('ClientConfirmModal' + this.proposal.connectProposalHashId)
  }

  get shouldDisplay(): boolean {
    return ModalStore.shouldDisplay('ClientConfirmModal' + this.proposal.connectProposalHashId)
  }

  get checkboxId(): string {
    if (!this.proposal) {
      return "connect_confirm_attention"
    }
    return `connect_confirm_attention-${this.proposal.connectProposalHashId}`
  }

  private changeCheckbox(e: Event) {
    const checkbox = <HTMLInputElement>e.target

    this.isChecked = checkbox.checked
  }

  private onClickNavigate() {
    if (!this.isChecked) return
    this.onNavigate();
  }

  @Emit('onNegotiate')
  private onNavigate() {}

  @Emit('onRequestQuotation')
  private onRequestQuotation() {}
}
