import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { ConnectClientProposalMemoStore } from '~/utils/store-accessor'

export default class ConnectClientProposalMemoService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async write(
    connectClientProposalMemoHashId: string | null,
    connectProposalHashId: string,
    memo: string
  ): Promise<void> {
    try {
      const response = await this.axios.post('api/client/proposal/memo', {
        connect_client_proposal_memo_hash_id: connectClientProposalMemoHashId,
        connect_proposal_hash_id: connectProposalHashId,
        memo,
      })

      const clientMemo = JSON.parse(JSON.stringify(response.data))
      ConnectClientProposalMemoStore.setConnectClientProposalMemo(clientMemo)
    } catch (err) {
      console.log(err)
    } finally {
    }
  }
}
