// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".box_row_3spZz{padding:20px;width:100%;background:#f7f7f7;border-radius:4px}.box_row_3spZz .star_box_2qLkB{background:#fff;padding:16px;margin-bottom:20px}.box_row_3spZz .star_box_2qLkB .stars_gJ7Sc{display:flex;justify-content:center;align-items:center;grid-gap:4px;gap:4px}.box_row_3spZz .star_box_2qLkB .label_3hGEZ{display:flex;justify-content:center;align-content:center;font-size:14px;line-height:21px;font-weight:400;letter-spacing:0;color:#0e273c}.box_row_3spZz .row_1tWFT{margin-bottom:20px}.box_row_3spZz .row_1tWFT .title_2turq{line-height:14px;font-weight:600;margin-bottom:4px}.box_row_3spZz .row_1tWFT .text_1oN0d,.box_row_3spZz .row_1tWFT .title_2turq{font-size:14px;letter-spacing:0;color:#0e273c}.box_row_3spZz .row_1tWFT .text_1oN0d{white-space:pre-wrap;word-break:break-word;line-height:21px}.box_row_3spZz .row_1tWFT:last-child{margin-bottom:0}", ""]);
// Exports
exports.locals = {
	"box_row": "box_row_3spZz",
	"star_box": "star_box_2qLkB",
	"stars": "stars_gJ7Sc",
	"label": "label_3hGEZ",
	"row": "row_1tWFT",
	"title": "title_2turq",
	"text": "text_1oN0d"
};
module.exports = exports;
