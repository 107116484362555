























































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { ClientProposalCell, ClientProposalCellVersion2 } from './components'
import { IConnectProposal, IConnectProposalStatus } from '~/Interfaces'
import { ClientProposalStatusLabel } from '~/components'

@Component({
  components: {
    ClientProposalCell,
    ClientProposalCellVersion2,
    ClientProposalStatusLabel,
  },
})
export default class ClientProposalTable extends Vue {
  @Prop()
  private connectProposals!: IConnectProposal[]

  @Prop()
  private connectProposalStatus!: IConnectProposalStatus

  private isOpen: boolean = false

  mounted() {
    this.isOpen = this.proposals.length > 0
  }

  get isVisible(): boolean {
    return (
      (this.connectProposalStatus as any) !== 'waiting' ||
      this.proposals.length > 0
    )
  }

  get isContracted(): boolean {
    return (
      (this.connectProposalStatus as any) === 'accepted' ||
      (this.connectProposalStatus as any) === 'terminated'
    )
  }

  get isVisibleProposalContent(): boolean {
    const connectProposalStatus = (this.connectProposalStatus as any) as string

    return [
      'waiting',
      'negotiating',
      'pending',
      'rejected',
      'rejected_not_correspond',
    ].includes(connectProposalStatus)
  }

  get id(): string {
    return `client-proposal-table-${this.connectProposalStatus}`
  }

  get proposals(): IConnectProposal[] {
    if ((this.connectProposalStatus as any) === 'rejected') {
      return this.connectProposals.filter((proposal) =>
        ['rejected', 'rejected_not_correspond'].includes(
          proposal.connectProposalStatus as any
        )
      )
    }

    if ((this.connectProposalStatus as any) === 'accepted') {
      return this.connectProposals.filter(
        (proposal) =>
          proposal.connectProposalStatus === this.connectProposalStatus &&
          !proposal.isExpired
      )
    }

    if ((this.connectProposalStatus as any) === 'terminated') {
      return this.connectProposals.filter(
        (proposal) =>
          proposal.connectProposalStatus === this.connectProposalStatus ||
          proposal.isExpired
      )
    }

    return this.connectProposals.filter(
      (proposal) =>
        proposal.connectProposalStatus === this.connectProposalStatus
    )
  }

  private onAccordion() {
    this.isOpen = !this.isOpen
  }

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}
}
