// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header_1UkWG{background:#fcf0dd;width:100%;padding:20px;display:flex;justify-content:center;align-items:center;grid-gap:0 24px;gap:0 24px}.text_4bNhx{font-size:16px;line-height:24px;color:#2c3846}.profile_1HnWe,.text_4bNhx{font-weight:600}.profile_1HnWe{display:flex;justify-content:center;align-items:center;width:175px;height:32px;background:#2f5980;border-radius:20px;font-size:12px;line-height:18px;text-align:center;color:#fff;text-decoration:none;grid-gap:0 14px;gap:0 14px}.profile_1HnWe:hover{opacity:.8}.profile_1HnWe .icon_T3vyb{font-size:14px}.release_RHPZe{font-weight:400;font-size:13px;line-height:20px;color:#555;text-decoration:none}.release_RHPZe:hover{opacity:.8}", ""]);
// Exports
exports.locals = {
	"header": "header_1UkWG",
	"text": "text_4bNhx",
	"profile": "profile_1HnWe",
	"icon": "icon_T3vyb",
	"release": "release_RHPZe"
};
module.exports = exports;
