import { Context } from '@nuxt/types'
import { LockStore } from '~/utils/store-accessor'
import IConnectProductContentRequest from '~/Interfaces/connectProduct/content/IConnectProductContentRequest'
import IConnectProductContentProduct from '~/Interfaces/connectProduct/content/IConnectProductContentProduct'
import IConnectProductContentMethod from '~/Interfaces/connectProduct/content/IConnectProductContentMethod'
import IConnectProductContentCompany from '~/Interfaces/connectProduct/content/IConnectProductContentCompany'
import IConnectHearingSheet from '~/Interfaces/connectProduct/IConnectHearingSheet'
import IConnectHearingSheetForKakutokuStaff from '~/Interfaces/connectProduct/IConnectHearingSheetForKakutokuStaff'

export default class HearingSheetV2Service {
  private readonly context: Context

  constructor(context: Context) {
    this.context = context
  }

  /**
   */
  async updateRequest(
    hashId: string,
    connectProductContentRequest: IConnectProductContentRequest
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.post(
        'api/hearing_sheet/request/' + hashId,
        {
          request: connectProductContentRequest,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }

  /**
   */
  async updateProduct(
    hashId: string,
    connectProductContentProduct: IConnectProductContentProduct,
    forKakutokuStaff: IConnectHearingSheetForKakutokuStaff
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.post(
        'api/hearing_sheet/product/' + hashId,
        {
          product: connectProductContentProduct,
          forKakutokuStaff,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }

  /**
   */
  async updateMethod(
    hashId: string,
    connectProductContentMethod: IConnectProductContentMethod
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.post(
        'api/hearing_sheet/method/' + hashId,
        {
          method: connectProductContentMethod,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }

  /**
   */
  async updateCompany(
    hashId: string,
    connectProductContentCompany: IConnectProductContentCompany
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.post(
        'api/hearing_sheet/company/' + hashId,
        {
          company: connectProductContentCompany,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }

  /**
   */
  async confirm(hashId: string): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.post(
        'api/hearing_sheet/confirm/' + hashId
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }

  /**
   *
   * @param hashId
   */
  async find(hashId: string): Promise<IConnectHearingSheet | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.get(
        'api/hearing_sheet/' + hashId
      )

      console.log(response)
      const data = JSON.parse(JSON.stringify(response.data))
      if (data === false) {
        return undefined
      }
      return data
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }

  /**
   */
  async findTmp(hashId: string): Promise<IConnectHearingSheet | undefined> {
    LockStore.lock(LockStore.types.hearing_sheet)
    try {
      const response = await this.context.$axios.get(
        'api/hearing_sheet/tmp/' + hashId
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.hearing_sheet)
    }
  }
}
