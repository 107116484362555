// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button_2e-8w{font-style:normal;font-weight:600;font-size:14px;line-height:21px;display:flex;justify-content:center;align-items:center;text-align:center;width:200px;height:40px;border-radius:20px;cursor:pointer;border:none}.button_2e-8w.primary_2UrFg{background:linear-gradient(90deg,#0066cb,#00a1c7 50.13%,#00a5a7);color:#fff}.button_2e-8w.primary_ver2_2ocrq{background:#3379b7;color:#fff}.button_2e-8w.primary_ver2_2ocrq:hover{background:#2c69a0;opacity:1}.button_2e-8w.primary_ver2_2ocrq:active{background:#245480}.button_2e-8w.secondary_3jFVb{background:#2f5980;color:#fff}.button_2e-8w.tertiary_1PtD5{background:#fff;color:#2f5980;border:1px solid #2f5980}.button_2e-8w.gray_UkP0Q{background:#e9edf1;color:#222;color:var(--color-text-90-default,#222)}.button_2e-8w:hover{opacity:.8}.button_2e-8w.clientPrimary_1ObPV{background:#3379b7;color:#fff}.button_2e-8w.clientPrimary_1ObPV:hover{background:#2c69a0;opacity:1!important}.button_2e-8w.clientPrimary_1ObPV:disabled{background:#245480;color:#afadac}.button_2e-8w.transparent_2uVe0{background:transparent;color:#555}.button_2e-8w.sm_31lKP{width:160px;height:32px;font-size:12px;line-height:18px}.button_2e-8w:disabled{background:#e9edf1;color:#c0cbd8;cursor:not-allowed}.inner_xEPyf{display:flex;justify-content:center;align-items:center;width:100%}.icon_2qZcj{font-size:18px}.icon_2qZcj.primary_2UrFg,.icon_2qZcj.secondary_3jFVb{color:#fff}.icon_2qZcj.gray_UkP0Q,.icon_2qZcj.tertiary_1PtD5{color:#2f5980}", ""]);
// Exports
exports.locals = {
	"button": "button_2e-8w",
	"primary": "primary_2UrFg",
	"primary_ver2": "primary_ver2_2ocrq",
	"secondary": "secondary_3jFVb",
	"tertiary": "tertiary_1PtD5",
	"gray": "gray_UkP0Q",
	"clientPrimary": "clientPrimary_1ObPV",
	"transparent": "transparent_2uVe0",
	"sm": "sm_31lKP",
	"inner": "inner_xEPyf",
	"icon": "icon_2qZcj"
};
module.exports = exports;
