// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".enterActive_16WFJ{transition:opacity .3s}.leaveActive_3DGzh{opacity:0}.header_3uMNm{display:flex;justify-content:flex-start;width:100%;padding:0 50px}.header_3uMNm .h3_1tMAn{font-size:22px;font-weight:600;line-height:28.6px;text-align:left;color:#555;color:var(--Text-60,#555)}.modal_2rrBM{padding-left:50px;padding-right:50px;display:flex;flex-flow:column;align-items:center;width:100%}.modal_2rrBM .document_list_1Im5l{width:100%;margin-top:100px;height:240px;overflow-y:auto;margin-bottom:20px}.modal_2rrBM .document_list_1Im5l .document_head_1Wtf3{display:flex;justify-content:space-between;align-items:center;padding:10px;font-size:13px;font-weight:400;line-height:19.5px;color:#454545;color:var(--Text-80,#454545)}.modal_2rrBM .document_list_1Im5l .document_head_1Wtf3 p:nth-child(2){padding-right:72px}.modal_2rrBM .document_list_1Im5l .document_item_2wp2U{display:flex;justify-content:space-between;align-items:center;padding:10px}.modal_2rrBM .document_list_1Im5l .document_item_2wp2U a{font-size:14px;font-weight:300;line-height:21px;color:#3379b7;color:var(--Primary-60,#3379b7);text-decoration:none;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;padding-right:20px}.modal_2rrBM .document_list_1Im5l .document_item_2wp2U p{font-size:14px;font-weight:400;line-height:21px;color:#454545;color:var(--Text-80,#454545);white-space:nowrap}.modal_2rrBM .document_list_1Im5l .document_item_2wp2U:nth-child(2n){background:#f8f7f7;background:var(--Background-Grey,#f8f7f7)}.modal_2rrBM .document_list_1Im5l .document_item_2wp2U:nth-child(odd){background:#fff;background:var(--Background-White,#fff)}", ""]);
// Exports
exports.locals = {
	"enterActive": "enterActive_16WFJ",
	"leaveActive": "leaveActive_3DGzh",
	"header": "header_3uMNm",
	"h3": "h3_1tMAn",
	"modal": "modal_2rrBM",
	"document_list": "document_list_1Im5l",
	"document_head": "document_head_1Wtf3",
	"document_item": "document_item_2wp2U"
};
module.exports = exports;
