var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","enter-class":_vm.s.enterActive,"enter-to-class":_vm.s.enterActive,"enter-active-class":_vm.s.enterActive,"leave-active-class":_vm.s.leaveActive}},[(_vm.connectProposal)?_c('div',{class:[
      _vm.s.clientProposalDetail,
      ( _obj = {}, _obj[_vm.s.clientProposalDetailSupportAccount] = _vm.isSupportAccount, _obj ) ],on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onClick($event)}}},[_c('div',{class:[_vm.s.modal, ( _obj$1 = {}, _obj$1[_vm.s.openedMemoModal] = _vm.clientMemo !== null, _obj$1 )]},[_c('ClientProposalDetailHeader',{attrs:{"connect-proposal":_vm.connectProposal,"is-message-tab-default":_vm.isMessageTabDefault},on:{"onChangeTab":_vm.onChangeTab,"updateStatus":_vm.updateStatus}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'proposal'),expression:"currentTab === 'proposal'"}],class:[
          _vm.s.content,
          _vm.s.proposal,
          ( _obj$2 = {}, _obj$2[_vm.s.contentSupportAccount] = _vm.isSupportAccount, _obj$2 ) ],attrs:{"id":"proposal-unordered","role":"tabpanel","tabindex":"0","aria-labelledby":"proposal-tab-proposal"}},[_c('ClientProposalDetailContent',{class:_vm.s.detailContent,attrs:{"connect-proposal":_vm.connectProposal},on:{"updateStatus":_vm.updateStatus}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'contract'),expression:"currentTab === 'contract'"}],class:[
          _vm.s.content,
          _vm.s.contract,
          ( _obj$3 = {}, _obj$3[_vm.s.contentSupportAccount] = _vm.isSupportAccount, _obj$3 ) ],attrs:{"id":"proposal-contract","role":"tabpanel","tabindex":"0","aria-labelledby":"proposal-tab-contract"}},[_c('ClientProposalContract',{attrs:{"connect-proposal":_vm.connectProposal},on:{"refreshProposals":_vm.refreshProposals}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'message'),expression:"currentTab === 'message'"}],class:[
          _vm.s.content,
          _vm.s.message,
          ( _obj$4 = {}, _obj$4[_vm.s.contentSupportAccount] = _vm.isSupportAccount, _obj$4 ) ],attrs:{"id":"proposal-message","role":"tabpanel","tabindex":"0","aria-labelledby":"proposal-tab-message"}},[(_vm.currentTab === 'message')?_c('ClientProposalMessage',{attrs:{"will-appear-client-message-attention-modal":_vm.willAppearClientMessageAttentionModal,"connect-proposal":_vm.connectProposal},on:{"refreshProposals":_vm.refreshProposals,"onMessagesLoaded":_vm.onMessagesLoaded}}):_vm._e()],1)],1),_vm._v(" "),_c('ClientProposalMemo',{attrs:{"client-memo":_vm.clientMemo},on:{"refreshProposals":_vm.refreshProposals}}),_vm._v(" "),_c('SnackBar',{attrs:{"message":"メモを保存しました","name":'client_memo'}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }