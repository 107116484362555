




















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ClientAccountRow from '~/components/common/profile/client/components/client-account-row.vue'
import { IConnectClientAccountForAccountList } from '~/Interfaces'

@Component({
  components: { ClientAccountRow },
})
export default class ClientAccountTable extends Vue {
  @Prop()
  roles!: { [key: string]: string }

  @Prop()
  accounts!: IConnectClientAccountForAccountList[]
}
