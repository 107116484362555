import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import ConnectCompanyProfileCard from '../components/Client/profile/ConnectCompanyProfileCard'
import {
  IConnectCompanyConstants,
  IConnectCompanyProfile,
  IConnectCompanyProfileCard,
} from '~/Interfaces'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectCompanyProfileCardsDefine',
})
export default class ConnectCompanyProfileCardsDefine extends VuexModule {
  connectCompanyProfileCards: ConnectCompanyProfileCard[] = []
  connectCompanyConstants: IConnectCompanyConstants | undefined = undefined

  @Mutation
  set(connectCompanyProfileCards: IConnectCompanyProfileCard[]) {
    this.connectCompanyProfileCards = connectCompanyProfileCards.map(
      (e) => new ConnectCompanyProfileCard(e)
    )
  }

  @Mutation
  replace(connectCompanyProfileCards: IConnectCompanyProfileCard[]) {
    const profiles = this.connectCompanyProfileCards

    connectCompanyProfileCards.map((e) => {
      const index = this.connectCompanyProfileCards.findIndex(
        (connectCompanyProfile) =>
          connectCompanyProfile.value.companyHashId === e.companyHashId
      )
      if (index !== -1) {
        profiles.splice(index, 1)
      }

      profiles.push(new ConnectCompanyProfileCard(e))
    })

    this.connectCompanyProfileCards = profiles
  }

  get findByCompanyHashId(): (
    companyHashId: string
  ) => ConnectCompanyProfileCard | undefined {
    const self = this

    return (companyHashId: string) => {
      return self.connectCompanyProfileCards.find(
        (connectCompanyProfileCard) =>
          connectCompanyProfileCard.value.companyHashId === companyHashId
      )
    }
  }
}
