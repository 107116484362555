



































import { Component, Prop, Vue } from 'nuxt-property-decorator'

import ThreadMessageAttachment from '../ThreadMessageAttachment/index.vue'
import { TopicStore } from '~/utils/store-accessor'
import { IThreadDetail, IThreadGroupAccount } from '~/Interfaces'
import ClientProductIdIcon from '~/components/Client/ClientProductIdIcon.vue'

@Component({
  components: { ClientProductIdIcon, ThreadMessageAttachment },
})
export default class ThreadMessageOther extends Vue {
  @Prop()
  private threadDetail!: IThreadDetail

  get threadGroupAccount(): IThreadGroupAccount | null {
    return TopicStore.getThreadGroupAccountOther
  }

  get name(): string | null {
    return TopicStore.getNameOther
  }

  get image(): string | null {
    return TopicStore.getImageOther
  }

  get isConnect(): boolean {
    return TopicStore.isConnect
  }

  mounted() {}
}
