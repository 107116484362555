import { NuxtAxiosInstance } from '@nuxtjs/axios'
import IProduct from '~/Interfaces/IProduct'
import IClientProduct from '~/Interfaces/IClientProduct'
import { mapToClientProduct } from '~/components/Services/Mapper'
import IProductWithOthers from '~/Interfaces/clientProduct/IProductWithOthers'

export default class ClientProductService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * 一覧を取得
   */
  async get(): Promise<IClientProduct | undefined> {
    try {
      const response = await this.axios.get('api/client/product/get')

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 1件取得する
   * @param product_hash_id
   */
  async find(product_hash_id: string): Promise<IProduct | undefined> {
    try {
      const response = await this.axios.get(
        'api/client/product/' + product_hash_id
      )

      return mapToClientProduct(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 1件取得する
   * @param product_hash_id
   */
  async findWithoutCompany(
    product_hash_id: string
  ): Promise<{ product: IProduct; count: number } | undefined> {
    try {
      const response = await this.axios.get(
        'api/client/product/find_without_company/' + product_hash_id
      )

      return {
        product: mapToClientProduct(response.data.product),
        count: response.data.count,
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 1件取得する
   * @param product_hash_id
   */
  async findWithOthers(
    product_hash_id: string
  ): Promise<IProductWithOthers | undefined> {
    try {
      const response = await this.axios.get(
        'api/client/product/with_others/' + product_hash_id
      )

      return {
        connectProduct: mapToClientProduct(response.data.connectProduct),
        threadHashIds: response.data.threadHashIds,
        connectCompanyProfiles: response.data.connectCompanyProfiles,
        connectCompanyConstants: response.data.connectCompanyConstants,
      }
    } catch (err) {
      console.log(err)
    }
  }

  async countProduct(): Promise<number | undefined> {
    try {
      const response = await this.axios.get('api/client/product/count_product')

      console.log(response)
      return response.data.count
    } catch (err) {
      console.log(err)
    }
  }
}
