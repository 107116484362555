// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2LKoo{max-width:488px;margin:0 auto}.title_neZZR{font-size:18px;font-weight:600;line-height:130%;margin:0 0 40px}.description_2wVW-,.title_neZZR{color:#0e273c;text-align:center;padding:0}.description_2wVW-{font-size:14px;font-weight:400;margin:0 0 30px}.buttonLayer_1Pi-r{margin-top:40px;display:flex;justify-content:center}", ""]);
// Exports
exports.locals = {
	"container": "container_2LKoo",
	"title": "title_neZZR",
	"description": "description_2wVW-",
	"buttonLayer": "buttonLayer_1Pi-r"
};
module.exports = exports;
