




import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class PartnerDescription extends Vue {
  @Prop()
  private text!: string | null

  @Prop()
  private isThin!: boolean

  get displayText(): string {
    return this.text ?? ''
  }
}
