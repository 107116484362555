





























import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { SnackbarStore } from '~/utils/store-accessor'

@Component
export default class ConnectSnackbar extends Vue {
  @Prop()
  private message!: string

  @Prop()
  private name!: string

  @Prop()
  private error!: boolean

  @Prop()
  private hint!: string

  get isVisible() {
    return SnackbarStore.shouldDisplay(this.name)
  }

  private onAfterEnter() {
    setTimeout(() => {
      SnackbarStore.hide(this.name)
      this.snackBarLeave()
    }, 3000)
  }

  @Emit('snackBarLeave')
  snackBarLeave() {}
}
