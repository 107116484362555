























import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class StepBlock extends Vue {
  @Prop()
  step!: string

  @Prop({ default: undefined })
  count!: number | undefined

  @Prop({ default: false })
  isActive!: boolean

  @Prop({ default: false })
  isSubmitted!: boolean
}
