export default ({ $axios, app }, inject) => {
  $axios.onRequest((config) => {
    const adminHashId = app.$admin.getAdminHashId()
    const connectClientHashId = app.$admin.getConnectClientHashId()
    const isPartnerSupportAccount =
      app.$services?.user?.isSupportPartner ?? false

    if (adminHashId) {
      axios.defaults.headers.get.AdminHashId = adminHashId
    }

    if (connectClientHashId) {
      axios.defaults.headers.get.ConnectClientHashId = connectClientHashId

      if (config.method === 'get' && config.url !== 'api/term') {
        config.url = config.url.replace('api/', 'api/admin/')
      }

      if (config.method === 'post') {
        throw new Error(
          'adminからのプレビューではデータの作成・更新はできません'
        )
      }
    }

    if (
      isPartnerSupportAccount &&
      (config.method === 'post' || config.method === 'put')
    ) {
      const whiteList = ['api/company/make_login_done', './api/auth/logout']
      if (!whiteList.includes(config.url)) {
        const errorMsg = 'サポートアカウントではデータの作成・更新はできません'
        console.error(config.url)
        alert(errorMsg)
        throw new Error(errorMsg)
      }
    }

    return config
  })

  const axios = $axios
  inject('axios', axios)
}
