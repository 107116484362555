// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".service_3fybz{display:flex;align-items:center;justify-content:center;width:100px;height:24px;border-radius:10px;font-size:12px;letter-spacing:0;background:#2f5980;color:#fff}.service_3fybz.sp_1p6zq{width:80px;height:20px;border-radius:8px}.service_3fybz.in_topic_3YVCy{border-radius:8px 0 0 8px}", ""]);
// Exports
exports.locals = {
	"service": "service_3fybz",
	"sp": "sp_1p6zq",
	"in_topic": "in_topic_3YVCy"
};
module.exports = exports;
