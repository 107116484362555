import { IConnectCompanyProfile } from '~/Interfaces'

export default class ConnectCompanyProfile {
  value: IConnectCompanyProfile

  constructor(connectCompanyProfile: IConnectCompanyProfile) {
    this.value = connectCompanyProfile
  }

  get isExistsDegreeCompanies(): boolean {
    const length = this.value.degreeCompanies?.length ?? 0

    return length > 0
  }
}
