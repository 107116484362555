






import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class PartnerSubtitle extends Vue {
  @Prop()
  private title!: string
}
