import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectCompanyMember from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMember'
import IConnectCompanyMemberConstResponse from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberConstResponse'

export default class CompanyMemberService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   *
   */
  async setMember(
    member: {},
    connectCompanyMember: IConnectCompanyMember | undefined
  ): Promise<string | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post('api/company/member/add', {
        connect_company_member_hash_id:
          connectCompanyMember?.connectCompanyMemberHashId,
        member,
      })

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async uploadImage(
    connectCompanyMemberHashId: string,
    formData: FormData
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/member/upload_image/' + connectCompanyMemberHashId,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async deleteMember(
    connectCompanyMember: IConnectCompanyMember
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/member/delete/' +
          connectCompanyMember.connectCompanyMemberHashId
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async find(
    connectCompanyMemberHashId: string
  ): Promise<IConnectCompanyMember | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get(
        'api/company/member/find/' + connectCompanyMemberHashId
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getConsts(): Promise<IConnectCompanyMemberConstResponse | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get('api/company/member/const')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getMembers(): Promise<IConnectCompanyMember[]> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get('api/company/member/get')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getMembersByClient(
    connectCompanyHashId: string
  ): Promise<IConnectCompanyMember[]> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get(
        'api/client/connect_company/member/' + connectCompanyHashId
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getMemberByClient(
    connectCompanyHashId: string,
    connectCompanyMemberHashId: string
  ): Promise<IConnectCompanyMember[]> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get(
        'api/client/connect_company/' +
          connectCompanyHashId +
          '/member/' +
          connectCompanyMemberHashId
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async sort(sortData: {
    connectCompanyMemberHashIds: string[]
  }): Promise<void> {
    LockStore.lock(LockStore.types.company)
    try {
      await this.axios.post('api/company/member/sort', sortData)
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
