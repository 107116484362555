import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/utils/store-accessor'
import { IResendActivateResponse } from '~/Interfaces/IConnectClientAccount'

export default class ActivateService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * @deprecate (発行済みのメールに対応するため残す）
   * 認証する
   * @param email
   * @param hash
   */
  async auth(email: string, hash: string): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post('api/client/activate', {
        email,
        hash,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * 認証する
   * @param hash
   */
  async authByOnlyHash(hash: string): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post('api/client/activate/by_hash', {
        hash,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * 認証メールを再送する
   *
   * @param email
   */
  async resendActivate(email: string): Promise<IResendActivateResponse> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post('api/client/activate/resend', {
        email,
      })

      console.log(response)

      return { result: true }
    } catch (err: any) {
      console.log(err)
      return {
        result: false,
        reason:
          err.response.status === 409 ? err?.response?.data?.message : null,
      }
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * @deprecate (発行済みのメールに対応するため残す）
   * 招待アカウントを認証する
   * @param email
   * @param hash
   */
  async authInvited(email: string, hash: string): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post('api/client/invited/activate', {
        email,
        hash,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * 招待アカウントを認証する
   * @param hash
   */
  async authInvitedByOnlyHash(hash: string): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post(
        'api/client/invited/activate/by_hash',
        {
          hash,
        }
      )

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * 招待アカウントの認証メールを再送する
   *
   * @param email
   */
  async resendActivateInvited(email: string): Promise<IResendActivateResponse> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post(
        'api/client/invited/activate/resend',
        {
          email,
        }
      )

      console.log(response)

      return { result: true }
    } catch (err: any) {
      console.log(err)
      return {
        result: false,
        reason:
          err.response.status === 409 ? err?.response?.data?.message : null,
      }
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }
}
