





































import { Component, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import EmptyStateText from '../EmptyStateText/index.vue'
import PartnerDescription from '../PartnerDescription/index.vue'
import PartnerSubtitle from '../PartnerSubtitle/index.vue'
import ProfileConnectCompanyCareer from '~/components/common/profile/connectCompany/profile-connect-company-career.vue'
import {
  IConnectCompanyConstants,
  IConnectCompanyRepresentative,
} from '~/Interfaces'
import PartnerProfileModal from '~/components/common/profile/partner/PartnerProfile/PartnerProfileModal.vue'

@Component({
  components: {
    PartnerProfileModal,
    EmptyStateText,
    PartnerDescription,
    ProfileConnectCompanyCareer,
    PartnerSubtitle,
  },
})
export default class PartnerProfileCareerModal extends Vue {
  @Prop()
  private constants!: IConnectCompanyConstants

  @Prop()
  private representative!: IConnectCompanyRepresentative

  @PropSync('isVisible')
  private visible!: boolean

  @Prop({ default: false })
  private isClient!: boolean

  private onClose() {
    this.visible = false
  }
}
