



































































































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { ThreadMessages, ThreadFormFileUpload, ThreadForm } from './components'
import { IConnectProposal } from '~/Interfaces'
import ClientMessageAttentionModal from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientMessageAttentionModal/index.vue'
import ClientProposalDetailTitle from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalDetailTitle/index.vue'
import {
  ConnectContractRenewalStore,
  ConnectProposalThreadStore,
  ConnectServiceAgreementModalStore,
  ModalStore,
  ReadsStore,
  ThreadDetailsStore,
  ThreadGroupsStore,
  TopicStore,
  UploadFileStore,
} from '~/utils/store-accessor'

@Component({
  components: {
    ClientMessageAttentionModal,
    ClientProposalDetailTitle,
    ThreadMessages,
    ThreadForm,
    ThreadFormFileUpload,
  },
})
export default class ClientProposalMessage extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop({ default: true })
  private willAppearClientMessageAttentionModal!: boolean

  private threadGroupHashId!: string
  private isConnect!: boolean
  private isLocked: boolean = false

  // メッセージの読み込み完了をemitするためにasync/awaitを追加
  async mounted() {
    await this.load()
    this.onMessagesLoaded()
  }

  async load(force: boolean = false) {
    const messageService = this.$services.message

    if (!ThreadGroupsStore.getThreadGroups.length || force) {
      const groups = await messageService.getGroups()
      if (groups) {
        ThreadGroupsStore.setThreadGroups(groups.thread_groups)

        ReadsStore.setReads(groups.reads)
      }
    }

    if (['waiting', 'pending'].includes(this.connectProposalStatus)) {
      return
    }

    let threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    if (!threadHashId) {
      threadHashId = await messageService.findThreadHashId(
        this.connectProposal.connectProposalHashId,
        true
      )
    }

    if (!threadHashId) return

    // threadHashId を cache
    ConnectProposalThreadStore.setThreadHashId({
      connectProposalHashId: this.connectProposal.connectProposalHashId,
      threadHashId,
    })

    const couldRead = await messageService.postRead(threadHashId)
    if (couldRead) {
      ReadsStore.setIsRead(threadHashId)
    }

    const topic = await messageService.getTopic(threadHashId)
    TopicStore.setTopic(topic)

    ThreadDetailsStore.setThreadDetails(topic?.thread.threadDetails)

    if (this.willAppearClientMessageAttentionModal) {
      this.checkAttention()
    }
  }

  private checkAttention() {
    if (!TopicStore.getTopic?.thread.isAgreedMessageAttentionToClient) {
      ModalStore.show(
        'clientMessageAttentionModal' +
          this.connectProposal.connectProposalHashId
      )
    }
  }

  get connectProposalStatus(): string {
    return this.connectProposal.connectProposalStatus as any
  }

  get disabledType(): string | null {
    if (
      ['rejected', 'rejected_not_correspond'].includes(
        this.connectProposalStatus
      )
    ) {
      return 'rejected'
    }
    return null
  }

  get disabledMessage(): boolean {
    if (
      ['waiting', 'pending', 'rejected_not_correspond'].includes(
        this.connectProposalStatus
      )
    ) {
      return true
    }

    const threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    return (
      this.connectProposalStatus === 'rejected' && threadHashId === undefined
    )
  }

  get disabledQuotation() {
    // ゲストアカウントの場合は非活性
    if (this.$services.user.isGuestAccount) {
      return true
    }

    return [
      'waiting',
      'pending',
      'rejected_not_correspond',
      'rejected',
    ].includes(this.connectProposalStatus)
  }

  get hiddenQuotation() {
    const connectContractRenewal = ConnectContractRenewalStore.get(
      this.connectProposal.connectProposalHashId
    )

    if (connectContractRenewal?.isRequestedQuotationConfirm) {
      return false
    }

    return ['accepted', 'terminated'].includes(this.connectProposalStatus)
  }

  get isRequestedQuotationApprove(): boolean {
    return this.connectProposal?.isRequestedQuotationApprove
  }

  get isRequestedQuotationConfirm(): boolean {
    return this.connectProposal?.isRequestedQuotationConfirm
  }

  get isRequestedQuotationEdit(): boolean {
    return this.connectProposal?.isRequestedQuotationEdit
  }

  get isRequestedQuotation(): boolean {
    return this.connectProposal?.isRequestedQuotation
  }

  private get isSupportAccount(): boolean {
    return (
      this.$services.user.isSupportClient ||
      this.$services.user.isSupportPartner
    )
  }

  get canRequest(): boolean {
    if (!this.$auth.user) {
      return false
    }

    if (!this.$auth.user.connect_client_address) {
      return false
    }

    return !!this.$auth.user.connect_client_address
  }

  get isOpenedFileUpload(): boolean {
    return UploadFileStore.getIsOpened
  }

  private scrollBottom() {
    scrollTo({
      top: this.$el.scrollHeight,
      behavior: 'smooth',
    })
  }

  private changeTextareaHeight() {
    this.$nextTick(() => {
      const form = this.$refs.form as Vue
      const threadForm = form.$refs.threadForm as HTMLDivElement

      const messages = this.$refs.messages as Vue
      const threadMessages = messages.$refs.threadMessages as HTMLDivElement
      threadMessages.style.marginBottom = threadForm.scrollHeight + 'px'
    })
  }

  /**
   * 商談する(実行)
   */
  async executeNegotiate() {
    // 住所登録をしていない場合は、処理を停止しモーダルで登録を促す
    if (this.$services.user.shouldRegisterAddress) {
      ModalStore.show('ClientUrgeProfileModal')
      return
    }

    this.isLocked = true
    const proposal = await this.$services.proposal.negotiate(
      this.connectProposal.connectProposalHashId
    )

    await this.openThread()
    this.isLocked = false
    if (proposal) {
      this.connectProposal.connectProposalStatus =
        proposal.connectProposalStatus
    }
    await this.load()
  }

  /**
   * 見積作成を依頼する(確認)
   */
  requestQuotation() {
    if (this.disabledMessage) return
    if (!this.canRequest) {
      ModalStore.show('ClientUrgeProfileModal')
      return
    }
    ModalStore.show(
      'ClientConfirmModal' + this.connectProposal.connectProposalHashId
    )
  }

  quotation() {
    if (!this.canRequest) {
      ModalStore.show('ClientUrgeProfileModal')
      return
    }

    const goToQuotation = async () => {
      await this.$router.push(
        '/client/quotation/' +
          this.connectProposal.connectProductHashId +
          '/' +
          this.connectProposal.connectProposalHashId
      )
    }

    goToQuotation()
  }

  async reject() {
    const proposal = await this.$services.proposal.reject(
      this.connectProposal.connectProposalHashId
    )
    if (proposal) {
      this.connectProposal.connectProposalStatus =
        proposal.connectProposalStatus
    }
  }

  private async openThread() {
    await this.$services.message.openThread(
      this.connectProposal.connectProposalHashId
    )
    this.$auth.fetchUser()
  }

  @Emit('refreshProposals')
  private refreshProposals() {}

  @Emit('onMessagesLoaded')
  private onMessagesLoaded() {}
}
