// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".checkbox_R3PWT{display:flex;align-items:center;flex-direction:column;align-items:flex-start;grid-gap:5px;gap:5px}.checkbox_R3PWT span{font-size:14px;line-height:14px;letter-spacing:.98px;color:#0e273c}.checkbox_R3PWT input[type=checkbox]{display:none}.checkbox_R3PWT input[type=checkbox]+label{cursor:pointer;display:inline-block;position:relative;padding-left:25px;padding-right:10px}.checkbox_R3PWT input[type=checkbox]+label:before{content:\"\";position:absolute;display:block;box-sizing:border-box;width:16px;height:16px;margin-top:-9px;left:0;top:50%;border:1px solid #b5bdbe;background-color:#e4ecf5}.checkbox_R3PWT input[type=checkbox]:checked+label:before{border-color:#224d74;background-color:#224d74}.checkbox_R3PWT input[type=checkbox]:checked+label:after{content:\"\";position:absolute;width:7px;height:3px;top:4px;left:4px;transform:rotate(-45deg);border-left:2px solid #fff;border-bottom:2px solid #fff;border-right-color:#fff;border-top-color:#fff}.checkbox_R3PWT input[type=checkbox]+label:before{background:#fff!important;border:2px solid #afadac}.checkbox_R3PWT input[type=checkbox]:checked+label:before{background:#3379b7!important;border-color:#3379b7!important}.checkbox_R3PWT.error_2wEeZ input[type=checkbox]+label:before{background:#ffe6f2!important;border:1px solid #c94282!important}.checkbox_R3PWT .label_1O_f3{color:#0e273c}.disabledCheckBox_3sOpP{opacity:.6}.checkboxError_3SopZ input[type=checkbox]+label:before{background:#ffe6f2!important;border:1px solid #c94282!important}", ""]);
// Exports
exports.locals = {
	"checkbox": "checkbox_R3PWT",
	"error": "error_2wEeZ",
	"label": "label_1O_f3",
	"disabledCheckBox": "disabledCheckBox_3sOpP",
	"checkboxError": "checkboxError_3SopZ"
};
module.exports = exports;
