









import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IClientSideMenuLink } from '~/Interfaces'

@Component
export default class ClientSideMenuLink extends Vue {
  @Prop()
  private sideMenuLink!: IClientSideMenuLink
}
