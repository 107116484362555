
















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectCompanyPreferredCustomerOrProduct } from '~/Interfaces'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import IConnectCompanyMemberPreferredCustomerOrProduct from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberPreferredCustomerOrProduct'

@Component({
  components: { ConnectIcon },
})
export default class PreferredItems extends Vue {
  @Prop()
  private title!: string

  @Prop()
  private preferred!:
    | IConnectCompanyPreferredCustomerOrProduct
    | IConnectCompanyMemberPreferredCustomerOrProduct

  get list(): string[] {
    return this.preferred.preferredValueStrings.map((p) => {
      if (p === 'その他') {
        return this.preferred.preferredOthers ?? ''
      }
      return p
    })
  }
}
