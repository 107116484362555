import { render, staticRenderFns } from "./client-title.vue?vue&type=template&id=2a9b2214&scoped=true&"
import script from "./client-title.vue?vue&type=script&lang=ts&"
export * from "./client-title.vue?vue&type=script&lang=ts&"
import style0 from "./client-title.vue?vue&type=style&index=0&lang=scss&module=s&"
import style1 from "./client-title.vue?vue&type=style&index=1&id=2a9b2214&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2a9b2214",
  null
  
)

export default component.exports