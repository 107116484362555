// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".partnerModal_3SyuM{position:fixed;width:100%;height:100vh;top:0;bottom:0;z-index:10}.partnerModal_3SyuM .modal_k3MmB{position:relative;width:860px;height:100vh;background:#fff;margin-left:auto;margin-right:0;box-shadow:0 0 20px rgba(109,132,155,.3);margin-top:72px}.partnerModal_3SyuM .modal_k3MmB .header_1nUcW{display:flex;justify-content:flex-start;align-items:center;grid-gap:0 10px;gap:0 10px;height:72px;background:#f9f9f9;padding:0 20px}.partnerModal_3SyuM .modal_k3MmB .header_1nUcW .title_2dK90{font-weight:600;font-size:16px;line-height:130%;color:#2c3846;display:flex;justify-content:flex-start;align-items:center;grid-gap:0 27px;gap:0 27px}.partnerModal_3SyuM .modal_k3MmB .header_1nUcW .button_1Vxzy{background:none;border:none;cursor:pointer;padding:0;margin:0;width:32px;height:32px;border-radius:50%}.partnerModal_3SyuM .modal_k3MmB .header_1nUcW .button_1Vxzy .icon_29H8m{color:#7ca8d5;font-size:18px}.partnerModal_3SyuM .modal_k3MmB .container_2LW4Z{padding:40px;overflow-y:auto;height:calc(100% - 144px)}.enterActive_3G8Li{-webkit-animation:slideIn_1JUP2 .3s ease-out;animation:slideIn_1JUP2 .3s ease-out}@-webkit-keyframes slideIn_1JUP2{0%{left:100vw}to{left:0}}@keyframes slideIn_1JUP2{0%{left:100vw}to{left:0}}.leaveActive_1QomD{-webkit-animation:slideOut_3qanS .3s ease-out;animation:slideOut_3qanS .3s ease-out}@-webkit-keyframes slideOut_3qanS{0%{left:0}to{left:100vw}}@keyframes slideOut_3qanS{0%{left:0}to{left:100vw}}.table_1WxId{margin-top:20px;display:flex;flex-direction:column;width:100%;font-size:14px;line-height:26px;letter-spacing:0;color:#2c3846;border-top:1px solid #ccc;border-left:1px solid #ccc;border-right:1px solid #ccc}.table_1WxId .row_ODKtp{display:flex;border-bottom:1px solid #ccc}.table_1WxId .row_ODKtp .title_2dK90{padding:14px 0 14px 16px;width:197px;background:#f5f7f8;font-weight:600}.table_1WxId .row_ODKtp .text_3JGgb{word-wrap:anywhere;white-space:pre-line;padding:14px 0 14px 16px;width:100%}", ""]);
// Exports
exports.locals = {
	"partnerModal": "partnerModal_3SyuM",
	"modal": "modal_k3MmB",
	"header": "header_1nUcW",
	"title": "title_2dK90",
	"button": "button_1Vxzy",
	"icon": "icon_29H8m",
	"container": "container_2LW4Z",
	"enterActive": "enterActive_3G8Li",
	"slideIn": "slideIn_1JUP2",
	"leaveActive": "leaveActive_1QomD",
	"slideOut": "slideOut_3qanS",
	"table": "table_1WxId",
	"row": "row_ODKtp",
	"text": "text_3JGgb"
};
module.exports = exports;
