import {LockStore} from "~/utils/store-accessor";
import {Context} from "@nuxt/types";
import IGoogleUser from "~/Interfaces/google/IGoogleUser";

export default class GoogleAuthService {
  private readonly context: Context;

  constructor(context: Context) {
    this.context = context
  }

  /**
   * ログイン済みかどうか
   */
  async isLoginDone(): Promise<boolean|undefined>
  {
    LockStore.lock(LockStore.types['login'])
    try {
      let response = await this.context.$axios.get('api/operation/google/is_login_done')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['login'])
    }
  }

  /**
   * ログイン済みかどうか
   */
  async setUser(token: string): Promise<boolean|undefined>
  {
    LockStore.lock(LockStore.types['login'])
    try {
      let response = await this.context.$axios.get('api/operation/google/user/' + token)

      console.log(response)
      return true;
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types['login'])
    }
  }

  /**
   * ログイン済みかどうか
   */
  async getUser(): Promise<IGoogleUser|undefined>
  {
    LockStore.lock(LockStore.types['login'])
    try {
      let response = await this.context.$axios.get('api/operation/google/user')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['login'])
    }
  }

}
