import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { TermStore } from '~/utils/store-accessor'

export default class TermService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * 規約取得
   */
  async getTerms(): Promise<void> {
    try {
      const response = await this.axios.get('api/term')

      console.log(response)
      const terms = JSON.parse(JSON.stringify(response.data))

      TermStore.set(terms)
    } catch (err) {
      console.log(err)
    } finally {
    }
  }
}
