import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import Offset from '~/store/Offset'
import Snackbar from '~/store/Snackbar'
import Lock from '~/store/Lock'
import ConnectTeamWithMemberWrapper from '~/store/ConnectTeamWithMemberWrapper'
import References from '~/store/References'
import BackPoint from '~/store/BackPoint'
import ConnectQuotationStoreDefine from '~/store/ConnectQuotationStoreDefine'
import Modal from '~/store/Modal'
import ConnectQuotationDifferStoreDefine from '~/store/ConnectQuotationDifferStoreDefine'
import ConnectQuotationDifferSelfStoreDefine from '~/store/ConnectQuotationDifferSelfStoreDefine'
import ConnectCompanyDefine from '~/store/ConnectCompanyDefine'
import PurchaseOrdersDefine from '~/store/PurchaseOrdersDefine'
import TermStoreDefine from '~/store/TermStoreDefine'
import ConnectServiceAgreementModalStoreDefine from '~/store/ConnectServiceAgreementModalStoreDefine'
import ConnectProposalSearchText from '~/store/ConnectProposalSearchText'
import ConnectProposalReadStoreDefine from '~/store/ConnectProposalReadStoreDefine'
import CompanyProposalStoreDefine from '~/store/CompanyProposalStoreDefine'
import ConnectRenewalAggregateStoreDefine from '~/store/ConnectRenewalAggregateStoreDefine'
import ConnectContractRenewalStoreDefine from '~/store/ConnectContractRenewalStoreDefine'
import CurrentConnectProposal from '~/store/CurrentConnectProposal'
import UploadFile from '~/store/UploadFile'
import ThreadGroup from '~/store/ThreadGroup'
import ThreadGroups from '~/store/ThreadGroups'
import Topic from '~/store/Topic'
import ThreadDetails from '~/store/ThreadDetails'
import Reads from '~/store/Reads'
import ConnectProposalThreadStoreDefine from '~/store/ConnectProposalThreadStoreDefine'
import RenewalPartnerQuotationDefine from '~/store/RenewalPartnerQuotationDefine'
import StaffContact from '~/store/StaffContact'
import ClientConnectProposalDetailsDefine from '~/store/ClientConnectProposalDetailsDefine'
import ConnectClientProposalMemoStoreDefine from '~/store/ConnectClientProposalMemoStoreDefine'
import ConnectCompanyProfileDefine from '~/store/ConnectCompanyProfileDefine'
import ConnectCompanyProfilesDefine from '~/store/ConnectCompanyProfilesDefine'
import ConnectCompanyProfileValidatorDefine from '~/store/ConnectCompanyProfileValidatorDefine'
import ConnectCompanyProfileCardsDefine from '~/store/ConnectCompanyProfileCardsDefine'
import QuotationIsFirstApproveRequestDefine from '~/store/QuotationIsFirstApproveRequestDefine'
import ConnectInvoicesDefine from '~/store/ConnectInvoicesDefine'
import ConnectClientProfileStoreDefine from '~/store/ConnectClientProfileStoreDefine'

let OffsetStore: Offset
let SnackbarStore: Snackbar
let LockStore: Lock
let ConnectTeamWithMemberWrapperStore: ConnectTeamWithMemberWrapper
let ReferencesStore: References
let BackPointStore: BackPoint
let ConnectQuotationStore: ConnectQuotationStoreDefine
let ModalStore: Modal
let ConnectQuotationDifferStore: ConnectQuotationDifferStoreDefine
let ConnectQuotationDifferSelfStore: ConnectQuotationDifferSelfStoreDefine
let ConnectCompanyStore: ConnectCompanyDefine
let PurchaseOrdersStore: PurchaseOrdersDefine
let TermStore: TermStoreDefine
let ConnectServiceAgreementModalStore: ConnectServiceAgreementModalStoreDefine
let ConnectProposalSearchTextStore: ConnectProposalSearchText
let CompanyProposalStore: CompanyProposalStoreDefine
let ConnectProposalReadStore: ConnectProposalReadStoreDefine
let ConnectRenewalAggregateStore: ConnectRenewalAggregateStoreDefine
let ConnectContractRenewalStore: ConnectContractRenewalStoreDefine
let CurrentConnectProposalStore: CurrentConnectProposal
let ConnectClientProposalMemoStore: ConnectClientProposalMemoStoreDefine
let UploadFileStore: UploadFile
let ThreadGroupStore: ThreadGroup
let ThreadGroupsStore: ThreadGroups
let TopicStore: Topic
let ThreadDetailsStore: ThreadDetails
let ReadsStore: Reads
let ConnectProposalThreadStore: ConnectProposalThreadStoreDefine
let RenewalPartnerQuotationStore: RenewalPartnerQuotationDefine
let StaffContactStore: StaffContact
let ClientConnectProposalDetailsStore: ClientConnectProposalDetailsDefine
let ConnectCompanyProfileStore: ConnectCompanyProfileDefine
let ConnectCompanyProfilesStore: ConnectCompanyProfilesDefine
let ConnectCompanyProfileValidatorStore: ConnectCompanyProfileValidatorDefine
let ConnectCompanyProfileCardsStore: ConnectCompanyProfileCardsDefine
let QuotationIsFirstApproveRequestStore: QuotationIsFirstApproveRequestDefine
let ConnectInvoicesStore: ConnectInvoicesDefine
let ConnectClientProfileStore: ConnectClientProfileStoreDefine

function initialiseStores(store: Store<any>): void {
  OffsetStore = getModule(Offset, store)
  SnackbarStore = getModule(Snackbar, store)
  LockStore = getModule(Lock, store)
  ConnectTeamWithMemberWrapperStore = getModule(
    ConnectTeamWithMemberWrapper,
    store
  )
  ReferencesStore = getModule(References, store)
  BackPointStore = getModule(BackPoint, store)
  ConnectQuotationStore = getModule(ConnectQuotationStoreDefine, store)
  ModalStore = getModule(Modal, store)
  ConnectQuotationDifferStore = getModule(
    ConnectQuotationDifferStoreDefine,
    store
  )
  ConnectQuotationDifferSelfStore = getModule(
    ConnectQuotationDifferSelfStoreDefine,
    store
  )
  ConnectCompanyStore = getModule(ConnectCompanyDefine, store)
  PurchaseOrdersStore = getModule(PurchaseOrdersDefine, store)
  TermStore = getModule(TermStoreDefine, store)
  ConnectServiceAgreementModalStore = getModule(
    ConnectServiceAgreementModalStoreDefine,
    store
  )
  ConnectProposalSearchTextStore = getModule(ConnectProposalSearchText, store)
  ConnectProposalReadStore = getModule(ConnectProposalReadStoreDefine, store)
  CompanyProposalStore = getModule(CompanyProposalStoreDefine, store)
  ConnectRenewalAggregateStore = getModule(
    ConnectRenewalAggregateStoreDefine,
    store
  )
  ConnectContractRenewalStore = getModule(
    ConnectContractRenewalStoreDefine,
    store
  )
  CurrentConnectProposalStore = getModule(CurrentConnectProposal, store)
  UploadFileStore = getModule(UploadFile, store)
  ThreadGroupStore = getModule(ThreadGroup, store)
  ThreadGroupsStore = getModule(ThreadGroups, store)
  TopicStore = getModule(Topic, store)
  ThreadDetailsStore = getModule(ThreadDetails, store)
  ReadsStore = getModule(Reads, store)
  ConnectProposalThreadStore = getModule(
    ConnectProposalThreadStoreDefine,
    store
  )
  RenewalPartnerQuotationStore = getModule(RenewalPartnerQuotationDefine, store)
  StaffContactStore = getModule(StaffContact, store)
  ClientConnectProposalDetailsStore = getModule(
    ClientConnectProposalDetailsDefine,
    store
  )
  ConnectClientProposalMemoStore = getModule(
    ConnectClientProposalMemoStoreDefine,
    store
  )
  ConnectCompanyProfileStore = getModule(ConnectCompanyProfileDefine, store)
  ConnectCompanyProfilesStore = getModule(ConnectCompanyProfilesDefine, store)
  ConnectCompanyProfileValidatorStore = getModule(
    ConnectCompanyProfileValidatorDefine,
    store
  )
  ConnectCompanyProfileCardsStore = getModule(
    ConnectCompanyProfileCardsDefine,
    store
  )
  QuotationIsFirstApproveRequestStore = getModule(
    QuotationIsFirstApproveRequestDefine,
    store
  )
  ConnectInvoicesStore = getModule(ConnectInvoicesDefine, store)
  ConnectClientProfileStore = getModule(ConnectClientProfileStoreDefine, store)
}

export {
  initialiseStores,
  OffsetStore,
  SnackbarStore,
  LockStore,
  ConnectTeamWithMemberWrapperStore,
  ReferencesStore,
  BackPointStore,
  ConnectQuotationStore,
  ModalStore,
  ConnectQuotationDifferStore,
  ConnectQuotationDifferSelfStore,
  ConnectCompanyStore,
  PurchaseOrdersStore,
  TermStore,
  ConnectServiceAgreementModalStore,
  ConnectProposalSearchTextStore,
  ConnectProposalReadStore,
  CompanyProposalStore,
  ConnectRenewalAggregateStore,
  ConnectContractRenewalStore,
  CurrentConnectProposalStore,
  UploadFileStore,
  ThreadGroupStore,
  ThreadGroupsStore,
  TopicStore,
  ThreadDetailsStore,
  ReadsStore,
  ConnectProposalThreadStore,
  RenewalPartnerQuotationStore,
  StaffContactStore,
  ClientConnectProposalDetailsStore,
  ConnectClientProposalMemoStore,
  ConnectCompanyProfileStore,
  ConnectCompanyProfilesStore,
  ConnectCompanyProfileValidatorStore,
  ConnectCompanyProfileCardsStore,
  QuotationIsFirstApproveRequestStore,
  ConnectInvoicesStore,
  ConnectClientProfileStore,
}
