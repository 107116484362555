






































import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import ClientProposalListItem from './components/ClientProposalListItem/index.vue'
import ClientProposalListLoading from './components/ClientProposalListLoading/index.vue'
import ClientProposalSnackbar from '~/components/Client/ClientProposal/ClientProposalSnackbar/index.vue'
import { IProduct } from '~/Interfaces'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import ConnectFilterChip from '~/components/common/components/ConnectFilterChip.vue'

@Component({
  components: {
    ConnectFilterChip,
    ConnectIcon,
    ClientProposalSnackbar,
    ClientProposalListItem,
    ClientProposalListLoading,
  },
})
export default class ClientProposalTableList extends Vue {
  @Prop()
  private product!: IProduct

  private isLoading: boolean = false

  private statusFilters = [
    {
      id: 'waiting',
      targetStatuses: ['waiting'],
      label: '未対応',
      selected: true,
    },
    {
      id: 'negotiating',
      targetStatuses: ['negotiating'],
      label: '検討中',
      selected: true,
    },
    {
      id: 'pending',
      targetStatuses: ['pending'],
      label: '保留',
      selected: false,
    },
    {
      id: 'rejected',
      targetStatuses: ['rejected', 'terminated', 'rejected_not_correspond'],
      label: '見送り',
      selected: false,
    },
  ]

  get proposalNum(): number {
    // 「依頼(accepted)」ステータスを除く件数
    return this.product.connectProposals.filter(
      (proposal) => (proposal.connectProposalStatus as any) !== 'accepted'
    ).length
  }

  get visibleStatusFilters() {
    // 未対応の提案がない場合は、未対応フィルターを非表示にする
    return this.statusFilters.filter(
      (filter) =>
        !(filter.id === 'waiting' && this.getNumberByFilterId('waiting') === 0)
    )
  }

  get filteredProposals() {
    return this.product.connectProposals.filter((proposal) => {
      return this.statusFilters.some((filter) => {
        return (
          filter.selected &&
          filter.targetStatuses.includes(proposal.connectProposalStatus as any)
        )
      })
    })
  }

  @Watch('filteredProposals')
  private onConnectProposalsChange() {
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
    }, 800)
  }

  private getNumberByFilterId(filterId: string): number {
    const filter = this.statusFilters.find((f) => f.id === filterId)
    if (!filter) return 0

    return this.product.connectProposals.filter((proposal) =>
      filter.targetStatuses.includes(proposal.connectProposalStatus as any)
    ).length
  }

  private onClickFilter(id: string) {
    const filter = this.statusFilters.find((f) => f.id === id)
    if (!filter) return

    filter.selected = !filter.selected
  }
}
