







































































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { ModalStore } from '~/utils/store-accessor'
import ProfileConnectCompanyMemberImageModal from '~/components/common/profile/connectCompany/profile-connect-company-member-image-modal.vue'
import IConnectCompanyMember from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMember'
import { ConnectInputLabel, ConnectTextarea } from '~/components/common'

interface IMember {
  name: string
  imageUrl: string | null
  role: string
  startDate: string
  originCompany: string
  profile: string | null
}

@Component({
  components: {
    ConnectTextarea,
    ConnectInputLabel,
    ProfileConnectCompanyMemberImageModal,
  },
})
export default class ProfileConnectCompanyMember extends Vue {
  @Prop()
  private index!: number

  @Prop()
  private connectCompanyMember!: IConnectCompanyMember | undefined

  member: IMember = {
    name: '',
    imageUrl: null,
    role: '',
    startDate: '',
    originCompany: '',
    profile: '',
  }

  uploadImageBlob: Blob | null = null

  get uploadImageUrl(): string | null | undefined {
    if (this.uploadImageBlob) {
      return window.URL.createObjectURL(this.uploadImageBlob)
    }
    return null
  }

  get profileLetterCount() {
    return this.member.profile?.length ?? 0
  }

  get joinedMonth() {
    return (
      this.member.startDate.split('-')[0] +
      '-' +
      this.member.startDate.split('-')[1]
    )
  }

  set joinedMonth(value: string) {
    this.member.startDate = value + '-01'
  }

  private passImage(blob: Blob) {
    this.uploadImageBlob = blob
  }

  private openUploadImageModal() {
    ModalStore.show('ProfileConnectCompanyMemberImageModal')
  }

  mounted() {
    this.member.name = this.connectCompanyMember?.name ?? ''
    this.member.imageUrl = this.connectCompanyMember?.imageUrl ?? null
    this.member.role = this.connectCompanyMember?.role ?? ''
    this.member.startDate = this.connectCompanyMember?.startDate ?? ''
    this.member.originCompany = this.connectCompanyMember?.originCompany ?? ''
    this.member.profile = this.connectCompanyMember?.profile ?? ''
  }
}
