














































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'
import {
  ConnectContractRenewalStore,
  ConnectProposalThreadStore,
  ModalStore,
} from '~/utils/store-accessor'

@Component({})
export default class ClientProposalDetailNegotiationButton extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  get hiddenQuotation() {
    const connectContractRenewal = ConnectContractRenewalStore.get(
      this.connectProposal.connectProposalHashId
    )

    if (connectContractRenewal?.isRequestedQuotationConfirm) {
      return false
    }

    return ['accepted', 'terminated'].includes(this.status)
  }

  get isRequestedQuotationApprove(): boolean {
    return this.connectProposal?.isRequestedQuotationApprove
  }

  get isRequestedQuotationConfirm(): boolean {
    return this.connectProposal?.isRequestedQuotationConfirm
  }

  get isRequestedQuotationEdit(): boolean {
    return this.connectProposal?.isRequestedQuotationEdit
  }

  get isRequestedQuotation(): boolean {
    return this.connectProposal?.isRequestedQuotation
  }

  get canRequest(): boolean {
    if (!this.$auth.user) {
      return false
    }

    if (!this.$auth.user.connect_client_address) {
      return false
    }

    return !!this.$auth.user.connect_client_address
  }

  private get status(): string {
    return (this.connectProposal.connectProposalStatus as any) as string
  }

  get disabledMessage(): boolean {
    if (
      ['waiting', 'pending', 'rejected_not_correspond'].includes(this.status)
    ) {
      return true
    }

    const threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    return this.status === 'rejected' && threadHashId === undefined
  }

  get disabledQuotation() {
    // ゲストアカウントの場合は非活性
    if (this.$services.user.isGuestAccount) {
      return true
    }

    return [
      'waiting',
      'pending',
      'rejected_not_correspond',
      'rejected',
    ].includes(this.status)
  }

  /**
   * 見積作成を依頼する(確認)
   */
  requestQuotation() {
    if (this.disabledMessage) return
    if (!this.canRequest) {
      ModalStore.show('ClientUrgeProfileModal')
      return
    }
    ModalStore.show(
      'ClientConfirmModal' + this.connectProposal.connectProposalHashId
    )
  }

  quotation() {
    if (!this.canRequest) {
      ModalStore.show('ClientUrgeProfileModal')
      return
    }

    const goToQuotation = async () => {
      await this.$router.push(
        '/client/quotation/' +
          this.connectProposal.connectProductHashId +
          '/' +
          this.connectProposal.connectProposalHashId
      )
    }

    goToQuotation()
  }
}
