import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { IThreadDetail } from '~/Interfaces'

@Module({ stateFactory: true, namespaced: true, name: 'ThreadDetails' })
export default class ThreadDetails extends VuexModule {
  details: IThreadDetail[] = []

  @Mutation
  setThreadDetails(details: IThreadDetail[] | undefined) {
    if (details === undefined) {
      return
    }

    this.details = details
  }

  @Mutation
  clearThreadDetails() {
    this.details = []
  }

  get getThreadDetails(): IThreadDetail[] {
    return this.details
  }
}
