import { Dayjs } from 'dayjs'
import 'dayjs/locale/ja'

export default class DayService {
  private readonly dayjs: (time: string | Dayjs | void) => Dayjs

  constructor(dayjs: (time: string | Dayjs | void) => Dayjs) {
    this.dayjs = dayjs
  }

  /**
   * MM/DD(dd)
   * @param time
   */
  public getMdWithDayOfWeek(time: string | Dayjs): string {
    const dayjs = this.dayjs(time).locale('ja')

    return dayjs.format('MM/DD(dd)')
  }

  /**
   * YYYY/MM/DD(dd)
   * @param time
   */
  public getYMdWithDayOfWeek(time: string | Dayjs): string {
    const dayjs = this.dayjs(time).locale('ja')

    return dayjs.format('YYYY/MM/DD(dd)')
  }

  /**
   * YYYY/M/D
   * @param time
   */
  public getYmd(time: string): string {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY/M/D')
  }

  /**
   * YYYY年MM月DD日
   * @param time
   */
  public getYmdJa(time: string): string {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY年MM月DD日')
  }

  /**
   * YYYY年MM月DD日
   * @param time
   */
  public getYmJa(time: string): string {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY年MM月')
  }

  /**
   * YYYY年M月
   * @param time
   */
  public getYnJa(time: string): string {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY年M月')
  }

  /**
   * 過ぎたかどうか
   * @param time
   */
  public expired(time: string | Dayjs): boolean {
    return this.dayjs().isAfter(time)
  }

  /**
   * 過ぎたかどうか(日付のみの判定)
   * @param time
   */
  public expiredByDay(time: string | Dayjs): boolean {
    return this.dayjs().startOf('day').isAfter(time)
  }

  /**
   * YYYY/M/D
   * @param time
   */
  public getYYYYMMDD(time: string): string {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY/MM/DD')
  }

  public getDashYYYYMMDD(time: string): string {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY-MM-DD')
  }

  /**
   * YYYY-MM-DD
   * @param time
   */
  public getYYYYMMDDWithHyphen(time: string): string {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY-MM-DD')
  }

  /**
   * Dayjs
   * @param time
   */
  public getDayjs(time: string): Dayjs {
    return this.dayjs(time)
  }

  /**
   * YYYY/M/D HH:mm
   * @param time
   */
  public getYYYYMMDDHHmm(time: string): string {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY/MM/DD HH:mm')
  }

  /**
   * MM月DD日(dd)
   * @param time
   */
  public getMDWithDayOfWeekJa(time: string | Dayjs): string {
    const dayjs = this.dayjs(time).locale('ja')

    return dayjs.format('M月D日(dd)')
  }

  public getEndOfDay(time: string | Dayjs): string {
    const dayjs = this.dayjs(time)

    return dayjs.endOf('day').format('YYYY-MM-DD HH:mm:ss')
  }

  public isAfter(time: string, target: string): boolean {
    const timeDayjs = this.dayjs(time)
    const targetDayjs = this.dayjs(target)

    return timeDayjs.isAfter(targetDayjs)
  }

  /**
   * 差分(月）
   * @param start
   * @param end
   */
  public diffMonth(start: string, end: string): number {
    const startDayjs = this.dayjs(start)
    const endDayjs = this.dayjs(end)

    return endDayjs.diff(startDayjs, 'month')
  }
}
