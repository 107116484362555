

























































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ConnectTextarea from '../../components/ConnectTextarea.vue'
import ConnectInputLabel from '../../components/ConnectInputLabel.vue'
import IConnectSupportAchievementCategoryList from '~/Interfaces/connectMember/IConnectSupportAchievementCategoryList'
import Textarea from '~/components/Form/Textarea.vue'
import { ConnectCompanyStore } from '~/utils/store-accessor'
import IConnectCompanyEducation from '~/Interfaces/connectCompany/IConnectCompanyEducation'

@Component({
  components: { ConnectTextarea, ConnectInputLabel, Textarea },
})
export default class ProfileConnectCompanyEducationTemplate extends Vue {
  @Prop()
  private index!: number

  @Prop()
  private categoryList!: IConnectSupportAchievementCategoryList[]

  private etc: string = ''

  private range(start: number, end: number): number[] {
    return [...Array(end + 1).keys()].slice(start)
  }

  get years(): number[] {
    const year = new Date().getFullYear()
    return this.range(1945, year).reverse()
  }

  get months(): number[] {
    return this.range(1, 12)
  }

  get education(): IConnectCompanyEducation | undefined {
    const connectCompany = ConnectCompanyStore.getConnectCompany

    return connectCompany?.connectCompanyEducation
  }

  mounted() {
    this.etc = this.education?.etc || ''
  }
}
