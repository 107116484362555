import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectQuotation from '~/Interfaces/connectQuotaton/IConnectQuotation'

export default class ContractService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * ContractHashId取得
   */
  async getContractHashId(): Promise<string | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.get(
        'api/contract/company/get_contract_hash_id'
      )
      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   * @param connect_proposal_hash_id
   * @param is_client
   */
  async find(
    connect_proposal_hash_id: string,
    is_client: boolean = false
  ): Promise<IConnectQuotation | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      let response
      if (is_client) {
        response = await this.axios.get(
          'api/contract/client/find/' + connect_proposal_hash_id
        )
      } else {
        response = await this.axios.get(
          'api/contract/company/find/' + connect_proposal_hash_id
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  async getNewGuidelines(
    connectProposalHashId: string,
    isClient: boolean = false
  ): Promise<{ result: boolean }> {
    LockStore.lock(LockStore.types.contract)
    try {
      let path
      if (isClient) {
        path = `api/contract/client/new_guidelines/${connectProposalHashId}`
      } else {
        path = `api/contract/company/new_guidelines/${connectProposalHashId}`
      }

      const response = await this.axios.get(path)
      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return { result: true }
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  async copy(connect_proposal_hash_id: string): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.post(
        'api/contract/company/copy/' + connect_proposal_hash_id
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  async requestCopy(
    connect_proposal_hash_id: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.post(
        'api/contract/client/request_copy/' + connect_proposal_hash_id
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  async pdf(
    connect_contract_hash_id: string,
    is_client: boolean = false
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      let response
      if (is_client) {
        response = await this.axios.post(
          'api/contract/client/pdf/' + connect_contract_hash_id,
          {
            is_client: true,
          }
        )
      } else {
        response = await this.axios.post(
          'api/contract/company/pdf/' + connect_contract_hash_id
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   * PDF再生成
   * @param connectProposalHashId
   * @param connectContractRenewalHashId
   */
  async regenerate(
    connectProposalHashId: string | undefined,
    connectContractRenewalHashId: string | undefined
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.contract)

    try {
      let response

      if (connectContractRenewalHashId === undefined) {
        response = await this.axios.post(
          'api/operation/contract/regenerate/' + connectProposalHashId
        )
      } else {
        response = await this.axios.post(
          'api/operation/contract/regenerate/renewal/' +
            connectContractRenewalHashId
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   * 契約停止申請
   */
  async cancellation(
    threadHashId: string,
    terminationDate: string,
    reason: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.post(
        'api/contract/client/termination',
        {
          thread_hash_id: threadHashId,
          termination_date: terminationDate,
          reason,
        },
        {
          withCredentials: true,
        }
      )

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }
}
