// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".trouble_report_tutorial_box_wrapper_2BBkf{position:absolute;top:0;left:-265px}.trouble_report_tutorial_box_wrapper_2BBkf .trouble_report_tutorial_box_1aQaR{position:relative;display:flex;flex-direction:row-reverse}.trouble_report_tutorial_box_wrapper_2BBkf .trouble_report_tutorial_box_1aQaR .polygon_M8l4Q{margin-top:10px;-webkit-clip-path:polygon(0 0,100% 50%,0 100%);clip-path:polygon(0 0,100% 50%,0 100%);background:#fff;width:16px;height:18.48px}.trouble_report_tutorial_box_wrapper_2BBkf .trouble_report_tutorial_box_1aQaR .trouble_report_tutorial_2kBr5{padding:20px;display:flex;flex-direction:column;grid-gap:10px;gap:10px;width:240px;background:#fff;border-radius:5px}.trouble_report_tutorial_box_wrapper_2BBkf .trouble_report_tutorial_box_1aQaR .trouble_report_tutorial_2kBr5 .text_3mpp1{font-family:\"Hiragino Sans\";font-size:14px;font-weight:400;color:#454545}.trouble_report_tutorial_box_wrapper_2BBkf .trouble_report_tutorial_box_1aQaR .trouble_report_tutorial_2kBr5 .button_39bgd{pointer-events:auto;display:flex;align-items:center;justify-content:center;border-radius:100px;background:#f3f2f2;width:200px;height:35px;font-family:\"Hiragino Kaku Gothic Pro\";font-size:13px;font-weight:600;color:#555}.trouble_report_tutorial_box_wrapper_2BBkf .trouble_report_tutorial_box_1aQaR .trouble_report_tutorial_2kBr5 .button_39bgd:hover{background:#ebebea}.trouble_report_tutorial_box_wrapper_2BBkf .trouble_report_tutorial_box_1aQaR .trouble_report_tutorial_2kBr5 .button_39bgd:active{background:#d6d5d4}", ""]);
// Exports
exports.locals = {
	"trouble_report_tutorial_box_wrapper": "trouble_report_tutorial_box_wrapper_2BBkf",
	"trouble_report_tutorial_box": "trouble_report_tutorial_box_1aQaR",
	"polygon": "polygon_M8l4Q",
	"trouble_report_tutorial": "trouble_report_tutorial_2kBr5",
	"text": "text_3mpp1",
	"button": "button_39bgd"
};
module.exports = exports;
