import { Context } from '@nuxt/types'
import { LockStore } from '~/utils/store-accessor'
import IConnectTeamWithMemberWrapper from '~/Interfaces/connectTeam/IConnectTeamWithMemberWrapper'
import IReferenceCheck from '~/Interfaces/referenceCheck/IReferenceCheck'
import IConnectSupportAchievementCategoryList from '~/Interfaces/connectMember/IConnectSupportAchievementCategoryList'
import IConnectCompany from '~/Interfaces/connectCompany/IConnectCompany'
import IConnectCompanyConstants from '~/Interfaces/connectCompany/IConnectCompanyConstants'
import IQuickFixConnectCompany from '~/Interfaces/connectCompany/IQuickFixConnectCompany'

export default class CompanyService {
  private readonly context: Context

  constructor(context: Context) {
    this.context = context
  }

  /**
   * ログイン済みかどうか
   */
  async isLoginDone(): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/company/is_login_done'
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * ログイン済みかどうか
   */
  async makeLoginDone(): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.post(
        'api/company/make_login_done'
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getConnectTeam(): Promise<IConnectTeamWithMemberWrapper | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get('api/company/connect_team')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getConnectTeamByHashId(
    team_hash_id: string | null
  ): Promise<IConnectTeamWithMemberWrapper | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/client/connect_team/' + team_hash_id
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getConnectTeamBySaHashId(
    sa_hash_id: string | null
  ): Promise<IConnectTeamWithMemberWrapper | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/client/connect_team/sa/' + sa_hash_id
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async teamOverview(overview: string): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.post(
        'api/company/team/overview',
        {
          overview,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getReferenceChecks(
    hash_id: string
  ): Promise<IReferenceCheck[] | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/member/reference_check/' + hash_id
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getCategoryList(): Promise<
    IConnectSupportAchievementCategoryList | undefined
  > {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get('api/member/category/list')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async achievements(data: Object[], sa_hash_id: string): Promise<boolean> {
    console.log(data)
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.post(
        'api/company/member/achievements/' + sa_hash_id,
        {
          data,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getConnectCompany(): Promise<IConnectCompany | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/company/connect_company'
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getCanPropose(): Promise<boolean | undefined | null> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get('api/company/can_propose')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getConnectCompanyByCompanyHashId(
    company_hash_id: string
  ): Promise<IConnectCompany | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/client/connect_company/' + company_hash_id
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async connectCompanyOverview(
    overview: string,
    uploadImageUrl: string | null,
    invoiceNumber: string | null,
    isIssuingInvoiceNumber: boolean
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.post(
        'api/company/connect_company/overview',
        {
          overview,
          upload_image_url: uploadImageUrl,
          invoice_number: invoiceNumber,
          is_issuing_invoice_number: isIssuingInvoiceNumber,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async updateCompanyInfo(name: string, address: string): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.post(
        'api/company/company/info',
        {
          name,
          address,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getConnectCompanyConstants(
    is_client: boolean = false
  ): Promise<IConnectCompanyConstants | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      let response

      if (is_client) {
        response = await this.context.$axios.get(
          'api/client/connect_company/constants'
        )
      } else {
        response = await this.context.$axios.get(
          'api/company/connect_company/constants'
        )
      }

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async setConnectCompanySupportAchievements(data: Object[]): Promise<boolean> {
    console.log(data)
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.post(
        'api/company/connect_company/achievements',
        {
          data,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async setConnectCompanyCareer(
    education: Object,
    careers: Object[]
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.post(
        'api/company/connect_company/career',
        {
          education,
          careers,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * @TODO あくまで応急処置用。正式なバージョンを作る際にはこちらは破棄する
   * @deprecated
   */
  async getQuickFixUrls(): Promise<IQuickFixConnectCompany | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/company/connect_company/urls'
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * @TODO あくまで応急処置用。正式なバージョンを作る際にはこちらは破棄する
   * @deprecated
   */
  async getQuickFixUrlsByCompanyHashId(
    company_hash_id: string
  ): Promise<IQuickFixConnectCompany | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/client/connect_company/urls/' + company_hash_id
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async uploadImage(formData: FormData): Promise<string | false> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.post(
        'api/company/connect_company/upload_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      console.log(response)
      return response.data.savePath
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getFulltextByProductHashId(product_hash_id: string): Promise<any> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/client/connect_company/fulltext/' + product_hash_id
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
