import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import IConnectProposalRead from '../Interfaces/IConnectProposalRead'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectProposalReadStoreDefine',
})
export default class ConnectProposalReadStoreDefine extends VuexModule {
  connectProposalReads: IConnectProposalRead[] = []

  get isRead(): (connectProposalHashId: string) => boolean {
    const self = this

    return (connectProposalHashId: string) => {
      const connectProposalRead = self.connectProposalReads.find(
        (connectProposalRead) =>
          connectProposalRead.connectProposalHashId === connectProposalHashId
      )

      return !!connectProposalRead
    }
  }

  @Mutation
  set(connectProposalReads: IConnectProposalRead[]) {
    this.connectProposalReads = connectProposalReads
  }

  @Mutation
  read(connectProposalHashId: string) {
    const index = this.connectProposalReads.findIndex(
      (v) => v.connectProposalHashId === connectProposalHashId
    )
    if (index < 0) {
      this.connectProposalReads.push({ connectProposalHashId })
    }
  }
}
