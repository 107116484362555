import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectProposalDetail from '~/Interfaces/ConnectProposalDetail/IConnectProposalDetail'
import IConnectProposalPresentation from '~/Interfaces/IConnectProposalPresentation'
import IConnectProposalIndexPresentation from '~/Interfaces/IConnectProposalIndexPresentation'
import IConnectProposalClientPresentation from '~/Interfaces/IConnectProposalClientPresentation'

export default class ConnectProposalDetailService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   *
   */
  async getPrioritiesMaster(): Promise<string[] | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.get(
        'api/proposal_detail/priorities_master'
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   *
   */
  async find(
    connectProductHashId: string
  ): Promise<IConnectProposalPresentation | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.get(
        'api/proposal_detail/company/find/' + connectProductHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   *
   */
  async getByClient(
    connectProductHashId: string
  ): Promise<IConnectProposalClientPresentation | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.get(
        'api/proposal_detail/client/get/' + connectProductHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   *
   */
  async findByIndexPage(
    connectProposalHashId: string
  ): Promise<IConnectProposalIndexPresentation | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.get(
        'api/proposal_detail/company/find/index/' + connectProposalHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   *
   */
  async postSave(
    connectProposalDetail: IConnectProposalDetail
  ): Promise<IConnectProposalDetail | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.post(
        'api/proposal_detail/company/save/' +
          connectProposalDetail.connectProductHashId,
        { connectProposalDetail }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  /**
   * 保存する時のキー
   */
  get getLocalSaveKey(): string {
    return 'connectProposalDetailEdit_'
  }

  /**
   *
   */
  async getContractedByClient(): Promise<
    IConnectProposalClientPresentation | undefined
  > {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.get(
        'api/proposal_detail/client/get_contracted'
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }
}
