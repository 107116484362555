import { Context } from '@nuxt/types'
import { LockStore } from '~/utils/store-accessor'

export default class LogService {
  private readonly context: Context
  private timerId: number | undefined

  constructor(context: Context) {
    this.context = context
  }

  async accessLog(path: string | null): Promise<boolean> {
    LockStore.lock(LockStore.types.log)
    try {
      const response = await this.context.$axios.post('api/client/log/access', {
        path,
      })

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.log)
    }
  }

  public startAccessLogInterval() {
    this.clearAccessLogInterval()

    // @MEMO: ACCESS_LOGの取得をしない
    if (!this.context.$config.ACCESS_LOG_INTERVAL_MINUTES) {
      return
    }

    this.accessLog(this.context.route.fullPath)

    this.timerId = window.setInterval(() => {
      this.accessLog(this.context.route.fullPath)
    }, 1000 * 60 * this.context.$config.ACCESS_LOG_INTERVAL_MINUTES)
  }

  public clearAccessLogInterval() {
    clearInterval(this.timerId)
  }
}
