import { Context } from '@nuxt/types'
import IConnectCompany from '~/Interfaces/connectCompany/IConnectCompany'
import { ConnectCompanyStore, LockStore } from '~/utils/store-accessor'
import IConnectCompanyConstants from '~/Interfaces/connectCompany/IConnectCompanyConstants'
import IConnectCompanyMember from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMember'
import IConnectReferenceCheck from '~/Interfaces/IConnectReferenceCheck'
import IClientProduct from '~/Interfaces/IClientProduct'
import { IConnectCompanyProfileResponse } from '~/Interfaces'

type CompanyPreviewData = {
  connectCompany: IConnectCompany | undefined
  constants: IConnectCompanyConstants | undefined
  connectCompanyMembers: IConnectCompanyMember[]
  connectReferenceChecks: IConnectReferenceCheck[] | undefined
  quickFixConnectCompany: null
}

export default class AdminService {
  private readonly context: Context
  private headers: { [key in string]: string }

  constructor(context: Context) {
    this.context = context
    this.headers = {}
  }

  isAdminUser(): boolean {
    return this.context.$admin.isAdminUser()
  }

  async getProducts(): Promise<IClientProduct | undefined> {
    this.setHeaders()

    try {
      const response = await this.context.$axios.get('api/client/product/get', {
        headers: this.headers,
      })

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async getCompanyPreview(
    companyHashId: string,
    adminHashId?: string
  ): Promise<CompanyPreviewData | undefined> {
    this.setHeaders(adminHashId)

    const connectCompany = await this.getConnectCompanyByCompanyHashId(
      companyHashId
    )

    ConnectCompanyStore.setConnectCompany(connectCompany)

    const res = await Promise.all([
      this.getConnectCompanyConstants(),
      this.getMembers(companyHashId),
      this.getReleased(companyHashId),
    ])

    const quickFixConnectCompany = null
    const constants = res[0]
    const connectCompanyMembers = res[1]
    const connectReferenceChecks = res[2]

    return {
      connectCompany,
      constants,
      connectCompanyMembers,
      connectReferenceChecks,
      quickFixConnectCompany,
    }
  }

  async getCompanyProfile(
    companyHashId: string,
    adminHashId?: string
  ): Promise<IConnectCompanyProfileResponse | undefined> {
    this.setHeaders(adminHashId)

    LockStore.lock(LockStore.types.company)
    try {
      this.rejectWithoutAdmin()

      const response = await this.context.$axios.get(
        `api/admin/connect_company/profile/get/${companyHashId}`,
        {
          headers: this.headers,
        }
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * 会社情報を取得
   * @param companyHashId
   */
  private async getConnectCompanyByCompanyHashId(
    companyHashId: string
  ): Promise<IConnectCompany | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      this.rejectWithoutAdmin()

      const response = await this.context.$axios.get(
        `api/admin/connect_company/${companyHashId}`,
        {
          headers: this.headers,
        }
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * constを取得する
   */
  private async getConnectCompanyConstants(): Promise<
    IConnectCompanyConstants | undefined
  > {
    LockStore.lock(LockStore.types.company)
    try {
      this.rejectWithoutAdmin()

      const response = await this.context.$axios.get(
        'api/admin/connect_company/constants',
        {
          headers: this.headers,
        }
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * メンバー情報を取得
   * @param companyHashId
   */
  private async getMembers(
    companyHashId: string
  ): Promise<IConnectCompanyMember[]> {
    LockStore.lock(LockStore.types.company)
    try {
      this.rejectWithoutAdmin()

      const response = await this.context.$axios.get(
        `api/admin/connect_company/member/${companyHashId}`,
        {
          headers: this.headers,
        }
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
      return []
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * 公開されたリファレンスチェック取得
   * @param companyHashId
   */
  private async getReleased(
    companyHashId: string
  ): Promise<IConnectReferenceCheck[] | undefined> {
    LockStore.lock(LockStore.types.reference_check)
    try {
      this.rejectWithoutAdmin()

      const response = await this.context.$axios.get(
        `api/admin/reference_check/get/${companyHashId}`,
        {
          headers: this.headers,
        }
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.reference_check)
    }
  }

  private rejectWithoutAdmin() {
    if (!this.context.$admin.isAdminUser() && !this.headers.AdminHashId) {
      throw new Error('リクエストできないユーザーです')
    }
  }

  private setHeaders(adminHashId: string = '') {
    this.headers = {
      AdminHashId: this.context.$admin.getAdminHashId() ?? adminHashId,
      ConnectClientHashId: this.context.$admin.getConnectClientHashId() ?? '',
    }
  }
}
