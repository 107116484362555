








































import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import ClientProductStatusIcon from '~/components/Client/ClientProductStatusIcon.vue'
import ClientProductIdIcon from '~/components/Client/ClientProductIdIcon.vue'
import ClientProposalLarge from '~/components/Client/ClientProposalLarge.vue'
import Modal from '~/components/Modal.vue'
import {StaffContactStore} from "~/utils/store-accessor";
@Component({
  components: {
    Modal,
    ClientProposalLarge,
    ClientProductIdIcon,
    ClientProductStatusIcon,
  },
})
export default class ClientProductModal extends Vue {
  private cc: string = 'sales.group@kakutoku.co.jp'

  async mounted() {
    if (StaffContactStore.isEmpty) {
      const product = await this.$services.client.getConnectStaffInfo()
      if (!product) return

      StaffContactStore.set({
        name: product?.connectProductStaffName,
        email: product?.connectProductStaffEmail,
      })
    }
  }

  get name(): string {
    return StaffContactStore.get?.name ?? ''
  }

  get email(): string {
    return StaffContactStore.get?.email ?? ''
  }

  @Emit('closeModal')
  private closeModal() {}
}
