import IConnectCompanyMember from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMember'
import IConnectReferenceCheck from '~/Interfaces/IConnectReferenceCheck'
import IConnectCompany from '~/Interfaces/connectCompany/IConnectCompany'

type CompanyFulltextServiceProps = {
  companies: IConnectCompany[]
  companyMembers: IConnectCompanyMember[]
  connectReferenceChecks: IConnectReferenceCheck[]
}

let instance: CompanyFulltextService

/**
 * ハイライト対象の全文を生成
 */
export default class CompanyFulltextService {
  private readonly companies: IConnectCompany[] = []
  private readonly companyMembers: IConnectCompanyMember[] = []
  private readonly connectReferenceChecks: IConnectReferenceCheck[] = []

  constructor(props?: CompanyFulltextServiceProps) {
    if (props === null) {
      return
    }

    if (props?.companies) {
      this.companies = props.companies
    }

    if (props?.companyMembers) {
      this.companyMembers = props.companyMembers
    }

    if (props?.connectReferenceChecks) {
      this.connectReferenceChecks = props.connectReferenceChecks
    }
    instance = this
  }

  public setProps(props: CompanyFulltextServiceProps) {
    return new CompanyFulltextService(props)
  }

  public getInstance() {
    return instance
  }

  public getFulltext(companyHashId: string): string {
    const companyFulltext = this.getCompanyFulltext(companyHashId)

    const companyMembersFulltext = this.getCompanyMembersFulltext(companyHashId)

    const connectReferenceChecksText = this.getConnectReferenceChecksFulltext(
      companyHashId
    )

    return `${companyFulltext},${companyMembersFulltext},${connectReferenceChecksText}`
  }

  private getCompanyFulltext(companyHashId: string): string {
    const company = this.companies.find(
      (company) => company.companyHashId === companyHashId
    )

    if (!company) return ''

    const connectCompanySupportAchievementsText = company.connectCompanySupportAchievements
      .map((supportAchievement) => {
        return [
          supportAchievement.companyName,
          supportAchievement.overview,
          supportAchievement.jobRole,
          supportAchievement.mainAchievement,
          supportAchievement.supportedProductsDetail,
        ].join(',')
      })
      .join(',')

    const connectCompanyCareersText = company.connectCompanyCareers
      .map((career) => {
        return [
          career.companyName,
          career.role,
          career.employment,
          career.employmentEtc,
        ]
      })
      .join(',')

    return [
      company.overview,
      company.connectCompanyEducation.schoolName,
      company.connectCompanyEducation.major,
      company.connectCompanyEducation.etc,
      connectCompanySupportAchievementsText,
      connectCompanyCareersText,
    ].join(',')
  }

  private getCompanyMembersFulltext(companyHashId: string): string {
    const companyMembers = this.companyMembers.filter(
      (companyMember) => companyMember.connectCompanyHashId === companyHashId
    )

    if (companyMembers.length === 0) return ''

    return companyMembers
      .map((companyMember) => {
        // TODO supportAchievementsに変更
        // const achievementsText = companyMember.achievements
        //   .map((achievement) => achievement.companyName)
        //   .join(',')

        return [
          companyMember.originCompany,
          companyMember.role,
          // achievementsText,
        ].join(',')
      })
      .join(',')
  }

  private getConnectReferenceChecksFulltext(companyHashId: string): string {
    const connectReferenceChecks = this.connectReferenceChecks.filter(
      (connectReferenceCheck) =>
        connectReferenceCheck.companyHashId === companyHashId
    )

    if (connectReferenceChecks.length === 0) return ''

    return connectReferenceChecks
      .map((connectReferenceCheck) => {
        return [connectReferenceCheck.recommendComment].join(',')
      })
      .join(',')
  }
}
