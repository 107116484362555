// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".partnerModal_2B--E{position:fixed;width:100%;height:100vh;top:0;bottom:0;right:0;z-index:20}.partnerModal_2B--E.client_15Y4M .modal_3sTq1{width:auto;max-width:900px}.partnerModal_2B--E .modal_3sTq1{position:relative;width:860px;height:100vh;background:#fff;margin-left:auto;margin-right:0;box-shadow:0 0 20px rgba(109,132,155,.3)}.partnerModal_2B--E .modal_3sTq1 .header_2BYDe{display:flex;justify-content:flex-start;align-items:center;grid-gap:0 10px;gap:0 10px;height:72px;background:#f9f9f9;padding:0 20px}.partnerModal_2B--E .modal_3sTq1 .header_2BYDe .title_1EsYq{font-weight:600;font-size:16px;line-height:130%;color:#2c3846;display:flex;justify-content:flex-start;align-items:center;grid-gap:0 27px;gap:0 27px}.partnerModal_2B--E .modal_3sTq1 .header_2BYDe .button_3hi91{display:flex;justify-content:center;align-items:center;background:none;border:none;cursor:pointer;padding:0;margin:0;width:32px;height:32px;border-radius:5px}.partnerModal_2B--E .modal_3sTq1 .header_2BYDe .button_3hi91 .hover_3_2Qu{display:none}.partnerModal_2B--E .modal_3sTq1 .header_2BYDe .button_3hi91:hover{background-color:#e3eef7}.partnerModal_2B--E .modal_3sTq1 .header_2BYDe .button_3hi91:hover .hover_3_2Qu{display:block}.partnerModal_2B--E .modal_3sTq1 .header_2BYDe .button_3hi91:hover .not_hover_31Bu3{display:none}.partnerModal_2B--E .modal_3sTq1 .header_2BYDe .new_tab_open_button_1CZKd{margin-left:auto}.partnerModal_2B--E .modal_3sTq1 .container_3koYK{padding:40px;overflow-y:auto;height:calc(100% - 144px)}.enterActive_2gd43{-webkit-animation:slideIn_3l4Wv .3s ease-out;animation:slideIn_3l4Wv .3s ease-out}@-webkit-keyframes slideIn_3l4Wv{0%{left:100vw}to{left:0}}@keyframes slideIn_3l4Wv{0%{left:100vw}to{left:0}}.leaveActive_aPYUn{-webkit-animation:slideOut_2UNZD .3s ease-out;animation:slideOut_2UNZD .3s ease-out}@-webkit-keyframes slideOut_2UNZD{0%{left:0}to{left:100vw}}@keyframes slideOut_2UNZD{0%{left:0}to{left:100vw}}", ""]);
// Exports
exports.locals = {
	"partnerModal": "partnerModal_2B--E",
	"client": "client_15Y4M",
	"modal": "modal_3sTq1",
	"header": "header_2BYDe",
	"title": "title_1EsYq",
	"button": "button_3hi91",
	"hover": "hover_3_2Qu",
	"not_hover": "not_hover_31Bu3",
	"new_tab_open_button": "new_tab_open_button_1CZKd",
	"container": "container_3koYK",
	"enterActive": "enterActive_2gd43",
	"slideIn": "slideIn_3l4Wv",
	"leaveActive": "leaveActive_aPYUn",
	"slideOut": "slideOut_2UNZD"
};
module.exports = exports;
