// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cloud_1XJl8{background:#f9f9f9;padding:20px;margin:0 0 30px}.cloudDescription_3Cjda{font-weight:400;font-size:14px;line-height:21px;color:#2c3846;padding:0 0 30px}.content_3HfbL{margin-top:10px}.table_3s6HH{margin-top:20px;margin-bottom:40px}.subtitle_3vd0t{margin-top:43.5px;margin-bottom:23.5px}", ""]);
// Exports
exports.locals = {
	"cloud": "cloud_1XJl8",
	"cloudDescription": "cloudDescription_3Cjda",
	"content": "content_3HfbL",
	"table": "table_3s6HH",
	"subtitle": "subtitle_3vd0t"
};
module.exports = exports;
