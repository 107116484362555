import { render, staticRenderFns } from "./PreviewMemberDetail.vue?vue&type=template&id=fe5a8b2a&"
import script from "./PreviewMemberDetail.vue?vue&type=script&lang=ts&"
export * from "./PreviewMemberDetail.vue?vue&type=script&lang=ts&"
import style0 from "./PreviewMemberDetail.vue?vue&type=style&index=0&lang=scss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MemberDetail: require('/var/www/kakutoku_connect/nuxt/src/components/Profile/ConnectCompany/Member/MemberDetail.vue').default,PartnerModal: require('/var/www/kakutoku_connect/nuxt/src/components/common/profile/partner/PartnerModal/index.vue').default})
