// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_1yzWf{display:flex;justify-content:flex-start;align-items:center;line-height:18px;color:#555;color:var(--Text-60,#555)}.container_1yzWf>img{width:18px}.container_1yzWf .average_Ho9L6{font-size:13px;font-weight:600;margin-left:5px;line-height:1;margin-top:1px}.container_1yzWf .review_count_1mv12{font-size:11px;font-weight:400;line-height:1}", ""]);
// Exports
exports.locals = {
	"container": "container_1yzWf",
	"average": "average_Ho9L6",
	"review_count": "review_count_1mv12"
};
module.exports = exports;
