








































































































































import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import { ModalStore } from '~/utils/store-accessor'
import ClientProductModal from '~/components/Modal.vue'
@Component({
  components: { ClientProductModal },
})
export default class SupportSystemHelpModal extends Vue {
  private onClose() {
    ModalStore.hide('SupportSystemHelpModal')
  }

  get shouldDisplay(): boolean {
    return ModalStore.shouldDisplay('SupportSystemHelpModal')
  }
}
