















import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'

/**
 * input
 */
@Component
export default class Input extends Vue {
  @Prop()
  private id!: string

  @Prop()
  private name!: string

  @Prop()
  private type!: 'text' | 'email'

  @Prop()
  private maxlength!: number

  @Prop()
  private initValue!: string

  @Prop()
  private required!: boolean

  @Prop()
  private should_error!: boolean

  private value: string = ''

  mounted() {
    this.value = this.initValue
  }

  /**
   * valueの変更を通知する
   */
  @Emit('onInput')
  onInput() {
    return this.value
  }

  /**
   * valueの変更を通知する
   */
  @Emit('onChange')
  onChange() {
    return this.value
  }
}
