

















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ClientProductIdIcon from '~/components/Client/ClientProductIdIcon.vue'
import IConnectCompany from '~/Interfaces/connectCompany/IConnectCompany'
import IConnectProposal from '~/Interfaces/IConnectProposal'
@Component({
  components: { ClientProductIdIcon },
})
export default class ClientProductIdIconWrapper extends Vue {
  @Prop({ default: undefined })
  private connectCompany!: IConnectCompany | undefined

  @Prop()
  private proposal!: IConnectProposal | undefined

  @Prop()
  private size!:
    | 'small'
    | 'large'
    | 'circle'
    | 'proposalCard'
    | 'proposalDetail'

  @Prop()
  private isVisibleForce: boolean | undefined

  get connectCompanyEntity() {
    if (this.connectCompany) {
      return this.connectCompany
    }
    if (this.proposal === undefined) {
      return undefined
    }

    return this.proposal.company.connectCompany
  }

  get proposalStatus(): string {
    return this.proposal?.connectProposalStatus as any
  }

  get isVisible(): boolean {
    if (
      !this.connectCompanyEntity ||
      !this.connectCompanyEntity?.uploadImageUrl
    ) {
      return false
    }

    if (this.isVisibleForce) return true

    return !!(
      this.connectCompanyEntity &&
      this.connectCompanyEntity?.uploadImageUrl &&
      ['negotiating', 'accepted', 'terminated', 'rejected'].includes(
        this.proposalStatus
      )
    )
  }

  get colorIndex() {
    if (this.connectCompany) {
      return this.connectCompany?.colorIndex
    }
    if (this.proposal === undefined) {
      return 0
    }

    return this.proposal.company.colorIndex
  }

  mounted() {}
}
