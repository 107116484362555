
































import { Component, Emit, Prop, PropSync, Vue } from 'nuxt-property-decorator'

@Component
export default class ConnectTextarea extends Vue {
  @PropSync('modelValue')
  private model!: string | number | null

  @Prop()
  private id!: string

  @Prop()
  private className!: string

  @Prop()
  private name!: string

  @Prop()
  private required!: boolean

  @Prop()
  private disabled!: boolean

  @Prop()
  private placeholder!: string

  @Prop()
  private maxlength!: number

  @Prop()
  private hint!: string

  @Prop()
  private error!: boolean

  @Prop({ default: 100 })
  private height!: number

  get componentId(): string {
    return this.id || Math.random().toString(32).substring(2)
  }

  get hintId(): string {
    return `${this.componentId}-hint`
  }

  get errorId(): string {
    return `${this.componentId}-error`
  }

  get ariaDescribedby(): string | undefined {
    if (this.error) {
      return this.errorId
    }

    if (this.hint) {
      return this.hintId
    }

    return undefined
  }

  get characterCount(): number {
    return this.model ? this.model.toString().length : 0
  }

  get isExceeded(): boolean {
    if (!this.maxlength) {
      return false
    }
    return this.characterCount > this.maxlength
  }

  get hasError(): boolean {
    return this.error || this.isExceeded
  }

  @Emit('focus')
  private onFocus() {}

  @Emit('input')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onInput(event: Event) {}

  @Emit('change')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange(event: Event) {}

  @Emit('blur')
  private onBlur() {}

  @Emit('update:modelValue')
  private updateModelValue() {}
}
