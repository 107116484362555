// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".partner_3KyDx{width:380px;display:flex;justify-content:flex-start;align-items:center}.partner_3KyDx.header_2_wH4{width:354px}.partner_3KyDx .partnerName_38seG{max-width:290px;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;text-align:left;padding-left:10px;color:#0e273c;color:var(--Black,#0e273c);font-family:Hiragino Kaku Gothic Pro;font-size:14px;font-style:normal;font-weight:400;line-height:normal}.partner_3KyDx .partnerName_38seG.header_2_wH4{-webkit-line-clamp:1;max-width:260px;font-weight:600;font-size:18px;line-height:130%}.partner_3KyDx .companyId_1o6QM{white-space:nowrap;font-size:12px;line-height:18px;color:#999;padding-left:20px}", ""]);
// Exports
exports.locals = {
	"partner": "partner_3KyDx",
	"header": "header_2_wH4",
	"partnerName": "partnerName_38seG",
	"companyId": "companyId_1o6QM"
};
module.exports = exports;
