


































import { Component, Emit, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import { FormItem, FormValidator, Rule } from '~/components'

type SelectOption = {
  label: string
  value: string | number
}

@Component
export default class ConnectDropdown extends Vue {
  @Prop()
  private id!: string

  @Prop()
  private name!: string

  @PropSync('modelValue')
  private model!: string | number | null

  @Prop()
  private disabled!: boolean

  @Prop()
  private placeholder!: string

  @Prop()
  private hint!: string

  @Prop()
  private error!: boolean

  @Prop()
  private options!: SelectOption[]

  @Prop({ default: '360px' })
  private width!: string

  @Prop()
  private rules!: Rule[]

  private formValidator!: FormValidator

  mounted() {
    this.formValidator = FormValidator.get()
    this.formValidator.addFormItem(this.formItem)
    this.$emit('register', this)
  }

  get formItemId(): string {
    return this.name ?? this.componentId
  }

  get formItem(): FormItem {
    return {
      id: this.componentId,
      name: this.formItemId,
      value: this.model,
      rules: this.rules,
      message: '',
      validate: this.validate,
    }
  }

  get innerStyle() {
    return {
      width: this.width,
    }
  }

  get componentId(): string {
    return this.id || Math.random().toString(32).substring(2)
  }

  get errorId(): string {
    return `${this.componentId}-error`
  }

  public validate(): boolean {
    const isValid = this.formValidator.validate(this.formItemId)
    if (!isValid) {
      this.error = true
      return false
    }

    return true
  }

  @Emit('change')
  private onChange() {}

  @Emit('blur')
  private onBlur(event: Event) {
    this.formValidator.updateFormItem(
      this.formItemId,
      (event.target as HTMLOptionElement).value
    )

    if (!this.formValidator.validate(this.formItemId)) {
      this.error = true
    }
  }
}
