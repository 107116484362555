// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".enterActive_2U4hw{transition:opacity .3s}.leaveActive_ZZYoS{opacity:0}.wrapper_1qcxn{display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;height:100%;width:100%;z-index:10;background:rgba(0,0,0,.15)}.modal_2leM2{position:relative;padding:50px;background:#fff;box-shadow:0 4px 4px 0 rgba(0,0,0,.25);border-radius:10px;z-index:11;width:840px}.modal_2leM2 .inner_gu-oY{display:flex;flex-direction:column;grid-gap:30px;gap:30px}.modal_2leM2 .inner_gu-oY .header_OUBD-{display:flex;justify-content:space-between}.modal_2leM2 .inner_gu-oY .header_OUBD- .title_2JZ0c{font-family:\"Hiragino Kaku Gothic Pro\";font-size:22px;font-weight:600;color:#555}.modal_2leM2 .inner_gu-oY .header_OUBD- .skip_3zkH1{cursor:pointer;font-size:14px;font-weight:400;color:#3379b7}.modal_2leM2 .inner_gu-oY .header_OUBD- .skip_3zkH1:hover{text-decoration:underline}.modal_2leM2 .inner_gu-oY .contents_LvnjU{display:flex;flex-direction:column;grid-gap:30px;gap:30px}.modal_2leM2 .inner_gu-oY .contents_LvnjU .descriptions_2x-u1{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.modal_2leM2 .inner_gu-oY .contents_LvnjU .descriptions_2x-u1 .main_22hbv{font-family:\"Hiragino Kaku Gothic Pro\";font-size:14px;font-weight:600;color:#555}.modal_2leM2 .inner_gu-oY .contents_LvnjU .descriptions_2x-u1 .sub_Sni3S{font-family:\"Hiragino Kaku Gothic Pro\";font-size:14px;font-weight:400;color:#555}.modal_2leM2 .inner_gu-oY .contents_LvnjU .cards_3IEfb{display:flex;grid-gap:15px;gap:15px}", ""]);
// Exports
exports.locals = {
	"enterActive": "enterActive_2U4hw",
	"leaveActive": "leaveActive_ZZYoS",
	"wrapper": "wrapper_1qcxn",
	"modal": "modal_2leM2",
	"inner": "inner_gu-oY",
	"header": "header_OUBD-",
	"title": "title_2JZ0c",
	"skip": "skip_3zkH1",
	"contents": "contents_LvnjU",
	"descriptions": "descriptions_2x-u1",
	"main": "main_22hbv",
	"sub": "sub_Sni3S",
	"cards": "cards_3IEfb"
};
module.exports = exports;
