import { render, staticRenderFns } from "./ClientProposalDetailModal.vue?vue&type=template&id=c1cb7c70&"
import script from "./ClientProposalDetailModal.vue?vue&type=script&lang=ts&"
export * from "./ClientProposalDetailModal.vue?vue&type=script&lang=ts&"
import style0 from "./ClientProposalDetailModal.vue?vue&type=style&index=0&lang=scss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClientProposalDetailHeader: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailHeader.vue').default,ClientProposalDetailBanner: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailBanner.vue').default,ClientProposalDetailSegmentedButtons: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailSegmentedButtons.vue').default,ClientProposalDetailProposalMessage: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetailVersion2/message/ClientProposalDetailProposalMessage.vue').default,ClientProposalDetailContent: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalDetailContent/index.vue').default,ClientProposalDetailNegotiationButton: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailNegotiationButton.vue').default,ClientProposalDetailSegmentedButtonsMini: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailSegmentedButtonsMini.vue').default,ClientProposalDetailPartnerInfo: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailPartnerInfo.vue').default})
