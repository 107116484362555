import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export interface IOffset {
  x: number,
  y: number
}

@Module({ stateFactory: true, namespaced: true, name: 'Offset'})
export default class Offset extends VuexModule {
  offset: IOffset = {x: 500, y: 500}

  @Mutation
  setX(x: number)
  {
    this.offset.x = x
  }

  @Mutation
  setY( y: number)
  {
    this.offset.y = y
  }

  get get(): IOffset
  {
    return this.offset;
  }

}
