


















import { Component, Vue } from 'nuxt-property-decorator'
import ConnectConfirmModal from '~/components/common/components/ConnectConfirmModal.vue'
import { SnackbarStore } from '~/utils/store-accessor'
import ClientSideMenu from '~/components/common/profile/client/client-side-menu.vue'
import ConnectV2Snackbar from '~/components/common/components/v2/ConnectV2Snackbar.vue'

@Component({
  components: {
    ClientSideMenu,
    ConnectV2Snackbar,
    ConnectConfirmModal,
  },
})
export default class ClientLayout extends Vue {
  private onBack() {
    this.$router.back()
  }

  beforeDestroy() {
    SnackbarStore.hideAll()
  }
}
