// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".root_lM-gK{position:fixed;z-index:1055;right:60px;top:20px}.root_lM-gK .snackBar_N8uHg{padding:10px;display:flex;justify-content:center;align-items:center;grid-gap:10px;gap:10px;background:#fff;box-shadow:1px 4px 12px rgba(0,0,0,.25);border-radius:5px;font-family:\"Hiragino Kaku Gothic Pro\";font-size:14px;font-weight:600;color:#454545}.root_lM-gK .snackBar_N8uHg .close_12Ug1,.root_lM-gK .snackBar_N8uHg .icon_35jEY{display:flex;align-items:center;justify-content:center;width:30px;height:30px}.root_lM-gK .snackBar_N8uHg .close_12Ug1{cursor:pointer}.enterActive_2NXww{-webkit-animation:slideIn_1AYWP .3s ease-out;animation:slideIn_1AYWP .3s ease-out}@-webkit-keyframes slideIn_1AYWP{0%{top:0}to{top:20px}}@keyframes slideIn_1AYWP{0%{top:0}to{top:20px}}.leaveActive_3P1ee{-webkit-animation:slideOut_Yk2yk .3s ease-out;animation:slideOut_Yk2yk .3s ease-out}@-webkit-keyframes slideOut_Yk2yk{0%{top:20px}to{top:0}}@keyframes slideOut_Yk2yk{0%{top:20px}to{top:0}}", ""]);
// Exports
exports.locals = {
	"root": "root_lM-gK",
	"snackBar": "snackBar_N8uHg",
	"close": "close_12Ug1",
	"icon": "icon_35jEY",
	"enterActive": "enterActive_2NXww",
	"slideIn": "slideIn_1AYWP",
	"leaveActive": "leaveActive_3P1ee",
	"slideOut": "slideOut_Yk2yk"
};
module.exports = exports;
