















































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import IProposalDocumentGroups from '~/Interfaces/Message/IProposalDocumentGroups'
import FormModal from '~/components/common/modal/form-modal.vue'
import ConnectModal from '~/components/common/components/ConnectModal.vue'
@Component({
  components: { ConnectModal, FormModal },
})
export default class ClientProposalDocumentListModal extends Vue {
  @Prop()
  proposalDocumentList!: IProposalDocumentGroups

  mounted() {}

  @Emit('closeModal')
  private closeModal() {}
}
