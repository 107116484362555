// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".paging_3P_tK{margin-top:60px;grid-gap:20px;gap:20px;font-size:12px;font-weight:600;line-height:12px;letter-spacing:0;color:#a7b2bc}.paging_3P_tK,.paging_3P_tK .block_3_R4l{display:flex;align-items:center;justify-content:center}.paging_3P_tK .block_3_R4l{cursor:pointer;width:27px;height:27px;border-radius:4px}.paging_3P_tK .block_3_R4l img{display:block}.paging_3P_tK .block_3_R4l img.hover_2wSJd{display:none}.paging_3P_tK .block_3_R4l:hover{background:#2f5980;color:#fff}.paging_3P_tK .block_3_R4l:hover img{display:none}.paging_3P_tK .block_3_R4l:hover img.hover_2wSJd{display:block}.paging_3P_tK .block_3_R4l.active_2d0JL{pointer-events:none;background:#0e273c;color:#fff}.paging_3P_tK .block_3_R4l.ellipsis_zYu6q{pointer-events:none}", ""]);
// Exports
exports.locals = {
	"paging": "paging_3P_tK",
	"block": "block_3_R4l",
	"hover": "hover_2wSJd",
	"active": "active_2d0JL",
	"ellipsis": "ellipsis_zYu6q"
};
module.exports = exports;
