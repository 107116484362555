







import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IConnectDegreeCompany from '~/Interfaces/connectCompany/IConnectDegreeCompany'

@Component({})
export default class ClientProposalListItemDegree extends Vue {
  @Prop()
  private degree!: IConnectDegreeCompany
}
