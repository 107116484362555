import { NuxtAxiosInstance } from '@nuxtjs/axios'

export default class CaseInterviewService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   *
   * @param url
   */
  async get(url: string): Promise<boolean> {
    try {
      const response = await this.axios.get(url)
      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
    }
  }
}
