// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".connectProposalStatus_vuWmF{position:relative;background:#e4f0fc;border:1px solid #71a8d9;border-radius:100px}.connectProposalStatus_vuWmF .select_2Ri8d{display:flex;flex-wrap:wrap;align-content:center;justify-content:center;cursor:pointer;outline:none;width:90px;height:30px;border:none;background:transparent;-webkit-appearance:none;-moz-appearance:none;appearance:none;padding:0 15px;color:#0e273c;color:var(--Black,#0e273c);font-family:Hiragino Kaku Gothic Pro;font-size:12px;font-style:normal;font-weight:400;line-height:normal}.connectProposalStatus_vuWmF .select_2Ri8d.header_3qexW{font-size:14px;width:100px;height:40px}.connectProposalStatus_vuWmF .select_2Ri8d:disabled{background:none!important;opacity:.5}.connectProposalStatus_vuWmF .icon_2D5Tb{position:absolute;top:2px;right:15px;color:#71a8d9;font-size:18px}", ""]);
// Exports
exports.locals = {
	"connectProposalStatus": "connectProposalStatus_vuWmF",
	"select": "select_2Ri8d",
	"header": "header_3qexW",
	"icon": "icon_2D5Tb"
};
module.exports = exports;
