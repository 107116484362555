


































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { ModalStore } from '~/utils/store-accessor'
import ConnectButton from '~/components/common/components/ConnectButton.vue'

@Component({
  components: {
    ConnectButton,
  },
})
export default class ConnectConfirmModal extends Vue {
  @Prop()
  private name!: string

  @Prop()
  private hideClose!: boolean

  @Prop()
  private width!: string

  get isVisible(): boolean {
    return ModalStore.shouldDisplay(this.name)
  }

  get style(): string {
    return `width: ${this.width}`
  }

  private onClose() {
    ModalStore.hide(this.name)
  }

  @Emit('cancel')
  private onCancel() {
    this.onClose()
  }
}
