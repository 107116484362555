



































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IConnectProposal from '~/Interfaces/IConnectProposal'

@Component({})
export default class ProposalFavoriteButton extends Vue {
  @Prop()
  private proposal!: IConnectProposal

  @Prop({ default: false })
  private isShowFavoriteText!: boolean

  @Prop({ default: false })
  private version2!: boolean

  private onClick() {
    // POST権限がある場合にのみバックエンドへ通信し、フロントは見た目だけ変える
    if (!this.$services.user.isRequestLimited) {
      try {
        if (this.proposal.isConnectClientFavorited) {
          this.$services.proposal.unfavorite(
            this.proposal.connectProposalHashId
          )
        } else {
          this.$services.proposal.favorite(this.proposal.connectProposalHashId)
        }
      } catch (error: any) {
        console.warn(error)
      }
    }

    this.proposal.isConnectClientFavorited = !this.proposal
      .isConnectClientFavorited
  }

  get favoriteText(): string {
    return this.proposal.isConnectClientFavorited
      ? 'お気に入り解除'
      : 'お気に入り登録'
  }
}
