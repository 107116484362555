var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5, _obj$6, _obj$7, _obj$8, _obj$9, _obj$10;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    _vm.s.button,
    ( _obj = {}, _obj[_vm.s.primary] = _vm.buttonColor === 'primary', _obj ),
    ( _obj$1 = {}, _obj$1[_vm.s.secondary] = _vm.buttonColor === 'secondary', _obj$1 ),
    ( _obj$2 = {}, _obj$2[_vm.s.tertiary] = _vm.buttonColor === 'tertiary', _obj$2 ),
    ( _obj$3 = {}, _obj$3[_vm.s.gray] = _vm.buttonColor === 'gray', _obj$3 ),
    ( _obj$4 = {}, _obj$4[_vm.s.clientPrimary] = _vm.buttonColor === 'clientPrimary', _obj$4 ),
    ( _obj$5 = {}, _obj$5[_vm.s.transparent] = _vm.buttonColor === 'transparent', _obj$5 ),
    ( _obj$6 = {}, _obj$6[_vm.s.primary_ver2] = _vm.buttonColor === 'primary_ver2', _obj$6 ),
    ( _obj$7 = {}, _obj$7[_vm.s.sm] = _vm.buttonSize === 'sm', _obj$7 ) ],style:(_vm.style),attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":_vm.onClick}},[_c('span',{class:_vm.s.inner},[(_vm.icon)?_c('font-awesome-icon',{class:[
        _vm.s.icon,
        ( _obj$8 = {}, _obj$8[_vm.s.primary] = _vm.buttonColor === 'primary', _obj$8 ),
        ( _obj$9 = {}, _obj$9[_vm.s.secondary] = _vm.buttonColor === 'secondary', _obj$9 ),
        ( _obj$10 = {}, _obj$10[_vm.s.tertiary] = _vm.buttonColor === 'tertiary', _obj$10 ) ],attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }