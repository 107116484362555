































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'

@Component({})
export default class ClientQuestionnaireCard extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop()
  private value!: 'policy' | 'career' | 'personality' | 'price'

  @Prop()
  private title!: string

  @Prop()
  private items!: string[]

  @Emit('submit')
  private onSubmit(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: 'policy' | 'career' | 'personality' | 'price'
  ) {}
}
