// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".menuLink_1qXcm{display:flex;align-items:center;grid-gap:10px;gap:10px;padding:7px 10px;width:180px;height:34px;background:#fff;font-weight:600;font-size:14px;line-height:21px;color:#555;text-decoration:none}.menuLink_1qXcm:hover{background:#eaebeb;opacity:.8}.menuLink_1qXcm .incomplete_12Zxm{padding:1px 5px;display:flex;justify-content:center;align-items:center;grid-gap:10px;gap:10px;border-radius:2px;background:#eb6b8a;background:var(--Red-50,#eb6b8a);color:#fff;color:var(--Background-White,#fff);font-family:Hiragino Kaku Gothic Pro;font-size:12px;font-style:normal;font-weight:600;line-height:normal}", ""]);
// Exports
exports.locals = {
	"menuLink": "menuLink_1qXcm",
	"incomplete": "incomplete_12Zxm"
};
module.exports = exports;
