// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_3Bvbj{position:relative;font-size:16px;line-height:24px;color:#2c3846;border-bottom:2px solid #0475e6;-o-border-image:linear-gradient(90deg,#0475e6,#03bae5 50.13%,#04c9c9);border-image:linear-gradient(90deg,#0475e6,#03bae5 50.13%,#04c9c9);border-image-slice:1;padding-top:9px}.order_3g32O,.title_3Bvbj{font-style:normal;font-weight:600}.order_3g32O{position:absolute;top:0;left:0;font-family:\"Hiragino Kaku Gothic Pro\",\"Hiragino Sans\";font-size:24px;line-height:36px;background:linear-gradient(90deg,#0066cb,#00a1c7 50.13%,#00a5a7);-webkit-background-clip:text;-webkit-text-fill-color:transparent}.text_2vtZg{display:inline-block;padding-left:30px}", ""]);
// Exports
exports.locals = {
	"title": "title_3Bvbj",
	"order": "order_3g32O",
	"text": "text_2vtZg"
};
module.exports = exports;
