


















































































import {Component, Vue, Prop} from 'nuxt-property-decorator'
import IConnectSupportAchievementCategoryList from '~/Interfaces/connectMember/IConnectSupportAchievementCategoryList'
import IConnectCompanySupportAchievement from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'
import {ConnectCompanyStore} from '~/utils/store-accessor'
import HighlightText from '~/components/common/profile/connectCompany/highlight-text.vue'

@Component({
  components: {
    HighlightText,
  },
})
export default class ProfileConnectMemberCareer extends Vue {
  @Prop()
  private categoryList!: IConnectSupportAchievementCategoryList[]

  @Prop()
  private searchWords!: string[]

  ym(date: string | null) {
    if (date === null) {
      return ''
    }

    return this.$services.day.getYmJa(date)
  }

  categoryName(categoryId: number): string {
    const category = this.categoryList.find(
      (category: IConnectSupportAchievementCategoryList) => {
        return category.id === categoryId
      }
    )

    if (!category) {
      return ''
    }

    return category.text
  }

  get achievements(): IConnectCompanySupportAchievement[] | undefined {
    const connectCompany = ConnectCompanyStore.getConnectCompany

    return connectCompany?.connectCompanySupportAchievements
  }
}
