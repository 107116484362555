
import { Component, Vue } from 'nuxt-property-decorator'
import { Route } from 'vue-router'

@Component
export default class ConnectBlockUnloadMixin extends Vue {
  private isEdited!: boolean

  mounted() {
    this.initBlock()
  }

  beforeDestroy() {
    const inputs = document.querySelectorAll('input, textarea, select')
    inputs.forEach((input) => {
      input.removeEventListener('change', this.onChange)
    })
    window.removeEventListener('beforeunload', this.onBeforeunload)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeRouteLeave(to: Route, from: Route, next: any) {
    if (this.isEdited) {
      const answer = window.confirm(
        'このサイトを離れますか？行った変更が保存されない可能性があります。'
      )
      next(answer)
      return
    }
    next()
  }

  private onChange() {
    this.isEdited = true
  }

  private onBeforeunload(event: BeforeUnloadEvent) {
    if (this.isEdited) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  initBlock() {
    this.isEdited = false
    const inputs = document.querySelectorAll('input, textarea, select')
    inputs.forEach((input) => {
      input.addEventListener('change', this.onChange)
    })
    window.addEventListener('beforeunload', this.onBeforeunload)
  }

  forceEdit() {
    this.isEdited = true
  }

  resetEdit() {
    this.isEdited = false
  }
}
