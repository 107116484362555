// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".threadMessages_2g_cJ{width:100%;height:calc(100% - 64px);overflow-y:auto;padding:0 20px 20px}", ""]);
// Exports
exports.locals = {
	"threadMessages": "threadMessages_2g_cJ"
};
module.exports = exports;
