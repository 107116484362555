




































































































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import {
  ClientProposalDetailHeader,
  ClientProposalDetailTitle,
  ClientProposalDetailContent,
  ClientProposalContract,
  ClientProposalMessage,
} from './components'
import { IClientProposalDetailHeaderTab, IConnectProposal } from '~/Interfaces'
import {
  ConnectContractRenewalStore,
  ConnectClientProposalMemoStore,
  CurrentConnectProposalStore,
} from '~/utils/store-accessor'
import ClientProposalMemo from '~/components/Client/ClientProposal/ClientProposalMemo/index.vue'
import IConnectClientProposalMemo from '~/Interfaces/IConnectClientProposalMemo'
import SnackBar from '~/components/SnackBar.vue'

@Component({
  components: {
    SnackBar,
    ClientProposalMemo,
    ClientProposalDetailHeader,
    ClientProposalDetailTitle,
    ClientProposalDetailContent,
    ClientProposalContract,
    ClientProposalMessage,
  },
})
export default class ClientProposalDetail extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop({ default: true })
  private willAppearClientMessageAttentionModal!: boolean

  @Prop({ default: false })
  private isMessageTabDefault!: boolean

  private currentTab: IClientProposalDetailHeaderTab = 'proposal'

  mounted() {
    this.currentTab = this.isMessageTabDefault ? 'message' : 'proposal'

    this.$services.connectProposalRead.read(
      this.connectProposal?.connectProposalHashId
    )
    CurrentConnectProposalStore.setCurrentConnectProposal(this.connectProposal)
  }

  private onClick() {
    CurrentConnectProposalStore.setCurrentConnectProposal(null)
    this.currentTab = 'proposal'
    this.$router.push({
      query: {},
    })
  }

  private onChangeTab(tabState: IClientProposalDetailHeaderTab) {
    this.currentTab = tabState
    if (tabState === 'message') {
      this.$nextTick(() => {
        const element = document.getElementById('threadMessagesContainer')
        if (!element) return
        element.scrollIntoView({ behavior: 'smooth', block: 'end' })
      })
    }
  }

  private get isSupportAccount(): boolean {
    return (
      this.$services.user.isSupportClient ||
      this.$services.user.isSupportPartner
    )
  }

  // メッセージの読み込みが完了してからでないと最新メッセージまでスクロールできないため用意（ClientProposalMessageからemit）
  private onMessagesLoaded() {
    if (this.isMessageTabDefault) {
      this.onChangeTab('message')
    }
  }

  @Emit('refreshProposals')
  private refreshProposals() {}

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}

  @Watch('connectProposal')
  private async loadRenewals() {
    if (!this.connectProposal?.connectProposalHashId) return

    const connectRenewal = await this.$services.connectRenewal.find(
      this.connectProposal?.connectProposalHashId,
      true
    )
    if (connectRenewal?.connectContractRenewals) {
      const connectContractRenewals = Object.values(
        connectRenewal?.connectContractRenewals
      )

      ConnectContractRenewalStore.set(connectContractRenewals)
    }
  }

  get clientMemo(): IConnectClientProposalMemo | null {
    return ConnectClientProposalMemoStore.getConnectClientProposalMemo
  }
}
