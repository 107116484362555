
















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: { ConnectIcon },
})
export default class ClientProposalListItemFavButton extends Vue {
  @Prop({ default: false })
  private isFavorite!: boolean
}
