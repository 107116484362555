















































































import { Component, Vue } from 'nuxt-property-decorator'
import { Context } from '@nuxt/types'
import cloneDeep from 'lodash.clonedeep'
import {
  ConnectBlockUnloadMixin,
  ConnectButton,
  ConnectDropdown,
  ConnectInput,
  ConnectInputLabel,
  ConnectTextarea,
  PartnerButtonColumn,
  PartnerHeader,
  PartnerLayout,
  PartnerTitle,
  ProfileConnectCompanyCareerTemplate,
  ProfileConnectCompanyEducationTemplate,
  Wrapper,
} from '~/components'
import {
  ConnectCompanyProfileStore,
  ConnectCompanyStore,
  ModalStore,
  SnackbarStore,
} from '~/utils/store-accessor'
import {
  IConnectCompany,
  IConnectCompanyCareer,
  IConnectCompanyConstants,
  IConnectCompanyProfile,
  IConnectCompanyRepresentative,
} from '~/Interfaces'

@Component({
  computed: {
    EditRepresentativePage() {
      return EditRepresentativePage
    },
  },
  components: {
    ConnectTextarea,
    ProfileConnectCompanyEducationTemplate,
    ProfileConnectCompanyCareerTemplate,
    ConnectButton,
    Wrapper,
    PartnerLayout,
    PartnerHeader,
    PartnerTitle,
    ConnectInput,
    ConnectInputLabel,
    ConnectDropdown,
    PartnerButtonColumn,
  },
  mixins: [ConnectBlockUnloadMixin],
})
export default class EditRepresentativePage extends Vue {
  private connectCompany!: IConnectCompany
  private constants!: IConnectCompanyConstants
  private connectCompanyProfile!: IConnectCompanyProfile
  private representative!: IConnectCompanyRepresentative

  private forms: number[] = []
  private formsInvisible: number[] = []

  public static PROFILE_MAXLENGTH = 300

  mounted() {
    if (!this.$config.PARTNER_NEW_PROFILE_ENABLED) {
      return this.$router.push('/company/profile/connect_company')
    }

    this.resetCareerForms()
  }

  private snackBarLeave() {
    this.$router.push('/company/profile/connect_company')
  }

  get careers(): IConnectCompanyCareer[] | undefined {
    return this.connectCompany.connectCompanyCareers
  }

  private addForm(index: number) {
    this.forms.push(index)
  }

  private resetCareerForms() {
    this.forms = []
    this.formsInvisible = []

    if (!this.careers) {
      this.addForm(0)
      return
    }

    this.careers.map((_, index) => {
      this.addForm(index)
    })

    if (this.careers.length === 0) {
      this.addForm(0)
    }
  }

  private deleteForm(index: number) {
    this.formsInvisible.push(index)
  }

  // @TODO Serviceに切り出すなどして、二重管理を防ぐため共通化する
  // @TODO formに依存しているのを回避する
  // @SEE ConnectCompanyProfileValidator.validateRepresentative
  // @WARNING ConnectCompanyProfileValidatorと同期させること
  private validator() {
    const profileLength = this.representative.profile?.length ?? 0
    return profileLength <= EditRepresentativePage.PROFILE_MAXLENGTH
  }

  private async onSave() {
    if (!this.validator()) {
      SnackbarStore.show('errorPartnerProfile')
      return
    }

    const payloads = this.getCareersPayload()
    if (!payloads) return

    const payload = {
      profile: this.representative.profile,
    }
    const res = await Promise.all([
      this.$services.connectCompanyProfile.postRepresentative(payload),
      this.$services.company.setConnectCompanyCareer(
        payloads.education,
        payloads.careers
      ),
    ])

    if (res[1]) {
      const connectCompany = await this.$services.company.getConnectCompany()
      ConnectCompanyStore.setConnectCompany(connectCompany)

      this.connectCompany = connectCompany!
      this.resetCareerForms()
    }

    ;(this as any).resetEdit()

    await this.$services.connectCompanyProfileValidator.refresh()

    SnackbarStore.show('savedPartnerProfile')
  }

  private onBack() {
    ;(this as any).resetEdit()
    ModalStore.show('disposePartnerProfile')
  }

  async asyncData(ctx: Context) {
    const companyService = ctx.$services.company
    const connectCompany = await companyService.getConnectCompany()
    ConnectCompanyStore.setConnectCompany(connectCompany)

    const constants = await companyService.getConnectCompanyConstants()

    const representative = await ctx.$services.connectCompanyProfile.getRepresentative()

    const clonedRepresentative = cloneDeep(representative)
    ConnectCompanyProfileStore.setRepresentative(clonedRepresentative)

    return {
      connectCompany,
      constants,
      representative,
    }
  }

  getCareersPayload():
    | {
        education: Object
        careers: Object[]
      }
    | undefined {
    // TODO: ロジックが肥大化しているので ConnectForm コンポート等の共通化を検討する
    const form = this.$refs.form as HTMLFormElement

    this.resetInputValidity(form, '.endDate')
    this.resetInputValidity(form, '.isOngoing')

    let shouldReportValidity = false

    const schoolName = form.querySelector('.schoolName') as HTMLInputElement
    const major = form.querySelector('.major') as HTMLInputElement
    const graduateYear = form.querySelector('.graduateYear') as HTMLInputElement
    const graduateMonth = form.querySelector(
      '.graduateMonth'
    ) as HTMLInputElement
    const etc = form.querySelector('.etc') as HTMLTextAreaElement

    const education = {
      schoolName: schoolName.value,
      major: major.value,
      graduateYear: graduateYear.value,
      graduateMonth: graduateMonth.value,
      etc: etc.value,
    } as Object

    if (
      etc.value &&
      etc.value.length > EditRepresentativePage.PROFILE_MAXLENGTH
    ) {
      shouldReportValidity = true
    }

    const careersData: Object[] = []
    const careers = form.querySelectorAll('.career') as NodeList
    careers.forEach((career: Node, index: number) => {
      const div = career as HTMLDivElement
      const companyName = div.querySelector('.companyName') as HTMLInputElement

      const startDateYear = div.querySelector(
        '.startDateYear'
      ) as HTMLInputElement
      const startDateMonth = div.querySelector(
        '.startDateMonth'
      ) as HTMLInputElement
      const startDate = new Date(
        (startDateYear.value as unknown) as number,
        ((startDateMonth.value as unknown) as number) - 1
      )

      const endDateYear = div.querySelector('.endDateYear') as HTMLInputElement
      const endDateMonth = div.querySelector(
        '.endDateMonth'
      ) as HTMLInputElement
      const endDate =
        endDateYear.value !== ''
          ? new Date(
              (endDateYear.value as unknown) as number,
              ((endDateMonth.value as unknown) as number) - 1
            )
          : null

      endDateYear.setCustomValidity('')
      endDateMonth.setCustomValidity('')

      const isOngoing = div.querySelector('.isOngoing') as HTMLInputElement
      const role = div.querySelector('.role') as HTMLInputElement
      const employment = Array.from(div.querySelectorAll('.employment'))
        .filter((node: Node) => {
          const input = node as HTMLInputElement
          return input.checked
        })
        .pop() as HTMLInputElement
      const employmentEtc = div.querySelector(
        '.employmentEtc'
      ) as HTMLTextAreaElement
      const involvement = Array.from(div.querySelectorAll('.involvement'))
        .filter((node: Node) => {
          const input = node as HTMLInputElement
          return input.checked
        })
        .pop() as HTMLInputElement
      const mainAchievement = div.querySelector(
        '.mainAchievement'
      ) as HTMLTextAreaElement

      // @MEMO: validation
      if (endDateYear.value === '' && !isOngoing.checked) {
        endDateYear.setCustomValidity('終了年を入力してください。')
        shouldReportValidity = true
      } else if (endDateMonth.value === '' && !isOngoing.checked) {
        endDateMonth.setCustomValidity('終了月を入力してください。')
        shouldReportValidity = true
      } else if (endDate && endDate < startDate && !isOngoing.checked) {
        endDateYear.setCustomValidity(
          '終了年月は開始年月より前の日付を入れてください。'
        )
        shouldReportValidity = true
      } else if (endDateYear.value !== '' && isOngoing.checked) {
        endDateYear.setCustomValidity(
          '終了している場合は現在継続中のチェックを外してください。'
        )
        shouldReportValidity = true
      } else if (
        mainAchievement.value.length > EditRepresentativePage.PROFILE_MAXLENGTH
      ) {
        shouldReportValidity = true
      }

      careersData.push({
        companyName: companyName.value,
        startDate: this.$services.day
          .getDayjs(startDate.toDateString())
          .format('YYYY-MM-DD'),
        endDate: endDate
          ? this.$services.day
              .getDayjs(endDate.toDateString())
              .format('YYYY-MM-DD')
          : null,
        isOngoing: isOngoing.checked,
        role: role.value,
        employment: employment ? employment.value : null,
        employmentEtc: employmentEtc.value,
        involvement: involvement ? involvement.value : null,
        mainAchievement: mainAchievement.value,
      })
    })

    if (shouldReportValidity || !form.checkValidity()) {
      form.reportValidity()
      SnackbarStore.show('errorPartnerProfile')
      return
    }

    return {
      education,
      careers: careersData,
    }
  }

  private resetInputValidity(form: HTMLFormElement, selector: string) {
    const inputs = form.querySelectorAll(selector) as NodeList
    inputs.forEach((input: Node) => {
      // @ts-ignore
      input.setCustomValidity('')
    })
  }
}
