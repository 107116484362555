// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".root_2CecO{pointer-events:none;position:fixed;z-index:1055;right:0;left:0;top:20px}.root_2CecO .wrapper_1o86C{text-align:center}.root_2CecO .snackBar_1dcQ8{pointer-events:all;display:inline-block;background:#d4efe7;box-shadow:0 0 20px rgba(109,132,155,.3);font-weight:600;font-size:24px;line-height:130%;color:#2c3846;margin:0 auto;padding:20px 100px}.root_2CecO .snackBar_1dcQ8.error_3FX7Y{background:#ffe8e8}.root_2CecO .snackBar_1dcQ8 .inner_1q9q1{display:flex;justify-content:center;align-items:center;grid-gap:0 10px;gap:0 10px}.root_2CecO .snackBar_1dcQ8 .inner_1q9q1 .icon_lLk6K{font-size:26px;color:#1ac08f}.root_2CecO .snackBar_1dcQ8 .inner_1q9q1 .icon_lLk6K.error_3FX7Y{color:#d60303}.root_2CecO .snackBar_1dcQ8 .hint_25hqW{font-weight:400;font-size:14px;line-height:21px;display:flex;justify-content:center;align-items:center;text-align:center;color:#2c3846;padding-top:10px}.enterActive_l_oT4{-webkit-animation:slideIn_18fQ- .3s ease-out;animation:slideIn_18fQ- .3s ease-out}@-webkit-keyframes slideIn_18fQ-{0%{top:0;opacity:0}to{top:20px;opacity:1}}@keyframes slideIn_18fQ-{0%{top:0;opacity:0}to{top:20px;opacity:1}}.leaveActive_2oI3L{-webkit-animation:slideOut_Kontq .3s ease-out;animation:slideOut_Kontq .3s ease-out}@-webkit-keyframes slideOut_Kontq{0%{top:20px;opacity:1}to{top:0;opacity:0}}@keyframes slideOut_Kontq{0%{top:20px;opacity:1}to{top:0;opacity:0}}", ""]);
// Exports
exports.locals = {
	"root": "root_2CecO",
	"wrapper": "wrapper_1o86C",
	"snackBar": "snackBar_1dcQ8",
	"error": "error_3FX7Y",
	"inner": "inner_1q9q1",
	"icon": "icon_lLk6K",
	"hint": "hint_25hqW",
	"enterActive": "enterActive_l_oT4",
	"slideIn": "slideIn_18fQ-",
	"leaveActive": "leaveActive_2oI3L",
	"slideOut": "slideOut_Kontq"
};
module.exports = exports;
