













































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import ConnectButton from '~/components/common/components/ConnectButton.vue'
import {
  TAchievementConstants,
  TConnectCompanySupportAchievement,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'
import AchievementGeneralService from '~/components/Profile/ConnectCompany/Achievement/AchievementGeneralService'

@Component({
  components: {
    ConnectButton,
  },
})
export default class PartnerProfileSupportAchievement extends Vue {
  @Prop()
  private achievement!: TConnectCompanySupportAchievement

  @Prop()
  private constants!: TAchievementConstants

  @Prop({ default: null })
  private selectedAchievement!: TConnectCompanySupportAchievement | null

  private service: AchievementGeneralService = new AchievementGeneralService()

  get isSelected(): boolean {
    return this.selectedAchievement?.hash === this.achievement.hash
  }

  @Emit('onClick')
  private onClick(hash: string) {}

  get term(): string {
    return this.service.getTermForClientProposalDetail(this.achievement)
  }

  get supportedProducts(): string[] {
    return this.service.getSupportedProducts(this.achievement, this.constants)
  }

  get industries(): string[] {
    return this.service.getIndustries(this.achievement, this.constants)
  }
}
