import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ConnectQuotation from '~/components/Product/ConnectQuotation'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectQuotationStoreDefine',
})
export default class ConnectQuotationStoreDefine extends VuexModule {
  connectQuotations: ConnectQuotation[] = []

  @Mutation
  setConnectQuotation(connectQuotation: ConnectQuotation | undefined) {
    if (connectQuotation === undefined) {
      return
    }

    const index = this.connectQuotations.findIndex(
      (v) =>
        v.value.connectProposalHashId ===
        connectQuotation.value.connectProposalHashId
    )
    if (index < 0) {
      this.connectQuotations.push(connectQuotation)

      return
    }

    this.connectQuotations[index] = connectQuotation
  }

  @Mutation
  setProperties(payload: {
    connectProposalHashId: string | undefined
    func: (connectQuotation: ConnectQuotation) => ConnectQuotation
  }) {
    const index = this.connectQuotations.findIndex(
      (v) => v.value.connectProposalHashId === payload.connectProposalHashId
    )
    if (index < 0) {
      return
    }

    this.connectQuotations[index] = payload.func(this.connectQuotations[index])
  }

  get getConnectQuotation() {
    const self = this

    return (connectProposalHashId: string) => {
      const connectQuotation = self.connectQuotations.find(
        (connectQuotation) =>
          connectQuotation.value.connectProposalHashId === connectProposalHashId
      )
      if (!connectQuotation) {
        return new ConnectQuotation({})
      }

      return connectQuotation
    }
  }
}
