







































import { Component, Prop, Vue, Emit } from 'nuxt-property-decorator'
import PurchaseOrder from '~/components/PurchaseOrderList/PurchaseOrder'

type PageType = PurchaseOrder

@Component({})
export default class Paging extends Vue {
  @Prop({ default: 1 })
  activePage!: number

  @Prop()
  private pageContents!: PageType[]

  @Emit('page')
  page(page: number) {}

  get currentPage() {
    return this.activePage + 1
  }

  get pageLength() {
    return Math.ceil(this.pageContents.length / 10)
  }

  before() {
    const beforePage = this.activePage - 1
    if (beforePage < 0) {
      return
    }

    this.page(beforePage)
  }

  next() {
    const nextPage = this.activePage + 1
    if (this.pageLength - 1 < nextPage) {
      return
    }

    this.page(nextPage)
  }

  numberOrEllipsis(value: string | number): string | number {
    return value < 0 ? '…' : value
  }

  get paginationItems() {
    return ([] as number[]).concat(
      this.paginationFirst,
      this.leftEllipsis,
      this.paginationMiddle,
      this.rightEllipsis,
      this.paginationLast
    )
  }

  get paginationFirst(): number[] {
    if (this.pageLength === 0) {
      return [1]
    }
    if (this.pageLength <= 10) {
      return Paging.range(1, this.pageLength)
    }
    if (this.currentPage >= 7) {
      return [1, 2]
    }

    return Paging.range(1, 8)
  }

  get leftEllipsis(): number[] {
    if (
      this.paginationMiddle.length === 0 &&
      this.paginationLast.length === 0
    ) {
      return []
    }

    const paginationFirstLastNumber = this.paginationFirst[
      this.paginationFirst.length - 1
    ]

    if (this.paginationMiddle.length > 0) {
      if (paginationFirstLastNumber + 1 !== this.paginationLast[0]) {
        return [-1]
      }
    } else if (paginationFirstLastNumber + 1 !== this.paginationMiddle[0]) {
      return [-1]
    }

    return []
  }

  get paginationMiddle(): number[] {
    if (this.currentPage < 7) {
      return []
    }
    if (this.currentPage > this.pageLength - 5) {
      return []
    }

    return Paging.range(this.currentPage - 3, this.currentPage + 3)
  }

  get rightEllipsis(): number[] {
    if (this.paginationMiddle.length === 0) {
      return []
    }

    const paginationMiddleLastNumber = this.paginationMiddle[
      this.paginationMiddle.length - 1
    ]
    if (paginationMiddleLastNumber + 1 === this.paginationLast[0]) {
      return []
    }

    return [-1]
  }

  get paginationLast(): number[] {
    if (this.pageLength <= 10) {
      return []
    }

    if (this.currentPage <= this.pageLength - 5) {
      return [this.pageLength - 1, this.pageLength]
    }

    return Paging.range(this.pageLength - 7, this.pageLength)
  }

  private static range(start: number, end: number): number[] {
    return [...Array(end + 1).keys()].slice(start)
  }
}
