// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3eRdS{display:flex;flex-direction:column;align-items:center;grid-gap:20px;gap:20px}.container_3eRdS .header_3lwpd{font-size:14px;font-weight:600;font-family:\"Hiragino Kaku Gothic Pro\";color:#454545}.container_3eRdS .buttons_1HgrK{overflow:clip;display:flex;justify-content:space-evenly;height:50px;max-width:600px;width:100%;border:1px solid #afadac;border-radius:100px}.container_3eRdS .buttons_1HgrK .button_2vp_w{cursor:pointer;display:flex;align-items:center;justify-content:center;grid-gap:8px;gap:8px;max-width:200px;width:100%;font-size:14px;font-weight:600;font-family:\"Hiragino Kaku Gothic Pro\";color:#454545}.container_3eRdS .buttons_1HgrK .button_2vp_w.center_28352{border-left:1px solid #afadac;border-right:1px solid #afadac}.container_3eRdS .buttons_1HgrK .button_2vp_w.active_X5RZP{pointer-events:none;background:#e3eef7}.container_3eRdS .buttons_1HgrK .button_2vp_w:hover{background:#e3eef7}", ""]);
// Exports
exports.locals = {
	"container": "container_3eRdS",
	"header": "header_3lwpd",
	"buttons": "buttons_1HgrK",
	"button": "button_2vp_w",
	"center": "center_28352",
	"active": "active_X5RZP"
};
module.exports = exports;
