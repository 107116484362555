








































































import { Component, Vue } from 'nuxt-property-decorator'
import { Context } from '@nuxt/types'
import {
  ConnectBlockUnloadMixin,
  ConnectButton,
  ConnectDropdown,
  ConnectInput,
  ConnectInputLabel,
  ConnectTextarea,
  PartnerButtonColumn,
  PartnerHeader,
  PartnerLayout,
  PartnerSubtitle,
  PartnerTitle,
  Wrapper,
  SupportSystemTable,
} from '~/components'
import {
  ConnectCompanySupportSystemPayload,
  IConnectCompanyProfile,
  IConnectCompanySupportSystem,
} from '~/Interfaces'
import {
  ConnectCompanyProfileStore,
  ModalStore,
  SnackbarStore,
} from '~/utils/store-accessor'

@Component({
  computed: {
    EditSupportSystemPage() {
      return EditSupportSystemPage
    },
  },
  components: {
    ConnectTextarea,
    ConnectButton,
    Wrapper,
    PartnerLayout,
    PartnerHeader,
    PartnerTitle,
    ConnectInput,
    ConnectInputLabel,
    ConnectDropdown,
    PartnerButtonColumn,
    PartnerSubtitle,
    SupportSystemTable,
  },
  mixins: [ConnectBlockUnloadMixin],
})
export default class EditSupportSystemPage extends Vue {
  private connectCompanyProfile!: IConnectCompanyProfile
  private supportSystem!: IConnectCompanySupportSystem

  public static FEATURE_MAXLENGTH = 300

  mounted() {
    if (!this.$config.PARTNER_NEW_PROFILE_ENABLED) {
      return this.$router.push('/company/profile/connect_company')
    }
  }

  get activityOptions() {
    return [
      {
        value: 2,
        label: '組織型',
      },
      {
        value: 1,
        label: 'チーム型',
      },
      {
        value: 3,
        label: '個人ディレクター型',
      },
      {
        value: 0,
        label: '個人プレイヤー型',
      },
    ]
  }

  get workOptions() {
    return [
      {
        value: 0,
        label: 'リモート・常駐いずれも対応可',
      },
      {
        value: 1,
        label: 'すべてリモート稼働',
      },
      {
        value: 2,
        label: '原則リモート稼働（案件によって常駐稼働可）',
      },
      {
        value: 3,
        label: '原則常駐稼働（案件によってリモート稼働可）',
      },
      {
        value: 4,
        label: 'すべて常駐稼働',
      },
    ]
  }

  // @TODO Serviceに切り出すなどして、二重管理を防ぐため共通化する
  // @SEE ConnectCompanyProfileValidator.validateSupportSystem
  // @WARNING ConnectCompanyProfileValidatorと同期させること
  private validator() {
    const featureLength = this.supportSystem.feature?.length ?? 0
    return featureLength <= EditSupportSystemPage.FEATURE_MAXLENGTH
  }

  private async onSave() {
    if (!this.validator()) {
      SnackbarStore.show('errorPartnerProfile')
      return
    }
    const payload = {
      activity_type: this.supportSystem.activityType,
      working_style: this.supportSystem.workingStyle,
      feature: this.supportSystem.feature,
    } as ConnectCompanySupportSystemPayload
    await this.$services.connectCompanyProfile.postSupportSystem(payload)
    ;(this as any).resetEdit()

    await this.$services.connectCompanyProfileValidator.refresh()

    SnackbarStore.show('savedPartnerProfile')
  }

  private onBack() {
    ;(this as any).resetEdit()
    ModalStore.show('disposePartnerProfile')
  }

  async asyncData(ctx: Context) {
    const supportSystem = await ctx.$services.connectCompanyProfile.getSupportSystem()
    ConnectCompanyProfileStore.setSupportSystem(supportSystem)

    const supportSystemStore = {
      activityType: supportSystem?.activityType ?? 0,
      workingStyle: supportSystem?.workingStyle ?? 0,
      feature: supportSystem?.feature ?? '',
    } as IConnectCompanySupportSystem

    return {
      supportSystem: supportSystemStore,
    }
  }
}
