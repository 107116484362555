




































































































































import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import IConnectCompanyMember from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMember'
import {
  ConnectButton,
  EmptyStateText,
  PartnerSubtitle,
  PreferredItems,
  SupportItems,
  SupportUsage,
} from '~/components/common'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: {
    PartnerSubtitle,
    SupportItems,
    EmptyStateText,
    SupportUsage,
    PreferredItems,
    ConnectIcon,
    ConnectButton,
  },
})
export default class Page extends Vue {
  @Prop()
  private connectCompanyMember!: IConnectCompanyMember

  @Prop({ default: false })
  private isPreview!: boolean

  @Prop({ default: false })
  private isClient!: boolean

  mounted() {}

  get isVisibleSupport() {
    return this.connectCompanyMember.supports.length > 0
  }

  get isVisiblePreferred() {
    return this.connectCompanyMember.preferredCustomerOrProducts.some(
      (p) => p.preferredValues.length > 0
    )
  }

  private getSupport(categoryId: number) {
    const list = [8, 17, 19, 26, 28, 34, 40, 44, 53, 56]
    let startId = -1
    if (list[categoryId - 1]) {
      startId = list[categoryId - 1]
    }
    return this.connectCompanyMember.supports.filter(
      (s) => s.supportId > startId && s.supportId <= list[categoryId]
    )
  }

  private getPreferred(categoryId: number) {
    return this.connectCompanyMember.preferredCustomerOrProducts.find(
      (p) => p.categoryId === categoryId
    )
  }

  private formatDate(date: string, format: string): string {
    return dayjs(date).format(format)
  }

  private onClickAchievement(connectCompanySupportAchievementHashId: string) {
    let url = ''
    if (this.isClient) {
      url =
        '/client/profile/connect_company/' +
        this.connectCompanyMember.connectCompanyHashId + // CAUTION: このconnectCompanyHashIdは実際にはcompanyHashIdになってしまっているので注意（DBに既に入ってしまっているので修正不可）
        '/support_achievement/' +
        connectCompanySupportAchievementHashId
    } else {
      url = this.isPreview
        ? '/company/profile/connect_company/new_preview/support_achievements/' +
          connectCompanySupportAchievementHashId
        : '/company/profile/connect_company/support_achievements/detail?hash=' +
          connectCompanySupportAchievementHashId
    }
    window.open(url, '_blank')
  }
}
