// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".proposal_detail_1TvBf{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.proposal_detail_1TvBf .top_2CnoS{display:flex;align-items:center;grid-gap:20px;gap:20px}.proposal_detail_1TvBf .top_2CnoS .heading_C1y1c{display:flex;align-items:center;grid-gap:2px;gap:2px}.proposal_detail_1TvBf .top_2CnoS .heading_C1y1c img{width:20px;height:20px}.proposal_detail_1TvBf .top_2CnoS .heading_C1y1c span{white-space:nowrap;color:grey;color:var(--Text-50,grey);font-family:Hiragino Kaku Gothic Pro;font-size:13px;font-style:normal;font-weight:400;line-height:normal}.proposal_detail_1TvBf .top_2CnoS .priorities_2Qsjq,.proposal_detail_1TvBf .top_2CnoS .priorities_2Qsjq .priority_1wgyf{display:flex;align-items:center;grid-gap:7px;gap:7px}.proposal_detail_1TvBf .top_2CnoS .priorities_2Qsjq .priority_1wgyf .text_3yFBT{color:#555;color:var(--Text-60,#555);font-family:Hiragino Kaku Gothic Pro;font-size:14px;font-style:normal;font-weight:600;line-height:normal}.proposal_detail_1TvBf .top_2CnoS .priorities_2Qsjq .priority_1wgyf .bullet_j1yEC{display:flex;align-items:center;justify-content:center;border-radius:100px;background:#63615f;background:var(--Grey-90,#63615f);width:4px;height:4px}.proposal_detail_1TvBf .bottom_3WTeN .priority_detail_38f8N{-webkit-box-orient:vertical;-webkit-line-clamp:3;overflow:hidden;display:-webkit-box;color:#454545;color:var(--Text-100,#454545);text-overflow:ellipsis;font-family:Hiragino Kaku Gothic Pro;font-size:14px;font-style:normal;font-weight:400;line-height:normal}", ""]);
// Exports
exports.locals = {
	"proposal_detail": "proposal_detail_1TvBf",
	"top": "top_2CnoS",
	"heading": "heading_C1y1c",
	"priorities": "priorities_2Qsjq",
	"priority": "priority_1wgyf",
	"text": "text_3yFBT",
	"bullet": "bullet_j1yEC",
	"bottom": "bottom_3WTeN",
	"priority_detail": "priority_detail_38f8N"
};
module.exports = exports;
