


























































































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

import {
  ThreadGroupsStore,
  ThreadDetailsStore,
  TopicStore,
  ReadsStore,
  UploadFileStore,
  ThreadGroupStore,
  SnackbarStore,
} from '~/utils/store-accessor'
import {
  IConnectProposalStatus,
  IThreadGroupAccount,
  ITopic,
} from '~/Interfaces'
import ServiceIcon from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalMessage/components/ServiceIcon/index.vue'
import ThreadFormFileUpload from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalMessage/components/ThreadFormFileUpload/index.vue'
import ThreadFormTemplate from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalMessage/components/ThreadFormTemplate/index.vue'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: {
    ServiceIcon,
    ThreadFormFileUpload,
    ThreadFormTemplate,
    ConnectIcon,
  },
})
export default class ThreadForm extends Vue {
  private textareaClientHeight!: number
  private isTemplateOpened: boolean = false
  private message: string = ''
  private isSending: boolean = false

  @Prop()
  private disabled!: boolean

  @Prop()
  private disabledType!: 'waiting' | 'rejected' | null

  get isVisible(): boolean {
    if (this.disabled) return true

    return !!this.topic
  }

  get placeholder(): string {
    if (this.disabledType === 'rejected') {
      return '見送り後のメッセージの送信はできません'
    }
    return 'メッセージを入力'
  }

  get disabledForm(): boolean {
    if (this.isSending) return true
    if (this.disabled) return true
    if (this.disabledType === 'rejected') return true

    // ゲストアカウントの場合は非活性
    if (this.$services.user.isGuestAccount) {
      return true
    }

    return false
  }

  get topic(): ITopic | undefined {
    return TopicStore.getTopic
  }

  get connectProposalStatus(): IConnectProposalStatus | undefined {
    return this.topic?.thread.connectProposal?.connectProposalStatus
  }

  get threadGroupAccountSelf(): IThreadGroupAccount | null {
    if (!this.topic) {
      return ThreadGroupStore.getThreadGroupAccountSelf
    }
    return TopicStore.getThreadGroupAccountSelf
  }

  mounted() {
    const textarea = this.$refs.textarea as HTMLTextAreaElement
    if (!textarea || !textarea.clientHeight) return

    this.textareaClientHeight = textarea.clientHeight
  }

  /**
   * ファイルアップロードを開く
   * @private
   */
  private openFileUpload() {
    UploadFileStore.setIsOpen(true)
  }

  /**
   * ファイルアップロードを開く
   * @private
   */
  get file() {
    return UploadFileStore.getFile
  }

  fileUploadCancel() {
    UploadFileStore.setFile(null)
  }

  /**
   * templateを閉じる
   * @private
   */
  private closeTemplate() {
    this.isTemplateOpened = false
  }

  private copyTemplate(value: string) {
    this.closeTemplate()

    this.message = value

    this.autoTextareaHeight()
  }

  @Emit('scrollBottom')
  private async submitMessage() {
    if (this.topic === undefined) {
      return
    }

    const file = UploadFileStore.getFile

    if (this.message === '' && !file) {
      return
    }

    const messageText = this.message
    this.isSending = true
    this.message = ''

    const res = await this.$services.message.createMessage(
      this.topic.thread.threadHashId,
      messageText,
      file
    )

    this.isSending = false

    if (!res) {
      SnackbarStore.show('errorSendMessage')
      this.message = messageText
      return
    }

    this.autoTextareaHeight()

    const groups = await this.$services.message.getGroups()
    if (groups) {
      ThreadGroupsStore.setThreadGroups(groups.thread_groups)

      ReadsStore.setReads(groups.reads)
    }

    const topic = await this.$services.message.getTopic(
      this.topic.thread.threadHashId
    )
    TopicStore.setTopic(topic)
    ThreadDetailsStore.setThreadDetails(topic?.thread.threadDetails)
    UploadFileStore.setFile(null)

    this.$nextTick(() => {
      const textarea = this.$refs.textarea as HTMLTextAreaElement
      if (!textarea) {
        return
      }

      textarea.style.height = '40px'
      const submitButton = this.$refs.submitButton as HTMLTextAreaElement
      submitButton.style.height = textarea.scrollHeight + 'px'
    })
  }

  @Emit('changeTextareaHeight')
  private autoTextareaHeight() {
    const textarea = this.$refs.textarea as HTMLTextAreaElement
    if (!textarea) {
      return
    }

    textarea.style.height = this.textareaClientHeight + 'px'
    this.$nextTick(() => {
      const textarea = this.$refs.textarea as HTMLTextAreaElement
      if (!textarea) {
        return
      }

      textarea.style.height = textarea.scrollHeight + 'px'
      const submitButton = this.$refs.submitButton as HTMLTextAreaElement
      submitButton.style.height = textarea.scrollHeight + 'px'
    })
  }
}
