import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  ConnectCompanyFeaturePayload,
  ConnectCompanyInfoPayload,
  ConnectCompanyPolicyPayload,
  ConnectCompanyPricePayload,
  ConnectCompanyRepresentativePayload,
  ConnectCompanySupportPayload,
  ConnectCompanySupportSystemPayload,
  IConnectCompany,
  IConnectCompanyFeatures,
  IConnectCompanyInfoConstants,
  IConnectCompanyPolicy,
  IConnectCompanyPrice,
  IConnectCompanyProfileFindResponse,
  IConnectCompanyRepresentative,
  IConnectCompanySupportResponse,
  IConnectCompanySupportSystem,
} from '~/Interfaces'
import { LockStore } from '~/utils/store-accessor'

export default class ConnectCompanyProfileService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async getInfo(): Promise<IConnectCompany | undefined> {
    try {
      const response = await this.axios.get('api/company/profile/info')

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async postInfo(
    payload: ConnectCompanyInfoPayload
  ): Promise<IConnectCompany | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/profile/info',
        payload
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getFeatures(): Promise<IConnectCompanyFeatures | undefined> {
    try {
      const response = await this.axios.get('api/company/profile/features')

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async postFeatures(
    payload: ConnectCompanyFeaturePayload
  ): Promise<IConnectCompanyFeatures | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/profile/features',
        payload
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getSupports(): Promise<IConnectCompanySupportResponse | undefined> {
    try {
      const response = await this.axios.get('api/company/profile/support')

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async postSupport(
    payload: ConnectCompanySupportPayload
  ): Promise<IConnectCompanySupportResponse | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/profile/support',
        payload
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getSupportSystem(): Promise<IConnectCompanySupportSystem | undefined> {
    try {
      const response = await this.axios.get(
        'api/company/profile/support_system'
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async postSupportSystem(
    payload: ConnectCompanySupportSystemPayload
  ): Promise<IConnectCompanySupportSystem | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/profile/support_system',
        payload
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getRepresentative(): Promise<
    IConnectCompanyRepresentative | undefined
  > {
    try {
      const response = await this.axios.get(
        'api/company/profile/representative'
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async postRepresentative(
    payload: ConnectCompanyRepresentativePayload
  ): Promise<IConnectCompanyRepresentative | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/profile/representative',
        payload
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getPolicy(): Promise<IConnectCompanyPolicy | undefined> {
    try {
      const response = await this.axios.get('api/company/profile/policy')

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async postPolicy(
    payload: ConnectCompanyPolicyPayload
  ): Promise<IConnectCompanyPolicy | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/profile/policy',
        payload
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getPrice(): Promise<IConnectCompanyPrice | undefined> {
    try {
      const response = await this.axios.get('api/company/profile/price')

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async postPrice(
    payload: ConnectCompanyPricePayload
  ): Promise<IConnectCompanyPrice | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/profile/price',
        payload
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getInfoConstants(): Promise<IConnectCompanyInfoConstants | undefined> {
    try {
      const response = await this.axios.get(
        'api/company/profile/constants/info'
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  async getConnectCompanyProfile(): Promise<
    IConnectCompanyProfileFindResponse | undefined
  > {
    try {
      const response = await this.axios.get('api/company/profile/find')

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }
}
