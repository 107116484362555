









import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import Textarea from '~/components/Form/Textarea.vue'
import IConnectCompanyCareer from '~/Interfaces/connectCompany/IConnectCompanyCareer'
import ConnectTextarea from '~/components/common/components/ConnectTextarea.vue'

@Component({
  components: { ConnectTextarea, Textarea },
})
export default class ProfileConnectCompanyCareerTemplateMainachievement extends Vue {
  @Prop()
  private career!: IConnectCompanyCareer

  private mainAchievement: string = ''

  mounted() {
    this.mainAchievement = this.career?.mainAchievement ?? ''
  }
}
