

















import { Component, Vue } from 'nuxt-property-decorator'
import { IClientSideMenuLink } from '~/Interfaces'
import { ConnectButton } from '~/components/common/components'
import ClientSideMenuLink from '~/components/common/profile/client/components/client-side-menu-link.vue'

@Component({
  components: {
    ConnectButton,
    ClientSideMenuLink,
  },
})
export default class ClientSideMenu extends Vue {
  get clientSideMenuLinks(): IClientSideMenuLink[] {
    return [
      {
        label: 'プロフィール',
        to: '/client/setting/profile',
      },
      {
        label: '会社情報',
        to: '/client/setting/company',
      },
      {
        label: '請求先情報',
        to: '/client/setting/billing',
      },
      {
        label: 'ユーザー管理',
        to: '/client/setting/accounts',
      },
    ]
  }
}
