







































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import ClientProposalDetailSegmentedButtons from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailSegmentedButtons.vue'

@Component({})
export default class ClientProposalDetailSegmentedButtonsMini extends ClientProposalDetailSegmentedButtons {}
