// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal_3ijA5{margin-top:60px;display:flex;flex-direction:column;align-items:center}.modal_3ijA5 .title_1fqli{font-size:18px;font-weight:600;letter-spacing:0;color:#002853}.modal_3ijA5 .description_2iZqJ{text-align:center;margin-top:40px;font-size:14px;font-weight:400;line-height:24px;letter-spacing:0;color:#0e273c}.modal_3ijA5 .buttonList__pH3j{margin-top:40px;grid-gap:0 20px;gap:0 20px}.modal_3ijA5 .buttonList__pH3j,.modal_3ijA5 .buttonList__pH3j .close_2jksw{display:flex;justify-content:center;align-items:center}.modal_3ijA5 .buttonList__pH3j .close_2jksw{background:#e9edf1;border:none;border-radius:20px;text-align:center;color:#2f5980}.modal_3ijA5 .buttonList__pH3j .button_10mul,.modal_3ijA5 .buttonList__pH3j .close_2jksw{width:200px;height:40px;cursor:pointer;font-style:normal;font-weight:600;font-size:14px;line-height:21px}.modal_3ijA5 .buttonList__pH3j .button_10mul{display:flex;align-items:center;justify-content:center;background:#224d74;border-radius:30px;grid-gap:10px;gap:10px;text-decoration:none;color:#fff}", ""]);
// Exports
exports.locals = {
	"modal": "modal_3ijA5",
	"title": "title_1fqli",
	"description": "description_2iZqJ",
	"buttonList": "buttonList__pH3j",
	"close": "close_2jksw",
	"button": "button_10mul"
};
module.exports = exports;
