











import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ConnectInputLabel extends Vue {
  @Prop()
  private level!: number

  @Prop()
  private required!: boolean

  @Prop()
  private label!: string

  get headline() {
    if (!this.level) {
      return 'h3'
    }
    return `h${this.level}`
  }
}
