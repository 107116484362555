// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_SgTdw{display:flex;justify-content:flex-start;align-items:center;width:100%;grid-gap:0 20px;gap:0 20px;padding:12px 23px;background:#fcf0dd;font-weight:400;font-size:14px;line-height:24px;color:#2c3846}.container_SgTdw .icon_3uMoe{display:inline-flex;justify-content:center;align-items:center;flex-shrink:0}.container_SgTdw.danger_2x9fo{background:#ffe8e8}.container_SgTdw.info_31wpZ,.container_SgTdw.lightbulb_2kJm0{background:#f3f2f2}", ""]);
// Exports
exports.locals = {
	"container": "container_SgTdw",
	"icon": "icon_3uMoe",
	"danger": "danger_2x9fo",
	"info": "info_31wpZ",
	"lightbulb": "lightbulb_2kJm0"
};
module.exports = exports;
