



































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'gray'
  | 'clientPrimary'
  | 'transparent'
  | 'primary_ver2'
  | 'gray_ver2'

@Component
export default class ConnectButton extends Vue {
  @Prop({ default: 'button' })
  private type!: 'button' | 'submit' | 'reset'

  @Prop()
  private disabled!: boolean

  @Prop()
  private icon!: string

  @Prop()
  private size!: 'md' | 'sm'

  @Prop()
  private buttonColor!: ButtonColor

  @Prop()
  private width!: string

  get buttonSize() {
    if (!this.size) {
      return 'md'
    }
    return this.size
  }

  get style() {
    if (!this.width) {
      return ''
    }
    return `width: ${this.width}`
  }

  @Emit('click')
  private onClick() {}
}
