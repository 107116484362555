



















import { Component, Emit, Vue } from 'nuxt-property-decorator'

import { CurrentConnectProposalStore, TopicStore } from '~/utils/store-accessor'
import { ConnectClientTemplate } from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalMessage/components'
import { IConnectProposal } from '~/Interfaces'
@Component({
  components: {
    ConnectClientTemplate,
  },
})
export default class ThreadFormTemplate extends Vue {
  get isConnectClientSelf(): boolean {
    return TopicStore.isConnectClientSelf
  }

  get isConnectCompanySelf(): boolean {
    return TopicStore.isConnectCompanySelf
  }

  get connectProposal(): IConnectProposal | null {
    return CurrentConnectProposalStore.getCurrentConnectProposal
  }

  get partnerName(): string {
    if (!this.connectProposal) return 'パートナーA'

    return this.$services.proposal.getCompanyName(this.connectProposal)
  }

  mounted() {}

  @Emit('closeTemplate')
  private closeTemplate() {}

  @Emit('copyTemplate')
  private copyTemplate(value: string) {
    return value
      .replace('xxxxxx', this.partnerName)
      .replace('yyyyyy', TopicStore.getNameSelf as string)
  }
}
