import dayjs from 'dayjs'
import {
  TAchievementConstants,
  TConnectCompanySupportAchievement,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'

export default class AchievementGeneralService {
  getRewardType(
    achievement: TConnectCompanySupportAchievement | null,
    constants: TAchievementConstants
  ): string {
    if (constants.rewardTypes.length === 0 || achievement === null) {
      return ''
    }

    const result = constants.rewardTypes.find(
      (obj) => obj.id === achievement?.rewardType
    )
    return result ? result.name : ''
  }

  getTerm(achievement: TConnectCompanySupportAchievement | null): string {
    if (achievement === null) {
      return ''
    }

    const startDate = dayjs(achievement.startDate).format('YYYY年MM月')
    if (achievement.isOngoing) {
      return `${startDate}〜`
    }

    const endDate = dayjs(achievement.endDate).format('YYYY年MM月')
    return `${startDate}〜${endDate}`
  }

  getSupportedProducts(
    achievement: TConnectCompanySupportAchievement | null,
    constants: TAchievementConstants
  ): string[] {
    if (achievement === null) {
      return []
    }

    if (
      achievement.supportedProducts.length === 0 ||
      constants.supportedProducts.length === 0
    ) {
      return []
    }

    const result: string[] = []
    achievement.supportedProducts.map((supportedProduct) => {
      let obj = constants.supportedProducts[0].find(
        (v) => v.id === supportedProduct
      )
      if (!obj) {
        obj = constants.supportedProducts[1].find(
          (v) => v.id === supportedProduct
        )
      }
      result.push(obj ? obj.name : '')
    })

    return result
  }

  getIndustries(
    achievement: TConnectCompanySupportAchievement | null,
    constants: TAchievementConstants
  ): string[] {
    if (achievement === null) {
      return []
    }

    if (
      achievement.industries.length === 0 ||
      constants.industries.length === 0
    ) {
      return []
    }

    const result: string[] = []
    achievement.industries.map((industry) => {
      let obj = constants.industries[0].find((v) => v.id === industry)
      if (!obj) {
        obj = constants.industries[1].find((v) => v.id === industry)
      }
      result.push(obj ? obj.name : '')
    })

    return result
  }

  getCompanyScale(
    achievement: TConnectCompanySupportAchievement | null,
    constants: TAchievementConstants
  ): string {
    if (constants.companyScales.length === 0 || achievement === null) {
      return ''
    }

    const result = constants.companyScales.find(
      (obj) => obj.id === achievement?.companyScale
    )
    return result ? result.name : ''
  }

  getCompanyForm(
    achievement: TConnectCompanySupportAchievement | null,
    constants: TAchievementConstants
  ): string {
    if (constants.companyForms.length === 0 || achievement === null) {
      return ''
    }

    const result = constants.companyForms.find(
      (obj) => obj.id === achievement?.companyForm
    )
    return result ? result.name : ''
  }

  getTermForClientProposalDetail(
    achievement: TConnectCompanySupportAchievement | null
  ): string {
    if (achievement === null) {
      return ''
    }

    const startDate = dayjs(achievement.startDate).format('YYYY年MM月')
    return `${startDate}〜`
  }
}
