// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_3R2Ct{font-style:normal;font-weight:600;font-size:18px;line-height:130%;color:#2f5980;border-bottom:1px solid #98a0a8;padding-bottom:10px}", ""]);
// Exports
exports.locals = {
	"title": "title_3R2Ct"
};
module.exports = exports;
