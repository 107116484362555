// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3D2Vy{padding:20px;flex-direction:column;border-radius:5px;background:#f3f2f2}.container_3D2Vy,.container_3D2Vy .header_3cQFG{display:flex;grid-gap:10px;gap:10px}.container_3D2Vy .header_3cQFG{align-items:center}.container_3D2Vy .header_3cQFG .title_2WdAK{font-size:14px;font-weight:600}.container_3D2Vy .description_aeQRL,.container_3D2Vy .header_3cQFG .title_2WdAK{font-family:\"Hiragino Kaku Gothic Pro\";color:#454545}.container_3D2Vy .description_aeQRL{font-size:13px;font-weight:400}", ""]);
// Exports
exports.locals = {
	"container": "container_3D2Vy",
	"header": "header_3cQFG",
	"title": "title_2WdAK",
	"description": "description_aeQRL"
};
module.exports = exports;
