import { Context } from '@nuxt/types'
import { ConnectCompanyProfileValidatorStore } from '~/utils/store-accessor'
import ConnectCompanyProfileValidator from '~/components/Profile/ConnectCompany/ConnectCompanyProfileValidator'

export default class ConnectCompanyProfileValidatorService {
  private readonly context: Context

  constructor(context: Context) {
    this.context = context
  }

  async set(): Promise<void> {
    await this.refresh()
  }

  async refresh(): Promise<void> {
    const response = await this.context.$services.connectCompanyProfile.getConnectCompanyProfile()
    if (response === undefined) {
      return
    }

    if (response.connectCompanyProfile) {
      ConnectCompanyProfileValidatorStore.set(
        new ConnectCompanyProfileValidator(
          response.connectCompanyProfile,
          response.preferredCustomerOrProductConstants,
          response.supportConstants,
          response.connectCompanyConstants,
          this.context.$auth.user?.company_name as string | undefined | null,
          this.context.$auth.user?.company_address as string | undefined | null
        )
      )
    }
  }
}
