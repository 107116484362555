// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layout_1p9BW{display:grid;grid-template-areas:\"header header\" \"menu content\";grid-template-columns:200px 1fr;grid-template-rows:auto auto}.header_31Fbi{grid-area:header}.menu_1lFbF{grid-area:menu}.main_1rLwZ{position:relative;grid-area:content;height:100%;padding:45px 40px;max-width:840px}", ""]);
// Exports
exports.locals = {
	"layout": "layout_1p9BW",
	"header": "header_31Fbi",
	"menu": "menu_1lFbF",
	"main": "main_1rLwZ"
};
module.exports = exports;
