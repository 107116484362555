































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ConnectInput from '~/components/common/components/ConnectInput.vue'
import {
  TSupportButtonLabel,
  TSupportButtonSubItems,
  TSupportButtonSubItemSelect,
} from '~/Interfaces/connectCompany/ISupportItem'

@Component({
  components: { ConnectInput },
})
export default class SupportItemButtonList extends Vue {
  @Prop()
  private label!: string

  @Prop()
  private supportId!: number

  @Prop()
  private buttonLabels!: TSupportButtonLabel[]

  @Prop()
  private subItems!: TSupportButtonSubItems

  @Prop()
  private modelSelectValue!: number

  @Prop()
  private modelSubItemSelectValues!: number[]

  @Prop()
  private modelSubItemInputValue!: string

  @Prop()
  private errors!: number[]

  @Prop()
  private errorAllZero!: boolean

  @Prop()
  private index!: number

  private isSelected(value: number): boolean {
    return this.modelSelectValue === value
  }

  private toggleAll(values: number[]) {
    const modelSubItemSelectValues = this.modelSubItemSelectValues
    this.$emit(
      'onSelectSubItem',
      this.supportId,
      modelSubItemSelectValues.length === values.length ? [] : values
    )
  }

  private toggle(value: number, index: number) {
    const modelSubItemSelectValues = this.modelSubItemSelectValues
    if (modelSubItemSelectValues.includes(value)) {
      const deleteIndex = modelSubItemSelectValues.indexOf(value)
      modelSubItemSelectValues.splice(deleteIndex, 1)

      // AlLCheckの存在確認
      const allCheckId = this.getAllCheck(index)
      if (allCheckId && modelSubItemSelectValues.includes(allCheckId)) {
        // ALlCheckがついていればAllCheckを外す
        const deleteIndex = modelSubItemSelectValues.indexOf(allCheckId)
        modelSubItemSelectValues.splice(deleteIndex, 1)
      }
    } else {
      modelSubItemSelectValues.push(value)
      const allCheckId = this.getAllCheck(index)
      if (allCheckId) {
        // allCheckが存在している場合
        if (
          this.isAllCheckedExcludeAllCheckId(
            allCheckId,
            index,
            modelSubItemSelectValues
          )
        ) {
          modelSubItemSelectValues.push(allCheckId)
        }
      }
    }

    this.$emit('onSelectSubItem', this.supportId, modelSubItemSelectValues)
  }

  private subItemSelected(value: number): boolean {
    return this.modelSubItemSelectValues.includes(value)
  }

  private getAllCheck(index: number): number | null {
    const subItem = this.subItems[index] as TSupportButtonSubItemSelect
    const allCheckId = subItem.values.find((v) => v.allCheck)?.id
    return allCheckId || null
  }

  private getSubItemIds(index: number): number[] {
    const subItem = this.subItems[index] as TSupportButtonSubItemSelect
    return subItem.values.map((v) => v.id)
  }

  private isAllCheckedExcludeAllCheckId(
    allCheckId: number,
    index: number,
    modelSubItemSelectValues: number[]
  ): boolean {
    let result = true

    this.getSubItemIds(index).map((v) => {
      if (v !== allCheckId) {
        if (!modelSubItemSelectValues.includes(v)) {
          result = false
          return false
        }
      }
    })

    return result
  }

  private isStatusError(buttonId: number): boolean {
    if (this.errors.includes(this.supportId) && this.modelSelectValue !== buttonId) {
      return true
    }

    return this.errorAllZero && buttonId !== 0
  }

  get isGrey() {
    return this.index % 2 === 0
  }

  get isSubItemError() {
    return this.errors.includes(this.supportId) && !this.disabledSubItems
  }

  get disabledSubItems() {
    return this.modelSelectValue === 0
  }
}
