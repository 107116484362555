import {LockStore} from "~/utils/store-accessor";
import {NuxtAxiosInstance} from "@nuxtjs/axios";

export default class PasswordService {
  private readonly axios: NuxtAxiosInstance;

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * パスワードをリセットする
   * @param email
   */
  async resetPassword(email: string): Promise<boolean>
  {
    LockStore.lock(LockStore.types['message'])
    try {
      let response = await this.axios.post('api/client/password/reset', {
        email,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types['message'])
    }
  }

  /**
   * 認証する
   * @param hash
   */
  async auth(hash: string): Promise<string|undefined>
  {
    LockStore.lock(LockStore.types['message'])
    try {
      let response = await this.axios.post('api/client/password/auth', {
        hash,
      })

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.password));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['message'])
    }
  }
}
