// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cloud_2z_3y{background:#f9f9f9;padding:20px;margin:0 0 30px}.cloudDescription_2U3XJ{font-weight:400;font-size:14px;line-height:21px;color:#2c3846;padding:0 0 30px}.textField_3RFUa{margin:0 0 30px}.textField_3RFUa textarea{height:140px}.boxes_1D67z .box_2ffS1{margin-top:30px}.boxes_1D67z .box_2ffS1:not(:first-child){margin-top:40px}.boxes_1D67z .box_2ffS1 .education_3K2kc{padding:22px 25px;background:#f9f9f9;border-radius:5px}.boxes_1D67z .box_2ffS1 .item_name_2QvoH{font-size:16px;line-height:16px;font-weight:600;color:#0e273c}.boxes_1D67z .box_2ffS1 .description_CI8qE{margin-top:20px;font-size:14px;line-height:14px;color:#2c3846}.boxes_1D67z .box_2ffS1 .items_1TvG4 .career_3MXoG{margin-top:20px}.boxes_1D67z .box_2ffS1 .items_1TvG4 .career_3MXoG .careerItem_3mcE-{padding:20px;background:#f9f9f9;border-radius:5px}.boxes_1D67z .box_2ffS1 .items_1TvG4 .career_3MXoG:not(:first-child){margin-top:40px}.add_32wiC{margin-top:40px}.add_32wiC,.add_32wiC .button_2qGN9{display:flex;justify-content:center}.add_32wiC .button_2qGN9{cursor:pointer;text-decoration:none;align-items:center;width:240px;height:40px;border:1px solid #0e273c;border-radius:30px;font-size:14px;line-height:14px;font-weight:600;letter-spacing:0;color:#0e273c}", ""]);
// Exports
exports.locals = {
	"cloud": "cloud_2z_3y",
	"cloudDescription": "cloudDescription_2U3XJ",
	"textField": "textField_3RFUa",
	"boxes": "boxes_1D67z",
	"box": "box_2ffS1",
	"education": "education_3K2kc",
	"item_name": "item_name_2QvoH",
	"description": "description_CI8qE",
	"items": "items_1TvG4",
	"career": "career_3MXoG",
	"careerItem": "careerItem_3mcE-",
	"add": "add_32wiC",
	"button": "button_2qGN9"
};
module.exports = exports;
