import { render, staticRenderFns } from "./ClientProductModal.vue?vue&type=template&id=0a46bdc0&"
import script from "./ClientProductModal.vue?vue&type=script&lang=ts&"
export * from "./ClientProductModal.vue?vue&type=script&lang=ts&"
import style0 from "./ClientProductModal.vue?vue&type=style&index=0&module=s&lang=scss&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClientProposalLarge: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposalLarge.vue').default,Modal: require('/var/www/kakutoku_connect/nuxt/src/components/Modal.vue').default})
