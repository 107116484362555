// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".template_CO531 .lists_1KmlS div{cursor:pointer;font-size:14px;font-weight:600;line-height:14px;letter-spacing:0;color:#494e50}.template_CO531 .lists_1KmlS div:not(:first-child){margin-top:16px}.template_CO531 .templates_2o8pc{display:none}", ""]);
// Exports
exports.locals = {
	"template": "template_CO531",
	"lists": "lists_1KmlS",
	"templates": "templates_2o8pc"
};
module.exports = exports;
