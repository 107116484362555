import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

type IStaffContact = {
  name: string
  email: string
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'StaffContact',
})
export default class StaffContact extends VuexModule {
  staffContact: IStaffContact | null = null

  @Mutation
  set(staffContact: IStaffContact | null) {
    this.staffContact = staffContact
  }

  get get(): IStaffContact | null {
    return this.staffContact
  }

  get isEmpty(): boolean {
    return !this.staffContact?.name || !this.staffContact.email
  }
}
