



















































































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'
import ClientProductIdIconWrapper from '~/components/Client/ClientProductIdIconWrapper.vue'
import {
  ConnectCompanyProfileCardsStore,
  ConnectCompanyProfilesStore,
  ConnectProposalThreadStore,
} from '~/utils/store-accessor'
import ConnectCompanyProfileCard from '~/components/Client/profile/ConnectCompanyProfileCard'
import { SupportSystemLabel } from '~/components'
import ConnectCompanyProfile from '~/components/Client/profile/ConnectCompanyProfile'
import ClientProposalDetailSupportSystemLabel from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailSupportSystemLabel.vue'
import IConnectDegreeCompany from '~/Interfaces/connectCompany/IConnectDegreeCompany'
import ClientProposalDetailMemo from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailMemo.vue'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: {
    ConnectIcon,
    ClientProposalDetailMemo,
    ClientProposalDetailSupportSystemLabel,
    SupportSystemLabel,
    ClientProductIdIconWrapper,
  },
})
export default class ClientProposalDetailPartnerInfo extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  get partnerNumber(): string {
    return this.$services.proposal.maskPartnerName(this.connectProposal)
  }

  get shouldShowCompanyName(): boolean {
    return !this.$services.proposal.shouldMask(this.connectProposal)
  }

  get isVisibleIcon(): boolean {
    const threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    return !!threadHashId
  }

  get connectCompanyProfile(): ConnectCompanyProfile | undefined {
    return ConnectCompanyProfilesStore.findByCompanyHashId(
      this.connectProposal.companyHashId
    )
  }

  get connectCompanyProfileCard(): ConnectCompanyProfileCard | undefined {
    return ConnectCompanyProfileCardsStore.findByCompanyHashId(
      this.connectProposal.companyHashId
    )
  }

  get firstDegree(): IConnectDegreeCompany | undefined {
    const degreeCompanies = this.connectCompanyProfile?.value.degreeCompanies

    return degreeCompanies ? degreeCompanies[0] : undefined
  }

  get isPrime(): boolean {
    return this.connectCompanyProfile?.value.info?.prime?.isPrime ?? false
  }

  get isVisibleProfileButton(): boolean {
    return this.$services.user.abTestPatternName === 'b'
  }

  private onClickFavorite() {
    // POST権限がある場合にのみバックエンドへ通信し、フロントは見た目だけ変える
    if (!this.$services.user.isRequestLimited) {
      try {
        if (this.connectProposal.isConnectClientFavorited) {
          this.$services.proposal.unfavorite(
            this.connectProposal.connectProposalHashId
          )
        } else {
          this.$services.proposal.favorite(
            this.connectProposal.connectProposalHashId
          )
        }
      } catch (error: any) {
        console.warn(error)
      }
    }

    this.connectProposal.isConnectClientFavorited = !this.connectProposal
      .isConnectClientFavorited
  }
}
