// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".enter_active_1d5Rc{transition:opacity .3s}.leave_active_3y4FG{opacity:0}.layout_2__g3{position:fixed;height:100%;width:100%;z-index:20;background:rgba(14,39,60,.30196)}.layout_2__g3 .snack_bar_2ebX_{position:fixed;display:flex;justify-content:center;align-items:center;top:50vh;bottom:50vh;left:0;margin:auto 0;width:100vw;height:80px;background:#d2e7fc;font-size:24px;font-weight:600;letter-spacing:0;color:#0e273c}", ""]);
// Exports
exports.locals = {
	"enter_active": "enter_active_1d5Rc",
	"leave_active": "leave_active_3y4FG",
	"layout": "layout_2__g3",
	"snack_bar": "snack_bar_2ebX_"
};
module.exports = exports;
