import { IConnectProposal, IProduct } from '~/Interfaces'

const mapToClientConnectProposals = (data: any): IConnectProposal[] => {
  if (!data.connectProposals) return []

  return data.connectProposals.map(
    (proposal: IConnectProposal, index: number) => {
      return {
        ...proposal,
        connectProposalIndex: index + 1,
      }
    }
  )
}

export const mapToClientProduct = (data: any): IProduct => {
  return {
    ...data,
    connectProposals: mapToClientConnectProposals(data),
  }
}
