







import { Component, Vue } from 'nuxt-property-decorator'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: { ConnectIcon },
})
export default class ClientProposalListItemMessageIcon extends Vue {}
