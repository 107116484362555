// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_Zeqra{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.container_Zeqra .header_Zfwek{display:flex;justify-content:space-between;align-items:center}.container_Zeqra .header_Zfwek .left_214iP{display:flex;align-items:center;grid-gap:5px;gap:5px;width:100%;height:30px}.container_Zeqra .header_Zfwek .left_214iP .icon_1zO8O{display:flex;align-items:center;justify-content:center;width:20px;height:20px}.container_Zeqra .header_Zfwek .left_214iP .text_xFt0Q{font-size:14px;font-weight:600;font-family:\"Hiragino Kaku Gothic Pro\";color:#454545}.container_Zeqra .header_Zfwek .right_-hkRa{display:flex;align-items:center;grid-gap:5px;gap:5px}.container_Zeqra .header_Zfwek .right_-hkRa .button_10AWK{cursor:pointer;display:flex;align-items:center;justify-content:center;width:54px;height:30px;font-size:12px;font-weight:600;color:#555}.container_Zeqra .header_Zfwek .right_-hkRa .button_10AWK.save_2nbuG{border-radius:100px;background:#e3eef7}.container_Zeqra .textarea_2m9O0{resize:vertical;padding:10px;height:300px;width:100%;border:1px solid grey;border-radius:2px;background:#fafafa;color:#454545;font-size:14px;font-weight:400}.container_Zeqra .textarea_2m9O0::-moz-placeholder{color:grey}.container_Zeqra .textarea_2m9O0:-ms-input-placeholder{color:grey}.container_Zeqra .textarea_2m9O0::placeholder{color:grey}.container_Zeqra .textarea_2m9O0:hover{background:#f3f2f2}.container_Zeqra .textarea_2m9O0:focus{border-width:2px;border-color:#5f9cd3;background:#fff}", ""]);
// Exports
exports.locals = {
	"container": "container_Zeqra",
	"header": "header_Zfwek",
	"left": "left_214iP",
	"icon": "icon_1zO8O",
	"text": "text_xFt0Q",
	"right": "right_-hkRa",
	"button": "button_10AWK",
	"save": "save_2nbuG",
	"textarea": "textarea_2m9O0"
};
module.exports = exports;
