






import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class EmptyStateText extends Vue {
  @Prop()
  private text!: string

  get emptyText() {
    if (!this.text) {
      return '内容がありません'
    }

    return this.text
  }
}
