// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textField_27lCs{margin-bottom:40px}.textField_27lCs.mb20_2cZ86{margin-bottom:20px}.textField_27lCs.flex_w6cHY{display:flex;justify-content:flex-start;align-items:flex-start;grid-gap:0 20px;gap:0 20px}.textField_27lCs.border_BP_hd{padding-bottom:40px;border-bottom:1px solid #c8c7c6}.textField_27lCs .my10_to1W0{margin:10px 0}.textField_27lCs.last_4elGy{margin-bottom:80px}.subTitle_1rneh{color:#454545;font-size:14px;font-weight:600;line-height:21px;letter-spacing:0;text-align:left;margin:0 0 10px}.other_emails_2CEkt{font-size:14px;font-style:normal;font-weight:400;line-height:normal}.field_3a93U{margin-top:10px}.field_3a93U:not(:first-child){margin-top:20px}.field_3a93U input{width:360px}.field_3a93U input.w100percent_1aXGO{width:100%}.field_3a93U .email_FgX7o{display:flex;align-items:center;position:relative}.field_3a93U .email_FgX7o .delete_JxxuU{position:absolute;top:5px;left:400px;padding:0;border:none;outline:none;cursor:pointer;display:flex;align-items:center;justify-content:center;width:30px;height:30px;border-radius:5px;font-size:16px;color:#969492;background:transparent}.field_3a93U .email_FgX7o .delete_JxxuU.disabled_2MV0P{pointer-events:none}.field_3a93U .email_FgX7o .delete_JxxuU:hover{color:#3379b7;background:#e3eef7}.add_mail_1vEWt{display:flex;justify-content:flex-start;width:360px;margin-top:20px;margin-bottom:40px}.add_mail_1vEWt.w100percent_1aXGO{width:100%}.add_mail_1vEWt .add_mail_button_2ugH-{cursor:pointer;display:flex;align-items:center;justify-content:center;width:100px;height:24px;border:1px solid #afadac;border-radius:30px;background:#fff;color:#555;font-size:12px;font-weight:600;line-height:18px;letter-spacing:0;text-align:center}.add_mail_1vEWt .add_mail_button_2ugH-.disabled_2MV0P{pointer-events:none}.billingEmailNotSetError_3e8JN{color:#c94282;font-size:12px;font-style:normal;font-weight:400;line-height:normal;margin:0 0 20px}", ""]);
// Exports
exports.locals = {
	"textField": "textField_27lCs",
	"mb20": "mb20_2cZ86",
	"flex": "flex_w6cHY",
	"border": "border_BP_hd",
	"my10": "my10_to1W0",
	"last": "last_4elGy",
	"subTitle": "subTitle_1rneh",
	"other_emails": "other_emails_2CEkt",
	"field": "field_3a93U",
	"w100percent": "w100percent_1aXGO",
	"email": "email_FgX7o",
	"delete": "delete_JxxuU",
	"disabled": "disabled_2MV0P",
	"add_mail": "add_mail_1vEWt",
	"add_mail_button": "add_mail_button_2ugH-",
	"billingEmailNotSetError": "billingEmailNotSetError_3e8JN"
};
module.exports = exports;
