import { Plugin } from '@nuxt/types'
import AuthService from '../components/Services/AuthService'
import WindowService from '~/components/Services/WindowService'
import ProductService from '~/components/Services/ProductService'
import ProposalService from '~/components/Services/ProposalService'
import DayService from '~/components/Services/DayService'
import PageService from '~/components/Services/PageService'
import UserService from '~/components/Services/UserService'
import ClientProductService from '~/components/Services/ClientProductService'
import MessageService from '~/components/Services/MessageService'
import PasswordService from '~/components/Services/PasswordService'
import ActivateService from '~/components/Services/ActivateService'
import CompanyService from '~/components/Services/CompanyService'
import QuotationService from '~/components/Services/QuotationService'
import GoogleAuthService from '~/components/Services/GoogleAuthService'
import HearingSheetService from '~/components/Services/HearingSheetService'
import ContractService from '~/components/Services/ContractService'
import PurchaseOrderListService from '~/components/Services/PurchaseOrderListService'
import ClientService from '~/components/Services/ClientService'
import TermService from '~/components/Services/TermService'
import LogService from '~/components/Services/LogService'
import CompanyMemberService from '~/components/Services/CompanyMemberService'
import StorageService from '~/components/Services/StorageService'
import ReferenceCheckService from '~/components/Services/ReferenceCheckService'
import CompanyFulltextService from '~/components/Services/CompanyFulltextService'
import ClientContactService from '~/components/Services/ClientContactService'
import ClientBillingService from '~/components/Services/ClientBillingService'
import ConnectProposalReadService from '~/components/Services/ConnectProposalReadService'
import AdminService from '~/components/Services/AdminService'
import ConnectRenewalService from '~/components/Services/ConnectRenewalService'
import ConnectProposalDetailService from '~/components/Services/ConnectProposalDetailService'
import ConnectClientProposalMemoService from '~/components/Services/ConnectClientProposalMemoService'
import ConnectCompanyProfileService from '~/components/Services/ConnectCompanyProfileService'
import HearingSheetV2Service from '~/components/Services/HearingSheetV2Service'
import PreHearingSheetV2Service from '~/components/Services/PreHearingSheetV2Service'
import ConnectCompanySupportAchievementService from '~/components/Services/ConnectCompanySupportAchievementService'
import ConnectCompanySupportAchievementReviewService from '~/components/Services/ConnectCompanySupportAchievementReviewService'
import ConnectProductV2Service from '~/components/Services/ConnectProductV2Service'
import ConnectCompanyProfileValidatorService from '~/components/Services/ConnectCompanyProfileValidatorService'
import ClientPartnerProfileService from '~/components/Services/ClientPartnerProfileService'
import ConnectClientSupportAchievementService from '~/components/Services/ConnectClientSupportAchievementService'
import ConnectInvoiceService from '~/components/Services/ConnectInvoiceService'
import ProposalDecisionReasonService from '~/components/Services/ProposalDecisionReasonService'
import CaseInterviewService from '~/components/Services/CaseInterviewService'
import ConnectProductUnlockService from '~/components/Services/ConnectProductUnlockService'
import ConnectClientSurveyService from '~/components/Services/ConnectClientSurveyService'
import ConnectTroubleReportService from '~/components/Services/ConnectTroubleReportService'

export interface IServices {
  auth: AuthService
  googleAuth: GoogleAuthService
  window: WindowService
  day: DayService
  user: UserService
  page: PageService
  log: LogService

  product: ProductService
  proposal: ProposalService
  client_product: ClientProductService
  message: MessageService
  password: PasswordService
  activate: ActivateService
  company: CompanyService
  quotation: QuotationService
  hearingSheet: HearingSheetService
  hearingSheetV2: HearingSheetV2Service
  preHearingSheetV2: PreHearingSheetV2Service
  contract: ContractService
  purchase_order_list: PurchaseOrderListService
  client: ClientService
  term: TermService
  companyMember: CompanyMemberService
  storage: StorageService
  referenceCheck: ReferenceCheckService
  companyFulltextService: CompanyFulltextService
  connectClientContact: ClientContactService
  connectClientBilling: ClientBillingService
  connectProposalRead: ConnectProposalReadService
  admin: AdminService
  connectRenewal: ConnectRenewalService
  connectProposalDetail: ConnectProposalDetailService
  connectClientProposalMemo: ConnectClientProposalMemoService
  connectCompanyProfile: ConnectCompanyProfileService
  connectSupportAchievement: ConnectCompanySupportAchievementService
  connectClientSupportAchievement: ConnectClientSupportAchievementService
  connectSupportAchievementReview: ConnectCompanySupportAchievementReviewService
  connectProductV2: ConnectProductV2Service
  connectCompanyProfileValidator: ConnectCompanyProfileValidatorService
  clientPartnerProfile: ClientPartnerProfileService
  connectInvoiceService: ConnectInvoiceService
  proposalDecisionReason: ProposalDecisionReasonService
  caseInterviewService: CaseInterviewService
  connectProductUnlockService: ConnectProductUnlockService
  connectClientSurveyService: ConnectClientSurveyService
  connectTroubleReportService: ConnectTroubleReportService
}

export interface IAdmin {
  isAdminUser: () => boolean
  getAdminHashId: () => string | null
  getConnectClientHashId: () => string | null
  getCompanyHashId: () => string | null
}

declare module 'vue/types/vue' {
  interface Vue {
    readonly $services: IServices
  }
}

declare module '@nuxt/types' {
  interface Context {
    readonly $services: IServices
    readonly $admin: IAdmin
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    readonly $services: IServices
  }
}

const servicesPlugin: Plugin = (context, inject) => {
  const services = {
    auth: new AuthService(context.$auth),
    googleAuth: new GoogleAuthService(context),
    window: new WindowService(),
    // @ts-ignore
    day: new DayService(context.$dayjs),
    user: new UserService(context.$auth),
    page: new PageService(undefined),
    log: new LogService(context),
    storage: new StorageService(),

    product: new ProductService(context.$axios),
    proposal: new ProposalService(context.$axios),
    client_product: new ClientProductService(context.$axios),
    message: new MessageService(context.$axios),
    password: new PasswordService(context.$axios),
    activate: new ActivateService(context.$axios),
    company: new CompanyService(context),
    quotation: new QuotationService(context.$axios),
    hearingSheet: new HearingSheetService(context),
    hearingSheetV2: new HearingSheetV2Service(context),
    preHearingSheetV2: new PreHearingSheetV2Service(context),
    contract: new ContractService(context.$axios),
    purchase_order_list: new PurchaseOrderListService(context.$axios),
    client: new ClientService(context.$axios, context.$auth),
    term: new TermService(context.$axios),
    companyMember: new CompanyMemberService(context.$axios),
    referenceCheck: new ReferenceCheckService(context.$axios),
    companyFulltextService: new CompanyFulltextService(),
    connectClientContact: new ClientContactService(context.$axios),
    connectClientBilling: new ClientBillingService(context.$axios),
    connectProposalRead: new ConnectProposalReadService(
      context.$axios,
      context.$auth
    ),
    admin: new AdminService(context),
    connectRenewal: new ConnectRenewalService(context.$axios),
    connectProposalDetail: new ConnectProposalDetailService(context.$axios),
    connectClientProposalMemo: new ConnectClientProposalMemoService(
      context.$axios
    ),
    connectCompanyProfile: new ConnectCompanyProfileService(context.$axios),
    connectSupportAchievement: new ConnectCompanySupportAchievementService(
      context.$axios
    ),
    connectClientSupportAchievement: new ConnectClientSupportAchievementService(
      context.$axios
    ),
    connectSupportAchievementReview: new ConnectCompanySupportAchievementReviewService(
      context.$axios
    ),
    connectProductV2: new ConnectProductV2Service(context),
    connectCompanyProfileValidator: new ConnectCompanyProfileValidatorService(
      context
    ),
    clientPartnerProfile: new ClientPartnerProfileService(context.$axios),
    connectInvoiceService: new ConnectInvoiceService(context.$axios),
    proposalDecisionReason: new ProposalDecisionReasonService(context.$axios),
    caseInterviewService: new CaseInterviewService(context.$axios),
    connectProductUnlockService: new ConnectProductUnlockService(
      context.$axios
    ),
    connectClientSurveyService: new ConnectClientSurveyService(context.$axios),
    connectTroubleReportService: new ConnectTroubleReportService(
      context.$axios
    ),
  }
  inject('services', services)
}

export default servicesPlugin
