import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IPurchaseOrder from '../Interfaces/purchaseOrder/IPurchaseOrder'

@Module({ stateFactory: true, namespaced: true, name: 'PurchaseOrdersDefine' })
export default class PurchaseOrdersDefine extends VuexModule {
  purchaseOrders: IPurchaseOrder[] = []

  @Mutation
  setPurchaseOrders(purchaseOrders: IPurchaseOrder[]) {
    if (purchaseOrders === undefined) {
      return
    }

    this.purchaseOrders = purchaseOrders
  }

  @Mutation
  clear() {
    this.purchaseOrders = []
  }

  get getPurchaseOrders(): IPurchaseOrder[] {
    return this.purchaseOrders
  }
}
