


















































































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import ConnectClientContact from '../../../../Client/ConnectClientContact'
import ConnectClientProfile from '../../../../Client/ConnectClientProfile'
import { PREFECTURES } from '~/utils'
import ConnectV2Form from '~/components/common/components/v2/ConnectV2Form.vue'
import ConnectV2Checkbox from '~/components/common/components/v2/ConnectV2Checkbox.vue'
import ConnectV2Dropdown from '~/components/common/components/v2/ConnectV2Dropdown.vue'
import SupportAttention from '~/components/common/profile/partner/SupportAttention/index.vue'
import ConnectButton from '~/components/common/components/ConnectButton.vue'
import ConnectV2InputLabel from '~/components/common/components/v2/ConnectV2InputLabel.vue'
import ConnectV2Input from '~/components/common/components/v2/ConnectV2Input.vue'
import ClientTitle from '~/components/common/profile/client/client-title.vue'
import ClientSubTitle from '~/components/common/profile/client/client-sub-title.vue'
import { FormValidator, Rule } from '~/components'

@Component({
  components: {
    ConnectV2Form,
    ConnectV2Checkbox,
    SupportAttention,
    ConnectV2Dropdown,
    ConnectButton,
    ConnectV2InputLabel,
    ConnectV2Input,
    ClientTitle,
    ClientSubTitle,
  },
})
export default class CompanyForm extends Vue {
  @Prop()
  private connectClientContact!: ConnectClientContact

  @Prop()
  private connectClientProfile!: ConnectClientProfile

  private connectClientContactTelDisabled: boolean = false

  private contactRules: Rule[] = []

  mounted() {
    this.toggleIsOpenContactTelChange()
  }

  get prefectures() {
    const list = PREFECTURES.map((prefecture) => ({
      label: prefecture,
      value: prefecture,
    }))

    list.unshift({ label: '選択してください', value: '' })

    return list
  }

  get disabled(): boolean {
    return !this.$services.user.getConnectClientAccountRollIsAdmin()
  }

  private onIsOpenContactTel(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target) {
      return
    }

    this.connectClientContact.value.isOpenContactTel = target.checked
    this.toggleIsOpenContactTelChange()
  }

  private toggleIsOpenContactTelChange() {
    const formValidator = FormValidator.get()
    this.connectClientContactTelDisabled = !this.connectClientContact.value
      .isOpenContactTel
    if (this.connectClientContactTelDisabled) {
      this.contactRules = []
      this.connectClientContact.value.connectClientContactTel = ''
    } else {
      this.contactRules = ['required', 'max:11']
    }

    formValidator.updateFormRule(
      'connectClientContactTel',
      this.contactRules as Rule[]
    )
  }
}
