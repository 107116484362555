


































import { Component, Vue } from 'nuxt-property-decorator'

import { UploadFileStore } from '~/utils/store-accessor'

@Component
export default class ThreadFormFileUpload extends Vue {
  mounted() {}

  private closeFileUpload() {
    UploadFileStore.setIsOpen(false)
  }

  private clickInput() {
    const input = this.$refs.input as HTMLInputElement
    input.click()
  }

  private dropFile(e: DragEvent) {
    if (!e.dataTransfer) {
      return
    }

    const file = e.dataTransfer.files[0]
    if (!file) {
      alert('ご指定のファイルの認識が出来ないため、添付できませんでした。')

      return
    }

    if (file.size > 1500000) {
      alert(
        'ご指定のファイルは1MB以上のデータでしたので、ファイルが添付できませんでした。'
      )
      return
    }

    this.setFile(file)
    this.closeFileUpload()
  }

  private inputFile(e: Event) {
    const target = e.target as any

    const file = target.files[0] as File

    this.setFile(file)
    this.closeFileUpload()
  }

  private setFile(file: File) {
    UploadFileStore.setFile(file)
  }
}
