import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  IConnectCompany,
  IConnectCompanyConstants,
  IConnectCompanyPreferredCustomerOrProduct,
  IConnectCompanyPreferredCustomerOrProducts,
  IConnectCompanyProfile,
  IConnectCompanyProfileCard,
  IConnectCompanySupport,
  IConnectCompanySupports,
  IConnectCompanySupportSystem,
  TConnectDegreeCompanies,
} from '~/Interfaces'
import { TConnectCompanySupportAchievementReviewContent } from '~/Interfaces/connectCompany/IConnectCompanySupportAchievementReview'
import IConnectDegreeCompany from '~/Interfaces/connectCompany/IConnectDegreeCompany'
import { IThreadHashId } from '~/Interfaces/clientProduct/IThreadHashId'

export default class ClientPartnerProfileService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async getProfileCards(
    connectProductHashId: string
  ): Promise<IConnectCompanyProfileCard[]> {
    const [
      infos,
      supports,
      preferredCustomerOrProducts,
      reviews,
      degreeCompanies,
      supportSystems,
    ] = await Promise.all([
      this.getInfos(connectProductHashId),
      this.getSupports(connectProductHashId),
      this.getPreferredCustomerOrProducts(connectProductHashId),
      this.getReview(connectProductHashId),
      this.getDegreeCompanies(connectProductHashId),
      this.getSupportSystems(connectProductHashId),
    ])

    return infos.map((info: IConnectCompany) => {
      const filteredSupports = supports.filter(
        (support: IConnectCompanySupport) =>
          support.connectCompanyHashId === info.connectCompanyHashId
      )
      const filteredPreferredCustomerOrProducts = preferredCustomerOrProducts.filter(
        (
          preferredCustomerOrProduct: IConnectCompanyPreferredCustomerOrProduct
        ) =>
          preferredCustomerOrProduct.connectCompanyHashId ===
          info.connectCompanyHashId
      )
      const filteredReview = reviews.filter(
        (review: TConnectCompanySupportAchievementReviewContent) =>
          review?.connectCompanyHashId === info.connectCompanyHashId
      )
      const filteredDegreeCompanies = degreeCompanies.filter(
        (degreeCompany: IConnectDegreeCompany) =>
          degreeCompany.connectCompanyHashId === info.connectCompanyHashId
      )

      const foundSupportSystem = supportSystems?.find(
        (supportSystem) =>
          supportSystem.connectCompanyHashId === info.connectCompanyHashId
      )

      return {
        companyHashId: info.companyHashId,
        connectCompanyHashId: info.connectCompanyHashId,
        info,
        supports: filteredSupports,
        preferredCustomerOrProducts: filteredPreferredCustomerOrProducts,
        review: filteredReview,
        degreeCompanies: filteredDegreeCompanies,
        supportSystem: foundSupportSystem,
      }
    })
  }

  async getInfos(connectProductHashId: string): Promise<IConnectCompany[]> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/info/' + connectProductHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    }
  }

  async getSupports(
    connectProductHashId: string
  ): Promise<IConnectCompanySupports> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/supports/' + connectProductHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    }
  }

  async getPreferredCustomerOrProducts(
    connectProductHashId: string
  ): Promise<IConnectCompanyPreferredCustomerOrProducts> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/preferred_customer_or_products/' +
          connectProductHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    }
  }

  async getReview(
    connectProductHashId: string
  ): Promise<TConnectCompanySupportAchievementReviewContent[]> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/review/' + connectProductHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    }
  }

  async getDegreeCompanies(
    connectProductHashId: string
  ): Promise<TConnectDegreeCompanies> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/degree_companies/' + connectProductHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    }
  }

  async getProfile(
    connectCompanyHashId: string
  ): Promise<IConnectCompanyProfile | undefined> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/profile/' + connectCompanyHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    }
  }

  async getConstants(): Promise<IConnectCompanyConstants | undefined> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/constants'
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.connectCompanyConstants))
    } catch (err) {
      console.log(err)
      return undefined
    }
  }

  async getThreadHashIds(
    connectProductHashId: string
  ): Promise<IThreadHashId[]> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/thread_hash_ids/' + connectProductHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.threadHashIds))
    } catch (err) {
      console.log(err)
      return []
    }
  }

  async getSupportSystems(
    connectProductHashId: string
  ): Promise<IConnectCompanySupportSystem[] | undefined> {
    try {
      const response = await this.axios.get(
        'api/client/partner_profile/support_systems/' + connectProductHashId
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    }
  }
}
