































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IAttachment } from '~/Interfaces'
import ConnectAttachmentThumbnail from '~/components/common/components/ConnectAttachmentThumbnail.vue'
import { CurrentConnectProposalStore } from '~/utils/store-accessor'
import ProposalConfirmProposalDocumentAgreementModal from '~/components/Proposal/proposal-confirm-proposal-document-agreement-modal.vue'

@Component({
  components: {
    ProposalConfirmProposalDocumentAgreementModal,
    ConnectAttachmentThumbnail,
  },
})
export default class ThreadMessageAttachment extends Vue {
  @Prop()
  private attachment!: IAttachment

  private isProposalDocumentAgreementModalOpen = false

  private onProposalDocumentAgreementModalOk = () => {}

  mounted() {}

  private openProposalDocumentAgreementModal() {
    this.isProposalDocumentAgreementModalOpen = true
  }

  private closeProposalDocumentAgreementModal() {
    this.isProposalDocumentAgreementModalOpen = false
  }

  private openProposalDocument(attachment: IAttachment) {
    if (!this.proposal) {
      console.error('proposal is not found')
      return
    }
    if (!this.proposal.isAgreedProposalDocumentAgreement) {
      this.onProposalDocumentAgreementModalOk = () => {
        this.openProposalDocument(attachment)
      }
      this.openProposalDocumentAgreementModal()
      return
    }
    window.open(attachment.threadDetailAttachment as string)
  }

  get proposal() {
    return CurrentConnectProposalStore.getCurrentConnectProposal
  }
}
