







































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'

import { IConnectProposal } from '~/Interfaces'
import {
  ConnectClientProposalMemoStore,
  ConnectContractRenewalStore,
  CurrentConnectProposalStore,
  ThreadDetailsStore,
} from '~/utils/store-accessor'
import SnackBar from '~/components/SnackBar.vue'
import ClientProposalDetailModal from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailModal.vue'
import ClientQuestionnaireModal from '~/components/Client/ClientProposal/ClientQuestionnaireModal/ClientQuestionnaireModal.vue'

@Component({
  components: {
    ClientQuestionnaireModal,
    ClientProposalDetailModal,
    SnackBar,
  },
})
export default class ClientProposalDetailVersion2 extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop()
  private isLoading!: boolean

  private async close() {
    if (this.isLoading) {
      return
    }

    if (ConnectClientProposalMemoStore.isOpenEditor) {
      if (
        !confirm(
          '入力内容が保存されない可能性があります。本当にメモを閉じますか？'
        )
      ) {
        return false
      }
    }

    const modal = this.$refs.modal as ClientProposalDetailModal
    modal.disappearScrollButton()

    await this.$nextTick(() => {
      ConnectClientProposalMemoStore.closeAll()

      CurrentConnectProposalStore.setCurrentConnectProposal(null)

      ThreadDetailsStore.clearThreadDetails()

      this.$router.push({
        query: {},
      })
    })
  }

  @Watch('connectProposal')
  private async loadRenewals() {
    if (!this.connectProposal?.connectProposalHashId) return

    const connectRenewal = await this.$services.connectRenewal.find(
      this.connectProposal?.connectProposalHashId!,
      true
    )
    if (connectRenewal?.connectContractRenewals) {
      const connectContractRenewals = Object.values(
        connectRenewal?.connectContractRenewals!
      )

      ConnectContractRenewalStore.set(connectContractRenewals)
    }
  }

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}
}
