




















































import { Component, Vue } from 'nuxt-property-decorator'
import { StaffContactStore } from '~/utils/store-accessor'

@Component
export default class ClientEmailBot extends Vue {
  private isVisiblePopup: boolean = false

  mounted() {
    this.init()
  }

  private async init() {
    if (this.$services.user.isClient() && StaffContactStore.isEmpty) {
      const product = await this.$services.client.getConnectStaffInfo()
      if (!product) return

      StaffContactStore.set({
        name: product?.connectProductStaffName,
        email: product?.connectProductStaffEmail,
      })
    }
  }

  private onClick() {
    this.isVisiblePopup = true
  }

  private onClose() {
    this.isVisiblePopup = false
  }

  get name(): string {
    return StaffContactStore.get?.name ?? ''
  }

  get email(): string {
    return StaffContactStore.get?.email ?? ''
  }
}
