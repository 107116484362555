import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export interface ISnackbar {
  name: string
  is_display: boolean
}

@Module({ stateFactory: true, namespaced: true, name: 'Snackbar' })
export default class Snackbar extends VuexModule {
  snackbars: ISnackbar[] = []

  @Mutation
  show(name: string) {
    const index = this.snackbars.findIndex((snackbar) => snackbar.name === name)
    if (index < 0) {
      this.snackbars.push({ name, is_display: true })

      return
    }

    this.snackbars[index].is_display = true
  }

  @Mutation
  hide(name: string) {
    const index = this.snackbars.findIndex((snackbar) => snackbar.name === name)
    if (index === undefined) {
      return
    }

    this.snackbars[index].is_display = false
  }

  @Mutation
  hideAll() {
    this.snackbars.forEach((snackbar) => (snackbar.is_display = false))
  }

  get shouldDisplay() {
    const self = this

    return (name: string) => {
      const snackbar = self.snackbars.find((snackbar) => snackbar.name === name)

      return snackbar?.is_display ?? false
    }
  }
}
