import { NuxtAxiosInstance } from '@nuxtjs/axios'
import IConnectClient from '~/Interfaces/IConnectClient'
import IConnectReferenceCheck, {
    ConnectReferenceCheckErrorResponse,
    IConnectReferenceCheckPayload,
} from '~/Interfaces/IConnectReferenceCheck'
import {LockStore} from '~/utils/store-accessor'

export default class ReferenceCheckService {
  private readonly axios: NuxtAxiosInstance

    constructor(axios: NuxtAxiosInstance) {
        this.axios = axios
    }

  /*
   * 稼働中の提案を取得する
   */
  async findWorking(): Promise<IConnectClient[] | undefined> {
      LockStore.lock(LockStore.types.reference_check)
      try {
          const response = await this.axios.get('api/company/proposal/find_working')

          // console.log(response)

          return JSON.parse(JSON.stringify(response.data))
      } catch (err) {
          console.log(err)
      } finally {
          LockStore.unlock(LockStore.types.reference_check)
      }
  }

  /**
   * リファレンスチェック取得
   */
  async get(): Promise<IConnectReferenceCheck[] | undefined> {
      LockStore.lock(LockStore.types.reference_check)
      try {
          const response = await this.axios.get('api/company/reference_check/get')

          // console.log(response)

          return JSON.parse(JSON.stringify(response.data))
      } catch (err) {
          console.log(err)
      } finally {
          LockStore.unlock(LockStore.types.reference_check)
      }
  }

  /**
   * 公開されたリファレンスチェック取得
   * @param isClient
   * @param companyHashId
   */
  async getReleased(
    isClient: boolean,
    companyHashId?: string
  ): Promise<IConnectReferenceCheck[] | undefined> {
      LockStore.lock(LockStore.types.reference_check)
    try {
      let response
      if (isClient) {
        response = await this.axios.get(
          `api/client/reference_check/get/${companyHashId}`
        )
      } else {
          response = await this.axios.get(
              'api/company/reference_check/get_released'
          )
      }

        // console.log(response)

        return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
        console.log(err)
    } finally {
        LockStore.unlock(LockStore.types.reference_check)
    }
  }

    /**
     * リファレンスチェック依頼
     *
     * @param payload
     */
    async request(
        payload: IConnectReferenceCheckPayload
    ): Promise<IConnectReferenceCheck | ConnectReferenceCheckErrorResponse> {
        LockStore.lock(LockStore.types.reference_check)
        try {
            const response = await this.axios.post(
                'api/company/reference_check/confirm',
                {
                    ...payload,
                }
            )

            // console.log(response)

            return JSON.parse(JSON.stringify(response.data))
        } catch (err: any) {
            const response = err.response
            if (response && response.status === 409) {
                return JSON.parse(JSON.stringify(response.data))
            }
            console.log(err)
        } finally {
            LockStore.unlock(LockStore.types.reference_check)
        }
  }

  /**
   * リファレンス承認
   * @param connectReferenceHashId
   */
  async application(
    connectReferenceHashId: string
  ): Promise<IConnectReferenceCheck | undefined> {
      LockStore.lock(LockStore.types.reference_check)
      try {
          const response = await this.axios.post(
              `api/company/reference_check/application/${connectReferenceHashId}`
          )

          // console.log(response)

          return JSON.parse(JSON.stringify(response.data))
      } catch (err) {
          console.log(err)
      } finally {
          LockStore.unlock(LockStore.types.reference_check)
      }
  }

  /**
   * 評価依頼メール再送信
   * @param connectReferenceHashId
   */
  async resend(
    connectReferenceHashId: string
  ): Promise<IConnectReferenceCheck | undefined> {
    LockStore.lock(LockStore.types.reference_check)
    try {
      const response = await this.axios.post(
        `api/company/reference_check/resend/${connectReferenceHashId}`
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.reference_check)
    }
  }

  /**
   * リファレンス否認
   * @param connectReferenceHashId
   */
  async private(
    connectReferenceHashId: string
  ): Promise<IConnectReferenceCheck | undefined> {
      try {
          const response = await this.axios.post(
              `api/company/reference_check/private/${connectReferenceHashId}`
          )

          // console.log(response)

          return JSON.parse(JSON.stringify(response.data))
      } catch (err) {
          console.log(err)
      } finally {
          LockStore.unlock(LockStore.types.reference_check)
      }
  }
}
