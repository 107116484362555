


















import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: { ConnectIcon },
})
export default class ConnectButton extends Vue {
  @Prop()
  private text!: string

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: null })
  private iconType!:
    | 'star'
    | 'alert'
    | 'info'
    | 'announcement'
    | 'loading'
    | 'outline_lightbulb'
    | 'pencil_box'
    | 'help'
    | 'trash_box'
    | 'new_tab'
    | 'dot'
    | 'right_arrow'

  @Prop({ default: null })
  private iconPosition!: 'left' | 'right'

  @Emit('click')
  private onClick() {}
}
