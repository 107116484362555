



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, PropSync } from 'nuxt-property-decorator'

@Component({})
export default class ConnectV2Radio extends Vue {
  @Prop()
  private label!: string

  @Prop()
  private value!: string | number

  @Prop()
  private name!: string

  @Prop()
  private error!: boolean

  @Prop()
  private required!: boolean

  @PropSync('modelValue')
  private model!: string | number | null

  get componentId(): string {
    return Math.random().toString(32).substring(2)
  }

  @Emit('change')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange(event: Event) {}
}
