
















































































































































































































































































































































































































































































import { Component, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import {
  ConnectCheckbox,
  ConnectInput,
  ConnectInputLabel,
  PartnerSubtitle,
  SupportAttention,
} from '~/components/common'
import SupportItemButton from '~/components/common/profile/partner/SupportItemButton/index.vue'
import {
  ConstantValue,
  IConnectCompanyPreferredCustomerOrProduct,
  IConnectCompanyPreferredCustomerOrProducts,
  IConnectCompanySupport,
  IConnectCompanySupports,
  SupportItemButtonStatus,
} from '~/Interfaces'
import SupportItemInspector from '~/components/Profile/ConnectCompany/Support/SupportItemInspector'
import IConnectCompanyMemberSupport from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberSupport'
import IConnectCompanyMemberPreferredCustomerOrProduct from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberPreferredCustomerOrProduct'

@Component({
  components: {
    SupportAttention,
    ConnectInput,
    PartnerSubtitle,
    ConnectInputLabel,
    SupportItemButton,
    ConnectCheckbox,
  },
})
export default class SupportItemsEdit extends Vue {
  @Prop()
  private constSupports!: ConstantValue[]

  @Prop()
  private constPreferredCustomerOrProducts!: ConstantValue[]

  @PropSync('supports')
  private privateSupports!:
    | IConnectCompanySupports
    | IConnectCompanyMemberSupport[]

  @PropSync('preferredCustomerOrProducts')
  private privatePreferredCustomerOrProducts!:
    | IConnectCompanyPreferredCustomerOrProducts
    | IConnectCompanyMemberPreferredCustomerOrProduct[]

  @PropSync('preferred0Others')
  private privatePreferred0Others!: string | null

  @PropSync('preferred3Others')
  private privatePreferred3Others!: string | null

  @PropSync('preferredCount')
  private privatePreferredCount!: number

  @PropSync('errors')
  private privateErrors!: {
    supports: boolean[]
    preferredCount: boolean
    preferred0Others: boolean
    preferred3Others: boolean
  }

  // support/preferred項目の共通操作ヘルパー
  get supportItemInspector() {
    return new SupportItemInspector(
      this.constSupports,
      this.constPreferredCustomerOrProducts,
      this.privateSupports,
      this.privatePreferredCustomerOrProducts,
      this.privatePreferred0Others,
      this.privatePreferred3Others,
      this.privatePreferredCount
    )
  }

  private updateSupport(
    id: number,
    status: SupportItemButtonStatus,
    areas: number[],
    etc: string | null
  ) {
    if (status === undefined) return
    const idx = this.privateSupports.findIndex(
      (s: IConnectCompanySupport | IConnectCompanyMemberSupport) =>
        s.supportId === id
    )

    this.privateErrors.supports[id] = false
    // ;(this as any).forceEdit()

    const support = {
      supportId: id,
      supportStatus: status,
      supportAreas: areas,
      supportEtc: etc,
    } as IConnectCompanySupport

    if (idx === -1) {
      this.$set(this.privateSupports, this.privateSupports.length, support)
    } else {
      this.$set(this.privateSupports, idx, support)
    }
    this.updatePreferredCount()
  }

  private updatePreferred(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target) return

    const categoryId = Number(target.dataset.groupId)
    const value = Number(target.dataset.value)

    const idx = this.privatePreferredCustomerOrProducts.findIndex(
      (p) => p.categoryId === categoryId
    )

    if (idx === -1) {
      const preferredCustomerOrProduct = {
        categoryId,
        preferredValues: [value],
      } as IConnectCompanyPreferredCustomerOrProduct
      this.privatePreferredCustomerOrProducts.push(preferredCustomerOrProduct)
    } else {
      const current = this.privatePreferredCustomerOrProducts[idx]
      this.privatePreferredCustomerOrProducts[idx] = {
        ...current,
        preferredValues: current.preferredValues.includes(value)
          ? current.preferredValues.filter((v) => v !== value)
          : [...current.preferredValues, value],
      } as IConnectCompanyPreferredCustomerOrProduct
    }
    this.updatePreferredCount()
  }

  private updatePreferredOthers(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target) return

    const value = target.value

    const categoryId = Number(target.dataset.groupId)

    const idx = this.privatePreferredCustomerOrProducts.findIndex(
      (p) => p.categoryId === categoryId
    )
    this.privateErrors = {
      ...this.privateErrors,
      preferred0Others: false,
      preferred3Others: false,
    }

    if (idx > -1) {
      const current = this.privatePreferredCustomerOrProducts[idx]
      this.privatePreferredCustomerOrProducts[idx] = {
        ...current,
        preferredOthers: value,
      } as IConnectCompanyPreferredCustomerOrProduct
    }
  }

  // 対応可能な支援 と 得意な販売先・商材 で得意と回答した合計
  private updatePreferredCount() {
    let supportCount = 0
    this.privateSupports.forEach(
      (s: IConnectCompanySupport | IConnectCompanyMemberSupport) => {
        if (s.supportStatus === 2) {
          supportCount++
        }
      }
    )

    let checkedCount = 0
    this.privatePreferredCustomerOrProducts.forEach((p) => {
      checkedCount += p.preferredValues.length
    })

    this.privatePreferredCount = supportCount + checkedCount
  }
}
