// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textField_1KZGn{margin-bottom:40px}.textField_1KZGn.flex_1Ldpd{display:flex;justify-content:flex-start;align-items:flex-start;grid-gap:0 20px;gap:0 20px}.textField_1KZGn.border_N-_TA{padding-bottom:40px;border-bottom:1px solid #c8c7c6}.textField_1KZGn.my10_1tptv{margin:10px 0}.textField_1KZGn.last_2sklb{margin-bottom:80px}.subTitle_3c0L_{color:#454545;font-size:14px;font-weight:600;line-height:21px;letter-spacing:0;text-align:left;margin:0 0 10px}", ""]);
// Exports
exports.locals = {
	"textField": "textField_1KZGn",
	"flex": "flex_1Ldpd",
	"border": "border_N-_TA",
	"my10": "my10_1tptv",
	"last": "last_2sklb",
	"subTitle": "subTitle_3c0L_"
};
module.exports = exports;
