

























import { Component, Prop, Vue, Emit, Watch } from 'nuxt-property-decorator'
import ConnectCompanyProfile from '~/components/Client/profile/ConnectCompanyProfile'
import ConnectCompanyProfileCard from '~/components/Client/profile/ConnectCompanyProfileCard'

@Component({})
export default class ClientProposalCellStrongPoint extends Vue {
  @Prop()
  private connectCompanyProfileCard!: ConnectCompanyProfileCard

  private mount: boolean = false

  private visible: boolean[] = []
  private isExistsInvisible: boolean = false

  canVisible(index: number): boolean {
    return this.visible[index] ?? true
  }

  mounted() {
    this.mount = true
  }

  get strongPoints() {
    return this.connectCompanyProfileCard.strongPoints
  }

  @Watch('mount')
  onMountedChange() {
    const heading = this.$refs.heading as HTMLDivElement
    const headingWidth = heading.getBoundingClientRect().width

    // @MEMO: headingとstrongPointsとのgapの幅
    const gapWidth = 20

    // @MEMO: ellipsis+gapの幅を考慮（表示が崩れないよう甘くしている）
    const ellipsisWidth = 30

    const strongPoints = this.$refs.strongPoints as HTMLDivElement
    const parentWidth =
      strongPoints.getBoundingClientRect().width -
      headingWidth -
      gapWidth -
      ellipsisWidth

    let childrenWidth = 0
    const strongPointChildren = this.$refs.strongPoint as HTMLDivElement[]
    if (!strongPointChildren) {
      return
    }

    strongPointChildren.map((strongPoint: HTMLDivElement) => {
      // @MEMO: 10はgapの幅（表示が崩れないよう甘くしている）
      childrenWidth += strongPoint.getBoundingClientRect().width + 10
      if (childrenWidth >= parentWidth) {
        this.visible.push(false)
        this.isExistsInvisible = true
      } else {
        this.visible.push(true)
      }
    })
  }
}
