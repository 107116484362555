import { NuxtAxiosInstance } from '@nuxtjs/axios'
import IPaging from '~/Interfaces/IPaging'
import IProduct from '~/Interfaces/IProduct'
import { LockStore } from '~/store'

export default class ProductService {
  private readonly axios: NuxtAxiosInstance

  PROPOSE_TITLE_STORAGE_KEY = 'propose_title_storage_key_'
  PROPOSE_CONTENT_STORAGE_KEY = 'propose_content_storage_key_'

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * ページを取得する
   * @param page
   * @param sortType
   */
  async get(
    page: number,
    sortType: string | null
  ): Promise<IPaging | undefined> {
    try {
      const response = await this.axios.get(
        'api/product/get/' + page + '/' + (sortType ?? 'start_at')
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 1件取得する
   * @param product_hash_id
   */
  async find(product_hash_id: string): Promise<IProduct | undefined> {
    try {
      const response = await this.axios.get(
        'api/product/find/' + product_hash_id
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 提案する
   * @param product_hash_id
   * @param title
   * @param content
   */
  async propose(
    product_hash_id: string,
    title: string,
    content: string
  ): Promise<IProduct | undefined | null> {
    LockStore.lock(LockStore.types.propose)
    try {
      const response = await this.axios.post(
        'api/product/propose/' + product_hash_id,
        {
          title,
          content,
        }
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.propose)
    }
  }

  async updateBookmark(
    product_hash_id: string,
    is_bookmarked: boolean
  ): Promise<IProduct|null|undefined> {
    try {
      const response = await this.axios.post(
        'api/product/update_bookmark/' + product_hash_id,
        {
          is_bookmarked,
        },
        {
          headers: { 'Cache-Control': 'no-cache'}
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.error(err);
    }
  }

  getTitleStorageKey(productHashId: string): string {
    return this.PROPOSE_TITLE_STORAGE_KEY + productHashId
  }

  getContentStorageKey(productHashId: string): string {
    return this.PROPOSE_CONTENT_STORAGE_KEY + productHashId
  }
}
