















import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { ConnectButton } from '~/components/common/components'

@Component({
  components: {
    ConnectButton,
  },
})
export default class PartnerButtonColumn extends Vue {
  @Prop({ default: 'primary' })
  private buttonColor!: 'primary' | 'primary_ver2'

  @Prop({ default: true })
  private isVisibleBackButton!: boolean

  @Prop({ default: false })
  private rightAligned!: boolean

  @Emit('click')
  private onClick() {}

  @Emit('back')
  private onBack() {}
}
