


























import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
@Component
export default class ClientProductModal extends Vue {
  @Prop()
  scroll_y!: boolean

  @Prop({ default: false })
  bottom_padding_40px!: boolean

  @Prop({ default: false })
  bottom_padding_0px!: boolean

  @Prop({ default: false })
  padding_width_50px!: boolean

  @Prop({ default: false })
  max_height_95vh!: boolean

  @Prop({ default: false })
  hide_close_button!: boolean

  @Emit('closeModal')
  private closeModal() {}
}
