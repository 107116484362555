import {
  IConnectCompanyProfileCard,
  IConnectCompanySupport,
} from '~/Interfaces'

export default class ConnectCompanyProfileCard {
  value: IConnectCompanyProfileCard

  constructor(connectCompanyProfileCard: IConnectCompanyProfileCard) {
    this.value = connectCompanyProfileCard
  }

  get reviewAverage(): number {
    if (this.value.review === undefined) {
      return 0
    }

    const contents = this.value.review
    if (!contents) {
      return 0
    }

    const sum = contents.reduce((n, content) => n + content.recommendPoint, 0)

    if (contents.length === 0) {
      return 0
    }

    return sum / contents.length
  }

  get reviewAverageFormatted(): string {
    return this.reviewAverage.toFixed(1)
  }

  get reviewCount(): number {
    return this.value.review?.length ?? 0
  }

  get shouldDescribeHalfStar(): boolean {
    return (
      Math.round(this.reviewAverage * 2) / 2 - Math.floor(this.reviewAverage) >
      0
    )
  }

  get isExistsDegreeCompanies(): boolean {
    const length = this.value.degreeCompanies?.length ?? 0

    return length > 0
  }

  get strongPoints(): string[] {
    const strongPoints: string[] = []

    if (this.value.supports) {
      this.value.supports
        .filter(
          (support: IConnectCompanySupport) => support.supportStatus === 2
        )
        .forEach((support: IConnectCompanySupport) => {
          strongPoints.push(support.supportSimplifiedString)
        })
    }

    this.value.preferredCustomerOrProducts?.forEach(
      (preferredCustomerOrProduct) => {
        const etc = preferredCustomerOrProduct.preferredOthers
        preferredCustomerOrProduct.preferredValueSimplifiedStrings.forEach(
          (preferredValueSimplifiedString) => {
            if (preferredValueSimplifiedString === 'その他' && etc !== null) {
              strongPoints.push(etc)
              return
            }

            strongPoints.push(preferredValueSimplifiedString)
          }
        )
      }
    )

    return strongPoints
  }
}
