























































































































































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { ModalStore } from '~/utils/store-accessor'
import ConnectButton from '~/components/common/components/ConnectButton.vue'
import ClientQuestionnaireCard from '~/components/Client/ClientProposal/ClientQuestionnaireModal/ClientQuestionnaireCard.vue'
import { IConnectProposal } from '~/Interfaces'

@Component({
  components: {
    ClientQuestionnaireCard,
    ConnectButton,
  },
})
export default class ClientQuestionnaireModal extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop()
  private name!: string

  @Prop()
  private type!: 'negotiating' | 'quotationApproveRequest' | 'rejected'

  get isVisible(): boolean {
    return ModalStore.shouldDisplay(this.name)
  }

  private close() {
    ModalStore.hide(this.name)
  }

  private skipForChangeStatus() {
    this.close()

    if (this.type === 'negotiating' || this.type === 'rejected') {
      this.updateStatus(this.connectProposal, this.type)
    } else {
      this.approveRequest()
    }
  }

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}

  @Emit('approveRequest')
  private approveRequest() {}

  private onSubmit(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: 'policy' | 'career' | 'personality' | 'price'
  ) {
    this.$services.proposalDecisionReason.postReason(
      this.connectProposal.connectProposalHashId,
      this.type,
      value
    )

    this.close()
    if (this.type === 'negotiating' || this.type === 'rejected') {
      this.updateStatus(this.connectProposal, this.type)
    } else {
      this.approveRequest()
    }
  }
}
