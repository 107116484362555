













import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import IConnectProposal from '~/Interfaces/IConnectProposal'
import ClientProductStatusIcon from '~/components/Client/ClientProductStatusIcon.vue'
import ClientProductIdIcon from '~/components/Client/ClientProductIdIcon.vue'
import ClientProposalLarge from '~/components/Client/ClientProposalLarge.vue'
import Modal from '~/components/Modal.vue'
@Component({
  components: {
    Modal,
    ClientProposalLarge,
    ClientProductIdIcon,
    ClientProductStatusIcon,
  },
})
export default class ClientProductModal extends Vue {
  @Prop()
  private proposal!: IConnectProposal

  @Emit('closeModal')
  private closeModal() {}

  @Emit('refreshProposals')
  refreshProposals() {}
}
