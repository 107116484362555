


















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: {
    ConnectIcon,
  },
})
export default class SupportUsage extends Vue {
  @Prop()
  private isSupport!: boolean
}
