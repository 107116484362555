import {NuxtAxiosInstance} from "@nuxtjs/axios";
import {LockStore} from "~/store";
import IConnectQuotation from "~/Interfaces/connectQuotaton/IConnectQuotation";
import ConnectQuotation from "~/components/Product/ConnectQuotation";
import IConnectQuotationFindPresentation from "~/Interfaces/connectQuotaton/IConnectQuotationFindPresentation";
import IPurchaseOrder from "~/Interfaces/purchaseOrder/IPurchaseOrder";

export default class PurchaseOrderListService {
  private readonly axios: NuxtAxiosInstance;

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * @param is_client
   */
  async get(is_client: boolean = false): Promise<IPurchaseOrder[]|undefined>
  {
    LockStore.lock(LockStore.types['contract'])
    try {
      let response
      if(is_client) {
        response = await this.axios.get('api/purchase_order/client')
      } else {
        response = await this.axios.get('api/purchase_order/company')
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['contract'])
    }
  }
}
