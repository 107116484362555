var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.s.mb30},[_c('PartnerSubtitle',{class:_vm.s.subTitle,attrs:{"title":"対応可能な支援"}})],1),_vm._v(" "),_c('SupportAttention',{class:_vm.s.mb10},[_c('p',[_c('strong',[_vm._v("すべての項目")]),_vm._v("について「得意」「対応」「未対応」のいずれかを"),_c('strong',[_vm._v("選択してください")])])]),_vm._v(" "),_c('SupportAttention',{class:_vm.s.mb30},[_c('p',[_c('strong',[_vm._v("「得意」")]),_vm._v("として登録できる内容は、後述の「得意な販売先・商材」と合わせて"),_c('strong',[_vm._v("10件まで")]),_vm._v("です\n    ")])]),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"新規開拓の実務"}}),_vm._v(" "),_vm._l((9),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 0),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 0),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 0)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 0)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 0),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 0)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"新規開拓の施策策定"}}),_vm._v(" "),_vm._l((9),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 1),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 1),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 1)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 1)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 1),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 1)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"商談/クロージング"}}),_vm._v(" "),_vm._l((2),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 2),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 2),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 2)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 2)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 2),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 2)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"戦略策定"}}),_vm._v(" "),_vm._l((7),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 3),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 3),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 3)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 3)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 3),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 3)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"既存顧客への営業"}}),_vm._v(" "),_vm._l((2),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 4),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 4),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 4)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 4)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 4),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 4)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"マネジメント"}}),_vm._v(" "),_vm._l((6),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 5),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 5),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 5)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 5)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 5),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 5)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"マーケティング"}}),_vm._v(" "),_vm._l((6),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 6),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 6),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 6)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 6)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 6),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 6)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"納品内容"}}),_vm._v(" "),_vm._l((4),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 7),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 7),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 7)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 7)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 7),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 7)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"営業コンサルティング"}}),_vm._v(" "),_vm._l((9),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 8),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 8),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 8)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 8)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 8),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 8)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{attrs:{"required":true,"label":"体制/システム構築"}}),_vm._v(" "),_vm._l((3),function(id,index){return _c('SupportItemButton',{key:_vm.supportItemInspector.getSupportId(id, 9),attrs:{"id":_vm.supportItemInspector.getSupportId(id, 9),"label":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 9)
        ).label,"index":index,"value":_vm.supportItemInspector.getSupport(
          _vm.supportItemInspector.getSupportId(id, 9)
        ).support.supportStatus,"options":_vm.supportItemInspector.getSupportOptions(id, 9),"error":_vm.privateErrors.supports[_vm.supportItemInspector.getSupportId(id, 9)]},on:{"click":_vm.updateSupport}})})],2),_vm._v(" "),_c('div',{class:[_vm.s.mb30, _vm.s.mt60]},[_c('PartnerSubtitle',{attrs:{"title":"得意な販売先・商材"}})],1),_vm._v(" "),_c('SupportAttention',{class:_vm.s.mb10},[_c('p',[_vm._v("\n      特に"),_c('strong',[_vm._v("得意なものがある場合のみ選択してください")]),_vm._v("。それ以外の内容については入力不要です。"),_c('br'),_vm._v("\n      ※選択されていない内容はプロフィールに表示されません\n    ")])]),_vm._v(" "),_c('SupportAttention',{class:_vm.s.mb30},[_c('p',[_c('strong',[_vm._v("「得意」")]),_vm._v("として登録できる内容は、上述の「対応可能な支援」と合わせて"),_c('strong',[_vm._v("10件まで")]),_vm._v("です\n    ")])]),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{class:_vm.s.mb20,attrs:{"label":"販売先の業界"}}),_vm._v(" "),_c('div',{class:_vm.s.itemFlex},[_c('div',{class:[_vm.s.itemList, _vm.s.flex]},_vm._l((22),function(id){return _c('ConnectCheckbox',{key:_vm.supportItemInspector.getPreferredCheckId(id, 0),attrs:{"group-id":0,"value":_vm.supportItemInspector.getPreferredCheckId(id, 0),"model-value":_vm.supportItemInspector.getPreferredValue(
              0,
              _vm.supportItemInspector.getPreferredCheckId(id, 0)
            ),"label":_vm.supportItemInspector.getPreferredLabel(
              _vm.supportItemInspector.getPreferredCheckId(id, 0)
            )},on:{"change":_vm.updatePreferred}})}),1),_vm._v(" "),_c('div',{class:[_vm.s.itemList, _vm.s.flex]},[_vm._l((19),function(id){return _c('ConnectCheckbox',{key:_vm.supportItemInspector.getPreferredCheckId(id, 1),attrs:{"group-id":0,"value":_vm.supportItemInspector.getPreferredCheckId(id, 1),"model-value":_vm.supportItemInspector.getPreferredValue(
              0,
              _vm.supportItemInspector.getPreferredCheckId(id, 1)
            ),"label":_vm.supportItemInspector.getPreferredLabel(
              _vm.supportItemInspector.getPreferredCheckId(id, 1)
            )},on:{"change":_vm.updatePreferred}})}),_vm._v(" "),(_vm.supportItemInspector.isVisibleOthers(0))?_c('div',{class:_vm.s.inputContainer},[_c('ConnectInput',{attrs:{"id":"preferred0Others","model-value":_vm.privatePreferred0Others,"group-id":0,"required":true,"error":_vm.privateErrors.preferred0Others,"input-type":"text","placeholder":"内容","width":"370px"},on:{"update:modelValue":function($event){_vm.privatePreferred0Others=$event},"update:model-value":function($event){_vm.privatePreferred0Others=$event},"input":_vm.updatePreferredOthers}})],1):_vm._e()],2)])],1),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{class:_vm.s.mb20,attrs:{"label":"販売先の企業規模"}}),_vm._v(" "),_c('div',{class:_vm.s.itemList},_vm._l((5),function(id){return _c('ConnectCheckbox',{key:_vm.supportItemInspector.getPreferredCheckId(id, 2),attrs:{"group-id":1,"value":_vm.supportItemInspector.getPreferredCheckId(id, 2),"model-value":_vm.supportItemInspector.getPreferredValue(
            1,
            _vm.supportItemInspector.getPreferredCheckId(id, 2)
          ),"label":_vm.supportItemInspector.getPreferredLabel(
            _vm.supportItemInspector.getPreferredCheckId(id, 2)
          )},on:{"change":_vm.updatePreferred}})}),1)],1),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{class:_vm.s.mb20,attrs:{"label":"販売先の特徴"}}),_vm._v(" "),_c('div',{class:_vm.s.itemList},_vm._l((6),function(id){return _c('ConnectCheckbox',{key:_vm.supportItemInspector.getPreferredCheckId(id, 3),attrs:{"group-id":2,"value":_vm.supportItemInspector.getPreferredCheckId(id, 3),"model-value":_vm.supportItemInspector.getPreferredValue(
            2,
            _vm.supportItemInspector.getPreferredCheckId(id, 3)
          ),"label":_vm.supportItemInspector.getPreferredLabel(
            _vm.supportItemInspector.getPreferredCheckId(id, 3)
          )},on:{"change":_vm.updatePreferred}})}),1)],1),_vm._v(" "),_c('div',{class:_vm.s.itemField},[_c('ConnectInputLabel',{class:_vm.s.mb20,attrs:{"label":"サービス/商材"}}),_vm._v(" "),_c('div',{class:_vm.s.itemFlex},[_c('div',{class:[_vm.s.itemList, _vm.s.flex]},_vm._l((18),function(id){return _c('ConnectCheckbox',{key:_vm.supportItemInspector.getPreferredCheckId(id, 4),attrs:{"group-id":3,"value":_vm.supportItemInspector.getPreferredCheckId(id, 4),"model-value":_vm.supportItemInspector.getPreferredValue(
              3,
              _vm.supportItemInspector.getPreferredCheckId(id, 4)
            ),"label":_vm.supportItemInspector.getPreferredLabel(
              _vm.supportItemInspector.getPreferredCheckId(id, 4)
            )},on:{"change":_vm.updatePreferred}})}),1),_vm._v(" "),_c('div',{class:[_vm.s.itemList, _vm.s.flex]},[_vm._l((14),function(id){return _c('ConnectCheckbox',{key:_vm.supportItemInspector.getPreferredCheckId(id, 5),attrs:{"group-id":3,"value":_vm.supportItemInspector.getPreferredCheckId(id, 5),"model-value":_vm.supportItemInspector.getPreferredValue(
              3,
              _vm.supportItemInspector.getPreferredCheckId(id, 5)
            ),"label":_vm.supportItemInspector.getPreferredLabel(
              _vm.supportItemInspector.getPreferredCheckId(id, 5)
            )},on:{"change":_vm.updatePreferred}})}),_vm._v(" "),(_vm.supportItemInspector.isVisibleOthers(3))?_c('div',{class:_vm.s.inputContainer},[_c('ConnectInput',{attrs:{"id":"preferred3Others","model-value":_vm.privatePreferred3Others,"group-id":3,"required":true,"error":_vm.privateErrors.preferred3Others,"input-type":"text","placeholder":"内容","width":"370px"},on:{"update:modelValue":function($event){_vm.privatePreferred3Others=$event},"update:model-value":function($event){_vm.privatePreferred3Others=$event},"input":_vm.updatePreferredOthers}})],1):_vm._e()],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }