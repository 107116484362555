import {Module, VuexModule, Mutation} from 'vuex-module-decorators'

type SearchText = string | undefined

@Module({
    stateFactory: true,
    namespaced: true,
    name: 'ConnectProposalSearchText',
})
export default class ConnectProposalSearchText extends VuexModule {
    searchText: SearchText = undefined

    @Mutation
    setSearchText(searchText: SearchText) {
        if (searchText === undefined) {
            return
        }

        this.searchText = searchText
    }

    @Mutation
    removeSearchText() {
        this.searchText = undefined
    }

    get getSearchText(): SearchText {
        return this.searchText
    }
}
