// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".card_3fXoK{transition:all .1s ease-out;cursor:pointer;padding:20px 15px;display:flex;flex-direction:column;align-items:center;width:170px;height:190px;border-radius:10px;border:2px solid #fff;box-shadow:0 1px 4px 0 rgba(0,0,0,.25),0 2px 10px 0 rgba(0,0,0,.1)}.card_3fXoK:hover{border:2px solid #3379b7;box-shadow:0 1px 4px 0 rgba(0,0,0,.2),0 2px 20px 0 rgba(0,0,0,.15)}.card_3fXoK .icon_1Wpzr{display:flex;align-items:center;justify-content:center;width:30px;height:30px}.card_3fXoK .title_1urYJ{margin-top:10px;font-family:\"Hiragino Kaku Gothic Pro\";font-size:18px;font-weight:600;color:#454545}.card_3fXoK .items_3Rn7i{margin-top:20px;padding-left:15px}.card_3fXoK .items_3Rn7i .item_1Ze3B{font-family:\"Hiragino Kaku Gothic Pro\";font-size:13px;font-weight:400;color:#454545}", ""]);
// Exports
exports.locals = {
	"card": "card_3fXoK",
	"icon": "icon_1Wpzr",
	"title": "title_1urYJ",
	"items": "items_3Rn7i",
	"item": "item_1Ze3B"
};
module.exports = exports;
