import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import dayjs from 'dayjs'
import { IThreadGroupAccount, ITopic } from '~/Interfaces'

@Module({ stateFactory: true, namespaced: true, name: 'Topic' })
export default class Topic extends VuexModule {
  topic: ITopic | undefined = undefined

  @Mutation
  setTopic(topic: ITopic | undefined | null) {
    if (topic === undefined) {
      return
    }

    if (topic === null) {
      this.topic = undefined
      return
    }

    this.topic = topic
  }

  get getTopic(): ITopic | undefined {
    return this.topic
  }

  get getThreadGroupAccountSelf(): IThreadGroupAccount | null {
    if (this.topic === undefined) {
      return null
    }

    const index = this.topic.group.threadGroupAccounts.findIndex(
      (threadGroupAccount: IThreadGroupAccount) => {
        return threadGroupAccount.isSelf
      }
    )

    return this.topic.group.threadGroupAccounts[index]
  }

  get getThreadGroupAccountOther(): IThreadGroupAccount | null {
    if (this.topic === undefined) {
      return null
    }

    const index = this.topic.group.threadGroupAccounts.findIndex(
      (threadGroupAccount: IThreadGroupAccount) => {
        return !threadGroupAccount.isSelf
      }
    )

    return this.topic.group.threadGroupAccounts[index]
  }

  get getNameOther(): string | null {
    const account = this.getThreadGroupAccountOther
    if (account === null) {
      return ''
    }

    if (account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }

    if (account.company) {
      return account.company.companyName
    }

    return ''
  }

  get getNameSelf(): string | null {
    const account = this.getThreadGroupAccountSelf
    if (account === null) {
      return ''
    }

    if (account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }

    if (account.company) {
      return account.company.companyName
    }

    return ''
  }

  get getImageOther(): string | null {
    const account = this.getThreadGroupAccountOther
    if (account === null) {
      return null
    }

    if (account.connectClient?.connectClientHashId) {
      return null
    }

    if (account.company?.companyHashId) {
      return account.company?.companyImage === ''
        ? null
        : account.company?.companyImage
    }

    return null
  }

  get getFoundationDate(): string | null {
    const account = this.getThreadGroupAccountOther
    if (account === null) {
      return null
    }

    if (account.company) {
      if (account.company.companyFoundedAt !== null) {
        return dayjs(account.company.companyFoundedAt).format('YYYY/MM/DD')
      }
    }

    if (account.connectClient) {
      return null
    }

    return null
  }

  get isConnectClientSelf(): boolean {
    const account = this.getThreadGroupAccountSelf
    if (account === null) {
      return false
    }

    if (account.accountTypeSelf === 'connect_client') {
      return true
    }

    return false
  }

  get isConnectCompanySelf(): boolean {
    const account = this.getThreadGroupAccountSelf
    if (account === null) {
      return false
    }

    if (account.accountTypeSelf === 'connect_company') {
      return true
    }

    return false
  }

  get isCompanySelf(): boolean {
    const account = this.getThreadGroupAccountSelf
    if (account === null) {
      return false
    }

    if (account.accountTypeSelf === 'company') {
      return true
    }

    return false
  }

  get isConnect(): boolean {
    if (this.getTopic === undefined) {
      return false
    }

    return this.getTopic?.thread.threadFlags.threadIsConnect
  }
}
