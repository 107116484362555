import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Auth } from '@nuxtjs/auth-next'
import IProduct from '../../Interfaces/IProduct'
import { LockStore } from '~/store'
import IConnectClientAccountForAccountList from '~/Interfaces/IConnectClientAccountForAccountList'
import {
  ConnectClientBilling,
  ConnectClientContact,
  ConnectClientProfile,
} from '~/components'

export default class ClientService {
  private readonly axios: NuxtAxiosInstance
  private readonly auth: Auth

  constructor(axios: NuxtAxiosInstance, auth: Auth) {
    this.axios = axios
    this.auth = auth
  }

  /**
   *
   */
  async setConnectClientInfo(
    name: string,
    address: string,
    tel: string
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post('api/client/connect_client/info', {
        name,
        address,
        tel,
      })

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async postConnectClientProfile(
    connectClientProfile: ConnectClientProfile,
    connectClientContact: ConnectClientContact
  ): Promise<boolean> {
    try {
      const response = await this.axios.post(
        'api/client/connect_client/profile',
        {
          profile: connectClientProfile.value,
          contact: connectClientContact.toParams(),
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async postConnectClientBilling(
    connectClientBilling: ConnectClientBilling
  ): Promise<boolean> {
    try {
      const response = await this.axios.post(
        'api/client/connect_client/billing/update',
        {
          billing: connectClientBilling.toParams(),
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getConnectStaffInfo(): Promise<IProduct | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get(
        'api/client/connect_client/staff_info'
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * ログイン済みかどうか
   */
  async isLoginDone(): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get('api/client/is_login_done')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * ログイン済みにする
   */
  async makeLoginDone(): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post('api/client/make_login_done')

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * 業務委託約款同意済みにする
   */
  async makeAgreedServiceAgreement(): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/client/make_agreed_service_agreement'
      )

      console.log(response)
      await this.auth.fetchUser()

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * 招待する
   */
  async inviteAccount(
    name: string,
    email: string,
    role: string
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post('api/client/account/invite', {
        name,
        email,
        role,
      })

      console.log(response)

      return response.data
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * 削除する
   */
  async deleteAccount(isActivated: boolean, hashId: string): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post('api/client/account/delete', {
        isActivated,
        hashId,
      })

      console.log(response)

      return response.data
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   * 削除する
   */
  async updateAccount(
    hashId: string,
    name: string,
    email: string,
    staffRole: string | null,
    role: string
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post('api/client/account/update', {
        hashId,
        name,
        email,
        staffRole,
        role,
      })

      console.log(response)

      return response.data
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getAccountsForAccountList(): Promise<
    IConnectClientAccountForAccountList[] | undefined
  > {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get('api/client/account/list')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   */
  async getRoles(): Promise<{ [key: string]: string } | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get('api/client/account/roles')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
