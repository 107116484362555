// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2jv2V{display:flex;height:40px;padding:10px;justify-content:center;align-items:center;grid-gap:10px;gap:10px;cursor:pointer;border-radius:5px;border:1px solid #afadac;border:1px solid var(--Grey-60,#afadac);background:#fff;background:var(--Primary-20,#fff);transition:all .1s ease-out}.container_2jv2V .name_2Hmib{font-size:14px;white-space:nowrap}.container_2jv2V .name_2Hmib,.container_2jv2V .number_18NTH{color:#454545;color:var(--Text-100,#454545);font-weight:600}.container_2jv2V .number_18NTH{display:flex;justify-content:center;align-items:center;height:16px;min-width:16px;padding:0 4px;border-radius:100px;font-size:10px}.container_2jv2V .number_18NTH,.container_2jv2V:hover{background:#f3f2f2;background:var(--Grey-20,#f3f2f2)}.container_2jv2V.selected_1GqzW{background:#e3eef7;background:var(--Primary-20,#e3eef7)}.container_2jv2V.selected_1GqzW .number_18NTH{background:#fff;background:var(--Background-White,#fff)}.container_2jv2V.selected_1GqzW:hover{background:#d7e6f4;background:var(--Primary-30,#d7e6f4)}", ""]);
// Exports
exports.locals = {
	"container": "container_2jv2V",
	"name": "name_2Hmib",
	"number": "number_18NTH",
	"selected": "selected_1GqzW"
};
module.exports = exports;
