// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".clientAccountRow_VvInE{display:grid;grid-template-columns:160px calc(100% - 220px) 60px;height:50px;background:#fff;color:#555}.clientAccountRow_VvInE:hover{background:#fafafa;color:#3379b7;cursor:pointer}.clientAccountRow__cell_x9m4S{display:flex;align-items:center;justify-content:flex-start;color:currentColor;font-size:14px;font-weight:300;line-height:21px;letter-spacing:0;text-align:left}.clientAccountRow__cell_x9m4S.name_32HJB{padding-left:10px}.clientAccountRow__cell_x9m4S.name_32HJB .userCircleInvited_2Vktc{width:30px;height:30px;border:1px dashed #0e273c;border-radius:20px}.clientAccountRow__cell_x9m4S.name_32HJB .userName_dyqf0{color:currentColor;font-weight:600;width:100px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;padding-left:20px}.clientAccountRow__cell_x9m4S.email_13t7p{color:#555;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.clientAccountRow__cell_x9m4S.role_1J9Ui{color:#555}", ""]);
// Exports
exports.locals = {
	"clientAccountRow": "clientAccountRow_VvInE",
	"clientAccountRow__cell": "clientAccountRow__cell_x9m4S",
	"name": "name_32HJB",
	"userCircleInvited": "userCircleInvited_2Vktc",
	"userName": "userName_dyqf0",
	"email": "email_13t7p",
	"role": "role_1J9Ui"
};
module.exports = exports;
