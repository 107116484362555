// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".label_1wM57{position:absolute;right:0;left:0;top:40px;display:flex;justify-content:center;align-items:flex-end;background:#e3eef7;border:1px solid #d6d5d4;box-shadow:0 3px 6px rgba(0,0,0,.16);border-radius:100px;font-weight:600;font-size:13px;line-height:19px;color:#555;padding:6px 15px;grid-gap:0 10px;gap:0 10px;margin:0 auto;max-width:210px;z-index:10}.label_1wM57.error_2uthP{background:#ffe8e8}.label_1wM57 .errorIcon_1lx9v{padding-top:2px}.label_1wM57 .number_1OTEb{font-family:\"Roboto\";font-size:16px;line-height:19px}.label_1wM57 .number_1OTEb.bold_2lB8V{font-weight:700;font-size:22px;line-height:26px}.label_1wM57 .number_1OTEb.bold_2lB8V.error_2uthP{color:#d60303}.mb10_3FrPn{margin-bottom:10px}.mb20_2clty{margin-bottom:20px}.mb30_3WSVh{margin-bottom:30px}.mt60_MCxV3{margin-top:60px}.itemField_330Oo{margin:0 0 30px}.itemFlex_dEIcD{display:flex;justify-content:space-between;align-items:flex-start;width:100%}.itemList_2IoPH{display:flex;flex-direction:column;grid-gap:10px 0;gap:10px 0}.itemList_2IoPH.flex_1pN35{width:50%}.inputContainer_1e_zh{padding-left:30px}", ""]);
// Exports
exports.locals = {
	"label": "label_1wM57",
	"error": "error_2uthP",
	"errorIcon": "errorIcon_1lx9v",
	"number": "number_1OTEb",
	"bold": "bold_2lB8V",
	"mb10": "mb10_3FrPn",
	"mb20": "mb20_2clty",
	"mb30": "mb30_3WSVh",
	"mt60": "mt60_MCxV3",
	"itemField": "itemField_330Oo",
	"itemFlex": "itemFlex_dEIcD",
	"itemList": "itemList_2IoPH",
	"flex": "flex_1pN35",
	"inputContainer": "inputContainer_1e_zh"
};
module.exports = exports;
