// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3MF24{max-width:488px;margin:0 auto}.title_UCABd{color:#555;text-align:center;font-size:18px;font-weight:600;line-height:130%;margin:0 0 40px;padding:0}.subTitle_1qGCT{font-style:normal;font-weight:600;line-height:normal}.description_2gsyq,.subTitle_1qGCT{color:#555;text-align:center;font-size:14px;margin:0 0 10px;padding:0}.description_2gsyq{font-weight:400}.link_3yqh0{display:block;color:#3379b7;text-align:center;-webkit-text-decoration-line:underline;text-decoration-line:underline;margin:0 0 40px;padding:0}.attention_26bUo,.link_3yqh0{font-size:14px;font-style:normal;font-weight:400;line-height:normal}.attention_26bUo{color:#555;text-align:left}.attention_26bUo .underline_13XTn{font-weight:600;text-decoration:underline}.attention_26bUo .impact_2TmMt{font-weight:600}.buttonLayer_3xDo0{margin-top:40px;display:flex;justify-content:center}", ""]);
// Exports
exports.locals = {
	"container": "container_3MF24",
	"title": "title_UCABd",
	"subTitle": "subTitle_1qGCT",
	"description": "description_2gsyq",
	"link": "link_3yqh0",
	"attention": "attention_26bUo",
	"underline": "underline_13XTn",
	"impact": "impact_2TmMt",
	"buttonLayer": "buttonLayer_3xDo0"
};
module.exports = exports;
