import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import ConnectQuotation from '~/components/Product/ConnectQuotation'
import IConnectQuotationFindPresentation from '~/Interfaces/connectQuotaton/IConnectQuotationFindPresentation'
import IConnectQuotationDiffer from '~/Interfaces/connectQuotaton/IConnectQuotationDiffer'
import IConnectProposal from '~/Interfaces/IConnectProposal'

export default class QuotationService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * 作成依頼
   * @param connect_proposal_hash_id
   */
  async requestQuotation(
    connect_proposal_hash_id: string
  ): Promise<IConnectProposal | null> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.post(
        'api/client/quotation/request_create/' + connect_proposal_hash_id
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return null
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   * 確認依頼
   * @param connect_proposal_hash_id
   * @param quotation
   */
  async requestConfirm(
    connect_proposal_hash_id: string,
    quotation: ConnectQuotation
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.post(
        'api/quotation/request_confirm/' + connect_proposal_hash_id,
        {
          quotation: quotation.payload,
        }
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   * 取得
   * @param connect_product_hash_id
   * @param is_client
   */
  async find(
    connect_product_hash_id: string,
    is_client: boolean = false
  ): Promise<IConnectQuotationFindPresentation | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      let response
      if (is_client) {
        response = await this.axios.get(
          'api/client/quotation/find/' + connect_product_hash_id
        )
      } else {
        response = await this.axios.get(
          'api/quotation/find/' + connect_product_hash_id
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   * 取得（更新用）
   * @param connectProposalHashId
   * @param is_client
   */
  async findToRenew(
    connectProposalHashId: string,
    is_client: boolean = false
  ): Promise<IConnectQuotationFindPresentation | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      let response
      if (is_client) {
        response = await this.axios.get(
          'api/client/quotation/find/renew/' + connectProposalHashId
        )
      } else {
        response = await this.axios.get(
          'api/quotation/find/renew/' + connectProposalHashId
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   * 差分取得
   * @param quotation
   * @param connect_product_hash_id
   * @param is_client
   */
  async diff(
    quotation: ConnectQuotation,
    connect_product_hash_id: string,
    is_client: boolean = false
  ): Promise<IConnectQuotationDiffer | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      let response
      if (is_client) {
        response = await this.axios.post(
          'api/client/quotation/diff/' + connect_product_hash_id,
          {
            quotation: quotation.payload,
          }
        )
      } else {
        response = await this.axios.post(
          'api/quotation/diff/' + connect_product_hash_id,
          {
            quotation: quotation.payload,
          }
        )
      }

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   * 修正依頼
   * @param connect_proposal_hash_id
   * @param quotation
   * @param edit_request_text
   */
  async requestEdit(
    connect_proposal_hash_id: string,
    quotation: ConnectQuotation,
    edit_request_text: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.post(
        'api/client/quotation/request_edit/' + connect_proposal_hash_id,
        {
          quotation: quotation.payload,
          edit_request_text,
        }
      )

      // console.log(response)

      return true
    } catch (err) {
      console.log(err)

      return false
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   * 承認依頼
   * @param connect_proposal_hash_id
   * @param quotation
   */
  async approveConfirm(
    connect_proposal_hash_id: string,
    quotation: ConnectQuotation
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.post(
        'api/client/quotation/request_approve/' + connect_proposal_hash_id,
        {
          quotation: quotation.payload,
        }
      )

      // console.log(response)

      return true
    } catch (err) {
      console.log(err)

      return false
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   * 他提案を一括見送り
   * @param connectProposalHashId
   */
  async rejectOtherProposals(
    connectProposalHashId: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      await this.axios.post(
        'api/client/quotation/reject_other_proposals/' + connectProposalHashId
      )

      return true
    } catch (err) {
      console.log(err)

      return false
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   * 承認
   * @param connectProposalHashId
   * @param connectContractHashId
   */
  async approve(
    connectProposalHashId: string,
    connectContractHashId: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.post(
        'api/quotation/approve/' +
          connectProposalHashId +
          '/' +
          connectContractHashId
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  async kakutokuPdf(
    connectQuotationHashId: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.contract)
    try {
      const response = await this.axios.post(
        'api/client/quotation/kakutoku_pdf/' + connectQuotationHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.contract)
    }
  }

  async pdf(
    connectQuotationHashId: string,
    isClient: boolean = false
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.post(
        'api/client/quotation/pdf/' + connectQuotationHashId,
        {
          is_client: isClient,
        }
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }
}
