var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('img',{class:[
    _vm.s.upload_image,
    ( _obj = {}, _obj[_vm.s.small] = _vm.size === 'small', _obj ),
    ( _obj$1 = {}, _obj$1[_vm.s.large] = _vm.size === 'large', _obj$1 ),
    ( _obj$2 = {}, _obj$2[_vm.s.circle] = _vm.size === 'circle', _obj$2 ),
    ( _obj$3 = {}, _obj$3[_vm.s.proposal_card] = _vm.size === 'proposalCard', _obj$3 ),
    ( _obj$4 = {}, _obj$4[_vm.s.proposal_detail] = _vm.size === 'proposalDetail', _obj$4 ) ],attrs:{"src":_vm.connectCompanyEntity.uploadImageUrl,"alt":""}}):_c('client-product-id-icon',{attrs:{"color-index":_vm.colorIndex,"size":_vm.size}})}
var staticRenderFns = []

export { render, staticRenderFns }