// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".member_TgHJp{padding:19px;width:430px}.member_TgHJp,.member_TgHJp a{display:flex;align-items:center}.member_TgHJp a{text-decoration:none}.member_TgHJp a:hover .name_PRhfb{color:#209cd0}.member_TgHJp .icon_1PypN{position:relative;margin-right:20px;display:flex;align-items:center;font-size:60px;color:#c1bbab}.member_TgHJp .icon_1PypN img{height:60px;width:60px;border-radius:50%}.member_TgHJp .name_PRhfb{word-break:keep-all;font-size:14px;font-weight:600;letter-spacing:0;color:#0e273c}", ""]);
// Exports
exports.locals = {
	"member": "member_TgHJp",
	"name": "name_PRhfb",
	"icon": "icon_1PypN"
};
module.exports = exports;
