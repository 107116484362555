


















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectCompanySupport } from '~/Interfaces'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import IConnectCompanyMemberSupport from '~/Interfaces/connectCompany/connectCompanyMember/IConnectCompanyMemberSupport'

@Component({
  components: {
    ConnectIcon,
  },
})
export default class SupportItems extends Vue {
  @Prop()
  private title!: string

  @Prop()
  private list!: IConnectCompanySupport[] | IConnectCompanyMemberSupport[]

  private AREA_LIST = [
    '1都3県',
    '北海道',
    '東北',
    '関東',
    '中部',
    '近畿',
    '中国',
    '四国',
    '九州',
  ]

  private isVisibleSpacer(areaId: number) {
    return [0, 4].includes(areaId)
  }
}
