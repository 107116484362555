import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export interface IBackPoint {
  name: string,
  from: string|undefined
}

@Module({ stateFactory: true, namespaced: true, name: 'BackPoint'})
export default class BackPoint extends VuexModule {
  backPoints: IBackPoint[] = []

  public types: {[index: string]:string} = {
    'profile': 'profile',
  }

  @Mutation
  save(backPoint: IBackPoint)
  {
    let index = this.backPoints.findIndex(bp => bp.name === backPoint.name);
    if(index < 0) {
      this.backPoints.push(backPoint)

      return
    }

    this.backPoints[index].from = backPoint.from;
  }

  @Mutation
  delete(name: string)
  {
    let index = this.backPoints.findIndex(bp => bp.name === name);
    if(index === undefined) {
      return;
    }

    this.backPoints[index].from = undefined;
  }

  get getPoint() {
    var self = this;

    return (name: string) => {
      let backPoint = self.backPoints.find(bp => bp.name === name);

      return backPoint?.from
    }
  }
}
