




























import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class ClientProposalDetailSupportSystemLabel extends Vue {
  @Prop()
  private label!: string

  @Prop()
  private value!: number

  get splitLabel(): string[] {
    return this.label.split(' ')
  }
}
