// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon_1wPpp{display:flex;align-items:center;justify-content:center}.icon_1wPpp.small_uJbPj{width:40px;height:40px;font-size:20px}.icon_1wPpp.large_19FUu{width:100px;height:100px;font-size:34px}.icon_1wPpp.circle_3vguu{width:24px;height:24px;min-width:24px;min-height:24px;border-radius:50%}.icon_1wPpp.mdCircle_1JNxn{width:34px;height:34px;border-radius:50%}.icon_1wPpp.proposal_card_1LgLY{width:60px;height:60px;border-radius:5px;font-size:34px}.icon_1wPpp.proposal_detail_1Qudz{width:80px;height:80px;border-radius:5px;font-size:34px}.icon_1wPpp.color1_2llPa{background:#99aac3;color:#eff4fc}.icon_1wPpp.color2_1HJY1{background:#c1bbab;color:#f4f2ea}.icon_1wPpp.color3_vUysM{background:#9db5c1;color:#ecf5f8}.icon_1wPpp.color4_18MPS{background:#a1b5b0;color:#f0f8f6}.icon_1wPpp.color5_8LQIu{background:#aca5bc;color:#f1eff5}", ""]);
// Exports
exports.locals = {
	"icon": "icon_1wPpp",
	"small": "small_uJbPj",
	"large": "large_19FUu",
	"circle": "circle_3vguu",
	"mdCircle": "mdCircle_1JNxn",
	"proposal_card": "proposal_card_1LgLY",
	"proposal_detail": "proposal_detail_1Qudz",
	"color1": "color1_2llPa",
	"color2": "color2_1HJY1",
	"color3": "color3_vUysM",
	"color4": "color4_18MPS",
	"color5": "color5_8LQIu"
};
module.exports = exports;
