
























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectClientAccountForAccountList } from '~/Interfaces'
import { ConnectIcon } from '~/components'

@Component({
  components: {
    ConnectIcon,
  },
})
export default class ClientAccountRow extends Vue {
  @Prop()
  account!: IConnectClientAccountForAccountList

  @Prop()
  roles!: { [key: string]: string }

  get componentId() {
    return `ClientAccountRow-${this.account.hashId}`
  }

  private onClick() {
    this.$router.push(`/client/setting/accounts/${this.account.hashId}`)
  }
}
