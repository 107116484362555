// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button_wrapper_1X4sK,.button_wrapper_1X4sK .button_1P7sn{display:flex;justify-content:center}.button_wrapper_1X4sK .button_1P7sn{outline:none;cursor:pointer;align-items:center;width:230px;height:40px;border-radius:100px;border:none;background:#3379b7;font-size:14px;font-weight:600;color:#fff;font-family:Hiragino Kaku Gothic Pro}.button_wrapper_1X4sK .button_1P7sn:hover{background:#2c69a0}.button_wrapper_1X4sK .button_1P7sn:active{background:#245480}.button_wrapper_1X4sK .button_1P7sn:disabled{pointer-events:none;background:#245480;color:#afadac}", ""]);
// Exports
exports.locals = {
	"button_wrapper": "button_wrapper_1X4sK",
	"button": "button_1P7sn"
};
module.exports = exports;
