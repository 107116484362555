// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_26bEL{width:100%;height:100%;display:flex;justify-content:center;align-items:center}.container_26bEL .spinner_1UM-8{-webkit-animation:spin_3Z8f- .5s linear infinite;animation:spin_3Z8f- .5s linear infinite}@-webkit-keyframes spin_3Z8f-{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes spin_3Z8f-{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
exports.locals = {
	"container": "container_26bEL",
	"spinner": "spinner_1UM-8",
	"spin": "spin_3Z8f-"
};
module.exports = exports;
