// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_dNybo{margin-top:60px;text-align:center;font-size:24px;font-weight:600;letter-spacing:0;color:#002853;line-height:1}.buttons_1T6Pl{margin-top:30px;display:flex;justify-content:center;grid-gap:20px;gap:20px}.buttons_1T6Pl .cancel_2n9J1{background:#e9edf1;color:#2f5980}.buttons_1T6Pl .cancel_2n9J1,.buttons_1T6Pl .reject_3rhKA{cursor:pointer;display:flex;align-items:center;justify-content:center;width:200px;height:40px;border-radius:30px;font-weight:600;font-size:14px;letter-spacing:0}.buttons_1T6Pl .reject_3rhKA{background:#2f5980;color:#fff}", ""]);
// Exports
exports.locals = {
	"title": "title_dNybo",
	"buttons": "buttons_1T6Pl",
	"cancel": "cancel_2n9J1",
	"reject": "reject_3rhKA"
};
module.exports = exports;
