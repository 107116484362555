














































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ClientProposalListItemActivityType from './components/ClientProposalListItemActivityType/index.vue'
import ClientProposalListItemDegree from './components/ClientProposalListItemDegree/index.vue'
import ClientProposalListItemDescription from './components/ClientProposalListItemDescription/index.vue'
import ClientProposalListItemFavButton from './components/ClientProposalListItemFavButton/index.vue'
import ClientProposalListItemMessageIcon from './components/ClientProposalListItemMessageIcon/index.vue'
import ClientProposalListItemPrimeLabel from './components/ClientProposalListItemPrimeLabel/index.vue'
import ClientProposalListItemNotPrimeLabel from './components/ClientProposalListItemNotPrimeLabel/index.vue'
import ClientProposalListItemReview from './components/ClientProposalListItemReview/index.vue'
import ClientProposalListItemStatusLabel from './components/ClientProposalListItemStatusLabel/index.vue'
import {
  ConnectCompanyProfileCardsStore,
  ConnectCompanyProfilesStore,
  ConnectCompanyStore,
  ConnectProposalThreadStore,
  CurrentConnectProposalStore,
  ReadsStore,
} from '~/utils/store-accessor'
import ProposalFavoriteButton from '~/components/Proposal/proposal-favorite-button.vue'
import ClientProductIdIconWrapper from '~/components/Client/ClientProductIdIconWrapper.vue'
import ConnectCompanyProfileCard from '~/components/Client/profile/ConnectCompanyProfileCard'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import IConnectDegreeCompany from '~/Interfaces/connectCompany/IConnectDegreeCompany'
import IConnectProposal from '~/Interfaces/IConnectProposal'
import IConnectCompany from '~/Interfaces/connectCompany/IConnectCompany'

@Component({
  components: {
    ConnectIcon,
    ClientProductIdIconWrapper,
    ProposalFavoriteButton,
    ClientProposalListItemStatusLabel,
    ClientProposalListItemReview,
    ClientProposalListItemDescription,
    ClientProposalListItemDegree,
    ClientProposalListItemFavButton,
    ClientProposalListItemMessageIcon,
    ClientProposalListItemActivityType,
    ClientProposalListItemPrimeLabel,
    ClientProposalListItemNotPrimeLabel,
  },
})
export default class ClientProposalListItem extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  get connectCompanyProfileCard(): ConnectCompanyProfileCard | undefined {
    return ConnectCompanyProfileCardsStore.findByCompanyHashId(
      this.connectProposal.companyHashId
    )
  }

  get connectCompany(): IConnectCompany | undefined {
    return this.connectCompanyProfileCard?.value.info
  }

  get partnerNumber(): string {
    return (this.connectProposal.connectProposalIndex || 1)
      .toString()
      .padStart(2, '0')
  }

  get partnerName(): string {
    return this.$services.proposal.getCompanyNameNotMasked(this.connectProposal)
  }

  get isPrime(): boolean {
    return this.connectCompany?.prime?.isPrime ?? false
  }

  get degree(): IConnectDegreeCompany | null {
    return this.connectCompanyProfileCard?.value.degreeCompanies?.length
      ? this.connectCompanyProfileCard.value.degreeCompanies[0]
      : null
  }

  get hasUnreadMessage(): boolean {
    const threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )
    if (!threadHashId) return false
    return !ReadsStore.isReadByThreadHashId(threadHashId)
  }

  private async onClick() {
    const profile = await this.$services.clientPartnerProfile.getProfile(
      this.connectCompany?.connectCompanyHashId!
    )

    ConnectCompanyProfilesStore.set([profile!])

    CurrentConnectProposalStore.setCurrentConnectProposal(this.connectProposal)

    ConnectCompanyStore.setConnectCompany(profile!.info)

    this.$router.push({
      query: { proposal_hash_id: this.connectProposal.connectProposalHashId },
    })
  }

  private async onClickFavButton() {
    try {
      if (this.connectProposal.isConnectClientFavorited) {
        await this.$services.proposal.unfavorite(
          this.connectProposal.connectProposalHashId
        )
      } else {
        await this.$services.proposal.favorite(
          this.connectProposal.connectProposalHashId
        )
      }
      this.connectProposal.isConnectClientFavorited = !this.connectProposal
        .isConnectClientFavorited
    } catch (error: any) {
      console.error(error)
    }
  }
}
