import { render, staticRenderFns } from "./MemberDetail.vue?vue&type=template&id=061d5b1a&scoped=true&"
import script from "./MemberDetail.vue?vue&type=script&lang=ts&"
export * from "./MemberDetail.vue?vue&type=script&lang=ts&"
import style0 from "./MemberDetail.vue?vue&type=style&index=0&id=061d5b1a&scoped=true&lang=scss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "061d5b1a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConnectIcon: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/ConnectIcon.vue').default,EmptyStateText: require('/var/www/kakutoku_connect/nuxt/src/components/common/profile/partner/EmptyStateText/index.vue').default,PartnerSubtitle: require('/var/www/kakutoku_connect/nuxt/src/components/common/profile/partner/PartnerSubtitle/index.vue').default,SupportUsage: require('/var/www/kakutoku_connect/nuxt/src/components/common/profile/partner/SupportUsage/index.vue').default,SupportItems: require('/var/www/kakutoku_connect/nuxt/src/components/common/profile/partner/SupportItems/index.vue').default,PreferredItems: require('/var/www/kakutoku_connect/nuxt/src/components/common/profile/partner/PreferredItems/index.vue').default})
