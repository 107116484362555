



























import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ConnectV2Checkbox extends Vue {
  @Prop()
  private modelValue!: string | boolean

  @Prop()
  private id!: string

  @Prop()
  private label!: string

  @Prop()
  private groupId!: string | number

  @Prop()
  private error!: boolean | undefined

  @Prop()
  private value!: string | number

  @Prop()
  private disabled!: boolean

  @Prop()
  private name!: string

  get componentId(): string {
    return this.id || Math.random().toString(32).substring(2)
  }

  get checked(): boolean {
    return !!this.modelValue
  }

  @Emit('change')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange(event: Event) {}
}
