import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IConnectTeamWithMemberWrapper from "../Interfaces/connectTeam/IConnectTeamWithMemberWrapper";

@Module({ stateFactory: true, namespaced: true, name: 'ConnectTeamWithMemberWrapper'})
export default class ConnectTeamWithMemberWrapper extends VuexModule {
  connectTeamWithMemberWrapper: IConnectTeamWithMemberWrapper|undefined = undefined

  @Mutation
  setWrapper(connectTeamWithMemberWrapper: IConnectTeamWithMemberWrapper|undefined|null)
  {
    if(connectTeamWithMemberWrapper === undefined) {
      return
    }

    if(connectTeamWithMemberWrapper === null) {
      this.connectTeamWithMemberWrapper = undefined
      return
    }

    this.connectTeamWithMemberWrapper = connectTeamWithMemberWrapper;
  }

  get getWrapper(): IConnectTeamWithMemberWrapper|undefined
  {
    return this.connectTeamWithMemberWrapper
  }

  get getMember() {
    var self = this;

    return (hash: string) => {
      let connectMemberWrapper = self.connectTeamWithMemberWrapper?.connectMemberWrappers.find(connectMemberWrapper => {
        return connectMemberWrapper.sa.saHashId === hash
      });

      return connectMemberWrapper
    }
  }

  get getIndex() {
    var self = this;

    return (hash: string) => {
      const index = self.connectTeamWithMemberWrapper?.connectMemberWrappers.findIndex(connectMemberWrapper => {
        return connectMemberWrapper.sa.saHashId === hash
      });

      if(index === undefined) {
        return undefined
      }

      if(self.isExistsRepresentative) {
        return index
      } else {
        return index + 1
      }
    }
  }

  get memberCount(): number {
    if(!this.connectTeamWithMemberWrapper) {
      return 0
    }

    return this.connectTeamWithMemberWrapper.connectMemberWrappers.length
  }

  get isExistsRepresentative() {
    const representative = this.connectTeamWithMemberWrapper?.connectMemberWrappers.find(connectMemberWrapper => {
      return connectMemberWrapper.sa.saRole === 0
    });

    return representative !== undefined
  }
}
