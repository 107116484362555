import { Vue } from 'nuxt-property-decorator'
import dayjs from 'dayjs'

// tsエラーが出てたので一旦anyにする
export default (context: any) => {
  const uuid = makeUUID()
  const scriptLoadedAt = dayjs().locale('Asia/Tokyo').format()

  // tsエラーが出てたので一旦anyにする
  context.$axios.onError((error: any) => {
    report('axios', {
      error,
      response: {
        status: error.response.status,
        statusText: error.response.statusText,
        responseData: error.response.data,
      },
    })
  })

  Vue.config.errorHandler = (error, vm, info) => {
    report('Vue.config.errorHandle', {
      message: error.message,
      name: error.name,
      tag: vm.$vnode?.componentOptions?.tag,
      properties: getProperties(vm),
      stack: error.stack,
    })
  }

  function getProperties(vm: Vue) {
    // @ts-ignore
    // eslint-disable-next-line no-proto
    return Object.keys(vm.__proto__)
      .map((key) => {
        if (key === 'constructor') {
          return undefined
        }
        // @ts-ignore
        return { key, value: vm[key] }
      })
      .filter(Boolean)
  }

  window.addEventListener('error', (event) => {
    report('EventListener', {
      message: event.message,
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      error: {
        message: event.error.message,
        name: event.error.name,
        stack: event.error.stack,
      },
    })
  })

  window.addEventListener('unhandledrejection', (event) => {
    report('unhandledrejection EventListener', event.reason)
  })

  /**
   * @param type
   * @param err
   */
  async function report(type: string, err: any) {
    if (shouldTopRedirect(type, err)) {
      context.redirect('/')
    }

    if (dontReport(type, err)) {
      return
    }

    const frontContext = {
      uuid,
      gitCommitHash: context.$config.GIT_COMMIT_HASH,
      compiledAt: context.$config.COMPILED_AT,
      scriptLoadedAt,
      localStorage,
    }

    console.log(`Captured in ${type} : `, err)
    try {
      const response = await context.$axios.post(
        'api/error/' + context.$auth.strategy.name + '/create',
        {
          type,
          content: JSON.stringify(err, null, '\t'),
          frontContext: JSON.stringify(frontContext, null, '\t'),
        }
      )
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @MEMO: エラー発生時はトップに遷移させる
   */
  function shouldTopRedirect(type: string, err: any): boolean {
    // 評価依頼時の依頼済みの場合は遷移させない
    if (
      type === 'axios' &&
      err.response?.responseData &&
      err.response?.status === 409 &&
      err.response?.responseData.result === false &&
      err.response?.responseData.reason === 'already send'
    ) {
      return false
    }

    // api リクエスト時に user 情報がないときには再ログインを促す
    if (
      type === 'axios' &&
      err.response?.responseData &&
      err.response?.status === 500 &&
      err.response?.responseData.message.startsWith(
        '見つかりませんでした。 client_company_hash_id:'
      )
    ) {
      alert(
        'アカウント情報がありませんでした。再度ログインをお願いいたします。'
      )
      context.$services.auth.logout()
      return false
    }

    // @MEMO: proposalが含まれているが遷移させる
    if (context.route.path.match('/client/proposal_contracted')) {
      return true
    }

    // オペレーション・メール認証・メッセージ以外
    return (
      !context.route.path.match('/operation/') &&
      !context.route.path.match('/client/activate/resend') &&
      !context.route.path.match('/proposal')
    )
  }

  function dontReport(type: string, err: any) {
    if (
      type === 'axios' &&
      err.response?.status === 401 &&
      err.response?.responseData?.message === 'Unauthenticated.'
    ) {
      return true
    }

    return false
  }

  function makeUUID() {
    const S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }

    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    )
  }
}
