














import { Component, Prop, Vue } from 'nuxt-property-decorator'
import {
  ConnectServiceAgreementModalStore,
  TopicStore,
} from '~/utils/store-accessor'
import { IConnectProposal, IThreadDetail, ITopic } from '~/Interfaces'

@Component
export default class ConnectQuotationConfirmToClient extends Vue {
  @Prop()
  private threadDetail!: IThreadDetail

  next() {
    const goToQuotation = () => {
      const quotationUrl =
        this.url +
        'client/quotation/' +
        this.proposal?.connectProductHashId +
        '/' +
        this.proposal?.connectProposalHashId

      window.open(quotationUrl, '_blank')
    }

    goToQuotation()
  }

  get buttonLabel(): string {
    if (this.proposal?.isRequestedQuotationApprove) {
      return '発注書を確認する'
    }
    return '見積を確認する'
  }

  get url(): string {
    return this.$config.APP_URL_CONNECT
  }

  get proposal(): IConnectProposal | undefined {
    return this.topic?.thread.connectProposal ?? undefined
  }

  get topic(): ITopic | undefined {
    return TopicStore.getTopic
  }
}
