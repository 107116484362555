









































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposalStatus } from '~/Interfaces/IConnectProposalStatus'
@Component
export default class ClientProduct extends Vue {
  @Prop()
  private connectProposalStatus!: IConnectProposalStatus
}
