



























import { Component, Emit, Vue } from 'nuxt-property-decorator'

@Component
export default class ConnectClientTemplate extends Vue {
  @Emit('copyTemplate')
  private copyTemplate(index: number): string {
    const textarea = this.$refs[index] as HTMLTextAreaElement

    return textarea.value
  }
}
