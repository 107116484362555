































import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import IConnectProposalDetail from '~/Interfaces/ConnectProposalDetail/IConnectProposalDetail'
import { ClientConnectProposalDetailsStore } from '~/utils/store-accessor'
import { IConnectProposal } from '~/Interfaces'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: { ConnectIcon },
})
export default class ClientProposalListItemDescription extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  private mount: boolean = false

  mounted() {
    this.mount = true
  }

  @Watch('mount')
  onMountedChange() {
    if (this.$refs.etc) {
      const etc = this.$refs.etc as HTMLDivElement[]
      if (etc[0]?.textContent?.length ?? 0 > 10) {
        etc[0].textContent = etc[0].textContent?.slice(0, 10) + '...'
      }
    }
  }

  get connectProposalDetail(): IConnectProposalDetail | undefined {
    return ClientConnectProposalDetailsStore.find(
      this.connectProposal.connectProposalHashId
    )
  }

  get prioritiesMaster(): string[] {
    return ClientConnectProposalDetailsStore.getPrioritiesMaster
  }

  get priorities(): string[] | undefined {
    return this.connectProposalDetail?.priorities.slice().sort((v, v2) => {
      if (v === '0') {
        return 1
      }

      return parseInt(v) - parseInt(v2)
    })
  }
}
