















import { Component, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import { IConnectCompanyMember } from '~/Interfaces'
import MemberDetail from '~/components/Profile/ConnectCompany/Member/MemberDetail.vue'
import { PartnerModal } from '~/components/common'

@Component({
  components: {
    MemberDetail,
    PartnerModal,
  },
})
export default class MemberModal extends Vue {
  @Prop()
  private connectCompanyMember!: IConnectCompanyMember

  @PropSync('isVisible')
  private visible!: boolean

  @Prop({ default: false })
  private isClient!: boolean

  get newTabOpenButtonUrl() {
    return this.isClient
      ? `/client/profile/connect_company/${this.connectCompanyMember.connectCompanyHashId}/member/${this.connectCompanyMember.connectCompanyMemberHashId}`
      : `/company/profile/connect_company/new_preview/members/${this.connectCompanyMember.connectCompanyMemberHashId}`
  }

  private onClose() {
    this.visible = false
  }
}
