// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".root_fobnh{position:-webkit-sticky;position:sticky;top:0;background:#fdf3f4;border-color:#eeacb2;border-style:solid;border-width:1px 0;min-height:38px;padding:10px 0;z-index:1}.container_3xRrw,.root_fobnh{display:flex;align-items:center}.container_3xRrw{width:100%;justify-content:space-between;padding:0 15px}.left_2jU-v{justify-content:flex-start}.left_2jU-v,.right_3tjGT{display:flex;align-items:center;grid-gap:0 4px;gap:0 4px}.right_3tjGT{justify-content:flex-end}.title_OuA9h{font-weight:700;text-wrap:nowrap}.text_3P_M_,.title_OuA9h{font-size:12px;line-height:18px;color:#222}.text_3P_M_{font-weight:400}.link_154Kb{display:flex;justify-content:center;align-items:center;padding:2px 12px;grid-gap:6px;gap:6px;width:168px;height:24px;background:#fff;border:1px solid #494746;border-radius:4px;font-weight:700;font-size:12px;line-height:18px;color:#222;text-decoration:none;text-wrap:nowrap}.close_2QBne{padding-top:4px;border:none;background:transparent;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"root": "root_fobnh",
	"container": "container_3xRrw",
	"left": "left_2jU-v",
	"right": "right_3tjGT",
	"title": "title_OuA9h",
	"text": "text_3P_M_",
	"link": "link_154Kb",
	"close": "close_2QBne"
};
module.exports = exports;
