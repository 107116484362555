// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".itemButton_3ztQr{background:#fff;padding:9px 10px}.itemButton_3ztQr.grey_1VrR7{background:#f9f9f9}.inner_Be1fm{display:flex;justify-content:space-between;align-items:center}.label_2-4a-{font-style:normal;font-weight:400;font-size:14px;line-height:21px;color:#2c3846}.buttonList_DKBcY{display:flex;justify-content:flex-start;grid-gap:0 10px;gap:0 10px}.toggle_2K4Ym{display:flex;justify-content:center;align-items:center;width:59px;height:24px;background:#e9edf1;color:#2f5980;opacity:.3;border:none;border-radius:20px;cursor:pointer}.toggle_2K4Ym.selected_3ZXAp{opacity:1;background:#e9edf1;border:3px solid #209cd0}.toggle_2K4Ym.error_3rQDZ{background:rgba(233,237,241,.13333);color:rgba(47,89,128,.26667);border:1px solid #d60303!important;opacity:1}.options_YGIaN{display:flex;justify-content:space-between;align-items:center;font-weight:400;font-size:14px;line-height:21px;color:#2c3846;padding-top:14px}.options_YGIaN .title_rUai1{padding-left:10px}.options_YGIaN .title_rUai1.etc_2hgmu{width:155px}.options_YGIaN .buttons_2kAts{grid-gap:0 5px;gap:0 5px}.options_YGIaN .buttons_2kAts,.options_YGIaN .buttons_2kAts .button_19nFv{display:flex;justify-content:center;align-items:center}.options_YGIaN .buttons_2kAts .button_19nFv{padding:4px 15px;background:#fff;border:1px solid #2f5980;border-radius:12px;font-weight:600;font-size:10px;line-height:15px;color:#2f5980;cursor:pointer}.options_YGIaN .buttons_2kAts .button_19nFv.selected_3ZXAp{background:#2f5980;color:#fff}.options_YGIaN .buttons_2kAts .button_19nFv:disabled{border:none;background:#e9edf1;color:#2c3846;opacity:.3;cursor:not-allowed}.options_YGIaN .buttons_2kAts .button_19nFv.error_3rQDZ{border:1px solid #d60303!important}.options_YGIaN .input_Dj2IH{flex:1}", ""]);
// Exports
exports.locals = {
	"itemButton": "itemButton_3ztQr",
	"grey": "grey_1VrR7",
	"inner": "inner_Be1fm",
	"label": "label_2-4a-",
	"buttonList": "buttonList_DKBcY",
	"toggle": "toggle_2K4Ym",
	"selected": "selected_3ZXAp",
	"error": "error_3rQDZ",
	"options": "options_YGIaN",
	"title": "title_rUai1",
	"etc": "etc_2hgmu",
	"buttons": "buttons_2kAts",
	"button": "button_19nFv",
	"input": "input_Dj2IH"
};
module.exports = exports;
