// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".clientAccountTable_pZEbo{width:100%;max-width:740px}.clientAccountTable__head_19NCb{color:grey;font-size:14px;font-weight:600;line-height:21px;letter-spacing:0;text-align:left;height:41px;border-bottom:1px solid #c8c7c6;display:grid;grid-template-columns:160px calc(100% - 220px) 60px}.clientAccountTable__cell_IB3d0{display:flex;align-items:center;justify-content:flex-start}.clientAccountTable__cell_IB3d0.name_3g2Oy{padding-left:60px;width:160px}.clientAccountTable__cell_IB3d0.role_39dI3{width:60px}", ""]);
// Exports
exports.locals = {
	"clientAccountTable": "clientAccountTable_pZEbo",
	"clientAccountTable__head": "clientAccountTable__head_19NCb",
	"clientAccountTable__cell": "clientAccountTable__cell_IB3d0",
	"name": "name_3g2Oy",
	"role": "role_39dI3"
};
module.exports = exports;
