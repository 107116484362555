






import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposalStatus } from '~/Interfaces'

@Component
export default class ClientProposalListItemStatusLabel extends Vue {
  @Prop()
  private connectProposalStatus!: IConnectProposalStatus

  get connectProposalStatusValue(): string {
    return this.connectProposalStatus as any
  }

  get connectProposalStatusLabel(): string {
    switch (this.connectProposalStatusValue) {
      case 'waiting':
        return '未対応'
      case 'negotiating':
        return '検討中'
      case 'pending':
        return '保留'
      case 'accepted':
        return '依頼'
      case 'rejected':
        return '見送り'
      case 'terminated':
        return '見送り'
      case 'rejected_not_correspond':
        return '見送り'
      default:
        return ''
    }
  }
}
