import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import {
  IProductCheckBox,
  IProductCheckBoxList,
} from '~/Interfaces/connectCompany/IProductCheckBox'
import {
  TSupportButtonSubItem,
  TSupportButtonTemplates,
  TSupportButtonTemplateValue,
} from '~/Interfaces/connectCompany/ISupportItem'
import {
  TAchievementConstants,
  TAchievementErrors,
  TConnectCompanySupportAchievement,
  TConnectCompanySupportAchievementDetail,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'

export default class AchievementValidateService {
  private errors: TAchievementErrors = {
    title: false,
    companyName: false,
    startDate: false,
    endDate: false,
    dateErrorMessage: '',
    startDateErrorMessage: '',
    supportedProducts: false,
    supportedProductsOther: false,
    supportedProductsDetail: false,
    industries: false,
    industriesOther: false,
    rewardType: false,
    companyScale: false,
    companyForm: false,
    textLength: {
      title: false,
      companyName: false,
      supportedProductsDetail: false,
      overview: false,
      mainAchievement: false,
    },
    details: [],
    detailsAllZero: false,
    membersContent: [],
    membersHash: [],
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly MAX_LENGTH_50: number = 50,
    private readonly MAX_LENGTH_100: number = 100,
    private readonly MAX_LENGTH_200: number = 200,
    private readonly MAX_LENGTH_300: number = 300
  ) {}

  public achievementChange(achievement: TConnectCompanySupportAchievement) {
    if (achievement.title.length >= 1) {
      this.errors.title = false
    }
    if (achievement.companyName.length >= 1) {
      this.errors.companyName = false
    }
    if (achievement.supportedProductsDetail.length >= 1) {
      this.errors.supportedProductsDetail = false
    }
    if (achievement.startDate.length >= 1) {
      this.errors.startDate = false
      this.errors.endDate = false
    }
    if (achievement.supportedProducts.length >= 1) {
      this.errors.supportedProducts = false
    }
    if (achievement.industries.length >= 1) {
      this.errors.industries = false
    }
    if (achievement.rewardType !== 0) {
      this.errors.rewardType = false
    }
    if (achievement.companyScale !== 0) {
      this.errors.companyScale = false
    }
    if (achievement.companyForm !== 0) {
      this.errors.companyForm = false
    }
    if (achievement.details.filter((d) => d.status === 0).length >= 1) {
      this.errors.detailsAllZero = false
    }
  }

  public validate(
    achievement: TConnectCompanySupportAchievement,
    contents: TAchievementConstants
  ): boolean {
    this.formatErrors()
    const errorsSnapShot = cloneDeep(this.errors)

    this.validateTitle(achievement)
    this.validateCompanyName(achievement)
    this.validateTerm(achievement)
    this.validateRewardType(achievement)
    this.validateCompanyScale(achievement)
    this.validateCompanyForm(achievement)
    this.validateSupportedProducts(achievement, contents)
    this.validateSupportedProductsDetail(achievement)
    this.validateIndustries(achievement, contents)
    this.validateOverview(achievement)
    this.validateMainAchievement(achievement)
    this.validateDetails(achievement, contents)
    this.validateMember(achievement)

    return JSON.stringify(this.errors) === JSON.stringify(errorsSnapShot)
  }

  public getErrors(): TAchievementErrors {
    return this.errors
  }

  /**
   * エラーデータの初期化
   * @private
   */
  private formatErrors(): void {
    this.errors.title = false
    this.errors.companyName = false
    this.errors.startDate = false
    this.errors.endDate = false
    this.errors.dateErrorMessage = ''
    this.errors.startDateErrorMessage = ''
    this.errors.supportedProducts = false
    this.errors.supportedProductsOther = false
    this.errors.supportedProductsDetail = false
    this.errors.industries = false
    this.errors.industriesOther = false
    this.errors.rewardType = false
    this.errors.companyScale = false
    this.errors.companyForm = false
    this.errors.textLength = {
      title: false,
      companyName: false,
      supportedProductsDetail: false,
      overview: false,
      mainAchievement: false,
    }
    this.errors.details = []
    this.errors.detailsAllZero = false
    this.errors.membersContent = []
    this.errors.membersHash = []
  }

  /**
   * タイトルのバリデーション
   * @param achievement
   * @private
   */
  private validateTitle(achievement: TConnectCompanySupportAchievement): void {
    if (achievement.title.length === 0) {
      this.errors.title = true
    }
    if (achievement.title.length > this.MAX_LENGTH_50) {
      this.errors.textLength.title = true
    }
  }

  /**
   * 支援先のバリデーション
   * @param achievement
   * @private
   */
  private validateCompanyName(
    achievement: TConnectCompanySupportAchievement
  ): void {
    if (achievement.companyName.length === 0) {
      this.errors.companyName = true
    }
    if (achievement.companyName.length > this.MAX_LENGTH_50) {
      this.errors.textLength.companyName = true
    }
  }

  /**
   * 支援期間のバリデーション
   * @param achievement
   * @private
   */
  private validateTerm(achievement: TConnectCompanySupportAchievement): void {
    const startDate = dayjs(achievement.startDate)
    const endDate = dayjs(achievement.endDate)
    if (startDate.isAfter(dayjs())) {
      this.errors.startDate = true
      this.errors.startDateErrorMessage =
        '開始日は未来ではない日付を入力してください。'
    }

    if (!achievement.isOngoing) {
      if (achievement.startDate.length === 0) {
        this.errors.startDate = true
        return
      }

      if (achievement.endDate.length === 0) {
        this.errors.endDate = true
        this.errors.dateErrorMessage = '必ず終了日を入力してください。'
        return
      }

      if (endDate.isBefore(startDate)) {
        this.errors.endDate = true
        this.errors.dateErrorMessage =
          '終了日は開始日より前の日付を入れてください。'
      }
    } else if (achievement.endDate.length >= 1) {
      this.errors.endDate = true
      this.errors.dateErrorMessage =
        '終了している場合は現在継続中のチェックを外してください。'
    }
  }

  /**
   * 報酬形態のバリデーション
   * @param achievement
   * @private
   */
  private validateRewardType(
    achievement: TConnectCompanySupportAchievement
  ): void {
    if (achievement.rewardType === 0) {
      this.errors.rewardType = true
    }
  }

  /**
   * 販売先の企業規模のバリデーション
   * @param achievement
   * @private
   */
  private validateCompanyScale(
    achievement: TConnectCompanySupportAchievement
  ): void {
    if (achievement.companyScale === 0) {
      this.errors.companyScale = true
    }
  }

  /**
   * 販売先の企業形態のバリデーション
   * @param achievement
   * @private
   */
  private validateCompanyForm(
    achievement: TConnectCompanySupportAchievement
  ): void {
    if (achievement.companyForm === 0) {
      this.errors.companyForm = true
    }
  }

  /**
   * 支援したサービス/商材の種類のバリデーション
   * @param achievement
   * @param contents
   * @private
   */
  private validateSupportedProducts(
    achievement: TConnectCompanySupportAchievement,
    contents: TAchievementConstants
  ): void {
    if (achievement.supportedProducts.length === 0) {
      this.errors.supportedProducts = true
    } else {
      contents.supportedProducts.map((list: IProductCheckBoxList) => {
        const value = list.find((v: IProductCheckBox) => v.otherInput)
        if (
          value &&
          achievement.supportedProducts.includes(value.id) &&
          achievement.supportedProductsOther.length === 0
        ) {
          this.errors.supportedProductsOther = true
        }
      })
    }
  }

  /**
   * 支援したサービス/商材のバリデーション
   * @param achievement
   * @private
   */
  private validateSupportedProductsDetail(
    achievement: TConnectCompanySupportAchievement
  ): void {
    if (achievement.supportedProductsDetail.length === 0) {
      this.errors.supportedProductsDetail = true
    }
    if (achievement.supportedProductsDetail.length > this.MAX_LENGTH_100) {
      this.errors.textLength.supportedProductsDetail = true
    }
  }

  /**
   * 販売先の業界のバリデーション
   * @param achievement
   * @param contents
   * @private
   */
  private validateIndustries(
    achievement: TConnectCompanySupportAchievement,
    contents: TAchievementConstants
  ): void {
    if (achievement.industries.length === 0) {
      this.errors.industries = true
    } else {
      contents.industries.map((list: IProductCheckBoxList) => {
        const value = list.find((v: IProductCheckBox) => v.otherInput)
        if (
          value &&
          achievement.industries.includes(value.id) &&
          achievement.industriesOther.length === 0
        ) {
          this.errors.industriesOther = true
        }
      })
    }
  }

  /**
   * 背景と企業概要のバリデーション
   * @param achievement
   * @private
   */
  private validateOverview(
    achievement: TConnectCompanySupportAchievement
  ): void {
    if (achievement.overview.length > this.MAX_LENGTH_300) {
      this.errors.textLength.overview = true
    }
  }

  /**
   * 主な実績のバリデーション
   * @param achievement
   * @private
   */
  private validateMainAchievement(
    achievement: TConnectCompanySupportAchievement
  ): void {
    if (achievement.mainAchievement.length > this.MAX_LENGTH_300) {
      this.errors.textLength.mainAchievement = true
    }
  }

  /**
   * 実施内容一覧のバリデーション
   * @param achievement
   * @param contents
   * @private
   */
  private validateDetails(
    achievement: TConnectCompanySupportAchievement,
    contents: TAchievementConstants
  ): void {
    // 各項目で未入力があるかどうかのチェック
    contents.achievementDetails.map((template: TSupportButtonTemplates) => {
      template.values.map((value: TSupportButtonTemplateValue) => {
        const detail = achievement.details?.find(
          (v: TConnectCompanySupportAchievementDetail) =>
            v.detailId === value.id
        )

        if (value.subItems) {
          value.subItems.map((item: TSupportButtonSubItem) => {
            const checkValue =
              item.type === 'select' ? detail?.areas : detail?.remark

            if (detail?.status !== 0 && checkValue?.length === 0) {
              // ステータスが 未実施の場合、サブ項目が未入力は許さない
              this.errors.details.push(value.id)
            }
          })
        }
      })
    })

    // 全部が未実施になっているかどうかのチェック
    if (
      achievement.details.length ===
      achievement.details.filter((d) => d.status === 0).length
    ) {
      this.errors.detailsAllZero = true
    }
  }

  /**
   * メンバーのバリデーション
   * @param achievement
   */
  public validateMember(achievement: TConnectCompanySupportAchievement): void {
    this.errors.membersContent = []
    this.errors.membersHash = []

    achievement.members.map((member, key) => {
      if (
        member.content.length === 0 ||
        member.content.length > this.MAX_LENGTH_200
      ) {
        this.errors.membersContent[key] = true
      }

      if (member.memberHash === '') {
        this.errors.membersHash[key] = true
      }
    })
  }
}
