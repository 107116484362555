// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".enterActive_3rkaf{transition:opacity .3s}.leaveActive_2tn5X{opacity:0}.wrapper_2NjTs{display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;height:100%;width:100%;z-index:10}.modal_3bXdS{position:relative;padding:60px 40px;background:#fff;box-shadow:0 0 20px rgba(109,132,155,.3);border-radius:10px;text-align:center;margin:0 auto;z-index:11;max-width:720px}.close_1qjvE{position:absolute;background:none;border:none;cursor:pointer;padding:0;margin:0;width:32px;height:32px;border-radius:50%;top:11px;right:11px}.close_1qjvE .icon_24N-q{color:#7ca8d5;font-size:18px}.title_jVTOM{font-weight:600;font-size:18px;line-height:130%}.description_3cqs2,.title_jVTOM{text-align:center;color:#0e273c;padding:0;margin:0 auto 40px}.description_3cqs2{font-weight:400;font-size:14px;line-height:21px}.buttons_2LDtE{display:flex;justify-content:center;align-items:center;grid-gap:0 20px;gap:0 20px}", ""]);
// Exports
exports.locals = {
	"enterActive": "enterActive_3rkaf",
	"leaveActive": "leaveActive_2tn5X",
	"wrapper": "wrapper_2NjTs",
	"modal": "modal_3bXdS",
	"close": "close_1qjvE",
	"icon": "icon_24N-q",
	"title": "title_jVTOM",
	"description": "description_3cqs2",
	"buttons": "buttons_2LDtE"
};
module.exports = exports;
