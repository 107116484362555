// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fade_active_GlupC{transition:opacity .3s ease-out}.fade_leave_2IIAJ{opacity:0}.upload_296xo .layout_3IUij{position:fixed;z-index:10;top:0;left:0;cursor:auto;display:flex;align-items:center;justify-content:center;height:100%;width:100%}.upload_296xo .layout_3IUij .box_2Y8Fo{padding:0 60px;position:absolute;width:760px;background:#fff;box-shadow:0 0 20px rgba(56,72,88,.2);border-radius:6px}.upload_296xo .layout_3IUij .box_2Y8Fo .close_button_-e4Xy{cursor:pointer;position:absolute;top:25px;right:25px}.upload_296xo .layout_3IUij .box_2Y8Fo .title_12B1I{margin-top:40px;display:flex;justify-content:center}.upload_296xo .layout_3IUij .box_2Y8Fo .title_12B1I span{font-size:16px;font-weight:600;line-height:16px;letter-spacing:0;color:#2c3846}.upload_296xo .layout_3IUij .box_2Y8Fo p{margin-top:30px;font-size:12px;line-height:20px;letter-spacing:0;color:#2c3846}.upload_296xo .layout_3IUij .box_2Y8Fo p span{font-weight:600}.upload_296xo .layout_3IUij .box_2Y8Fo .drop_area_3RYB5{margin-top:30px;margin-bottom:60px;display:flex;flex-flow:column;align-items:center;background:#edf4ff;border:2px dashed #b5bdbe;border-radius:10px}.upload_296xo .layout_3IUij .box_2Y8Fo .drop_area_3RYB5 img{margin-top:30px}.upload_296xo .layout_3IUij .box_2Y8Fo .drop_area_3RYB5 p{margin-top:15px;font-size:12px;letter-spacing:0;color:#b5bdbe}.upload_296xo .layout_3IUij .box_2Y8Fo .drop_area_3RYB5 .button_Nglai{cursor:pointer;margin-top:13px;margin-bottom:30px;display:flex;align-items:center;justify-content:center;width:178px;height:40px;background:transparent linear-gradient(90deg,#2b61e8,#0030ce);border-radius:30px;font-size:16px;font-weight:600;letter-spacing:0;color:#fff}.upload_296xo .layout_3IUij .box_2Y8Fo .drop_area_3RYB5 input{display:none}", ""]);
// Exports
exports.locals = {
	"fade_active": "fade_active_GlupC",
	"fade_leave": "fade_leave_2IIAJ",
	"upload": "upload_296xo",
	"layout": "layout_3IUij",
	"box": "box_2Y8Fo",
	"close_button": "close_button_-e4Xy",
	"title": "title_12B1I",
	"drop_area": "drop_area_3RYB5",
	"button": "button_Nglai"
};
module.exports = exports;
