// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3Usmc{display:flex;grid-gap:5px;gap:5px}.container_3Usmc .icon_wrapper_38OfR{display:flex;align-items:center;justify-content:center;width:20px;height:20px}.container_3Usmc .label_18q_z{font-size:14px;font-weight:600;font-family:\"Hiragino Kaku Gothic Pro\";color:#454545}", ""]);
// Exports
exports.locals = {
	"container": "container_3Usmc",
	"icon_wrapper": "icon_wrapper_38OfR",
	"label": "label_18q_z"
};
module.exports = exports;
