






























import { Component, Emit, Vue } from 'nuxt-property-decorator'
import { IConnectCompany } from '~/Interfaces'
import ConnectButton from '~/components/common/components/ConnectButton.vue'
import ConnectModal from '~/components/common/components/ConnectModal.vue'
import InvoiceForm from '~/components/common/profile/partner/InvoiceForm/index.vue'
import { ConnectCompanyStore, ModalStore } from '~/utils/store-accessor'

@Component({
  components: {
    ConnectButton,
    ConnectModal,
    InvoiceForm,
  },
})
export default class InvoiceFormModal extends Vue {
  private disabled: boolean = true

  get connectCompany(): IConnectCompany | undefined {
    return ConnectCompanyStore.getConnectCompany
  }

  private updateForm(value: IConnectCompany) {
    const updatedConnectCompany = {
      ...this.connectCompany,
      invoiceNumber: value.invoiceNumber,
      isIssuingInvoiceNumber: !!value.invoiceNumber,
    } as IConnectCompany
    ConnectCompanyStore.setConnectCompany(updatedConnectCompany)

    this.disabled = !(this.$refs.invoiceForm as any).validator()
  }

  @Emit('submit')
  private async submit() {
    if (!(this.$refs.invoiceForm as any).validator()) {
      return
    }

    // TODO: プロフィール刷新後には刷新後のAPIを呼び出すようにする
    const result = await this.$services.company.connectCompanyOverview(
      this.connectCompany?.overview ?? '',
      this.connectCompany?.uploadImageUrl ?? null,
      this.connectCompany?.invoiceNumber ?? null,
      false
    )

    if (result) {
      await this.$auth.fetchUser()
    }

    this.$emit('submit')
    ModalStore.hide('invoiceFormModal')
  }
}
