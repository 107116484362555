// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".review_10agK{grid-gap:5px;gap:5px}.review_10agK,.review_10agK .count_review_2xN1P,.review_10agK .stars_3-t0N{display:flex;align-items:center}.review_10agK .count_review_2xN1P span{color:grey;color:var(--Text-50,grey);font-family:Hiragino Kaku Gothic Pro;font-size:13px;font-style:normal;font-weight:400;line-height:normal}", ""]);
// Exports
exports.locals = {
	"review": "review_10agK",
	"count_review": "count_review_2xN1P",
	"stars": "stars_3-t0N"
};
module.exports = exports;
