// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".breadcrumb_-1Nje .breadcrumbItems_3WdLa{display:flex;justify-content:flex-start;align-items:center;list-style:none;grid-gap:0 10px;gap:0 10px;padding:0}.breadcrumb_-1Nje .breadcrumbItems_3WdLa .breadcrumbItem_1r_kz{font-style:normal;font-weight:400;font-size:14px;line-height:21px}.breadcrumb_-1Nje .breadcrumbItems_3WdLa .breadcrumbItem_1r_kz .breadcrumbItem__icon_Q_Q8V{color:#b5bdbe;margin-left:5px}.breadcrumb_-1Nje .breadcrumbItems_3WdLa .breadcrumbItem_1r_kz .breadcrumbItem__link_WwWi4{color:#209cd0;text-decoration:none}.breadcrumb_-1Nje .breadcrumbItems_3WdLa .breadcrumbItem_1r_kz .breadcrumbItems__text_9wJtk{color:#0e273c}", ""]);
// Exports
exports.locals = {
	"breadcrumb": "breadcrumb_-1Nje",
	"breadcrumbItems": "breadcrumbItems_3WdLa",
	"breadcrumbItem": "breadcrumbItem_1r_kz",
	"breadcrumbItem__icon": "breadcrumbItem__icon_Q_Q8V",
	"breadcrumbItem__link": "breadcrumbItem__link_WwWi4",
	"breadcrumbItems__text": "breadcrumbItems__text_9wJtk"
};
module.exports = exports;
