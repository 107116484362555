// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button_3ocvo{position:relative;font-size:12px;font-weight:600;line-height:18px;letter-spacing:0;text-align:center;color:#555;display:flex;justify-content:center;align-items:center;border-radius:100px;padding:4px 26px;cursor:pointer;border:none;background-color:transparent}.button_3ocvo:hover{background:#f3f2f2}.button_3ocvo:active{background:#d6d5d4}.button_3ocvo:disabled{background:#afadac;color:grey;cursor:not-allowed}.button_3ocvo .icon_1kf_C{position:absolute}.button_3ocvo .icon_1kf_C.left_1IIEf{left:5px;top:6px}.button_3ocvo .icon_1kf_C.right_3RBHB{right:5px;top:6px}", ""]);
// Exports
exports.locals = {
	"button": "button_3ocvo",
	"icon": "icon_1kf_C",
	"left": "left_1IIEf",
	"right": "right_3RBHB"
};
module.exports = exports;
