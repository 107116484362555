import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import {
  IConnectTroubleReportPartner,
  IConnectTroubleReportProduct,
} from '~/Interfaces/connectTroubleReport/IConnectTroubleReport'
import { IUploadFile } from '~/pages/client/report/trouble/index.vue'

export default class ConnectTroubleReportService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async getProducts(): Promise<{ id: string; text: string }[]> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.get(
        'api/report/trouble_report/products'
      )
      const products = JSON.parse(JSON.stringify(response.data))
      return products.map((product: IConnectTroubleReportProduct) => {
        return { id: product.connectProductHashId, text: product.name }
      })
    } catch (err) {
      console.log(err)
      return []
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  async getPartners(
    connectProductHashId: string
  ): Promise<{ id: string; text: string }[]> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.get(
        'api/report/trouble_report/partners/' + connectProductHashId
      )
      const partners = JSON.parse(JSON.stringify(response.data))

      return partners.map((product: IConnectTroubleReportPartner) => {
        return { id: product.companyHashId, text: product.name }
      })
    } catch (err) {
      console.log(err)
      return []
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   *
   * @param file
   */
  async uploadFile(file: File): Promise<string | undefined> {
    LockStore.lock(LockStore.types.message)

    const headers = { 'content-type': 'multipart/form-data' }
    const data = new FormData()
    data.append('file', file)

    try {
      const response = await this.axios.post(
        'api/report/trouble_report/upload_file',
        data,
        {
          headers,
        }
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data.path))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  /**
   *
   * @param productHashId
   * @param companyHashId
   * @param type
   * @param content
   * @param files
   * @param productName
   * @param partnerName
   */
  async register(
    productHashId: string,
    companyHashId: string,
    type: string,
    content: string,
    files: IUploadFile[],
    productName: string,
    partnerName: string
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.message)

    files.forEach((file) => {
      delete file.file
    })

    files.forEach((file, index) => {
      file.index = index
    })

    try {
      const response = await this.axios.post(
        'api/report/trouble_report/register',
        {
          productHashId,
          companyHashId,
          type,
          content,
          files,
          productName,
          partnerName,
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  async isFirstRequestApproveQuotation(): Promise<boolean> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const response = await this.axios.get(
        'api/report/trouble_report/is_first_request_approve_quotation'
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return true
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }
}
