// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".text_QLLXf{font-style:normal;font-weight:400;font-size:14px;line-height:21px;color:#2c3846;text-align:left;padding:0 0 30px;white-space:pre-wrap}.text_QLLXf.thin_3f67Q{padding:0}", ""]);
// Exports
exports.locals = {
	"text": "text_QLLXf",
	"thin": "thin_3f67Q"
};
module.exports = exports;
