// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".clientProposalTable_3sbSU{background:#f3f2f2;background:var(--grey-20,#f3f2f2);border-radius:2px;padding:20px}.clientProposalTable_3sbSU .accordionButton_1e2VE{padding:0;display:flex;justify-content:flex-start;align-items:center;background:transparent;border:none;grid-gap:0 10px;gap:0 10px;cursor:pointer}.clientProposalTable_3sbSU .accordionButton_1e2VE .icon_3_Psc{width:24px;height:24px}.clientProposalTable_3sbSU .accordionButton_1e2VE .count_X_1ab{display:flex;align-items:center;justify-content:center;width:30px;height:26px;border-radius:2px;background:#ebebea;background:var(--grey-30,#ebebea);color:#555;color:var(--text-grey-dark,var(--text-60,#555));font-family:Hiragino Kaku Gothic Pro;font-size:13px;font-style:normal;font-weight:600;line-height:normal}.clientProposalTable_3sbSU .table_3Pxlz{margin-top:20px;border:1px solid #c8c7c6;border-color:var(--Grey-50,#c8c7c6);border-bottom:0 solid var(--Grey-50,#c8c7c6)}.clientProposalTable_3sbSU .emptyText_WpEfy{font-weight:400;font-size:14px;line-height:21px;color:#000;padding:20px 25px 0}", ""]);
// Exports
exports.locals = {
	"clientProposalTable": "clientProposalTable_3sbSU",
	"accordionButton": "accordionButton_1e2VE",
	"icon": "icon_3_Psc",
	"count": "count_X_1ab",
	"table": "table_3Pxlz",
	"emptyText": "emptyText_WpEfy"
};
module.exports = exports;
