export default class WindowService {
  /**
   * URLをpushする
   * @param url
   */
  public pushState(url: string)
  {
    window.history.pushState(null, '', url)
  }

  /**
   * スムーズスクロール
   * @param top
   */
  public smoothScrollTo(top: number)
  {
    window.scrollTo({
      top: top,
      behavior: 'smooth'
    })
  }

  /**
   * スクロール
   * @param top
   */
  public scrollTo(top: number)
  {
    window.scrollTo({
      top: top,
    })
  }

  /**
   * innerHeightを取得する
   */
  public getInnerHeight(): number
  {
    return window.innerHeight
  }

  /**
   * innerWidthを取得する
   */
  public getInnerWidth(): number
  {
    return window.innerWidth
  }
}
