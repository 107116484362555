// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".message_other_24oie{margin-right:110px}.message_other_24oie.sp_D3-XE{margin-right:120px;margin-left:20px}.message_other_24oie .top_2VrIU{display:flex;justify-content:space-between}.message_other_24oie .top_2VrIU .user_vGgCL{display:flex;align-items:center}.message_other_24oie .top_2VrIU .user_vGgCL .name_veLjQ{margin-left:10px;font-size:14px;font-weight:600;color:#000;letter-spacing:0}.message_other_24oie .top_2VrIU .user_vGgCL .image_WmEk3{width:34px;height:34px;border-radius:50%}.message_other_24oie .top_2VrIU .time_1XN6A{display:flex;align-items:flex-end;font-size:10px;letter-spacing:0;color:#b3b3b3}.message_other_24oie .message_3ZCsU{margin-top:10px;padding:30px;border-radius:0 10px 10px 10px;font-size:14px;letter-spacing:0;line-height:21px}.message_other_24oie .message_3ZCsU .text_M2IBO{white-space:pre-wrap;word-break:break-all}.message_other_24oie .message_3ZCsU.connect_KkB7T{background:#f4f9ff}.message_other_24oie .message_3ZCsU.kakutoku_2jYSE{background:#ccecfa}.message_other_24oie .message_3ZCsU.cloud_3n80m{background:#fff}.message_other_24oie .message_3ZCsU .attachment_3gy79 a{color:#000}.message_other_24oie .time_bottom_2TvS4{margin-top:8px;display:flex;justify-content:flex-end;font-size:10px;letter-spacing:0;color:#b3b3b3}", ""]);
// Exports
exports.locals = {
	"message_other": "message_other_24oie",
	"sp": "sp_D3-XE",
	"top": "top_2VrIU",
	"user": "user_vGgCL",
	"name": "name_veLjQ",
	"image": "image_WmEk3",
	"time": "time_1XN6A",
	"message": "message_3ZCsU",
	"text": "text_M2IBO",
	"connect": "connect_KkB7T",
	"kakutoku": "kakutoku_2jYSE",
	"cloud": "cloud_3n80m",
	"attachment": "attachment_3gy79",
	"time_bottom": "time_bottom_2TvS4"
};
module.exports = exports;
