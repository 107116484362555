

















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'
import { ModalStore } from '~/utils/store-accessor'

@Component
export default class ClientProposalContract extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  get url() {
    return `/client/quotation/${this.connectProposal.connectProductHashId}/${this.connectProposal.connectProposalHashId}`
  }

  get disabled(): boolean {
    if (this.$services.user.isGuestAccount) {
      return true
    }

    if (this.connectProposal.isRequestedContractTermination) {
      return true
    }

    if (this.connectProposal.isExpired) {
      return true
    }

    return (this.connectProposal.connectProposalStatus as any) === 'terminated'
  }

  private onCancel() {
    ModalStore.show('ConnectCancellationModal')
  }
}
