



























import { Component, Prop, Vue } from 'nuxt-property-decorator'

type Breadcrumb = {
  name: string
  path?: string
}

@Component
export default class ConnectBreadcrumb extends Vue {
  @Prop()
  private breadcrumbs!: Breadcrumb[]
}
