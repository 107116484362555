









































































import { Component, Vue } from 'nuxt-property-decorator'
import { Context } from '@nuxt/types'
import {
  ConnectBlockUnloadMixin,
  ConnectButton,
  ConnectDropdown,
  ConnectInput,
  ConnectInputLabel,
  ConnectTextarea,
  FeatureLabel,
  PartnerButtonColumn,
  PartnerHeader,
  PartnerLayout,
  PartnerTitle,
  Wrapper,
} from '~/components'
import {
  ConnectCompanyProfileStore,
  ModalStore,
  SnackbarStore,
} from '~/utils/store-accessor'
import {
  ConnectCompanyFeaturePayload,
  IConnectCompanyFeatures,
} from '~/Interfaces'

@Component({
  computed: {
    EditFeaturePage() {
      return EditFeaturePage
    },
  },
  components: {
    ConnectTextarea,
    ConnectButton,
    Wrapper,
    PartnerLayout,
    PartnerHeader,
    PartnerTitle,
    ConnectInput,
    ConnectInputLabel,
    ConnectDropdown,
    PartnerButtonColumn,
    FeatureLabel,
  },
  mixins: [ConnectBlockUnloadMixin],
})
export default class EditFeaturePage extends Vue {
  private features!: IConnectCompanyFeatures
  private errors: {
    featureTitle: boolean
    featureDetail: boolean
  } = {
    featureTitle: false,
    featureDetail: false,
  }

  public static FEATURE_TITLE_MAXLENGTH = 50
  public static FEATURE_DETAIL_MAXLENGTH = 100

  mounted() {
    if (!this.$config.PARTNER_NEW_PROFILE_ENABLED) {
      return this.$router.push('/company/profile/connect_company')
    }
  }

  private resetError(index: number, key: 'featureTitle' | 'featureDetail') {
    if (index > 1) return
    this.errors[key] = false
  }

  // @TODO Serviceに切り出すなどして、二重管理を防ぐため共通化する
  // @SEE ConnectCompanyProfileValidator.validateFeature
  // @WARNING ConnectCompanyProfileValidatorと同期させること
  private validator() {
    if (!this.features) return false

    if (!this.errors) {
      this.errors = {
        featureTitle: false,
        featureDetail: false,
      }
    }

    if (!this.features[0] || !this.features[0].featureTitle) {
      this.errors.featureTitle = true
    }

    if (!this.features[0] || !this.features[0].featureDetail) {
      this.errors.featureDetail = true
    }

    const isExceeded = this.features.some((feature) => {
      if (!feature) return false
      if (!feature.featureTitle || !feature.featureDetail) return false

      return (
        feature.featureTitle.length > EditFeaturePage.FEATURE_TITLE_MAXLENGTH ||
        feature.featureDetail.length > EditFeaturePage.FEATURE_DETAIL_MAXLENGTH
      )
    })

    if (isExceeded) return false

    return Object.values(this.errors).every((error) => !error)
  }

  private async onSave() {
    if (!this.validator()) {
      SnackbarStore.show('errorPartnerProfile')
      return
    }

    const payload = {
      features: [
        {
          feature_title: this.features?.[0]?.featureTitle,
          feature_detail: this.features?.[0]?.featureDetail,
        },
        {
          feature_title: this.features?.[1]?.featureTitle ?? null,
          feature_detail: this.features?.[1]?.featureDetail ?? null,
        },
        {
          feature_title: this.features?.[2]?.featureTitle ?? null,
          feature_detail: this.features?.[2]?.featureDetail ?? null,
        },
      ],
    } as ConnectCompanyFeaturePayload
    await this.$services.connectCompanyProfile.postFeatures(payload)
    ;(this as any).resetEdit()

    await this.$services.connectCompanyProfileValidator.refresh()

    SnackbarStore.show('savedPartnerProfile')
  }

  private onBack() {
    ;(this as any).resetEdit()
    ModalStore.show('disposePartnerProfile')
  }

  async asyncData(ctx: Context) {
    const features = await ctx.$services.connectCompanyProfile.getFeatures()
    ConnectCompanyProfileStore.setFeatures(features)

    const featuresStore = [
      {
        featureTitle: features?.[0]?.featureTitle ?? '',
        featureDetail: features?.[0]?.featureDetail ?? '',
        featureOrder: 1,
      },
      {
        featureTitle: features?.[1]?.featureTitle ?? '',
        featureDetail: features?.[1]?.featureDetail ?? '',
        featureOrder: 2,
      },
      {
        featureTitle: features?.[2]?.featureTitle ?? '',
        featureDetail: features?.[2]?.featureDetail ?? '',
        featureOrder: 3,
      },
    ] as IConnectCompanyFeatures

    return {
      features: featuresStore,
    }
  }
}
