
























































































































































































































































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { Dayjs } from 'dayjs'
import Textarea from '~/components/Form/Textarea.vue'
import { ConnectCompanyStore } from '~/utils/store-accessor'
import IConnectCompanyCareer from '~/Interfaces/connectCompany/IConnectCompanyCareer'
import IConnectCompanyConstants from '~/Interfaces/connectCompany/IConnectCompanyConstants'
import ConnectTextarea from '~/components/common/components/ConnectTextarea.vue'
import ProfileConnectCompanyCareerTemplateMainachievement from '~/components/common/profile/connectCompany/profile-connect-company-career-template-mainachievement.vue'

@Component({
  components: {
    ProfileConnectCompanyCareerTemplateMainachievement,
    ConnectTextarea,
    Textarea,
  },
})
export default class ProfileConnectCompanyCareerTemplate extends Vue {
  @Prop()
  private index!: number

  @Prop()
  private constants!: IConnectCompanyConstants

  @Emit()
  private deleteForm(index: number) {}

  private range(start: number, end: number): number[] {
    return [...Array(end + 1).keys()].slice(start)
  }

  get years(): number[] {
    const year = new Date().getFullYear()
    return this.range(1945, year).reverse()
  }

  get months(): number[] {
    return this.range(1, 12)
  }

  dayjs(date: string | null): Dayjs | null {
    if (date === null) {
      return null
    }

    return this.$services.day.getDayjs(date)
  }

  get career(): IConnectCompanyCareer | undefined {
    if (this.careers === undefined) {
      return undefined
    }

    if (this.careers[this.index] === undefined) {
      return undefined
    }

    return this.careers[this.index]
  }

  get careers(): IConnectCompanyCareer[] | undefined {
    const connectCompany = ConnectCompanyStore.getConnectCompany

    return connectCompany?.connectCompanyCareers
  }
}
