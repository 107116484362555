


















































































































































































































import { Component, Prop, PropSync, Vue, Watch } from 'nuxt-property-decorator'
import {
  TAchievementConstants,
  TConnectCompanySupportAchievement,
} from '~/Interfaces/connectCompany/IConnectCompanySupportAchievement'
import AchievementGeneralService from '~/components/Profile/ConnectCompany/Achievement/AchievementGeneralService'
import { TAchievementReviewConstants } from '~/Interfaces/connectCompany/IConnectCompanySupportAchievementReview'
import MemberListRow from '~/components/Profile/ConnectCompany/Member/MemberListRow.vue'
import MemberListCard from '~/components/Profile/ConnectCompany/Member/MemberListCard.vue'
import PartnerProfileModal from '~/components/common/profile/partner/PartnerProfile/PartnerProfileModal.vue'
import ProfileConnectCompanyCareer from '~/components/common/profile/connectCompany/profile-connect-company-career.vue'
import PartnerProfileReviewContent from '~/components/common/profile/partner/PartnerProfile/PartnerProfileReviewContent.vue'
import PartnerProfileAchievementDetailsPreview from '~/components/common/profile/partner/PartnerProfile/PartnerProfileAchievementDetailsPreview.vue'

@Component({
  components: {
    PartnerProfileAchievementDetailsPreview,
    PartnerProfileReviewContent,
    ProfileConnectCompanyCareer,
    PartnerProfileModal,
    MemberListCard,
    MemberListRow,
  },
})
export default class PartnerProfileAchievementDetailModal extends Vue {
  @PropSync('isVisible')
  private visible!: boolean

  @Prop()
  private achievement!: TConnectCompanySupportAchievement | null

  @Prop()
  private constants!: TAchievementConstants

  @Prop()
  private reviewConstants!: TAchievementReviewConstants

  @Prop({ default: false })
  private isClient!: boolean

  private service: AchievementGeneralService = new AchievementGeneralService()

  private onClose() {
    this.visible = false
  }

  get rewardType(): string {
    return this.service.getRewardType(this.achievement, this.constants)
  }

  get term(): string {
    return this.service.getTerm(this.achievement)
  }

  get supportedProducts(): string[] {
    return this.service.getSupportedProducts(this.achievement, this.constants)
  }

  get industries(): string[] {
    return this.service.getIndustries(this.achievement, this.constants)
  }

  get companyScale(): string {
    return this.service.getCompanyScale(this.achievement, this.constants)
  }

  get companyForm(): string {
    return this.service.getCompanyForm(this.achievement, this.constants)
  }

  private onClickMember(member: any) {
    const url = this.isClient
      ? `/client/profile/connect_company/${this.achievement?.connectCompanyHashId}/member/${member.memberHash}`
      : '/company/profile/connect_company/new_preview/members/' +
        member.memberHash
    window.open(url, '_blank')
  }
}
