import { render, staticRenderFns } from "./ConnectV2ConfirmModal.vue?vue&type=template&id=56b4cb7e&scoped=true&"
import script from "./ConnectV2ConfirmModal.vue?vue&type=script&lang=ts&"
export * from "./ConnectV2ConfirmModal.vue?vue&type=script&lang=ts&"
import style0 from "./ConnectV2ConfirmModal.vue?vue&type=style&index=0&lang=scss&module=s&"
import style1 from "./ConnectV2ConfirmModal.vue?vue&type=style&index=1&id=56b4cb7e&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "56b4cb7e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConnectButton: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/ConnectButton.vue').default})
