// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_lTToF{display:inline-block;background:#f9f9f9;padding:20px;width:100%}.title_3pGeb{font-style:normal;font-weight:600;font-size:18px;line-height:130%;color:#2c3846;padding:0 0 20px}.list_2Yk0Z{padding:0 22px;margin:0}.item_2BMDx{position:relative;font-style:normal;font-weight:600;font-size:14px;line-height:21px;display:flex;justify-content:flex-start;align-items:center;color:#2c3846;margin-bottom:2px}.item_2BMDx .icon_2fxz-{position:absolute;top:1px;left:-20px}", ""]);
// Exports
exports.locals = {
	"container": "container_lTToF",
	"title": "title_3pGeb",
	"list": "list_2Yk0Z",
	"item": "item_2BMDx",
	"icon": "icon_2fxz-"
};
module.exports = exports;
