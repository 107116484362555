// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_3GtMU{margin-top:60px;text-align:center;font-size:24px;font-weight:600;letter-spacing:0;color:#002853}.content_u8aD3{margin-top:30px;width:100%}", ""]);
// Exports
exports.locals = {
	"title": "title_3GtMU",
	"content": "content_u8aD3"
};
module.exports = exports;
