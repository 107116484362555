// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".clientProposalDetail_1RPiX{position:fixed;width:100%;height:100vh;top:0;bottom:0;z-index:10}.clientProposalDetail_1RPiX .modal_3nrH2{position:relative;width:900px;height:100%;background:#fff;margin-left:auto;margin-right:0;box-shadow:0 0 20px rgba(109,132,155,.3)}.clientProposalDetail_1RPiX .modal_3nrH2 .content_1CGde{padding:40px;height:calc(100% - 113px)}.clientProposalDetail_1RPiX .modal_3nrH2 .content_1CGde .detailContent_2dId5{padding:40px}.clientProposalDetail_1RPiX .modal_3nrH2 .content_1CGde.proposal__W0KH{overflow-y:auto;padding:0}.clientProposalDetail_1RPiX .modal_3nrH2 .content_1CGde.message_1SW7J{overflow-y:hidden}.clientProposalDetail_1RPiX .openedMemoModal_1ZIIU{margin-left:0}.clientProposalDetailSupportAccount_1Wvb5{margin-top:72px!important}.contentSupportAccount_1bbVJ{height:calc(100% - 185px)!important}.enterActive_1JiBu{-webkit-animation:slideIn_a19GD .3s ease-out;animation:slideIn_a19GD .3s ease-out}@-webkit-keyframes slideIn_a19GD{0%{left:100vw}to{left:0}}@keyframes slideIn_a19GD{0%{left:100vw}to{left:0}}.leaveActive_2Eg-k{-webkit-animation:slideOut_dh99C .3s ease-out;animation:slideOut_dh99C .3s ease-out}@-webkit-keyframes slideOut_dh99C{0%{left:0}to{left:100vw}}@keyframes slideOut_dh99C{0%{left:0}to{left:100vw}}", ""]);
// Exports
exports.locals = {
	"clientProposalDetail": "clientProposalDetail_1RPiX",
	"modal": "modal_3nrH2",
	"content": "content_1CGde",
	"detailContent": "detailContent_2dId5",
	"proposal": "proposal__W0KH",
	"message": "message_1SW7J",
	"openedMemoModal": "openedMemoModal_1ZIIU",
	"clientProposalDetailSupportAccount": "clientProposalDetailSupportAccount_1Wvb5",
	"contentSupportAccount": "contentSupportAccount_1bbVJ",
	"enterActive": "enterActive_1JiBu",
	"slideIn": "slideIn_a19GD",
	"leaveActive": "leaveActive_2Eg-k",
	"slideOut": "slideOut_dh99C"
};
module.exports = exports;
