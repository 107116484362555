




































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'
import {
  ConnectProposalThreadStore,
  ModalStore,
  TopicStore,
} from '~/utils/store-accessor'
import ConnectModal from '~/components/common/components/ConnectModal.vue'
import ConnectButton from '~/components/common/components/ConnectButton.vue'
import SupportAttention from '~/components/common/profile/partner/SupportAttention/index.vue'

@Component({
  components: {
    ConnectButton,
    ConnectModal,
    SupportAttention,
  },
})
export default class ClientMessageAttentionModal extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  private async agree() {
    const messageService = this.$services.message

    const threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    if (!threadHashId) {
      return
    }

    await messageService.agreeAttentionToClient(threadHashId)

    const topic = await messageService.getTopic(threadHashId)
    TopicStore.setTopic(topic)

    ModalStore.hide(
      'clientMessageAttentionModal' + this.connectProposal.connectProposalHashId
    )
  }
}
