export default class CropImage {
  private file!: File
  imgSrc: string | undefined = ''

  constructor(file: File) {
    this.file = file
  }

  public async getImage(): Promise<string | ArrayBuffer | undefined | null> {
    if (!this.file.type.includes('image/')) {
      alert('画像のみアップロードできます。')
      return
    }

    if (typeof FileReader !== 'function') {
      alert('Sorry, FileReader API not supported')
    }

    return await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        resolve(event?.target?.result)
      }
      reader.readAsDataURL(this.file)
    })
  }
}
