// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".member_support_achievements_1F_c0{margin-top:30px}.member_support_achievements_1F_c0 .empty_3F8CB{padding:40px 20px;display:flex;flex-direction:column;align-items:center;width:100%;background:#f5f7f8;border-radius:4px}.member_support_achievements_1F_c0 .empty_3F8CB .title_gYeYL{font-size:16px;font-weight:600;letter-spacing:0;color:#2c3846}.member_support_achievements_1F_c0 .empty_3F8CB .description_2IDXN{margin-top:20px;font-size:14px;letter-spacing:0;color:#2c3846}.member_support_achievements_1F_c0 .empty_3F8CB a{margin-top:20px;display:flex;align-items:center;justify-content:center;width:240px;height:40px;background:transparent linear-gradient(261deg,#00a7a7,#00a1c7 49%,#0065cb);border-radius:30px;text-decoration:none;font-size:14px;letter-spacing:0;color:#fff}.member_support_achievements_1F_c0 .empty_3F8CB a:hover{background:transparent linear-gradient(261deg,#04c9c9,#03bae5 49%,#0475e6)}.achievements_2KXpE{margin-top:30px;display:flex;flex-direction:column;grid-gap:40px;gap:40px}.achievements_2KXpE .achievement_3WR05 h3{font-size:14px;line-height:14px;font-weight:600;letter-spacing:0;color:#2c3846}.achievements_2KXpE .achievement_3WR05 .table_301g6{margin-top:20px;display:flex;flex-direction:column;width:100%;font-size:14px;line-height:26px;letter-spacing:0;color:#2c3846;border-top:1px solid #ccc;border-left:1px solid #ccc;border-right:1px solid #ccc}.achievements_2KXpE .achievement_3WR05 .table_301g6 .row_ZL3ED{display:flex;border-bottom:1px solid #ccc}.achievements_2KXpE .achievement_3WR05 .table_301g6 .row_ZL3ED .title_gYeYL{padding:14px 0 14px 16px;width:197px;background:#f5f7f8;font-weight:600}.achievements_2KXpE .achievement_3WR05 .table_301g6 .row_ZL3ED .text_1i9wG{word-wrap:anywhere;white-space:pre-line;padding:14px 0 14px 16px;width:100%}", ""]);
// Exports
exports.locals = {
	"member_support_achievements": "member_support_achievements_1F_c0",
	"empty": "empty_3F8CB",
	"title": "title_gYeYL",
	"description": "description_2IDXN",
	"achievements": "achievements_2KXpE",
	"achievement": "achievement_3WR05",
	"table": "table_301g6",
	"row": "row_ZL3ED",
	"text": "text_1i9wG"
};
module.exports = exports;
