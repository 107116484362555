











































































































































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import WrapperIcon from '~/components/WrapperIcon.vue'
import { ClientEmailBot } from '~/components'
import {
  ConnectCompanyProfilesStore,
  QuotationIsFirstApproveRequestStore,
} from '~/utils/store-accessor'
import ClientInquiryModal from '~/components/Client/ClientInquiryModal.vue'
import TroubleReportTutorialBox from '~/components/header/trouble-report-tutorial-box.vue'
@Component({
  components: {
    TroubleReportTutorialBox,
    ClientInquiryModal,
    ClientEmailBot,
    WrapperIcon,
  },
})
export default class HeaderClient extends Vue {
  private showHeader: boolean = true
  private isOpenedUserMenu: boolean = false
  private isOpenedHelpMenu: boolean = false
  private isOpenedInquiryModal: boolean = false

  isScrollTop(): boolean {
    return window.scrollY === 0
  }

  private isCurrentPath(path: string): boolean {
    return this.$route.path === path
  }

  private contains(paths: string[]): boolean {
    return (
      paths.filter((path: string) => {
        return this.$route.path.includes(path)
      }).length > 0
    )
  }

  private headerAppear(event: Event, element: HTMLDivElement) {
    this.showHeader = this.isScrollTop()
    this.isOpenedHelpMenu = false
    this.isOpenedUserMenu = false
  }

  private async logout() {
    await this.$services.auth.logout()
    this.$services.log.clearAccessLogInterval()
    await this.$router.push('/')

    this.clearStores()
  }

  private clearStores() {
    ConnectCompanyProfilesStore.set([])
  }

  private openInquiryModal() {
    this.isOpenedInquiryModal = true
  }

  private closeInquiryModal() {
    console.log('closeInquiryModal')
    this.isOpenedInquiryModal = false
  }

  private closeTroubleReportTutorial() {
    QuotationIsFirstApproveRequestStore.setStatus(false)
  }

  private get showTroubleReportTutorial(): boolean {
    console.log(QuotationIsFirstApproveRequestStore.getStatus)
    return QuotationIsFirstApproveRequestStore.getStatus
  }
}
