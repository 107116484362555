











































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import {
  IConnectCompanySupportAchievement,
  IConnectSupportAchievementCategoryList,
} from '~/Interfaces'

@Component
export default class SupportAchievementModal extends Vue {
  @Prop()
  private categoryList!: IConnectSupportAchievementCategoryList[]

  @Prop()
  private achievement!: IConnectCompanySupportAchievement | null

  get isVisible() {
    return !!this.achievement
  }

  get term() {
    if (!this.achievement) {
      return ''
    }

    return (
      this.ym(this.achievement.startDate) +
      '～' +
      this.ym(this.achievement.endDate) +
      (this.achievement.isOngoing ? '現在' : '')
    )
  }

  ym(date: string | null) {
    if (date === null) {
      return ''
    }

    return this.$services.day.getYmJa(date)
  }

  categoryName(categoryId: number): string {
    const category = this.categoryList.find(
      (category: IConnectSupportAchievementCategoryList) => {
        return category.id === categoryId
      }
    )

    if (!category) {
      return ''
    }

    return category.text
  }

  private onClose() {
    this.achievement = null
  }
}
