// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_bBtV7{position:relative;transition:all .1s ease-out;padding:20px;cursor:pointer;display:flex;justify-content:space-between}.container_bBtV7.active_1DO_s,.container_bBtV7:hover{background-color:#fafafa}.container_bBtV7.active_1DO_s .button_wrapper_14xPX,.container_bBtV7:hover .button_wrapper_14xPX{opacity:1}.container_bBtV7 .content_1Z_MN{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.container_bBtV7 .content_1Z_MN .title_3qhrM{display:flex;align-items:center;grid-gap:10px;gap:10px}.container_bBtV7 .content_1Z_MN .title_3qhrM .icon_7wH_f{display:flex;align-items:center;justify-content:center;width:22px;height:22px}.container_bBtV7 .content_1Z_MN .title_3qhrM span{font-size:13px;line-height:13px;font-weight:600;color:#555}.container_bBtV7 .content_1Z_MN .items_2Swow{display:flex;align-items:center;grid-gap:10px;gap:10px;font-size:13px;font-weight:400;font-family:\"Hiragino Kaku Gothic Pro\";color:#454545}.container_bBtV7 .button_wrapper_14xPX{transition:all .1s ease-out;opacity:0}.container_bBtV7 .button_wrapper_14xPX,.container_bBtV7 .button_wrapper_14xPX .button_3H5VV{display:flex;align-items:center}.container_bBtV7 .button_wrapper_14xPX .button_3H5VV span{font-size:12px;font-weight:400;font-family:\"Hiragino Kaku Gothic Pro\";color:#555}", ""]);
// Exports
exports.locals = {
	"container": "container_bBtV7",
	"active": "active_1DO_s",
	"button_wrapper": "button_wrapper_14xPX",
	"content": "content_1Z_MN",
	"title": "title_3qhrM",
	"icon": "icon_7wH_f",
	"items": "items_2Swow",
	"button": "button_3H5VV"
};
module.exports = exports;
