

















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IConnectCompanySupport } from '~/Interfaces'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: {
    ConnectIcon,
  },
})
export default class PartnerProfileSupportItems extends Vue {
  @Prop()
  private title!: string

  @Prop()
  private list!: IConnectCompanySupport[]

  private AREA_LIST = [
    '1都3県',
    '北海道',
    '東北',
    '関東',
    '中部',
    '近畿',
    '中国',
    '四国',
    '九州',
  ]
}
