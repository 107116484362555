import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d7c75d5c&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&module=s&lang=scss&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClientProductIdIcon: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProductIdIcon.vue').default,ThreadMessageAttachment: require('/var/www/kakutoku_connect/nuxt/src/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalMessage/components/ThreadMessage/components/ThreadMessageAttachment/index.vue').default})
