






import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ConnectFooter extends Vue {
  private year: number

  constructor() {
    super()

    const date = new Date()
    this.year = date.getFullYear()
  }
}
