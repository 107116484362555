






import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class ClientSubTitle extends Vue {
  @Prop()
  private title!: string
}
