// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".profile_strong_point_2Oew1{display:flex;align-items:center;grid-gap:20px;gap:20px;width:100%}.profile_strong_point_2Oew1 .heading_17tFq{display:flex;align-items:center;grid-gap:2px;gap:2px}.profile_strong_point_2Oew1 .heading_17tFq img{width:20px;height:20px}.profile_strong_point_2Oew1 .heading_17tFq span{white-space:nowrap;color:grey;color:var(--Text-50,grey);font-family:Hiragino Kaku Gothic Pro;font-size:13px;font-style:normal;font-weight:400;line-height:normal}.profile_strong_point_2Oew1 .strong_points_3Ltgt{width:100%}.profile_strong_point_2Oew1 .strong_points_3Ltgt,.profile_strong_point_2Oew1 .strong_points_3Ltgt .strong_point_2ztvE{display:flex;align-items:center;grid-gap:7px;gap:7px}.profile_strong_point_2Oew1 .strong_points_3Ltgt .strong_point_2ztvE span{overflow:hidden;white-space:nowrap;max-width:150px;color:#555;color:var(--Text-60,#555);text-overflow:ellipsis;font-family:Hiragino Kaku Gothic Pro;font-size:12px;font-style:normal;font-weight:600;line-height:normal}.profile_strong_point_2Oew1 .strong_points_3Ltgt .strong_point_2ztvE .bullet_2hMqV{display:flex;align-items:center;justify-content:center;border-radius:100px;background:#63615f;background:var(--Grey-90,#63615f);width:4px;height:4px}.profile_strong_point_2Oew1 .ellipsis_2CdUL{color:grey;color:var(--Text-50,grey);font-family:Hiragino Kaku Gothic Pro;font-size:16px;font-style:normal;font-weight:600;line-height:normal}", ""]);
// Exports
exports.locals = {
	"profile_strong_point": "profile_strong_point_2Oew1",
	"heading": "heading_17tFq",
	"strong_points": "strong_points_3Ltgt",
	"strong_point": "strong_point_2ztvE",
	"bullet": "bullet_2hMqV",
	"ellipsis": "ellipsis_2CdUL"
};
module.exports = exports;
