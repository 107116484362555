import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IConnectTeamWithMemberWrapper from '../Interfaces/connectTeam/IConnectTeamWithMemberWrapper'
import IConnectCompany from '../Interfaces/connectCompany/IConnectCompany'

@Module({ stateFactory: true, namespaced: true, name: 'ConnectCompanyDefine' })
export default class ConnectCompanyDefine extends VuexModule {
  connectCompany: IConnectCompany | undefined = undefined

  @Mutation
  setConnectCompany(connectCompany: IConnectCompany | undefined) {
    if (connectCompany === undefined) {
      return
    }

    this.connectCompany = connectCompany
  }

  get getConnectCompany(): IConnectCompany | undefined {
    return this.connectCompany
  }
}
