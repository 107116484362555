import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectClientContact from '~/Interfaces/ConnectClientContact/IConnectClientContact'
import ConnectClientContact from '~/components/Client/ConnectClientContact'

export default class ClientContactService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   *
   */
  async find(): Promise<IConnectClientContact> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get('api/client/connect_client/contact')

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return {}
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  /**
   *
   * @param connectClientContact
   */
  async update(connectClientContact: ConnectClientContact): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/client/connect_client/contact/update',
        {
          data: connectClientContact.toData(),
        }
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
