// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".feature_1BrBD{flex-direction:column}.feature_1BrBD,.feature_1BrBD .title_2AM7C{display:flex;grid-gap:10px;gap:10px}.feature_1BrBD .title_2AM7C{align-items:center;height:30px}.feature_1BrBD .title_2AM7C .order_KwCVm{width:30px;border-right:1px solid #63615f;font-size:20px;color:#3379b7}.feature_1BrBD .title_2AM7C .order_KwCVm,.feature_1BrBD .title_2AM7C .text_3s3B_{font-family:\"Hiragino Kaku Gothic Pro\";font-weight:600}.feature_1BrBD .title_2AM7C .text_3s3B_{font-size:14px;color:#555}.feature_1BrBD .description_uxxJ0{font-family:\"Hiragino Kaku Gothic Pro\";font-size:14px;font-weight:400;color:#454545}", ""]);
// Exports
exports.locals = {
	"feature": "feature_1BrBD",
	"title": "title_2AM7C",
	"order": "order_KwCVm",
	"text": "text_3s3B_",
	"description": "description_uxxJ0"
};
module.exports = exports;
