// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".product_content_button_2_y52{text-decoration:none;padding:10px 15px;display:flex;justify-content:center;grid-gap:10px;gap:10px;height:40px;border-radius:2px;border:1px solid #d6d5d4;border:1px solid var(--grey-40,#d6d5d4);background:#fff;background:var(--White,#fff)}.product_content_button_2_y52:hover{cursor:pointer;border:1px solid #5f9cd3;border:1px solid var(--primary-50,#5f9cd3);background:#e3eef7;background:var(--primary-20,#e3eef7)}.product_content_button_2_y52 span{word-break:keep-all;overflow:hidden;color:#555;color:var(--text-60,#555);text-overflow:ellipsis;font-family:Hiragino Kaku Gothic Pro;font-size:14px;font-style:normal;font-weight:600;line-height:normal}", ""]);
// Exports
exports.locals = {
	"product_content_button": "product_content_button_2_y52"
};
module.exports = exports;
