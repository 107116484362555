// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_1Vulv{position:relative;width:36px;height:36px}.container_1Vulv,.container_1Vulv .unread_icon_1nKvb{display:flex;justify-content:center;align-items:center}.container_1Vulv .unread_icon_1nKvb{position:absolute;right:2px;top:5px;width:10px;height:10px;border-radius:100px;border-radius:var(--Max-width-100,100px);border:2px solid #fff;border:2px solid var(--Background-White,#fff);background:#e43a63;background:var(--Red-60,#e43a63);box-sizing:content-box}", ""]);
// Exports
exports.locals = {
	"container": "container_1Vulv",
	"unread_icon": "unread_icon_1nKvb"
};
module.exports = exports;
