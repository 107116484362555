








import { Component, Prop, Vue, Emit } from 'nuxt-property-decorator'
import IProduct from '~/Interfaces/IProduct'

@Component({
  components: {},
})
export default class ProductContentButton extends Vue {
  @Prop()
  private product!: IProduct

  get to(): string {
    if (this.product.version === 1) {
      return '/client/product/' + this.product.connectProductHashId + '/old'
    }

    return '/client/product/' + this.product.connectProductHashId
  }
}
