// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".proposalStatus_2GLa2{padding:0 10px;display:flex;justify-content:center;align-items:center;border-radius:2px;width:100px;height:28px;color:#fff;color:var(--White,var(--background-white,#fff));font-family:Hiragino Kaku Gothic Pro;font-size:14px;line-height:14px;font-style:normal;font-weight:600}.proposalStatus_2GLa2.waiting_3kv2B{background:#de9b36;background:var(--secondary-60,#de9b36)}.proposalStatus_2GLa2.negotiating_RXo3L{background:#ad4e1e}.proposalStatus_2GLa2.pending_23Tmh{background:#969492;background:var(--grey-70,#969492)}.proposalStatus_2GLa2.rejected_2J2HX{background:#494746;background:var(--grey-100,#494746)}.proposalStatus_2GLa2.accepted_24ipW{border:1px solid #17aa7e;border:1px solid var(--green-70,#17aa7e);background:#d6f5eb;background:var(--green-20,#d6f5eb);color:#128260;color:var(--green-80,#128260)}.proposalStatus_2GLa2.finished_2aA4S{border:1px solid #969492;border:1px solid var(--grey-70,#969492);background:#d6d5d4;background:var(--grey-40,#d6d5d4);color:#63615f;color:var(--grey-90,#63615f)}", ""]);
// Exports
exports.locals = {
	"proposalStatus": "proposalStatus_2GLa2",
	"waiting": "waiting_3kv2B",
	"negotiating": "negotiating_RXo3L",
	"pending": "pending_23Tmh",
	"rejected": "rejected_2J2HX",
	"accepted": "accepted_24ipW",
	"finished": "finished_2aA4S"
};
module.exports = exports;
