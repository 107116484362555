// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3OmXg{display:flex;justify-content:flex-start;align-items:center;grid-gap:0 20px;gap:0 20px}.title_Xl1ll{font-weight:600;font-size:24px;line-height:130%;color:#2f5980}.button_1t52O,.title_Xl1ll{padding:0;margin:0}.button_1t52O{background:none;border:none;cursor:pointer;width:32px;height:32px;border-radius:50%}.icon_178I7{color:#7ca8d5;font-size:18px}.required_1Lk1H{display:flex;align-items:center;justify-content:center;background:#2f5980;border-radius:4px;width:34px;height:22px;font-size:12px;font-weight:700;line-height:18px;color:#fff}", ""]);
// Exports
exports.locals = {
	"container": "container_3OmXg",
	"title": "title_Xl1ll",
	"button": "button_1t52O",
	"icon": "icon_178I7",
	"required": "required_1Lk1H"
};
module.exports = exports;
