


























import { Component, Prop, Vue } from 'nuxt-property-decorator'

import ThreadMessageAttachment from '../ThreadMessageAttachment/index.vue'
import IThreadDetail from '~/Interfaces/Message/IThreadDetail'
import { TopicStore } from '~/utils/store-accessor'
@Component({
  components: { ThreadMessageAttachment },
})
export default class ThreadMessageSelf extends Vue {
  @Prop()
  private threadDetail!: IThreadDetail

  get isConnect(): boolean {
    return TopicStore.isConnect
  }

  mounted() {}
}
