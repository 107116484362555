






















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class ClientTitle extends Vue {
  @Prop()
  private title!: string

  @Prop()
  private enableBack!: boolean

  @Prop()
  private required!: boolean

  @Prop()
  private backUrl!: string

  @Prop({ default: false })
  private noPadding!: boolean

  private onClick() {
    if (this.backUrl) {
      this.$router.push(this.backUrl)
    } else {
      this.$router.back()
    }
  }
}
