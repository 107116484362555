// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2zRYe{border-radius:2px;background:#f3f2f2;background:var(--grey-20,#f3f2f2);padding:10px;grid-gap:0 5px;gap:0 5px}.container_2zRYe,.icon_wrapper_2MAOL{display:flex;justify-content:center;align-items:center}.icon_wrapper_2MAOL{width:24px;height:24px}.icon_wrapper_2MAOL .icon_3UR21{color:#7d7a78;font-size:15px}.label_2X2Y0{color:#2c3846;color:var(--Text-Black,#2c3846);text-align:center;font-family:Hiragino Kaku Gothic Pro;font-size:14px;font-style:normal;font-weight:400;line-height:normal}.label_2X2Y0 .supplement_3xurn{font-size:12px;line-height:18px}.label_2X2Y0.activity_type_19Nr-{color:#555;color:var(--Text-60,#555);text-align:center;font-family:Hiragino Kaku Gothic Pro;font-size:13px;font-style:normal;font-weight:600;line-height:normal}.empty_3xyOk{font-weight:400;font-size:14px;line-height:21px;display:flex;align-items:center;color:#707070}", ""]);
// Exports
exports.locals = {
	"container": "container_2zRYe",
	"icon_wrapper": "icon_wrapper_2MAOL",
	"icon": "icon_3UR21",
	"label": "label_2X2Y0",
	"supplement": "supplement_3xurn",
	"activity_type": "activity_type_19Nr-",
	"empty": "empty_3xyOk"
};
module.exports = exports;
