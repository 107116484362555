// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title_D4wpU{color:#555;font-weight:600;font-size:16px;line-height:130%;border-bottom:1px solid #c8c7c6;padding:0 0 10px;margin-bottom:30px}", ""]);
// Exports
exports.locals = {
	"title": "title_D4wpU"
};
module.exports = exports;
