
















import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
@Component({
  components: {},
})
export default class TroubleReportTutorialBox extends Vue {
  @Emit()
  private closeTroubleReportTutorial() {}
}
